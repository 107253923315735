const DASHBOARD_ROUTE = '/dashboard';

// eslint-disable-next-line no-undef
angular.module('mTransportApp').controller('TvController', [
    '$rootScope',
    '$scope',
    '$controller',
    '$translate',
    '$interval',
    '$timeout',
    'sortingTools',
    '$location',
    '$httpParamSerializer',
    function ($rootScope, $scope, $controller, $translate, $interval, $timeout, sortingTools, $location, $httpParamSerializer) {
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('FavouriteController', { $scope: $scope }));

        // Data are not available before that date.
        // eslint-disable-next-line no-undef
        const minDate = moment().set({
            year: 2017,
            month: 8,
            date: 18,
            hour: 23,
            minutes: 59,
            second: 59,
        });

        /**
         * Initialize default data and fetch the list to display for the first time
         */
        this.$onInit = function () {
            $scope.currentProgress = 100; // The loading circle is at 100% by default
            const REFRESH_INTERVAL = 1000 * 45; // Refresh trigger every 45 seconds
            $scope.filterSections = []; // Empty, will be generated later after API call
            $scope.onlyFavorite = false; // By default, we display all data. If true, will only only the favourites trips

            $scope.showDriver = false;
            $scope.showCarrier = false;

            $scope.tickClock();
            $interval($scope.tickClock, 1000); // start the clock and refresh it each second

            const searchParams = $location.search();
            $scope.initDisplayedView(searchParams.view);

            this.showTooltip = true;

            $scope.areWeDisplayingDefaultView = true;
            $scope.isShowingScheduleForToday = false;

            $scope.searchValue = '';
            $scope.loading = true;

            $scope.activatedTrip = null;
            $scope.activatedTripText = null;
            $scope.showActivateTripModal = false;
            $scope.activatingTrip = false;

            $scope.initSelectedDate();
            $timeout(function () {
                $scope.initSelectedDate();
            }, 0);

            getListTrips();

            // Refresh data each 20sec
            if ($scope.isToday($scope.selectedDate)) {
                this.refreshInterval = $interval(() => {
                    if (!$scope.loading && !$scope.isShowingScheduleForToday) {
                        $scope.currentProgress += 500;
                        if ($scope.currentProgress >= REFRESH_INTERVAL) {
                            $scope.currentProgress = 0;
                            $scope.isItAM = isAM();

                            // If times passes from AM to PM and the user has never changed views (clicked on the view toggle)
                            // We automatically change views to the right one
                            const currentTime = $scope.isItAM ? $translate.instant('am') : $translate.instant('pm');
                            if (currentTime !== $scope.viewDisplayed && $scope.areWeDisplayingDefaultView) {
                                $timeout(
                                    () =>
                                        $scope.isItAM
                                            ? // eslint-disable-next-line no-undef
                                              document.getElementById('btn-group-toggle__button-am').click()
                                            : // eslint-disable-next-line no-undef
                                              document.getElementById('btn-group-toggle__button-pm').click(),
                                    0
                                );
                            }
                            refreshData();
                        }

                        // Setting the progress percentage for the loader
                        $scope.loaderProgress = 100 - ($scope.currentProgress * 100) / REFRESH_INTERVAL;
                    }
                }, 500);
            }
        };

        $scope.$on('$destroy', () => {
            // Cancel existing intervals to make sure they don't continue after we change the page
            $interval.cancel(this.refreshInterval);
        });

        $scope.initDisplayedView = (currentView) => {
            if (currentView != null && ['all', 'am', 'pm'].includes(currentView)) {
                // Use the view param for initialization if it's valid
                $scope.isItAM = currentView === 'am';
                if (currentView === 'am') {
                    $scope.buttonsGroupSelectedOption = 0;
                } else if (currentView === 'pm') {
                    $scope.buttonsGroupSelectedOption = 1;
                } else {
                    $scope.buttonsGroupSelectedOption = 2;
                }
                $scope.viewDisplayed = currentView === 'all' ? '' : $translate.instant(currentView);
            } else {
                // Checks if it is AM or not, and chooses view to toggle and to display
                $scope.isItAM = isAM();
                if ($scope.isItAM) {
                    $scope.buttonsGroupSelectedOption = 0;
                    $scope.viewDisplayed = $translate.instant('am');
                } else {
                    $scope.buttonsGroupSelectedOption = 1;
                    $scope.viewDisplayed = $translate.instant('pm');
                }
            }
        };

        // on buttonsGroupSelectedOption changed, update isItAM
        $scope.$watch('buttonsGroupSelectedOption', function (newValue) {
            $scope.isItAM = newValue === 0;
        });

        $scope.initSelectedDate = () => {
            // eslint-disable-next-line no-undef
            const date = moment($scope.getDateFromURL());

            // Defining the date to use for data fetching
            // eslint-disable-next-line no-undef
            $scope.selectedDate = date.isValid() ? date.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
            // eslint-disable-next-line no-undef
            $scope.selectedDateString = $scope.convertToReadableDate($scope.selectedDate, 'long');

            // Called here for the first time to fill favorites toggle with text, then at getListTrips() for page refresh
            $scope.isToday($scope.selectedDate) ? changeFavoritesToggleWithAMPM() : changeFavoritesToggleAllOnly();

            // Create the date pickers through jQuery. When we'll refactor to more recent framework, use proper libs
            // eslint-disable-next-line no-undef
            $('#tv__calendar-button').daterangepicker(
                {
                    singleDatePicker: true,
                    opens: 'center',
                    autoApply: true,
                    minDate: minDate,
                    // eslint-disable-next-line no-undef
                    startDate: moment($scope.selectedDate),
                    locale: {
                        format: 'LL',
                    },
                },
                function (start) {
                    updateSelectedDate(start);
                }
            ); // Refresh the date in the calendar when there is a change in URL date
        };

        /**
         * Called when the selected date today, to reset the favorites toggle to AM/PM/All/Favorites options
         */
        function changeFavoritesToggleWithAMPM() {
            $scope.buttonsGroupOptions = [
                {
                    name: $translate.instant('am'),
                    toggleId: 'btn-group-toggle__button-am',
                    onClick: () => {
                        $scope.areWeDisplayingDefaultView = false;
                        if ($scope.viewDisplayed !== $translate.instant('am')) {
                            $scope.viewDisplayed = $translate.instant('am');
                            getListTrips();
                        }
                        $scope.updateOnlyFavorite(false);
                    },
                },
                {
                    name: $translate.instant('pm'),
                    toggleId: 'btn-group-toggle__button-pm',
                    onClick: () => {
                        $scope.areWeDisplayingDefaultView = false;
                        if ($scope.viewDisplayed !== $translate.instant('pm')) {
                            $scope.viewDisplayed = $translate.instant('pm');
                            getListTrips();
                        }
                        $scope.updateOnlyFavorite(false);
                    },
                },
                {
                    name: $translate.instant('all'),
                    onClick: () => {
                        $scope.areWeDisplayingDefaultView = false;
                        if ($scope.viewDisplayed) {
                            $scope.viewDisplayed = '';
                            getListTrips();
                        }
                        $scope.updateOnlyFavorite(false);
                    },
                },
                {
                    name: `${$translate.instant('favourites')} <i class="fa fa-star status-yellow"></i>`,
                    onClick: () => {
                        $scope.areWeDisplayingDefaultView = false;
                        $scope.updateOnlyFavorite(true);
                    },
                },
            ];
        }

        /**
         * Called when the selected date is not today, to reset the favorites toggle to All/Favorites options only
         * Resets the view displayed too
         */
        function changeFavoritesToggleAllOnly() {
            $scope.buttonsGroupOptions = [
                {
                    name: $translate.instant('all'),
                    onClick: () => {
                        $scope.updateOnlyFavorite(false);
                    },
                },
                {
                    name: `${$translate.instant('favourites')} <i class="fa fa-star status-yellow"></i>`,
                    onClick: () => {
                        $scope.updateOnlyFavorite(true);
                    },
                },
            ];
            $scope.buttonsGroupSelectedOption = 0;
            $scope.viewDisplayed = '';
        }

        /**
         * Order an array of trips based on departure time
         * @param {Array} trip - Array of trip objects
         * @return {Array} - Array of ordered trip objects by departure time
         */
        function orderTripsByDeparture(trip) {
            if (trip.length === 0) return [];
            return trip.sort((a, b) => {
                if (new Date(a?.schedule).getTime() === new Date(b?.schedule).getTime()) {
                    return a?.route?.bus?.number - b?.route?.bus?.number;
                } else {
                    return new Date(a?.schedule) - new Date(b?.schedule);
                }
            });
        }

        /**
         * Order an array of trips based on bus number
         * Bus numbers that are numeric or that start with a number are placed after
         * bus numbers that start with a letter
         * The trips are ordered by alphanumeric order for those that start with a letter,
         * or by numeric ascending order for those that are numeric or that start with
         * a number (we compare the first integer in the string)
         * @param {Array} trips - Array of trips objects
         * @return {Array} - Array of ordered trip objects by bus number
         */
        function orderTripsByBusNumber(trips) {
            if (trips.length === 0) return [];

            const startsWithNumberRegex = /^\d/;
            return trips.sort((a, b) => {
                const aBusNumber = a?.route?.bus?.number;
                const bBusNumber = b?.route?.bus?.number;

                if (aBusNumber.localeCompare(bBusNumber) === 0) {
                    return new Date(a?.schedule) - new Date(b?.schedule);
                } else {
                    const aStartsWithNumber = startsWithNumberRegex.test(aBusNumber);
                    const bStartsWithNumber = startsWithNumberRegex.test(bBusNumber);

                    if (aStartsWithNumber && bStartsWithNumber) {
                        return parseInt(aBusNumber) - parseInt(bBusNumber);
                    } else if (!aStartsWithNumber && !bStartsWithNumber) {
                        return aBusNumber.localeCompare(bBusNumber);
                    } else if (aStartsWithNumber && !bStartsWithNumber) {
                        return 1; // bus numbers beginning with a letter placed first
                    } else {
                        return -1;
                    }
                }
            });
        }

        /**
         * Get the list of the trips for the selected date
         */
        const getListTrips = async () => {
            $scope.loading = true;

            let timeToFetch = '';
            if ($scope.viewDisplayed == $translate.instant('am')) timeToFetch = 'am';
            else if ($scope.viewDisplayed == $translate.instant('pm')) timeToFetch = 'pm';

            await $scope.requestTVTrips($scope.selectedDate, timeToFetch, $scope.isShowingScheduleForToday).then(
                (data) => {
                    // Make also deep copy to keep default data (for filtering)
                    const { am, pm, amLate, pmLate, amUnused, pmUnused, amCanceled, pmCanceled } = data.planned;
                    $scope.plannedTrips = {
                        am: orderTripsByDeparture(am),
                        pm: orderTripsByDeparture(pm),
                        amLate: amLate,
                        pmLate: pmLate,
                        amUnused: orderTripsByDeparture(amUnused),
                        pmUnused: orderTripsByDeparture(pmUnused),
                        amCanceled: orderTripsByDeparture(amCanceled),
                        pmCanceled: orderTripsByDeparture(pmCanceled),
                    };

                    $scope.plannedTrips = categorizePlannedTripsByDeparture($scope.plannedTrips);

                    $scope.defaultPlannedTrips = angular.copy($scope.plannedTrips);

                    const { amWontComplete, critical, moderated, ok, pmWontComplete, unknown } = data.inProgress;
                    $scope.inProgressTrips = {
                        amWontComplete: orderTripsByBusNumber(amWontComplete),
                        critical: critical,
                        moderated: moderated,
                        ok: ok,
                        pmWontComplete: orderTripsByBusNumber(pmWontComplete),
                        unknown: unknown,
                    };

                    $scope.defaultInProgressTrips = angular.copy($scope.inProgressTrips);

                    $scope.completedTrips = {
                        am: orderTripsByBusNumber(data.completed.am),
                        pm: orderTripsByBusNumber(data.completed.pm),
                    };
                    $scope.defaultCompletedTrips = angular.copy($scope.completedTrips);

                    // If selected date is in the future
                    if ($scope.isFuture($scope.selectedDate)) {
                        $scope.scheduledTrips = {
                            planned: [...am, ...pm],
                            unplanned: data.unplanned,
                            cancelled: [...amCanceled, ...pmCanceled],
                        };
                    }

                    // If date is today and we are showing the schedule
                    if ($scope.isShowingScheduleForToday) {
                        $scope.scheduledTrips = {
                            planned: [
                                ...am,
                                ...pm,
                                ...amLate,
                                ...pmLate,
                                ...amUnused,
                                ...pmUnused,
                                ...amWontComplete,
                                ...critical,
                                ...moderated,
                                ...ok,
                                ...pmWontComplete,
                                ...unknown,
                                ...data.completed.am,
                                ...data.completed.pm,
                            ],
                            unplanned: data.unplanned,
                            cancelled: [...amCanceled, ...pmCanceled],
                        };
                    }

                    if ($scope.isShowingScheduleOrIsFuture()) {
                        // Format and sort scheduled trips
                        ['planned', 'unplanned', 'cancelled'].forEach((context) => {
                            $scope.formatScheduledTrips($scope.scheduledTrips[context], context);
                            $scope.sortScheduledTrips(null, context);
                        });

                        // Create deep copies of trips
                        $scope.defaultScheduledPlannedTrips = angular.copy($scope.scheduledTrips.planned);
                        $scope.defaultScheduledUnplannedTrips = angular.copy($scope.scheduledTrips.unplanned);
                        $scope.defaultScheduledCancelledTrips = angular.copy($scope.scheduledTrips.cancelled);
                    }

                    if (['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                        $scope.showDriver = true;
                    }
                    if (['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole)) {
                        $scope.showCarrier = true;
                    }

                    countTripsForDisplay();
                    resetFilterOptions();
                    $scope.applyFilters();

                    $scope.$apply();
                    // eslint-disable-next-line no-undef
                    $('#search').focus();
                    $scope.loading = false;
                },
                (error) => {
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
        };

        /**
         * Define the combined cancelled trips array based on the am and pm cancelled trips
         */
        $scope.updateCombinedCancelledTrips = function () {
            $scope.combinedCancelledTrips = $scope.plannedTrips ? [...$scope.plannedTrips.amCanceled, ...$scope.plannedTrips.pmCanceled] : [];
        };

        /**
         * Update the combined cancelled trips array
         */
        $scope.$watchGroup(['plannedTrips.amCanceled', 'plannedTrips.pmCanceled'], function () {
            $scope.updateCombinedCancelledTrips();
        });

        /**
         * Categorize planned trips by scheduled departure
         * Trips that are scheduled to depart in the next 30 minutes are put in the trips.nextThirtyMinutes array
         * Trips that are one minute late or more are put in the trips.amLate or trips.pmLate array
         * @param {Object} trips
         * @return {Object}
         */
        const categorizePlannedTripsByDeparture = (trips) => {
            // eslint-disable-next-line no-undef
            const now = moment();
            // eslint-disable-next-line no-undef
            const thirtyMinutesFromNow = moment().add(30, 'minutes');
            trips.nextThirtyMinutes = [];

            const categorizeTrip = (trip, lateTripsArray, onTimeTripsArray) => {
                // eslint-disable-next-line no-undef
                const tripStartDateTime = moment(trip.schedule);
                // eslint-disable-next-line no-undef
                const oneMinuteAfterStart = moment(trip.schedule).add(1, 'minute');

                if (now.isAfter(oneMinuteAfterStart)) {
                    // The trip is late by more than a minute
                    lateTripsArray.push(trip);
                } else if (tripStartDateTime.isBetween(now, thirtyMinutesFromNow, 'minute', '[]')) {
                    // The trip is within the next 30 minutes (inclusive of start and end) and not yet late
                    trips.nextThirtyMinutes.push(trip);
                } else {
                    // The trip is neither in the next 30 minutes nor late
                    onTimeTripsArray.push(trip);
                }
            };

            const amTrips = [];
            const pmTrips = [];
            trips.am.forEach((trip) => {
                categorizeTrip(trip, trips.amLate, amTrips);
            });

            trips.pm.forEach((trip) => {
                categorizeTrip(trip, trips.pmLate, pmTrips);
            });

            // Reassign sorted trips back to the original object
            trips.am = amTrips;
            trips.pm = pmTrips;

            // Ensure trips are sorted appropriately if needed
            trips.amLate = orderTripsByDeparture(trips.amLate);
            trips.pmLate = orderTripsByDeparture(trips.pmLate);

            return trips;
        };

        /**
         * When the mouse enter the item
         * @param {Object} event
         */
        $scope.mouseEnter = (event) => {
            if (this.showTooltip === true) {
                // eslint-disable-next-line no-undef
                $(event.delegateTarget).tooltip('show');
            }
        };

        // ***************
        // *** REFRESH ***
        // ***************

        /**
         * clear tooltip when a trip is updated.
         */
        const clearTooltips = () => {
            // eslint-disable-next-line no-undef
            $('[data-toggle="tooltip"]').tooltip('hide');
        };

        /**
         * Refresh the list of trips. Different from getListTrips, since we must updates the array and not changing them.
         */
        const refreshData = () => {
            let timeToFetch = '';

            if ($scope.viewDisplayed == $translate.instant('am')) timeToFetch = 'am';
            else if ($scope.viewDisplayed == $translate.instant('pm')) timeToFetch = 'pm';

            $scope.requestTVTrips($scope.selectedDate, timeToFetch).then(
                (data) => {
                    clearTooltips();

                    // Make also deep copy to keep default data (for filtering)
                    data.planned = categorizePlannedTripsByDeparture(data.planned);
                    $scope.defaultPlannedTrips = angular.copy(data.planned);
                    $scope.defaultInProgressTrips = angular.copy(data.inProgress);
                    $scope.defaultCompletedTrips = angular.copy(data.completed);

                    refreshCategory('planned', $scope.plannedTrips, data.planned);
                    refreshCategory('inProgress', $scope.inProgressTrips, data.inProgress);
                    refreshCategory('completed', $scope.completedTrips, data.completed);
                    $timeout(() => {
                        // Waiting for all animation to finish
                        countTripsForDisplay();
                    }, 3000);
                },
                (error) => {
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
        };

        /**
         * Refresh the specified scoped category with the new specified data
         * @param {String} categoryKey - The object key name of the category. Used for animations.
         * @param {Object} scopeCategory - The category in the scope
         * @param {Object} newDataCategory - The same category but with the new data (normally just fetched from the API)
         */
        const refreshCategory = (categoryKey, scopeCategory, newDataCategory) => {
            // For each section in In Progress category
            Object.keys(scopeCategory).forEach((sectionKey) => {
                // Verify if we must remove the trip from this section
                scopeCategory[sectionKey].forEach((scopeTrip) => {
                    const tripIndex = newDataCategory[sectionKey].findIndex((trip) => trip.id === scopeTrip.id);
                    // The trip in the scoped data was not found in the new data: removing it
                    if (tripIndex === -1) {
                        // eslint-disable-next-line no-undef
                        const thumbnail = document.getElementById(`trip_${scopeTrip.id}`);

                        // Finding the new zone (category/section) the trip is going to be and the direction the animation must take.
                        const newZone = findTripCategoryAndSection(scopeTrip);
                        newZone.section = convertTripsSectionName(newZone.section);

                        const currentSectionKey = convertTripsSectionName(sectionKey);
                        // eslint-disable-next-line no-undef
                        const currentSection = document.getElementById(`tv-${categoryKey}.${currentSectionKey}`);
                        // eslint-disable-next-line no-undef
                        const newSection = document.getElementById(`tv-${newZone.category}.${newZone.section}`);
                        const direction = findDirectionToGo(currentSection, newSection);

                        if (direction != null && thumbnail != null) {
                            // Add animation of color blink, if needed, else only lift animation
                            switch (newZone.section) {
                                case 'critical':
                                    thumbnail.classList.add(`trip-dashboard-view--animation-${direction}-critical`);
                                    break;
                                case 'moderated':
                                    thumbnail.classList.add(`trip-dashboard-view--animation-${direction}-moderated`);
                                    break;
                                case 'ok':
                                    thumbnail.classList.add(`trip-dashboard-view--animation-${direction}-ok`);
                                    break;
                                default:
                                    thumbnail.classList.add(`trip-dashboard-view--animation-${direction}`);
                            }
                        }

                        // Waiting for the animation to be finished to remove the trip from the array.
                        $timeout(() => {
                            const tripToRemoveIndex = scopeCategory[sectionKey].findIndex((trip) => trip.id === scopeTrip.id);
                            scopeCategory[sectionKey].splice(tripToRemoveIndex, 1);
                        }, 2000);
                    }
                });

                // For each trip in new data
                newDataCategory[sectionKey].forEach((trip) => {
                    const scopeTripIndex = scopeCategory[sectionKey].findIndex((scopeTrip) => scopeTrip.id === trip.id);
                    // If the trip was found in current scope, updating the trip with new data, else adding new trip in scope
                    if (scopeTripIndex >= 0) {
                        Object.assign(scopeCategory[sectionKey][scopeTripIndex], trip);
                    } else {
                        // Waiting for the "exit" animation to finish
                        $timeout(() => {
                            updateFavouriteForTrip(trip);
                            if (isTripInFilters(trip)) {
                                trip.isFromRefresh = true; // Add flag into trip info so the component knows it was generated from refresh data. Animation will be added in the component template.
                                scopeCategory[sectionKey].push(trip);
                            }
                        }, 2000);
                    }
                });
            });
        };

        /**
         * Show schedule for today page
         */
        $scope.showScheduleForToday = () => {
            changeFavoritesToggleAllOnly();
            $scope.viewDisplayed = '';
            $scope.updateOnlyFavorite(false);
            $scope.isShowingScheduleForToday = true;
            getListTrips();
        };

        /**
         * Go back to the regular dashboard for today
         */
        $scope.backToDashboard = () => {
            changeFavoritesToggleWithAMPM();
            // Going back with an 'all' view
            const searchParams = $location.search();
            $scope.initDisplayedView(searchParams.view);
            $scope.updateOnlyFavorite(false);
            $scope.isShowingScheduleForToday = false;
        };

        /**
         * If the selected date is future or if we are showing today's schedule, false otherwise
         * @return {Boolean}
         */
        $scope.isShowingScheduleOrIsFuture = () => {
            return $scope.isShowingScheduleForToday || $scope.isFuture($scope.selectedDate);
        };

        // ***************
        // *** FILTERS ***
        // ***************

        /**
         * Update the filter of favourite only with the new specified value
         * @param {Boolean} newValue
         */
        $scope.updateOnlyFavorite = (newValue) => {
            $scope.onlyFavorite = newValue;
            $scope.applyFilters();
        };

        /**
         * This function will be given to the filter component to be executed each time the filter options and search input are changing.
         */
        $scope.onFilterChange = () => {
            $scope.applyFilters();
        };

        /**
         * This function will be given to the input of filter component to be executed each time the input value changes.
         * @param {String} searchValue - the string in the search input text
         */
        $scope.onInputChange = (searchValue) => {
            $scope.searchValue = searchValue;
            $scope.applyFilters();
        };

        /**
         * This function will be given to the reset button of the filter component to be executed on reset.
         */
        $scope.onReset = () => {
            $scope.searchValue = '';
            getListTrips();
        };

        /**
         * Apply the filters to the trips array
         * @param {Boolean} resetTrips - Reset trips to their default states before applying filters. Default to true.
         */
        $scope.applyFilters = (resetTrips = true) => {
            // Use deep copy to reset filters
            if (resetTrips) {
                $scope.plannedTrips = angular.copy($scope.defaultPlannedTrips);
                $scope.inProgressTrips = angular.copy($scope.defaultInProgressTrips);
                $scope.completedTrips = angular.copy($scope.defaultCompletedTrips);
                $scope.scheduledTrips = {
                    planned: angular.copy($scope.defaultScheduledPlannedTrips),
                    unplanned: angular.copy($scope.defaultScheduledUnplannedTrips),
                    cancelled: angular.copy($scope.defaultScheduledCancelledTrips),
                };
            }

            updateFavouriteStatus();

            filterTripCategory($scope.plannedTrips);
            filterTripCategory($scope.inProgressTrips);
            filterTripCategory($scope.completedTrips);
            // If the selected date is in the future or if we are showing today's schedule, filter the trips
            if ($scope.isShowingScheduleOrIsFuture()) {
                filterTripCategory($scope.scheduledTrips);
            }
            countTripsForDisplay();

            if (['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole) === false) {
                resetClientsFilterOptions(null, true);
            }
        };

        $scope.$watch(
            function () {
                return $location.search();
            },
            function (values) {
                const path = $location.path();

                if (path === DASHBOARD_ROUTE && $scope.loading === false) {
                    const updatedSerializedQuery = $httpParamSerializer(values);
                    if (updatedSerializedQuery !== $scope.serializedQuery) {
                        $scope.initDisplayedView(values.view);
                        $scope.initSelectedDate();
                        getListTrips();
                    }
                }
            }
        );

        /**
         * Update URL search query
         */
        const updateSearchQuery = () => {
            // Initialize query with "view" and "date"
            const query = {
                view: $scope.viewDisplayed === '' ? 'all' : $scope.viewDisplayed.toLocaleLowerCase(),
                date: $scope.selectedDate,
            };

            if (['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                query.clients = $scope.selectedClients;
            }
            $scope.serializedQuery = $httpParamSerializer(query);
            $location.search(query).replace();
        };

        /**
         * Filter the specified category arrays with the filters
         * @param {Object} category - the trip category (planned, in progress, completed)
         */
        const filterTripCategory = (category) => {
            Object.keys(category).forEach((key) => {
                category[key] = category[key].filter((trip) => isTripInFilters(trip));
            });
        };

        /**
         * Determine if the trip must be displayed or not
         * @param {Object} trip
         * @return {Boolean} True if the trip must be displayed, false if it's filtered out
         */
        const isTripInFilters = (trip) => {
            // AM PM Filter
            if ($scope.viewDisplayed === '') {
                const timeSection = $scope.filterSections.find((section) => section.id === 'time');
                if (
                    timeSection &&
                    ((!timeSection.options.find((option) => option.id === 'am').value && isAM(trip.schedule)) ||
                        (!timeSection.options.find((option) => option.id === 'pm').value && !isAM(trip.schedule)))
                ) {
                    return false;
                }
            }

            // Bus model filter
            const busTypeSection = $scope.filterSections.find((section) => section.id === 'busType');

            if (
                (trip.route.bus?.model == null &&
                    busTypeSection.options.some((option) => option.name === $translate.instant('noBusType') && option.value === true) === false) ||
                (trip.route.bus?.model != null &&
                    busTypeSection.options.some((option) => option.name === $translate.instant(trip.route.bus.model) && option.value === true) ===
                        false)
            ) {
                return false;
            }

            if (['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole)) {
                // Carriers filter
                const carriersSection = $scope.filterSections.find((section) => section.id === 'carriers');
                if (
                    (!Object.hasOwn(trip.route, 'carrier') &&
                        !carriersSection.options.find((option) => option?.name === $translate.instant('noCarrier'))?.value) ||
                    (Object.hasOwn(trip.route, 'carrier') &&
                        !carriersSection.options.find((option) => option.name === trip.route.carrier?.name)?.value)
                ) {
                    return false;
                }
            } else {
                // Client filter
                const clientsSection = $scope.filterSections.find((section) => section.id === 'clients');
                if (
                    (!Object.hasOwn(trip.route.client, 'name') &&
                        !clientsSection.options.find((option) => option?.name === $translate.instant('noClient'))?.value) ||
                    (Object.hasOwn(trip.route.client, 'name') &&
                        !clientsSection.options.find((option) => option.name === trip.route.client?.name)?.value)
                ) {
                    return false;
                }
            }

            // Institutions filter
            const institutionsSection = $scope.filterSections.find((section) => section.id === 'institutions');
            if (
                (countInstitutions(trip.stops) === 0 &&
                    institutionsSection.options.find((option) => option?.name === $translate.instant('noSchool')) &&
                    !institutionsSection.options.find((option) => option?.name === $translate.instant('noSchool')).value) ||
                (countInstitutions(trip.stops) > 0 && countInstitutionInFilters(trip.stops, institutionsSection.options) === 0)
            ) {
                return false;
            }

            // Favourite filter
            if ($scope.onlyFavorite && !trip.isFavourite) {
                return false;
            }

            // Text filter
            const dataToCompareWithSearch = {
                tripBusNumber: trip.route.bus.number,
                tripRouteName: trip.route?.name ?? '',
            };

            if (['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                dataToCompareWithSearch.routeClient = trip.route.client?.name ?? '';
            } else {
                dataToCompareWithSearch.routeCarrier = trip.route.carrier?.name ?? '';
            }

            return $scope.containsSearchValue(dataToCompareWithSearch, $scope.searchValue);
        };

        /**
         * Refresh the filter options
         */
        const resetFilterOptions = () => {
            // Setup base filters.
            $scope.filterSections = [
                ...($scope.viewDisplayed === ''
                    ? [
                          {
                              id: 'time',
                              name: $translate.instant('time'),
                              options: [
                                  {
                                      id: 'am',
                                      name: $translate.instant('AM'),
                                      value: true,
                                  },
                                  {
                                      id: 'pm',
                                      name: $translate.instant('PM'),
                                      value: true,
                                  },
                              ],
                          },
                      ]
                    : []),
                {
                    id: 'busType',
                    name: $translate.instant('busType'),
                    options: [],
                },
                {
                    id: 'carriers',
                    name: $translate.instant('carriers'),
                    options: [],
                },
                {
                    id: 'clients',
                    name: $translate.instant('clients'),
                    options: [],
                },
                {
                    id: 'institutions',
                    name: $translate.instant('schools'),
                    options: [],
                },
            ];

            const trips = $scope.isShowingScheduleOrIsFuture() ? [...getCategoryTrips($scope.scheduledTrips)] : getAllTripsWithFilters();

            if (['agent', 'manager', 'observer'].includes($rootScope.loggedUserRole)) {
                resetCarriersFilterOptions(trips);
            } else {
                resetClientsFilterOptions(trips);
            }

            resetBusFilterOptions(trips);
            resetInstitutionsFilterOptions(trips);
            updateSearchQuery();
        };

        /**
         * Reset clients filter options
         * @param {Array<Object>} [trips]
         * @param {Boolean} shouldUpdateSearchQuery
         */
        const resetClientsFilterOptions = (trips, shouldUpdateSearchQuery) => {
            if ($scope.availableClients == null) {
                // Keep track of list of clients until page is unmounted
                $scope.availableClients = new Map([
                    [$translate.instant('noClient').toLocaleLowerCase(), $translate.instant('noClient')],
                    ['all', 'all'],
                ]);
            }

            // Update available clients
            if (trips != null) {
                for (const trip of trips) {
                    const tripClientName = trip.route.client?.name;
                    if (tripClientName != null && $scope.availableClients.has(tripClientName) === false) {
                        $scope.availableClients.set(tripClientName.toLocaleLowerCase(), tripClientName);
                    }
                }
            }

            // Retrieve clients from url and sanitize
            let selectedClients =
                $location
                    .search()
                    .clients?.toLocaleLowerCase()
                    .split(',')
                    .filter((client) => $scope.availableClients.has(client)) || [];

            // Overwrite everything if no client or is viewing all
            if (selectedClients.length === 0 || selectedClients.includes('all')) {
                selectedClients = ['all'];
            }

            $scope.hasSelectedAll = selectedClients.includes('all');

            // Keep track of displayed clients
            const currentDisplayedClients = new Map();

            const clientsSection = $scope.filterSections.find((section) => section.id === 'clients');

            if (trips != null) {
                // Trips has been updated
                for (const trip of trips) {
                    const tripClientName = trip.route.client?.name;
                    if (tripClientName != null) {
                        const tripClientNameLowerCase = tripClientName.toLocaleLowerCase();
                        if (currentDisplayedClients.has(tripClientNameLowerCase) === false) {
                            // Add new trip client name
                            currentDisplayedClients.set(tripClientNameLowerCase, {
                                name: tripClientName,
                                value: $scope.hasSelectedAll || selectedClients.includes(tripClientNameLowerCase),
                            });
                        }
                    } else {
                        // Trip has no client
                        const noClientText = $translate.instant('noClient');
                        const noClientTextLowerCase = noClientText.toLocaleLowerCase();

                        if (currentDisplayedClients.has(noClientTextLowerCase) === false) {
                            // Add noClient option
                            currentDisplayedClients.set(noClientTextLowerCase, {
                                name: noClientText,
                                value: $scope.hasSelectedAll || selectedClients.includes(noClientTextLowerCase),
                            });
                        }
                    }
                }

                // Inject missing selected clients
                for (const selectedClient of selectedClients) {
                    if (selectedClient !== 'all' && currentDisplayedClients.has(selectedClient) === false) {
                        currentDisplayedClients.set(selectedClient, {
                            name: $scope.availableClients.get(selectedClient),
                            value: true,
                        });
                    }
                }

                clientsSection.options = [...currentDisplayedClients.values()];
                clientsSection.options = sortingTools.sortClients([...clientsSection.options], 'name', true);

                $scope.selectedClients = selectedClients.join(',');
            } else {
                // No update to trips
                const selectedOptions = clientsSection.options.filter((option) => option.value === true);
                if (clientsSection.options.length > 1) {
                    // If hasSelected is true, it means that the user had at least 2 options and unselect one of them. The user has selected specific clients
                    $scope.hasSelected = clientsSection.options.length > selectedOptions.length;
                }

                if (selectedOptions.length === clientsSection.options.length && $scope.hasSelected !== true) {
                    // Even if all the options are selected, if hasSelected is true, it means that the user is still selecting specific clients
                    $scope.selectedClients = 'all';
                } else if (clientsSection.options.length > 0 && selectedOptions.length === 0) {
                    $scope.selectedClients = 'none';
                } else {
                    $scope.selectedClients = selectedOptions.map((option) => option.name.toLocaleLowerCase()).join(',');
                }
            }

            if (shouldUpdateSearchQuery === true) {
                updateSearchQuery();
            }
        };

        /**
         * Reset carriers filter options
         * @param {Array<Object>} trips
         */
        const resetCarriersFilterOptions = (trips) => {
            // Generating filters for carriers
            const carriersSection = $scope.filterSections.find((section) => section.id === 'carriers');

            trips.forEach((trip) => {
                const tripCarrierName = trip.route.carrier?.name;
                if (tripCarrierName != null) {
                    if (carriersSection.options.some((carrier) => carrier.name === tripCarrierName) === false) {
                        carriersSection.options.push({
                            name: tripCarrierName,
                            value: true,
                        });
                    }
                } else if (carriersSection.options.some((carrier) => carrier.name === $translate.instant('noCarrier')) === false) {
                    carriersSection.options.push({
                        name: $translate.instant('noCarrier'),
                        value: true,
                    });
                }
            });

            // sorting carriers
            carriersSection.options = sortingTools.sortCarriers([...carriersSection.options], 'name', true);
        };

        /**
         * Reset bus filter options
         * @param {Array<Object>} trips
         */
        const resetBusFilterOptions = (trips) => {
            // Generating filters for bus models
            const busTypeSection = $scope.filterSections.find((section) => section.id === 'busType');

            trips.forEach((trip) => {
                const tripBusModel = trip.route.bus?.model;
                if (tripBusModel != null) {
                    if (busTypeSection.options.some((option) => option.name === $translate.instant(tripBusModel)) === false) {
                        busTypeSection.options.push({
                            name: $translate.instant(tripBusModel),
                            value: true,
                        });
                    }
                } else if (busTypeSection.options.some((busModel) => busModel === $translate.instant('noBusType')) === false) {
                    busTypeSection.options.push({
                        name: $translate.instant('noBusType'),
                        value: true,
                    });
                }
            });
        };

        /**
         * Reset institutions filter options
         * @param {Array<Object>} trips
         */
        const resetInstitutionsFilterOptions = (trips) => {
            // Generating filters for institutions
            const institutionsSection = $scope.filterSections.find((section) => section.id === 'institutions');
            let tripWithNoInstitutionExists = false;

            trips.forEach((trip) => {
                if (trip.stops != null) {
                    let tripInstitutionCount = 0;

                    trip.stops.forEach((stop) => {
                        if (stop.institution != null) {
                            if (institutionsSection.options.some((institution) => institution.name === stop.institution.name) === false) {
                                institutionsSection.options.push({
                                    name: stop.institution.name,
                                    value: true,
                                });
                            }
                            tripInstitutionCount++;
                        }
                    });

                    // If this trip has no institution at all
                    if (tripInstitutionCount === 0) {
                        tripWithNoInstitutionExists = true;
                    }
                }
            });

            // sorting institutions
            institutionsSection.options = sortingTools.sortInstitutions([...institutionsSection.options], 'name', true);

            if (tripWithNoInstitutionExists) {
                institutionsSection.options.unshift({
                    name: $translate.instant('noSchool'),
                    value: true,
                });
            }
        };

        // ******************
        // *** FAVOURITES ***
        // ******************

        /**
         * Toggle the favourite option of the specified trip
         * @param {String} trip
         */
        $scope.favouriteToggle = (trip) => {
            $scope.addOrRemoveRouteFavourite(trip.route.id);

            updateFavouriteStatus();
        };

        /**
         * Update favourites bools on all trips, since this info is in the cookies.
         * Note: This feature should later on be refactored to be managed in the backend.
         */
        const updateFavouriteStatus = () => {
            const favourites = $scope.getRouteFavourites();

            updateFavouriteForCategory($scope.plannedTrips, favourites);
            updateFavouriteForCategory($scope.inProgressTrips, favourites);
            updateFavouriteForCategory($scope.completedTrips, favourites);
            // If the selected date is in the future or if we are showing today's schdule, update the favorites scheduled trips
            if ($scope.isShowingScheduleOrIsFuture()) {
                updateFavouriteForCategory($scope.scheduledTrips, favourites);
            }
        };

        /**
         * Update the favourites bools for all trips in the given category.
         * @param {Object} category - the trip category (planned, in progress, completed)
         * @param {String[]} favourites - all  the route favourite ids
         */
        const updateFavouriteForCategory = (category, favourites) => {
            Object.keys(category).forEach((key) => {
                category[key].forEach((trip) => {
                    trip.isFavourite = favourites.indexOf(trip.route.id) > -1;
                });
            });
        };

        /**
         * Update the "isFavourite" bool in given trip object. True if the trip is linked to a favourited Route
         * @param {Object} trip
         */
        const updateFavouriteForTrip = (trip) => {
            const favourites = $scope.getRouteFavourites();
            trip.isFavourite = favourites.indexOf(trip.route.id) > -1;
        };

        // ********************
        // *** DATE AND TIME CHANGES ***
        // ********************

        /**
         * Change the URL and te selected date to use the new specified date
         * @param {Date} date
         */
        const updateSelectedDate = (date) => {
            // eslint-disable-next-line no-undef
            $scope.selectedDate = moment(date).format('YYYY-MM-DD');
            // eslint-disable-next-line no-undef
            $scope.selectedDateString = $scope.convertToReadableDate($scope.selectedDate, 'long');

            // Making sure the new date does not go before the minDate
            if (date < minDate) {
                date = minDate;
            }

            const formatedDate = date.format('YYYY-MM-DD');
            const newURL = $scope.setVariableOnURL('date', formatedDate);
            // eslint-disable-next-line no-undef
            document.location = newURL;
        };

        /**
         * Changes the selected date to the previous day from the current selected date
         */
        $scope.updateSelectedDateForPreviousDay = () => {
            $scope.isShowingScheduleForToday = false;
            // eslint-disable-next-line no-undef
            updateSelectedDate(moment($scope.selectedDate).subtract(1, 'days'));
        };

        /**
         * Changes the selected date to the next day from the current selected date
         */
        $scope.updateSelectedDateForNextDay = () => {
            $scope.isShowingScheduleForToday = false;
            // eslint-disable-next-line no-undef
            updateSelectedDate(moment($scope.selectedDate).add(1, 'days'));
        };

        /**
         * Changes the selected date to today
         */
        $scope.updateSelectedDateForToday = () => {
            // eslint-disable-next-line no-undef
            updateSelectedDate(moment());
        };

        /**
         * Return the actual time for the visual clock
         */
        $scope.tickClock = () => {
            // eslint-disable-next-line no-undef
            $scope.clock = moment().format('HH:mm'); // Clock to display time.
        };

        // ******************
        // *** ANIMATIONS ***
        // ******************

        /**
         * Find in which the direction the animation must go for the element to go from its current section to its new one.
         * @param {Object} currentSection - DOM element of the current section if the thumbnail.
         * @param {Object} newSection - DOM element of the new section the thumbnail must go to.
         * @return {String | null} the direction up|right|down|left
         */
        const findDirectionToGo = (currentSection, newSection) => {
            if (!currentSection || !newSection) {
                return null;
            }

            // Getting position of current section and the new section of the thumbnail.
            const currentSectionPosition = currentSection.getBoundingClientRect();
            newSection.classList.remove('ng-hide'); // ng-show set to true makes impossible to find the position of the new section. Removing its class so we can find it.
            const newSectionPosition = newSection.getBoundingClientRect();
            let direction;

            // If the different in X position is less than 85px, we will do a Y animation.
            if (Math.abs(currentSectionPosition.left - newSectionPosition.left) <= 85) {
                if (currentSectionPosition.top < newSectionPosition.top) {
                    direction = 'down';
                } else {
                    direction = 'up';
                }
            } else if (currentSectionPosition.right < newSectionPosition.right) {
                direction = 'right';
            } else {
                direction = 'left';
            }

            return direction;
        };

        /**
         * Find the category/section of the specified trip. Will only search in the "defaults" dataset.
         * @param {Object} tripToFind - the trip object what we must find
         * @return {Object} category and section
         */
        const findTripCategoryAndSection = (tripToFind) => {
            let foundCategory = null;
            let foundSection = null;

            Object.keys($scope.defaultPlannedTrips).forEach((key) => {
                if ($scope.defaultPlannedTrips[key].find((trip) => trip.id === tripToFind.id)) {
                    foundCategory = 'planned';
                    return (foundSection = key);
                }
            });

            Object.keys($scope.defaultInProgressTrips).forEach((key) => {
                if ($scope.defaultInProgressTrips[key].find((trip) => trip.id === tripToFind.id)) {
                    foundCategory = 'inProgress';
                    return (foundSection = key);
                }
            });

            Object.keys($scope.defaultCompletedTrips).forEach((key) => {
                if ($scope.defaultCompletedTrips[key].find((trip) => trip.id === tripToFind.id)) {
                    foundCategory = 'completed';
                    return (foundSection = key);
                }
            });

            return {
                category: foundCategory,
                section: foundSection,
            };
        };

        // *************
        // *** UTILS ***
        // *************

        /**
         * Count the number of Planned trips in AM and PM
         * @return {Number}
         */
        $scope.countPlannedTrips = () => {
            if ($scope.plannedTrips) {
                return $scope.plannedTrips.am.length + $scope.plannedTrips.pm.length;
            } else {
                return 0;
            }
        };

        /**
         * Count the number of no activity trips in AM and PM
         * @return {Number}
         */
        $scope.countNoActivityTrips = () => {
            if ($scope.plannedTrips) {
                return (
                    $scope.plannedTrips.amUnused.length +
                    $scope.plannedTrips.pmUnused.length +
                    $scope.plannedTrips.amCanceled.length +
                    $scope.plannedTrips.pmCanceled.length
                );
            } else {
                return 0;
            }
        };

        /**
         * Count the total number of trips to display in the Planned Trips section
         * @return {Number}
         */
        const countTotalPlannedTrips = () => {
            if ($scope.plannedTrips) {
                return (
                    $scope.plannedTrips.am.length +
                    $scope.plannedTrips.pm.length +
                    $scope.plannedTrips.amLate.length +
                    $scope.plannedTrips.pmLate.length +
                    $scope.plannedTrips.amUnused.length +
                    $scope.plannedTrips.pmUnused.length +
                    $scope.plannedTrips.amCanceled.length +
                    $scope.plannedTrips.pmCanceled.length +
                    $scope.plannedTrips.nextThirtyMinutes.length
                );
            } else {
                return 0;
            }
        };

        /**
         * Count the total number of trips to display in the In Progress Trips section
         * @return {Number}
         */
        const countTotalInProgressTrips = function () {
            if ($scope.inProgressTrips) {
                return (
                    $scope.inProgressTrips.critical.length +
                    $scope.inProgressTrips.moderated.length +
                    $scope.inProgressTrips.ok.length +
                    $scope.inProgressTrips.unknown.length
                );
            } else {
                return 0;
            }
        };

        /**
         * Count the total number of trips to display in the Completed Trips section
         * @return {Number}
         */
        const countTotalCompletedTrips = () => {
            if ($scope.completedTrips && $scope.inProgressTrips) {
                return (
                    $scope.completedTrips.am.length +
                    $scope.completedTrips.pm.length +
                    $scope.inProgressTrips.amWontComplete.length +
                    $scope.inProgressTrips.pmWontComplete.length
                );
            } else {
                return 0;
            }
        };

        /**
         * Counts trips when data is first charged, when refreshed and when filtering
         * To avoid faulty displays, counts have to be done all at once
         */
        const countTripsForDisplay = () => {
            // Counting total trips without filters
            $scope.totalDefaultTrips = getAllDefaultTrips().length;

            if ($scope.isToday($scope.selectedDate)) {
                $scope.totalPlannedTrips = countTotalPlannedTrips();
                $scope.totalInProgressTrips = countTotalInProgressTrips();
                $scope.totalCompletedTrips = countTotalCompletedTrips();

                // Counting total trips with filters
                $scope.totalTrips = getAllTripsWithFilters().length;

                // Counting trips for each delay type
                $scope.countInProgressTripsCritical = $scope.countInProgressTripsType($scope.inProgressTrips, 'critical');
                $scope.countInProgressTripsModerated = $scope.countInProgressTripsType($scope.inProgressTrips, 'moderated');
                $scope.countInProgressTripsOk = $scope.countInProgressTripsType($scope.inProgressTrips, 'ok');

                // Counting percentage trips for each category
                $scope.percentageTripsPlanned = $scope.calculatePercentage($scope.totalPlannedTrips, $scope.totalTrips);
                $scope.percentageTripsInProgress = $scope.calculatePercentage($scope.totalInProgressTrips, $scope.totalTrips);
                $scope.percentageTripsCompleted = $scope.calculatePercentage($scope.totalCompletedTrips, $scope.totalTrips);
            }

            // Counting trips for each category for past date
            if ($scope.isPast($scope.selectedDate)) {
                $scope.totalNoActivityTrips = $scope.countNoActivityTrips();
                $scope.totalCompletedTrips = countTotalCompletedTrips();
                $scope.totalPastTrips = $scope.totalNoActivityTrips + $scope.totalCompletedTrips;

                $scope.percentageTripsNoActivity = $scope.calculatePercentage($scope.totalNoActivityTrips, $scope.totalPastTrips);
                $scope.percentageTripsCompleted = $scope.calculatePercentage($scope.totalCompletedTrips, $scope.totalPastTrips);
            }

            // Counting the total trips while considering the applied filters (AM and PM)
            $scope.totalFilteredTrips = countAllFilteredTrips();
        };

        /**
         * Count all trips available (will consider the applied filters)
         * If the current display is AM or PM, we have to call countTotalInProgressAMOrPMTrips(showAM) for in progress trips
         * @return {Number}
         */
        const countAllFilteredTrips = () => {
            const showAM = $scope.viewDisplayed === $translate.instant('am');
            if ($scope.viewDisplayed) {
                return countTotalPlannedTrips() + countTotalInProgressAMOrPMTrips(showAM) + countTotalCompletedTrips();
            }
            return countTotalPlannedTrips() + countTotalInProgressTrips() + countTotalCompletedTrips();
        };

        /**
         * Count the total number of trips to display in the In Progress Trips section that are either AM or PM
         * @param {boolean} showAM - True if we want to count the AM trips, false if we want to count the PM trips
         * @return {Number}
         */
        const countTotalInProgressAMOrPMTrips = (showAM) => {
            if ($scope.inProgressTrips) {
                return (
                    $scope.inProgressTrips.critical.filter((trip) => isAM(trip.schedule) === showAM).length +
                    $scope.inProgressTrips.moderated.filter((trip) => isAM(trip.schedule) === showAM).length +
                    $scope.inProgressTrips.ok.filter((trip) => isAM(trip.schedule) === showAM).length +
                    $scope.inProgressTrips.unknown.filter((trip) => isAM(trip.schedule) === showAM).length
                );
            } else {
                return 0;
            }
        };

        /**
         * Get an array of all the trips (will consider the applied filters)
         * @return {Object[]}
         */
        const getAllTripsWithFilters = () => {
            return [
                ...getCategoryTrips($scope.plannedTrips),
                ...getCategoryTrips($scope.inProgressTrips),
                ...getCategoryTrips($scope.completedTrips),
            ];
        };

        /**
         * Get an array of all the trips (will not consider the applied filters)
         * @return {Object[]}
         */
        const getAllDefaultTrips = () => {
            return [
                ...getCategoryTrips($scope.defaultPlannedTrips),
                ...getCategoryTrips($scope.defaultInProgressTrips),
                ...getCategoryTrips($scope.defaultCompletedTrips),
            ];
        };

        /**
         * Get all the trips of the specified category
         * @param {Object} category - the trip category (planned, in progress, completed)
         * @return {Object[]} array of the trips
         */
        const getCategoryTrips = (category) => {
            let trips = [];
            Object.keys(category).forEach((key) => {
                trips = [...trips, ...category[key]];
            });
            return trips;
        };

        /**
         * Count the number of institutions of the specified stops that are in the specified options
         * @param {Object[]} stops
         * @param {Object} stops.institution - optional
         * @param {String} stops.institution.name
         * @param {Object} options
         * @return {Number}
         */
        const countInstitutionInFilters = (stops, options) => {
            let count = 0;

            options.forEach((option) => {
                if (option.value) {
                    stops.find((stop) => {
                        if (Object.hasOwn(stop, 'institution') && stop.institution.name === option.name) {
                            count++;
                        }
                    });
                }
            });

            return count;
        };

        /**
         * Count the number of intitutions in the specified stops
         * @param {Object[]} stops
         * @param {Object} stops.institution - optional
         * @return {Number}
         */
        const countInstitutions = (stops) => {
            let count = 0;
            if (stops) {
                stops.forEach((stop) => {
                    if (Object.hasOwn(stop, 'institution')) {
                        count++;
                    }
                });
            }
            return count;
        };

        /**
         * Count the number of planned trip in the future or on the schedule page
         * @return {Number}
         */
        $scope.countScheduledPlannedTrips = () => {
            return $scope.scheduledTrips.planned?.length ?? 0;
        };

        /**
         * Count the number of scheduled planned trip, for future or schedule page (will not consider the applied filters)
         * @return {Number}
         */
        $scope.countDefaultScheduledPlannedTrips = () => {
            return $scope.defaultScheduledPlannedTrips?.length ?? 0;
        };

        /**
         * Count the number of unplanned trip in the future or on the schedule page
         * @return {Number}
         */
        $scope.countScheduledUnplannedTrips = () => {
            return $scope.scheduledTrips.unplanned?.length ?? 0;
        };

        /**
         * Count the number of scheduled unplanned trips, for future or schedule page (will not consider the applied filters)
         * @return {Number}
         */
        $scope.countDefaultScheduledUnplannedTrips = () => {
            return $scope.defaultScheduledUnplannedTrips?.length ?? 0;
        };

        /**
         * Count the number of cancelled trip in the future or on the schedule page
         * @return {number}
         */
        $scope.countScheduledCancelledTrips = () => {
            return $scope.scheduledTrips.cancelled?.length ?? 0;
        };

        /**
         * Count the number of scheduled cancelled trip, for future or scheduled page (will not consider the applied filters)
         * @return {number}
         */
        $scope.countDefaultScheduledCancelledTrips = () => {
            return $scope.defaultScheduledCancelledTrips?.length ?? 0;
        };

        /**
         * Count the number of trips inProgress depending of delay type (critical, moderated, ok).
         * @param {Object} inProgressTrips - Object containing inProgress trips with different delay type.
         * @param {string} tripType - The delay type of the trip to count (critical, moderated, ok).
         * @return {Number}
         */
        $scope.countInProgressTripsType = (inProgressTrips, tripType) => {
            return inProgressTrips?.[tripType]?.length ?? 0;
        };

        /**
         * Calculate the percentage of trips of a given type (planned, inProgress, completed).
         * @param {Number} totalOfType - Total number of trips of the given type.
         * @param {Number} totalTrips - Total number of all trips.
         * @return {Number}
         */
        $scope.calculatePercentage = (totalOfType, totalTrips) => {
            if (totalTrips > 0) {
                const percentage = (totalOfType / totalTrips) * 100;
                return Math.round(percentage);
            }
            return 0;
        };

        /**
         * Verify if the specified date is one in the past
         * @param {Date} date
         * @return {Boolean} true if the date is in the past
         */
        $scope.isPast = (date) => {
            // eslint-disable-next-line no-undef
            const today = moment().startOf('day');
            // eslint-disable-next-line no-undef
            return moment(date).startOf('day').isBefore(today);
        };

        /**
         * Verify if the specified date is the current date
         * @param {Date} date
         * @return {Boolean} true if the date is the current date
         */
        $scope.isToday = (date) => {
            // eslint-disable-next-line no-undef
            const today = moment().startOf('day');
            // eslint-disable-next-line no-undef
            return moment(date).startOf('day').isSame(today);
        };

        /**
         * Verify if a date or if current date is in AM
         * @param {Date} date
         * @return {Boolean} true if AM
         */
        const isAM = (date) => {
            // eslint-disable-next-line no-undef
            if (moment(date, 'HH:mm', true).isValid()) {
                // If a schedule time was provided, check if it's AM or PM
                const time = date.split(':')[0];
                const amRegex = /0[1-9]|1[0-1]/gm;
                return time.match(amRegex) && time.length === 2;
            } else {
                /* If a date was provided, adjust the date to local timezone,
                if no date was provided, localDate receives the current date */
                const localDate = date ? new Date(date) : new Date();
                return localDate.getHours() >= 0 && localDate.getHours() <= 11;
            }
        };

        /**
         * If the section is an AM or PM late section, convert it to late for DOM manipulation by element id
         * @param {String} section
         * @return {String}
         */
        const convertTripsSectionName = (section) => {
            return section === 'amLate' || section === 'pmLate' ? 'late' : section;
        };

        // ***************************************************
        // *** SCHEDULED PAGE (FUTURE OR TODAY'S SCHEDULE) ***
        // ***************************************************

        /**
         * Format trips for sorting
         * @param {Array} trips - Array of trips objects
         * @param {'planned' | 'unplanned' | 'cancelled'} context
         */
        $scope.formatScheduledTrips = function (trips, context) {
            if (!trips) {
                return;
            }
            trips.map((trip) => {
                trip.busNumber = trip.route.bus.number;
                trip.carrierName = trip.route.carrier?.name ?? '';
                trip.clientName = trip.route.client?.name ?? '';
                if (context === 'unplanned') {
                    trip.canBeActivated = !isSchedulePastTwoHours(trip.schedule);
                }
            });
        };

        /**
         * Select trip to activate and open modal
         * @param {Object} trip
         */
        $scope.selectActivatedTrip = function (trip) {
            $scope.activatedTrip = trip;
            $scope.activatedTripText = $translate.instant('activate_trip_modal_text_1', {
                route_name: trip.route.name,
                start_time: trip.schedule,
            });
            $scope.showActivateTripModal = true;
        };

        /**
         * Close activate trip modal and reset state
         */
        $scope.closeActivateTripModal = function () {
            $scope.activatedTrip = null;
            $scope.activatedTripText = null;
            $scope.showActivateTripModal = false;
            $scope.activatingTrip = false;
        };

        /**
         * Activate trip
         */
        $scope.activateTrip = async function () {
            try {
                $scope.activatingTrip = true;
                await $scope.postTrip($scope.activatedTrip.route.id, { date: moment().format('YYYY-MM-DD') });
                await getListTrips();
                $scope.closeActivateTripModal();
            } catch (err) {
                $rootScope.$broadcast('request-error-activate-trip', err);
            } finally {
                $scope.activatingTrip = false;
            }
        };

        /**
         * Check if schedule is past 2 hours
         * @param {string} schedule
         * @return {boolean}
         */
        function isSchedulePastTwoHours(schedule) {
            const scheduleTime = moment(schedule, 'HH:mm');

            // Subtract 2 hours from the current time
            const twoHoursAgo = moment().subtract(2, 'hours');

            // Check if the schedule time is before two hours ago
            return scheduleTime.isBefore(twoHoursAgo);
        }

        // Order type for planned scheduled trips panel
        $scope.plannedOrderType = {
            orderBy: 'startTime',
            ascending: true,
        };

        // Order type for planned scheduled trips panel
        $scope.unplannedOrderType = {
            orderBy: 'startTime',
            ascending: true,
        };

        // Order type for cancelled scheduled trips panel
        $scope.cancelledOrderType = {
            orderBy: 'startTime',
            ascending: true,
        };

        /**
         * Sort scheduled planned / unplanned trips reports by field
         * @param {String | null} orderBy orderBy
         * @param {'planned' | 'unplanned' | 'cancelled'} context
         */
        $scope.sortScheduledTrips = function (orderBy, context) {
            $scope.registeringDisplayFunctions = true;

            const orderTypes = {
                planned: 'plannedOrderType',
                unplanned: 'unplannedOrderType',
                cancelled: 'cancelledOrderType',
            };

            // get the order type based on the context
            const orderType = orderTypes[context];

            if (orderBy !== null) {
                $scope[orderType] = {
                    orderBy: orderBy,
                    ascending: $scope[orderType].orderBy === orderBy ? !$scope[orderType].ascending : true,
                };
            }

            // unplanned trips are sorted differently than the planned trips because they don't have the same schedule format
            const sortingFunction =
                context === 'unplanned' ? sortingTools.sortScheduledTripsWithoutSchedule : sortingTools.sortScheduledTripsWithSchedule;

            // sort the trips
            $scope.scheduledTrips[context] = sortingFunction($scope.scheduledTrips[context], $scope[orderType].orderBy, $scope[orderType].ascending);

            $scope.registeringDisplayFunctions = false;
        };
    },
]);
