/**
 * @param {String} status trip status
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('statusBox', {
    templateUrl: './shared/statusBox/statusBox.html',
    bindings: {
        status: '<',
        isPast: '<?',
    },
});
