/*
 * {Number} size - the number of digit(s) in the verification code, min 1
 * {Function} callback - a function called each time the inputs are modified, returns the digits if all entered, false if not compeleted.
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('validationCode', {
    templateUrl: './shared/validationCode/validationCode.html',
    bindings: {
        size: '<',
        callback: '=',
    },
    controller: function ($scope, $timeout) {
        this.$onInit = function () {
            $scope.elementId = Math.random().toString(36).substring(2, 15);

            // $timeout will make sure this code will be executed after the rendering so listeners will work normally
            // TODO: If we refactor to another framework, use component proper life-cycle method.
            $timeout(() => {
                // Here we are using jQuery to make operations easier. We wouldn't normally.
                // In another framework than AngularJS, we would normally use a lib for this component.

                // eslint-disable-next-line no-undef
                const validationCodeElement = $('#' + $scope.elementId);

                /**
                 * Go to the next digit input to enter
                 * @param {Object} e - event
                 * @return {Boolean}
                 */
                const goToNextInput = (e) => {
                    const key = e.which;
                    // eslint-disable-next-line no-undef
                    const input = $(e.target);
                    let nextInput = input.parent().next('span').children('input');

                    if (key != 9 && !((key >= 48 && key <= 57) || (key >= 96 && key <= 105))) {
                        e.preventDefault();
                        return false;
                    }

                    if (key === 9) {
                        return true;
                    }

                    if (!nextInput?.length) {
                        nextInput = validationCodeElement.find('input').eq(0);
                    }
                    nextInput.select().focus();
                };

                /**
                 * Executed on event keydown, will make sure only digits are entered in inputs and copy shortcuts works
                 * @param {Object} e - event
                 * @return {Boolean}
                 */
                const onKeyDown = (e) => {
                    const key = e.which || e.keyCode; // keyCode detection
                    const ctrl = e.ctrlKey || e.metaKey; // ctrl/command detection

                    // If paste shortcut
                    if (key == 86 && ctrl) {
                        return true; // Will allow to listen paste event
                    } else if (key === 9 || (key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
                        return true;
                    }

                    e.preventDefault();
                    return false;
                };

                /**
                 * Executed on event paste, will complete the digit code with the clipboard data
                 * @param {Object} e - event
                 */
                const onPaste = (e) => {
                    e.preventDefault();
                    const data = e.originalEvent.clipboardData.getData('text'); // Data in the clipboard

                    // eslint-disable-next-line no-undef
                    let currentInput = $(e.target);
                    currentInput.val(data.charAt(0)); // Assign the fist char to the focused input
                    for (let i = 1; i < data.length; i++) {
                        // Assign next char to next input
                        currentInput = currentInput.parent().next('span').children('input').val(data.charAt(i));
                        currentInput.focus(); // Make the cursor follow the paste
                    }
                };

                /**
                 * Get the full code entered by the user
                 * @return {Number|Boolean} The digit code if all inputs are valids, false is not full or valid
                 */
                const getCode = () => {
                    let code = '';
                    for (let i = 0; i < this.size; i++) {
                        code += validationCodeElement.find('input').eq(i).val();
                    }

                    // If the code if a digit code of the proper size
                    const regex = new RegExp('^([0-9]{' + this.size + '})$');
                    if (regex.test(code)) {
                        return code;
                    } else {
                        return false;
                    }
                };

                /**
                 * Executed on event keyup, will call the callback function
                 */
                const onKeyUp = () => {
                    if (this.callback && typeof this.callback === 'function') {
                        this.callback(getCode());
                    }
                };

                validationCodeElement.on('keyup', 'input', goToNextInput);
                validationCodeElement.on('keydown', 'input', onKeyDown);
                validationCodeElement.on('keyup', 'input', onKeyUp);
                validationCodeElement.on('paste', onPaste);
            });
        };
    },
});
