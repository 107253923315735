const { isPast } = require('../../shared/tools/helpers/dateComparator');
const { getDisplayedComputedStatus } = require('../../shared/tools/helpers/getDisplayedComputedStatus');

// eslint-disable-next-line no-undef
angular.module('mTransportApp').controller('RoutesController', [
    '$scope',
    '$q',
    '$rootScope',
    '$controller',
    '$timeout',
    'sortingTools',
    '$translate',
    function ($scope, $q, $rootScope, $controller, $timeout, sortingTools, $translate) {
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('GlobalMapController', { $scope: $scope }));
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('PDFDirectionController', { $scope: $scope }));

        /** ******************************************************************************************************************* */
        //  INITIALIZATION
        /** ******************************************************************************************************************* */

        /**
         * Initialize routes
         */
        $scope.initializeRoutes = function () {
            initializeScopeVariables();
            $scope.setFocus('search');
            fetchRoutes();
            $scope.countSelectedRoutes();
        };

        /**
         * When the mouse enter the item
         * @param {Object} event
         */
        $scope.mouseEnter = (event) => {
            $(event.delegateTarget).tooltip('show');
        };

        /**
         * Initialize scope variables
         */
        function initializeScopeVariables() {
            $scope.filterSections = []; // Empty, will be generated later after API call
            $scope.loading = true;
            $scope.routes;
            $scope.searchValue;
            $scope.showCSVButton = true;
            $scope.hasDirections = false;
            $scope.areDirectionsEditable = false;
            $scope.pdfLoadingRoute = false;
            $scope.isSimplifiedView = true;
            $scope.orderType = {
                orderBy: 'routeName',
                ascending: true,
            };

            $scope.showFilterDropdown = false;
            $scope.filterOptions = {
                showOnlyFavourites: false,
            };

            $scope.isRemoveNoteModalDisplayed = false;
            $scope.modalLoading = false;

            $scope.selectedRoutesCount = 0;
            $scope.routeSelection = {
                areAllRoutesSelected: false,
                areRoutesSortedBySelection: false,
                attendanceDropdownCollapsed: true,
                notificationsDropdownCollapsed: true,
                delayDropdownCollapsed: true,
                showModalRoutesSettings: false,
                modalText: 'textToReplace',
                modalWarning: '',
                settingChoice: null,
                inProgress: false,
            };
            $scope.routesToEdit = [];
            $scope.currentLanguage = $rootScope.language;

            $scope.buttonsGroupSelectedOption = $scope.filterOptions.showOnlyFavourites ? 1 : 0;

            // Called here for the first time to fill favorites toggle with text, then at getListTrips() for page refresh
            initializeFavoritesToggleText();

            // For routes.html scope (https://stackoverflow.com/questions/39068971/angularjs-using-single-controller-with-two-views)
            $scope.canEditSettingsCommunications = $rootScope.loggedUserRole === 'agent' || $rootScope.loggedUserRole === 'manager';

            $scope.routes = []; // Contains all the routes
            $scope.filteredRoutes = []; // Contains all the routes that matches filters
            $scope.routesToShow = []; // Contains sorted or paginated routes to display
            $scope.currentPage = 1; // Initialize current page
            $scope.itemsPerPage = 500; // Items per page
        }

        /**
         * Initializes the text in the favorites toggle in the right language
         * Called when fetching routes information so that every time the page refreshes, this info does too
         */
        function initializeFavoritesToggleText() {
            $scope.buttonsGroupOptions = [
                {
                    name: $translate.instant('all'),
                    onClick: () => {
                        $scope.setFavouriteFiltering(false);
                    },
                },
                {
                    name: `${$translate.instant('favourites')} <i class="fa fa-star status-yellow"></i>`,
                    onClick: () => {
                        $scope.setFavouriteFiltering(true);
                    },
                },
            ];
        }

        $scope.initializeRouteDetails = function () {
            $scope.loading = true;
            $scope.routeId = $scope.getIdFromURL();
            $scope.stopCollapsedIcon = false;
            $scope.studentOrderBy = 'internalId';
            $scope.loadingRouteInfo = true;
            $scope.loadingTrips = true;
            $scope.loadingStops = true;
            $scope.loadingNotes = true;
            $scope.hasWaypoints = false;
            $scope.routeNotes = [];
            $scope.routeNotesToShow = [];
            $scope.routeNotesToggleStatus = 'effective';
            if (!$scope.routeId) {
                const error = {
                    status: 404,
                };
                $scope.catchErrorDefault(error);
            } else {
                $scope.getRouteDetails();
                $scope.getRouteTripsOfTheWeek();
                $scope.getBusStops();
                $scope.getRouteNotes();
            }
            // Make sure we don't register events twice
            $('#wrapper').off('show.bs.collapse hide.bs.collapse hidden.bs.collapse shown.bs.collapse', '.collapse');
            // Disable click on bootstrap collapsing events
            $('#wrapper').on('show.bs.collapse hide.bs.collapse', '.collapse', function () {
                $('tr.stop-list').css('pointerEvents', 'none');
            });
            $('#wrapper').on('hidden.bs.collapse shown.bs.collapse', '.collapse', function () {
                $('tr.stop-list').css('pointerEvents', 'auto');
            });

            // Add note modal : new note object
            $scope.newNote = {
                startDate: '',
                endDate: '',
                textContent: '',
                startDateModel: '',
                endDateModel: '',
            };

            $scope.isAddNoteModalDisplayed = false;

            // For routeInfo.html scope (https://stackoverflow.com/questions/39068971/angularjs-using-single-controller-with-two-views)
            $scope.canEditSettingsCommunications = $rootScope.loggedUserRole === 'agent' || $rootScope.loggedUserRole === 'manager';
        };

        $scope.initializeRouteHistory = function () {
            $scope.loading = true;
            $scope.sorting = false;
            $scope.routeHistory = {
                tripsToShow: [],
                allTrips: [],
            };
            $scope.searchValue = { value: '' };
            $scope.orderType = {
                orderBy: '',
                ascending: true,
            };

            // Init filters with translations that might be wrong, still needed
            $scope.historyFilterSections = [
                {
                    id: 'status',
                    name: $translate.instant('status'),
                    options: [
                        {
                            name: $translate.instant('completed'),
                            value: true,
                        },
                        {
                            name: $translate.instant('inProgress'),
                            value: true,
                        },
                        {
                            name: $translate.instant('incomplete'),
                            value: true,
                        },
                        {
                            name: $translate.instant('plannedPlural'),
                            value: true,
                        },
                    ],
                },
            ];
            getRouteHistory();
        };

        /** ******************************************************************************************************************* */
        //  ROUTE INFO : [ADD NOTE MODAL]
        /** ******************************************************************************************************************* */

        $scope.openAddNoteModal = function () {
            $scope.isAddNoteModalDisplayed = true;
        };

        /**
         * ADD NOTE MODAL : Form submit and AJAX call
         * @param {Object} form the submitted newNote form
         */
        $scope.submitAddNoteForm = function (form) {
            if (form.$valid) {
                $scope.modalLoading = true;

                // Formating body data as per expected by backend
                const data = {
                    note: $scope.newNote.textContent,
                };

                // Adding optional body params to data (checking both models to know whether the user has emptied the inputs manually or not)
                if ($scope.newNote.startDateToSend && $scope.newNote.startDateModel) data.startDate = $scope.newNote.startDateToSend;

                if ($scope.newNote.endDateToSend && $scope.newNote.endDateModel) data.endDate = $scope.newNote.endDateToSend;

                // AJAX CALL to add a note to a route
                $scope
                    .angRequestAddRouteNote($scope.routeId, data)
                    .then(() => {
                        refreshRoutesNotes();

                        $scope.resetAddNoteModalVars();
                        $scope.isAddNoteModalDisplayed = false;
                    })

                    .catch((error) => {
                        switch (error.status) {
                            case 404:
                                $scope.isAddNoteModalDisplayed = false;
                                error.key = $translate.instant('error404');

                                // Triggers the error banner in the notes table
                                $rootScope.$broadcast('request-error-routes-notes', error);
                                break;
                            case 400:
                                switch (error.data.error.code) {
                                    case 1001:
                                        error.key = $translate.instant('messageErrorInvalidParameters');
                                        break;
                                    case 1022:
                                        error.key = $translate.instant('messageErrorStudentNotPartOfRun');
                                        break;
                                    case 1023:
                                        error.key = $translate.instant('messageErrorEndDateBeforeStartDate');
                                        break;
                                    default:
                                        break;
                                }

                                // Triggers the error banner inside modal
                                $rootScope.$broadcast('request-error-route-add-note', error);
                                break;
                            default:
                                $rootScope.$broadcast('request-error-main', error);
                                break;
                        }
                    })

                    .finally(() => {
                        $scope.modalLoading = false;
                    });
            }
        };

        /**
         * Create add user modal form date pickers (start date and end date) via jQuery dateRangerPicker lib
         */
        $scope.initializeDatePickers = () => {
            // Once Document is ready
            $(() => {
                // Initialize start date picker
                $('#route-info__note-add-form__start-date-picker').daterangepicker(
                    {
                        singleDatePicker: true,
                        opens: 'right',
                        autoUpdateInput: false,
                        showDropdowns: true,
                        minYear: 2021,
                        maxYear: 2025,
                    },

                    // Once a date is selected
                    (selectedDate) => {
                        // We populate $scope.newNote.startDate with the formatted selectedDate
                        $scope.newNote.startDate = moment(selectedDate).format('YYYY-MM-DD');
                        $scope.newNote.startDateToSend = moment(selectedDate).startOf('day').utc();

                        // We update the input value with jquery and trigger input event so that the newNote.startDateModel is populated
                        $('#route-info__note-add-form__start-date-picker').val($scope.newNote.startDate).trigger('input');
                        // Calling custom form validator
                        validateNoteDates();
                    }
                );

                //  Adding an event listener for when the start date calendar is displayed
                $('#route-info__note-add-form__start-date-picker').on('show.daterangepicker', () => {
                    // If no start date has been selected yet(first time the calendar is displayed) we populate $scope.newNote.startDate with the formatted date of today
                    if (!$scope.newNote.startDate) {
                        $scope.newNote.startDate = moment().format('YYYY-MM-DD');
                        $scope.newNote.startDateToSend = moment().startOf('day').utc();
                    }
                    // We update the input value with jquery and trigger input event so that the newNote.startDateModel is populated
                    $('#route-info__note-add-form__start-date-picker').val($scope.newNote.startDate).trigger('input');
                    // Calling custom form validator
                    validateNoteDates();
                });

                // Initialize end date picker
                $('#route-info__note-add-form__end-date-picker').daterangepicker(
                    {
                        singleDatePicker: true,
                        opens: 'right',
                        autoUpdateInput: false,
                        showDropdowns: true,
                        minYear: 2021,
                        maxYear: 2025,
                    },

                    // Once a date is selected
                    (selectedDate) => {
                        // We populate $scope.newNote.endDate with the formatted selectedDate
                        $scope.newNote.endDate = moment(selectedDate).format('YYYY-MM-DD');
                        $scope.newNote.endDateToSend = moment(selectedDate).endOf('day').utc();
                        // We update the input value with jquery and trigger input event so that the newNote.endDateModel is populated
                        $('#route-info__note-add-form__end-date-picker').val($scope.newNote.endDate).trigger('input');

                        // Calling custom form validator
                        validateNoteDates();
                    }
                );

                //  Adding an event listener for when the end date calendar is displayed
                $('#route-info__note-add-form__end-date-picker').on('show.daterangepicker', () => {
                    // If no end date has been selected yet(first time the calendar is displayed) we populate $scope.newNote.endDate with the formatted date of today
                    if (!$scope.newNote.endDate) {
                        $scope.newNote.endDate = moment().format('YYYY-MM-DD');
                        $scope.newNote.endDateToSend = moment().endOf('day').utc();
                    }

                    // We update the input value with jquery and trigger input event so that the newNote.endDateModel is populated
                    $('#route-info__note-add-form__end-date-picker').val($scope.newNote.endDate).trigger('input');

                    // Calling custom form validator
                    validateNoteDates();
                });
            });
        };

        /**
         * Refreshes the data for student routes notes
         */
        function refreshRoutesNotes() {
            $scope
                .angRequestNotesForARoute($scope.routeId)
                .then(function (data) {
                    const notes = data.data.notes;
                    notes.forEach((noteRoute) => {
                        $scope.routeNotes.push({ route: { id: noteRoute.id, name: noteRoute.name }, ...noteRoute });
                    });
                    $scope.routeNotes = notes;
                    $scope.routeNotesToShow = filterRouteNotes();
                    $scope.availableCountRouteNotes = availableCountRouteNotes();
                    $scope.effectiveCountRouteNotes = effectiveCountRouteNotes();
                    $scope.openIfEffectiveCountRouteNotes();
                })

                .catch((error) => {
                    $('#notesTab').collapse('show');
                    $rootScope.$broadcast('request-error-routes-notes', error);
                });
        }

        /**
         * Watching the scope variables that represent the start and end dates for a note
         * This replaces ng-change in the dates inputs in the html, as ng-change is only triggered when "valid" dates are inputted
         * And does not take into account user-inputted empty dates
         */
        $scope.$watchGroup(['newNote.startDateModel', 'newNote.endDateModel'], function () {
            validateNoteDates();
        });

        /**
         * Custom form validator
         * Checks if the note start date is before or after the note end date and sets validity of both inputs accordingly
         * (side note: needs to be a function expression with an arrow function for the "this" keyword to be bound to the "this" of its parent lexical scope, in our case the instance of controller)
         */
        const validateNoteDates = () => {
            if ($scope.newNote) {
                // If start date or end date is empty, date is valid
                if (this.routeInfoNoteAddForm && (!$scope.newNote.endDateModel || !$scope.newNote.startDateModel)) {
                    this.routeInfoNoteAddForm.endDate.$setValidity('datesValidity', true);
                    this.routeInfoNoteAddForm.startDate.$setValidity('datesValidity', true);
                } else if ($scope.newNote.endDate) {
                    const isDateValid = $scope.newNote.startDate <= $scope.newNote.endDate;
                    this.routeInfoNoteAddForm.endDate.$setValidity('datesValidity', isDateValid);
                    this.routeInfoNoteAddForm.startDate.$setValidity('datesValidity', isDateValid);
                }
            }
        };

        /**
         * Function to reset Add Note modal variables
         */
        $scope.resetAddNoteModalVars = function () {
            $scope.newNote.routeSelected = '';
            $scope.newNote.startDate = '';
            $scope.newNote.endDate = '';
            $scope.newNote.textContent = '';
            $scope.newNote.startDateModel = '';
            $scope.newNote.endDateModel = '';
            $scope.newNote.startDateToSend = '';
            $scope.newNote.endDateToSend = '';
        };

        /** ******************************************************************************************************************* */
        //  ROUTE INFO : [REMOVE NOTE MODAL]
        /** ******************************************************************************************************************* */

        $scope.openRemoveNoteModal = function (noteToRemove) {
            $scope.noteToRemove = noteToRemove;
            $scope.isRemoveNoteModalDisplayed = true;
        };

        /**
         * Remove note from a route
         * ==> Success: refreshRoutesNotes()
         * ==> Error: displays an error banner and hide modal
         * ==> Then removes loading mode and hide modal
         */
        $scope.removeRouteNote = function () {
            $scope.loadingNotes = true;

            // Call API to remove note from a route
            $scope.angRequestRemoveNoteForARoute($scope.routeId, $scope.noteToRemove.id).then(
                function () {
                    refreshRoutesNotes();
                },
                function (error) {
                    $scope.isRemoveNoteModalDisplayed = false;

                    // Triggers the error banner in the notes table
                    $rootScope.$broadcast('request-error-routes-notes', error);
                }
            );

            $scope.loadingNotes = false;
            $scope.isRemoveNoteModalDisplayed = false;
        };

        /** ******************************************************************************************************************* */
        //  DATA GATHERING
        /** ******************************************************************************************************************* */

        /**
         * Fetch routes data
         * @return {void}
         */
        function fetchRoutes() {
            $scope.requestRoutes($scope.isSimplifiedView, true, false).then(
                function (data) {
                    $scope.routes = data.routes.map((route) => ({
                        isSelected: false,
                        // To disable the route settings toggles when the preferences are changed (while the server is called)
                        preferencesRestriction: {
                            studentsBoardingRegistrations: {
                                disabled: false,
                            },
                            notifications: {
                                disabled: false,
                            },
                            automaticDelayNotifications: {
                                disabled: false,
                            },
                        },
                        ...route,
                    }));

                    $scope.countSelectedRoutes();

                    // Sort institutions for each route
                    $scope.routes.forEach((route) => {
                        route.institutions = sortingTools.sortInstitutions(route.institutions);
                    });

                    initializeFavoritesToggleText();
                    $scope.addFavouriteAttribute();
                    loadRoutesTable($scope.routes).then(function () {
                        $scope.loading = false;
                        $('#search').focus();
                        $scope.applyFilters();
                    });
                    resetFilterOptions();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loading = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Load routes table
         * @param {Array<Object>} routes
         * @return {Promise}
         */
        function loadRoutesTable(routes) {
            return new Promise(function (resolve) {
                $timeout(function () {
                    resolve(($scope.routes = sortingTools.sortRoutes(routes, $scope.orderType.orderBy, $scope.orderType.ascending)));
                });
            });
        }

        /**
         * Add favorite attribute
         */
        $scope.addFavouriteAttribute = function () {
            const mailHash = sha256($rootScope.loggedUserMail);
            let favourites = [];
            const favouritesRaw = window.localStorage.getItem('favouriteRoutes-' + mailHash);
            if (favouritesRaw) {
                favourites = JSON.parse(favouritesRaw);
            }

            for (const route of $scope.routes) {
                route['isFavourite'] = favourites.indexOf(route.id) >= 0;
            }
        };

        $scope.getRouteTripsOfTheWeek = function () {
            $scope.routeId = $scope.getIdFromURL();
            if (!$scope.routeId) {
                $scope.catchErrorDefault({ status: 404 });
            }
            $scope.requestRouteDetailTrips($scope.routeId).then(
                function (data) {
                    loadTripsOfTheWeekTable(data.trips).then(function () {
                        $scope.loadingTrips = false;
                        $scope.validateDoneLoading();
                    });
                },
                function (error) {
                    $rootScope.$broadcast('request-error-route-history', error);
                    $scope.loadingTrips = false;
                    $scope.validateDoneLoading();
                }
            );
        };

        /**
         * Load trips of the week table
         * @param {Array<Object>} trips
         * @return {Promise}
         */
        async function loadTripsOfTheWeekTable(trips) {
            const thisWeekYear = moment().format('YYYY-ww');

            // Filter trips for this week
            const thisWeekTrips = trips.filter((trip) => {
                const tripWeekYear = moment(trip.schedule).format('YYYY-ww');
                if (thisWeekYear === tripWeekYear) {
                    formatTimeDeltas(trip);
                    trip.isPast = isPast(trip.schedule);
                    return true;
                }
                return false;
            });

            // Sort trips of the week
            const sortedTrips = await $scope.sortTripOfTheWeek(thisWeekTrips);
            $scope.tripsOfTheWeek = sortedTrips;
            $scope.loadingTrips = false;

            // Apply scope changes
            $timeout(() => {
                $scope.$apply();
            }, 250);
        }

        // Returns new trip with first/last stop formatted arrival time & time delta
        const formatTimeDeltas = (trip) => {
            if ((trip.status == 'completed' || trip.status == 'inProgress') && trip.stops.length >= 2) {
                const formatStopInfo = (stop) => {
                    return {
                        arrival: stop.arrival,
                        delta: $scope.timeDelta(
                            // eslint-disable-next-line no-undef
                            moment(stop.arrival).format('HH:mm'),
                            stop.schedule,
                            true
                        ),
                    };
                };
                // Format 1st+last stop with arrival & time delta
                trip.firstStop = formatStopInfo(trip.stops[0]);
                trip.lastStop = formatStopInfo(trip.stops[trip.stops.length - 1]);

                return trip;
            }
            return trip;
        };

        const getRouteHistory = function () {
            const routeId = $scope.getIdFromURL();
            if (routeId) {
                $scope.requestRouteDetailTrips(routeId).then(
                    function (datatrips) {
                        let tripHistory = datatrips.trips;
                        tripHistory = tripHistory.map((trip) => formatTimeDeltas(trip));

                        $scope.routeHistory.allTrips = tripHistory.map((trip) => ({
                            ...trip,
                            isPast: isPast(trip.schedule),
                            statusDisplay: getDisplayedComputedStatus(trip),
                        }));
                        $scope.routeHistory.tripsToShow = angular.copy($scope.routeHistory.allTrips);

                        // Dictionary has had time to load, reloading the history filters with accurate translations
                        // Get status section
                        const statusSection = $scope.historyFilterSections.find((section) => section.id === 'status');

                        // Create a Set to track unique statuses we've found
                        const foundStatuses = new Set();

                        // Check each trip for its statuses
                        $scope.routeHistory.allTrips.forEach((trip) => {
                            // Add status
                            foundStatuses.add($translate.instant(getDisplayedComputedStatus(trip)));
                        });

                        // Only add options for statuses that exist in the trips
                        statusSection.options = Array.from(foundStatuses).map((status) => ({
                            id: status,
                            name: $translate.instant(status),
                            value: true,
                        }));

                        $scope.requestRouteDetail(routeId).then(
                            function (datadetails) {
                                $scope.route = datadetails;
                                $scope.loading = false;
                                $scope.sortTripHistory('date');
                                $scope.$apply();
                            },
                            function (error) {
                                $scope.catchErrorDefault(error);
                            }
                        );
                    },
                    function (error) {
                        $scope.catchErrorDefault(error);
                    }
                );
            }
        };

        /**
         * This function will be given to the filter component in History page to be executed each time the filter options are changing.
         */
        $scope.onHistoryFilterChange = () => {
            $scope.applyHistoryFilters($scope.searchValue.value);
        };

        /**
         * This function will be given to the filter component in History page to be executed each time the search input is changing.
         * @param {String} searchValue - the string in the search input text
         */
        $scope.onHistoryFilterInput = (searchValue) => {
            $scope.searchValue.value = searchValue;
            $scope.applyHistoryFilters(searchValue);
        };

        /**
         * This function will be given to the filter component in History page to be executed each time the filter options and search input are reset.
         */
        $scope.onHistoryFilterReset = () => {
            $scope.searchValue.value = '';
            resetSortTripHistory();
        };

        $scope.getBusStops = function () {
            if ($scope.routeId) {
                $scope.requestRouteDetailStops($scope.routeId).then(
                    function (data) {
                        $scope.hasDirections = $scope.isDirectionsExist(data);
                        $scope.directionsLinkMessage = $scope.hasDirections ? 'editDirections' : 'addDirections';

                        const stops = data.stops;
                        if (stops.length == 0) {
                            $scope.loadingStops = false;
                            return;
                        }
                        $scope.listOfStops = [];
                        $scope.numberOfStudent = 0;
                        $scope.getStopDetailsFromStop(stops, 0);
                    },
                    function (error) {
                        $rootScope.$broadcast('request-error-route-stops', error);
                        $scope.loadingStops = false;
                    }
                );
            }
        };

        /**
         * Get the route notes for each student. Display it to the user if they are effective
         * @param {Object} student
         */
        $scope.getStudentRouteNote = function (student) {
            if (student.id != null) {
                const specialConditionNoteLength = student.specialConditions.message.length;
                const stopNoteLength = student.notes?.length ?? 0;
                const spaceAndBarLength = 3;

                let totalNoteLength = specialConditionNoteLength + stopNoteLength + spaceAndBarLength;
                const routeNotesList = [];

                for (const routeNote of student.routeNotes) {
                    // Only get effective and not removed route note
                    if (routeNote.isEffective && routeNote.removedAt == null) {
                        // Verify that the total note length doesn't exceed 97 characters
                        if (totalNoteLength + routeNote.studentNote.length + spaceAndBarLength < 97) {
                            routeNotesList.push(routeNote.studentNote);
                            totalNoteLength += routeNote.studentNote.length + spaceAndBarLength;
                        } else {
                            routeNotesList.push('...');
                            break;
                        }
                    }
                }

                // Populate allNotes in a specific order (special condition | route notes | stop note)
                const allNotes = [];

                // First, if there is one, add special conditions note
                if (student.specialConditions.message.length > 0) {
                    allNotes.push(student.specialConditions.message);
                }

                // Second, Add routeNotes
                allNotes.push(...routeNotesList);

                // Third, if there is one, add stop note
                if (student.notes?.length > 0) {
                    allNotes.push(student.notes);
                }

                // Seperate each notes with a " | "
                student.allNotes = allNotes.join(' | ');

                // Trigger a digest cycle update
                $scope.$apply();
            }
        };

        /**
         * Get the route details from stop
         * @param {Object} stops
         * @param {Number} index
         */
        $scope.getStopDetailsFromStop = function (stops, index) {
            if ($scope.routeId) {
                if (index < stops.length && index >= 0) {
                    const stop = stops[index];
                    const stopId = stop.id;

                    $scope.requestRouteDetailStopId($scope.routeId, stopId).then(
                        function (data) {
                            // Used in route details map
                            if (stops[index].stopTransfer) data.stopTransfer = stops[index].stopTransfer;

                            data.students.forEach((student) => {
                                $scope.getStudentRouteNote(student);
                            });

                            $scope.listOfStops.push(data);
                            if (data.kind == 'pickup') {
                                $scope.numberOfStudent += data.studentCount;
                            }
                            if (index === stops.length - 1) {
                                // last stop
                                $scope.loadingStops = false;
                                $scope.validateDoneLoading();
                                return;
                            } else {
                                const newIndex = index + 1;
                                $scope.getStopDetailsFromStop(stops, newIndex);
                            }

                            if (data.waypoints.length > 0) {
                                $scope.hasWaypoints = true;
                            }
                        },
                        function (error) {
                            $rootScope.$broadcast('request-error-route-stops', error);
                            $scope.loadingStops = false;
                            $scope.validateDoneLoading();
                        }
                    );
                }
            }
        };

        /**
         * API call to get a list of notes for a specified route
         * ==> then loads the route notes into $scope.routeNotesToShow (notes displayed in the view) after filtering them
         * ==> In case of error displays an error banner within the panel
         * ==> Then $scope.loadingNotes to false
         */
        $scope.getRouteNotes = function () {
            $scope
                .angRequestNotesForARoute($scope.routeId)
                .then((data) => {
                    $scope.routeNotes = data.data.notes;
                    $scope.routeNotesToShow = filterRouteNotes();
                    $scope.availableCountRouteNotes = availableCountRouteNotes();
                    $scope.effectiveCountRouteNotes = effectiveCountRouteNotes();
                    $scope.openIfEffectiveCountRouteNotes();
                })
                .catch((error) => {
                    $rootScope.$broadcast('request-error-routes-notes', error);
                })
                .then(() => {
                    $scope.loadingNotes = false;
                });
        };

        /**
         * Opens the route notes table on click of the radio button 'effective', only if there is at least one route. Otherwise, nothing is changed.
         */
        $scope.openIfEffectiveCountRouteNotes = function () {
            if ($scope.effectiveCountRouteNotes > 0) {
                if (!$('#notesTab').hasClass('show') && !$('#notesTab').hasClass('collapsing')) {
                    $('#notesTab').collapse('show');
                    $('#notesPanelHeader').find('.custom-caret').addClass('fa-caret-down').removeClass('fa-caret-right');
                }
            }
        };

        /**
         * Opens the route notes table on click of the radio button 'all', only if there is at least one route. Otherwise, nothing is changed.
         */
        $scope.openIfAvailableCountRouteNotes = function () {
            if ($scope.availableCountRouteNotes > 0) {
                if (!$('#notesTab').hasClass('show') && !$('#notesTab').hasClass('collapsing')) {
                    $('#notesTab').collapse('show');
                    $('#notesPanelHeader').find('.custom-caret').addClass('fa-caret-down').removeClass('fa-caret-right');
                }
            }
        };

        $scope.getRouteDetails = function () {
            if ($scope.routeId) {
                $scope.requestRouteDetail($scope.routeId).then(
                    function (data) {
                        const mailHash = sha256($rootScope.loggedUserMail);
                        let favourites = [];
                        const favouritesRaw = window.localStorage.getItem('favouriteRoutes-' + mailHash);
                        if (favouritesRaw) {
                            favourites = JSON.parse(favouritesRaw);
                        }
                        $scope.route = data;

                        $scope.areDirectionsEditable = $scope.route.areDirectionsEditable;

                        $scope.route.preferencesRestriction = {
                            studentsBoardingRegistrations: {
                                disabled: false,
                            },
                            notifications: {
                                disabled: false,
                            },
                            automaticDelayNotifications: {
                                disabled: false,
                            },
                        };

                        $scope.route['isFavourite'] = favourites.indexOf($scope.route.id) >= 0;
                        $scope.loadingRouteInfo = false;
                        $scope.validateDoneLoading();
                    },
                    function (error) {
                        $scope.catchErrorDefault(error);
                        $scope.loadingRouteInfo = false;
                        $scope.validateDoneLoading();
                    }
                );
            }
        };

        /** ******************************************************************************************************************* */
        //  DATA MANIPULATION
        /** ******************************************************************************************************************* */

        $scope.formatDate = function (dateString, shortDate) {
            return shortDate ? moment(dateString).format('dddd') + ' ' + moment(dateString).format('ll') : moment(dateString).format('LL');
        };

        /** ******************************************************************************************************************* */
        //  SETTINGS AND COMMUNICATIONS FUNCTIONS
        /** ******************************************************************************************************************* */

        /**
         * Sets the notifications preferences of a route(view first, then backend, then view again after receiving answer from backend)
         * @param {Object} route
         * @param {String} notificationsStatus
         * @return {Promise}
         */
        $scope.toggleNotifications = function (route, notificationsStatus) {
            if ($scope.canEditSettingsCommunications) {
                const valueBeforeToggle = route.preferences.notifications;
                route.preferences.notifications = notificationsStatus;

                if (valueBeforeToggle == notificationsStatus) return null;

                const body = {
                    notifications: notificationsStatus,
                };

                route.preferencesRestriction.notifications.disabled = true;

                const promiseRequest = $scope.requestRouteNotificationsUpdate(route.id, body).then(
                    (data) => {
                        route.preferences.notifications = data.preferences.notifications;
                        route.preferencesRestriction.notifications.disabled = false;

                        if (route.preferences.notifications === 'none' && route.preferences.automaticDelayNotifications === true)
                            $scope.toggleAutomaticDelayNotifications(route, false);

                        // To prevent digest loop errors : https://stackoverflow.com/questions/12729122/angularjs-prevent-error-digest-already-in-progress-when-calling-scope-apply
                        $timeout(function () {
                            $scope.$apply();
                        });
                    },
                    (error) => {
                        route.preferencesRestriction.notifications.disabled = false;
                        route.preferences.notifications = valueBeforeToggle;
                        $rootScope.$broadcast('request-error-routes-page', error);
                        $scope.routeSelection.inProgress = false;
                        $timeout(function () {
                            $scope.$apply();
                        });
                    }
                );

                return promiseRequest;
            }
        };

        /**
         * Sets the automatic delay notifications preferences of a route(view first, then backend, then view again after receiving answer from backend)
         * @param {Object} route
         * @param {Boolean} automaticDelayNotificationsStatus
         * @return {Promise}
         */
        $scope.toggleAutomaticDelayNotifications = function (route, automaticDelayNotificationsStatus) {
            if ($scope.canEditSettingsCommunications) {
                const valueBeforeToggle = route.preferences.automaticDelayNotifications;
                route.preferences.automaticDelayNotifications = automaticDelayNotificationsStatus;

                if (valueBeforeToggle == automaticDelayNotificationsStatus) return null;

                const body = {
                    automaticDelayNotifications: automaticDelayNotificationsStatus,
                };

                route.preferencesRestriction.automaticDelayNotifications.disabled = true;

                const promiseRequest = $scope.requestRouteNotificationsUpdate(route.id, body).then(
                    (data) => {
                        route.preferences.automaticDelayNotifications = data.preferences.automaticDelayNotifications;
                        route.preferencesRestriction.automaticDelayNotifications.disabled = false;

                        // To prevent digest loop errors :https://stackoverflow.com/questions/12729122/angularjs-prevent-error-digest-already-in-progress-when-calling-scope-apply
                        $timeout(function () {
                            $scope.$apply();
                        });
                    },
                    (error) => {
                        route.preferencesRestriction.automaticDelayNotifications.disabled = false;
                        route.preferences.automaticDelayNotifications = valueBeforeToggle;
                        $rootScope.$broadcast('request-error-routes-page', error);
                        $scope.routeSelection.inProgress = false;
                        $timeout(function () {
                            $scope.$apply();
                        });
                    }
                );

                return promiseRequest;
            }
        };

        /**
         * Sets the student boarding registrations preferences of a route(view first, then backend, then view again after receiving answer from backend)
         * Also sets the notifications preferences of the same route to "vehicleOnly" if students boarding registrations is being disabled and notifications preferences were set to "vehicleAndStudents"
         * @param {Object} route
         * @param {Boolean} studentsBoardingRegistrationsStatus
         * @return {Promise}
         */
        $scope.toggleStudentsBoardingRegistrations = function (route, studentsBoardingRegistrationsStatus) {
            if ($scope.canEditSettingsCommunications) {
                const valueBeforeToggle = route.preferences.studentsBoardingRegistrations;
                route.preferences.studentsBoardingRegistrations = studentsBoardingRegistrationsStatus;

                if (!studentsBoardingRegistrationsStatus && route.preferences.notifications === 'vehicleAndStudents') {
                    route.preferences.notifications = 'vehicleOnly';
                }

                if (valueBeforeToggle == studentsBoardingRegistrationsStatus) return null;

                const body = {
                    studentsBoardingRegistrations: studentsBoardingRegistrationsStatus,
                };

                route.preferencesRestriction.studentsBoardingRegistrations.disabled = true;

                const promiseRequest = $scope.requestRouteNotificationsUpdate(route.id, body).then(
                    (data) => {
                        route.preferences.studentsBoardingRegistrations = data.preferences.studentsBoardingRegistrations;
                        route.preferences.notifications = data.preferences.notifications;
                        route.preferencesRestriction.studentsBoardingRegistrations.disabled = false;

                        // To prevent digest loop errors : https://stackoverflow.com/questions/12729122/angularjs-prevent-error-digest-already-in-progress-when-calling-scope-apply
                        $timeout(function () {
                            $scope.$apply();
                        });
                    },
                    (error) => {
                        route.preferencesRestriction.studentsBoardingRegistrations.disabled = false;
                        route.preferences.studentsBoardingRegistrations = valueBeforeToggle;
                        $rootScope.$broadcast('request-error-routes-page', error);
                        $scope.routeSelection.inProgress = false;
                        $timeout(function () {
                            $scope.$apply();
                        });
                    }
                );

                return promiseRequest;
            }
        };

        /** ******************************************************************************************************************* */
        //  FAVORITES FUNCTIONS
        /** ******************************************************************************************************************* */

        $scope.toggleFavourite = function (route) {
            const mailHash = sha256($rootScope.loggedUserMail);
            const routeId = route.id;
            // Get favourites for user.
            let favourites = [];
            const favouritesRaw = window.localStorage.getItem('favouriteRoutes-' + mailHash);

            // Validate if current user has favourites.
            if (favouritesRaw) {
                favourites = JSON.parse(favouritesRaw);
            }

            // Search for current trip's route in favourites and add/remove.
            const index = favourites.indexOf(routeId);
            if (index > -1) {
                favourites.splice(index, 1);
            } else {
                favourites.push(routeId);
            }

            // Save favourites.
            window.localStorage.setItem('favouriteRoutes-' + mailHash, JSON.stringify(favourites));

            // Update items.
            $scope.updateRoutesForFavourite();
        };

        $scope.updateRoutesForFavourite = function () {
            const mailHash = sha256($rootScope.loggedUserMail);
            let favourites = [];
            const favouritesRaw = window.localStorage.getItem('favouriteRoutes-' + mailHash);
            if (favouritesRaw) {
                favourites = JSON.parse(favouritesRaw);
            }

            if ($scope.routes && $scope.routes.length > 0) {
                for (const route of $scope.routes) {
                    route['isFavourite'] = favourites.indexOf(route.id) >= 0;
                }
            }

            if ($scope.route) {
                $scope.route['isFavourite'] = favourites.indexOf($scope.route.id) >= 0;
            }

            $timeout(function () {
                $scope.$apply();
            });
        };

        /** ******************************************************************************************************************* */
        //  NOTES FUNCTIONS
        /** ******************************************************************************************************************* */

        /**
         * Click handler for the radio-toggle-notes component
         * Populates $scope.routeNotesToggleStatus with the value passed as parameter (either 'all' or 'effective')
         * Loads the route notes into $scope.routeNotesToShow (notes displayed in the view) after filtering them
         * @param {String} toggleStatus
         */
        $scope.handleNotesToggleClick = function (toggleStatus) {
            $scope.routeNotesToggleStatus = toggleStatus;
            $scope.routeNotesToShow = filterRouteNotes();
            if (toggleStatus === 'all') {
                $scope.openIfAvailableCountRouteNotes();
            } else if (toggleStatus === 'effective') {
                $scope.openIfEffectiveCountRouteNotes();
            }
        };

        /**
         * Filter array of $scope.routeNotes and return count of notes not removed
         * @return {Number} Count of notes not removed
         */
        function availableCountRouteNotes() {
            const availableRouteNotes = $scope.routeNotes.filter((note) => note.removedAt == null);
            return availableRouteNotes.length;
        }

        /**
         *Filter array of $scope.routeNotes and return count of notes not removed and effective
         * @return {Number} Count of effective routes notes
         */
        function effectiveCountRouteNotes() {
            const effectiveRouteNotes = $scope.routeNotes.filter((note) => note.removedAt == null && note.isEffective);
            return effectiveRouteNotes.length;
        }

        /**
         * Filters an array of notes depending on $scope.routeNotesToggleStatus value (either 'all' or 'effective')
         * @return {Array} filtered route notes
         */
        function filterRouteNotes() {
            let filteredRouteNotes = $scope.routeNotes;

            if (filteredRouteNotes.length > 0 && $scope.routeNotesToggleStatus === 'effective') {
                filteredRouteNotes = $scope.routeNotes.filter((note) => note.removedAt == null && note.isEffective);
            }

            return filteredRouteNotes;
        }

        /** ******************************************************************************************************************* */
        //  ROUTE BATCH SETTINGS EDITING
        /** ******************************************************************************************************************* */

        /**
         * Counts the number of selected routes.
         * It iterates over all routes in `$scope.routes`, checking each route's `isSelected` property.
         */
        $scope.countSelectedRoutes = function () {
            $scope.selectedRoutesCount = $scope.routes.reduce((count, route) => {
                return count + (route.isSelected ? 1 : 0);
            }, 0);
        };

        /**
         * Selects or de-selects all the routes showing/filtered in the table
         * @param {Boolean} areAllRoutesSelected true to select or false to de-select all the routes
         */
        $scope.selectAllRoutes = function (areAllRoutesSelected) {
            if (isFilterApplied()) {
                const filteredRoutesSet = new Set($scope.filteredRoutes);
                $scope.routes.forEach((route) => {
                    // Select or De-select the route if it is in the filtered set
                    if (filteredRoutesSet.has(route)) {
                        route.isSelected = areAllRoutesSelected;
                    }
                });
            } else {
                // Applies the 'Select All' state to all routes if no filter is applied
                $scope.routes.forEach((route) => {
                    route.isSelected = areAllRoutesSelected;
                });
            }

            $scope.updateRouteSelection();
        };

        /**
         * Called when a route checkbox is clicked
         * Checks if all routes are selected and makes the filtering of the table normal again
         */
        $scope.updateRouteSelection = function () {
            checkIfAllRoutesAreSelected();

            // When we check a checkbox, we are not sorting by route selection anymore
            $scope.routeSelection.areRoutesSortedBySelection = false;

            $scope.countSelectedRoutes();
        };

        /**
         * @return {Boolean} - returns true if there is a filter applied
         */
        function isFilterApplied() {
            return $scope.filteredRoutes.length !== $scope.routes.length;
        }

        /**
         * Checks if all the routes showing are checked
         */
        function checkIfAllRoutesAreSelected() {
            if (isFilterApplied()) {
                $scope.routeSelection.areAllRoutesSelected =
                    $scope.routesToShow?.length > 0 ? $scope.routesToShow.every((route) => route.isSelected) : null;
            } else {
                $scope.routeSelection.areAllRoutesSelected = $scope.routes.every((route) => route.isSelected);
            }
        }

        /**
         * Checks if any of the routes showing are selected
         * @return {Boolean | null} - returns true if there is at least one route showing selected, false if none
         */
        $scope.checkIfAnyRouteShowingSelected = function () {
            return $scope.routesToShow?.length > 0 ? $scope.routesToShow.some((route) => route.isSelected) : null;
        };

        /**
         * Checks if there is any route (all of them) selected
         * If there is none, the filtering and sorting of the routes returns to normal (not by route selection)
         * If there is, the filtering and sorting is still done with priority to the routes selected
         * (This is useful in the case of an empty table when filtering : we want to still filter with priority to the
         * route selection when the table is not empty anymore and there are routes selected showing)
         * @return {Boolean} - returns true if there is a selected route
         */
        function checkIfAnyRouteSelected() {
            $scope.routeSelection.isThereASelectedRoute = $scope.routes.some((route) => route.isSelected);
            if ($scope.routeSelection.isThereASelectedRoute === false) $scope.routeSelection.areRoutesSortedBySelection = false;
            return $scope.routeSelection.isThereASelectedRoute;
        }

        /**
         * Collapse variable for the dropdowns is put to true (called by on-outside-element-click of the setting dropdowns)
         * @param {String} setting the dropdown setting to collapse
         */
        $scope.hideSettingsDropdown = function (setting) {
            switch (setting) {
                case 'attendance':
                    $scope.routeSelection.attendanceDropdownCollapsed = true;
                    break;
                case 'notifications':
                    $scope.routeSelection.notificationsDropdownCollapsed = true;
                    break;
                case 'delay':
                    $scope.routeSelection.delayDropdownCollapsed = true;
                    break;
            }
        };

        /**
         * Opens the confirmation modal with the right text depending of the chosen setting
         * @param {String} settingType the setting to change to for the selected routes
         */
        $scope.openRoutesSettingsModal = function (settingType) {
            $scope.routeSelection.settingChoice = settingType;

            $scope.routeSelection.modalWarning = '';
            let showNoAttendanceRoutesWarning = false;
            let warningStar = '';

            $scope.routesToEdit = $scope.routes.filter((route) => route.isSelected);

            /* If the selection contains routes without attendance tracking and the user wants to change 
                notifications to Bus+Student, the confirmation modal displays a warning message */
            if (settingType == 'notificationsBusStudents') {
                showNoAttendanceRoutesWarning = $scope.routesToEdit.some((route) => route.preferences.studentsBoardingRegistrations === false);
            }
            if (showNoAttendanceRoutesWarning) {
                warningStar = '*';
                $scope.routeSelection.modalWarning = $translate.instant('runs_settingsModal_explanationAttendance');
            }

            const startText = $translate.instant('runs_settingsModal_confirmation');
            const endText =
                $scope.routesToEdit.length > 1
                    ? $translate.instant('runs_settingsModal_confirmationRuns', {
                          numberOfRoutes: $scope.routesToEdit.length,
                          warning: warningStar,
                      })
                    : $translate.instant('runs_settingsModal_confirmationRun', { warning: warningStar });

            let activationText = '';
            let typeText = '';
            switch (settingType) {
                case 'attendanceOn':
                    activationText = $translate.instant('runs_settingsModal_activate');
                    typeText = $translate.instant('runs_settingsModal_attendance');
                    break;
                case 'attendanceOff':
                    activationText = $translate.instant('runs_settingsModal_deactivate');
                    typeText = $translate.instant('runs_settingsModal_attendance');
                    break;
                case 'notificationsOff':
                    activationText = $translate.instant('runs_settingsModal_deactivate');
                    typeText = $translate.instant('runs_settingsModal_notifications');
                    break;
                case 'notificationsBus':
                    activationText = $translate.instant('runs_settingsModal_activate');
                    typeText = $translate.instant('runs_settingsModal_notificationsVehicle');
                    break;
                case 'notificationsBusStudents':
                    activationText = $translate.instant('runs_settingsModal_activate');
                    typeText = $translate.instant('runs_settingsModal_notificationsVehicleStudents');
                    break;
                case 'delayOn':
                    activationText = $translate.instant('runs_settingsModal_activate');
                    typeText = $translate.instant('runs_settingsModal_delay');
                    break;
                case 'delayOff':
                    activationText = $translate.instant('runs_settingsModal_deactivate');
                    typeText = $translate.instant('runs_settingsModal_delay');
                    break;
            }

            $scope.routeSelection.modalText = `${startText} ${activationText} ${typeText} ${endText}`;

            $scope.routeSelection.showModalRoutesSettings = true;
        };

        /**
         * Puts the variable to show settings modal  to false
         */
        $scope.closeRoutesSettingsModal = function () {
            $scope.routeSelection.showModalRoutesSettings = false;
        };

        /**
         * Shows the 'Modification in progress' modal
         * Edits the routes showing that are selected, the closes the modal
         */
        $scope.updateRouteBatchSettings = function () {
            $scope.routeSelection.inProgress = true;
            editRouteBatchSettings().then(() => {
                $scope.routeSelection.showModalRoutesSettings = false;
                $scope.routeSelection.inProgress = false;
            });
        };

        /**
         * Edits the routes showing that are selected one by one by collecting the toggle settings
         * functions promises and combining them all in one single promise with $q.all
         * The result is returned to updateRouteBatchSettings so that the 'Modification in progress'
         * modal can be closed after the promises are done
         * @return {Promise}
         */
        function editRouteBatchSettings() {
            const routesEditPromises = [];
            switch ($scope.routeSelection.settingChoice) {
                case 'attendanceOn':
                    $scope.routesToEdit.forEach((route) => {
                        routesEditPromises.push($scope.toggleStudentsBoardingRegistrations(route, true));
                    });
                    break;
                case 'attendanceOff':
                    $scope.routesToEdit.forEach((route) => {
                        routesEditPromises.push($scope.toggleStudentsBoardingRegistrations(route, false));
                    });
                    break;
                case 'notificationsOff':
                    $scope.routesToEdit.forEach((route) => {
                        routesEditPromises.push($scope.toggleNotifications(route, 'none'));
                    });
                    break;
                case 'notificationsBus':
                    $scope.routesToEdit.forEach((route) => {
                        routesEditPromises.push($scope.toggleNotifications(route, 'vehicleOnly'));
                    });
                    break;
                case 'notificationsBusStudents':
                    $scope.routesToEdit.forEach((route) => {
                        routesEditPromises.push($scope.toggleNotifications(route, 'vehicleAndStudents'));
                    });
                    break;
                case 'delayOn':
                    $scope.routesToEdit.forEach((route) => {
                        routesEditPromises.push($scope.toggleAutomaticDelayNotifications(route, true));
                    });
                    break;
                case 'delayOff':
                    $scope.routesToEdit.forEach((route) => {
                        routesEditPromises.push($scope.toggleAutomaticDelayNotifications(route, false));
                    });
                    break;
            }

            return $q.all(routesEditPromises);
        }

        /**
         * Show a leaving confirmation alert after the user Refresh or Close the page/browser
         * when modifications in settings are in progress
         */
        window.addEventListener('beforeunload', function (event) {
            if ($scope.routeSelection?.inProgress) {
                event.preventDefault();
                event.returnValue = '';
            }
        });

        /**
         * Show a leaving confirmation alert after the user Backward, Forward, or Change vue
         * when modifications in settings are in progress
         */
        $scope.$on('$routeChangeStart', function (event) {
            if ($scope.routeSelection?.inProgress) {
                const message = $translate.instant('runs_settings_inProgressWarning');
                const okClick = confirm(message);
                // if user clicks on Cancel
                if (!okClick) {
                    event.preventDefault();
                    event.returnValue = '';
                }
            }
        });

        /** ******************************************************************************************************************* */
        //  SORTING
        /** ******************************************************************************************************************* */

        /**
         * Sorts then filters the routes table
         * @param {String} orderBy which header to order by
         */
        $scope.sortRoutes = function (orderBy) {
            $scope.sorting = true;
            // Makes sure the user is notified that the sorting is currently being run.
            $timeout(function () {
                if (orderBy) {
                    if ($scope.orderType.orderBy === orderBy) {
                        const ascending = !$scope.orderType.ascending;
                        $scope.orderType = {
                            orderBy: orderBy,
                            ascending: ascending,
                        };
                    } else {
                        const ascending = true;
                        $scope.orderType = {
                            orderBy: orderBy,
                            ascending: ascending,
                        };
                    }
                    // If the function was called from the routes table header, we activate route sorting by selection first
                    $scope.routeSelection.areRoutesSortedBySelection = orderBy === 'routeName';

                    loadRoutesTable($scope.routes).then(function () {
                        $scope.applyFilters();
                        $scope.sorting = false;
                        $scope.$apply();
                    });
                }
            });
        };

        $scope.sortTripHistory = function (orderBy) {
            if (orderBy) {
                if ($scope.orderType.orderBy === orderBy) {
                    const ascending = !$scope.orderType.ascending;
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: ascending,
                    };
                } else {
                    const ascending = true;
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: ascending,
                    };
                }
            }

            $scope.routeHistory.tripsToShow = sortingTools.sortTripHistory(
                $scope.routeHistory.tripsToShow,
                $scope.orderType.orderBy,
                $scope.orderType.ascending
            );
        };

        /**
         * Sorts Trip history without changing the order type
         * @param {Array<Object>} trips
         */
        const resetSortTripHistory = function () {
            $scope.routeHistory.tripsToShow = sortingTools.sortTripHistory(
                angular.copy($scope.routeHistory.allTrips),
                $scope.orderType.orderBy,
                $scope.orderType.ascending
            );
        };

        $scope.sortTripOfTheWeek = function (trips) {
            return new Promise(function (resolve) {
                loadTripOfTheWeekTable(trips).then(function (answer) {
                    resolve(answer);
                });
            });
        };

        /**
         * Load trips of the week table
         * @param {Array<Object>} trips
         * @return {Promise}
         */
        function loadTripOfTheWeekTable(trips) {
            return new Promise(function (resolve) {
                $timeout(function () {
                    resolve(sortingTools.sortTripOfTheWeek(trips, true));
                });
            });
        }
        /**
         * TODO :: Same filter than StudentsController.js l.51
         * and CollapsableStudentTable...
         * maybe have to make a | filter
         *  **/

        $scope.sortStudents = function (stop, orderBy) {
            $scope.sorting = true;
            // Makes sure the user is notified that the sorting is currently being run.
            $timeout(function () {
                if (orderBy) {
                    if (stop.orderType != null && stop.orderType.orderBy === orderBy && stop.orderType.ascending != null) {
                        stop.orderType = {
                            orderBy: orderBy,
                            ascending: !stop.orderType.ascending,
                        };
                    } else {
                        const ascending = true;
                        stop.orderType = {
                            orderBy: orderBy,
                            ascending: ascending,
                        };
                    }
                    loadStudentsTable(stop).then(function () {
                        // To make sure the last digest is not running anymore in a case where someone would spam the sorting buttons.
                        $timeout(function () {
                            $scope.sorting = false;
                            $scope.$apply();
                        }, 250);
                    });
                }
            });
        };

        /**
         * Loads students table for a stop
         * @param {Object} stop
         * @return {Promise}
         */
        function loadStudentsTable(stop) {
            return new Promise(function (resolve) {
                $timeout(function () {
                    const students = stop.students;
                    $scope.listOfStops[stop.sequenceNumber - 1].students = sortingTools.sortStudents(
                        students,
                        stop.orderType.orderBy,
                        stop.orderType.ascending
                    );
                    $scope.applyFilters();
                    resolve();
                });
            });
        }

        /** ******************************************************************************************************************* */
        //  LAYOUT
        /** ******************************************************************************************************************* */

        /**
         * Initialization of route details page map
         */
        function initializeRouteDetailsMap() {
            // Edit the route to give it a data structure that can be used with $scope.initializeGlobalMapForMultipleTrips()
            const route = [];
            const routeForMap = angular.copy($scope.route);
            // This is necessary for the map, as some variables that are in a route are needed in this form : routeForMap.route.x
            routeForMap.route = angular.copy($scope.route);

            routeForMap.status = 'planned';
            routeForMap.stops = $scope.listOfStops ? angular.copy($scope.listOfStops) : [];

            route.push(routeForMap);
            $scope.initializeGlobalMapForMultipleTrips('routeMap', route);
        }

        /**
         * When the window resizes, the route details map height resizes according to the Information panel height
         */
        $(window).bind('resize', function () {
            adjustRouteDetailsContainersHeight();
        });

        /**
         * Adjusts the map container height to be the same as Information panel content's
         */
        function adjustRouteDetailsContainersHeight() {
            const map = document.getElementById('routeMap');
            const info = document.getElementById('route-details-info-panel__content');

            // Resize map to Information box size.
            if (map && info) {
                const infoHeight = info.clientHeight;
                map.style.height = infoHeight + 'px';
            }
        }

        $scope.validateDoneLoading = function () {
            if (!$scope.loadingTrips && !$scope.loadingRouteInfo && !$scope.loadingStops) {
                $timeout(function () {
                    initializeRouteDetailsMap();
                    $scope.loading = false;
                    $scope.$apply();
                    adjustRouteDetailsContainersHeight();
                }, 0);
            }
        };

        $scope.toggleStudentList = function (event, stop) {
            $(event.currentTarget.attributes.href.nodeValue).collapse('toggle');
            stop.collapsed = !stop.collapsed;
            let collapsedIcon = false;
            $scope.listOfStops.some(function (item) {
                if (item.collapsed == false) {
                    collapsedIcon = true;
                    return true;
                }
            });
            $scope.stopCollapsedIcon = collapsedIcon;
        };

        /**
         * Toggle (show/hide) info about students in the stops/students list.
         */
        $scope.toggleAllStudentList = () => {
            let action;
            let collapsed;

            if ($scope.stopCollapsedIcon == false) {
                action = 'show';
                collapsed = false;
            } else {
                action = 'hide';
                collapsed = true;
            }

            for (let i = 0; i < $scope.listOfStops.length; i++) {
                $(`#collapse-${i}`).collapse(action);
                $scope.listOfStops[i].collapsed = collapsed;
            }

            $scope.stopCollapsedIcon = !collapsed;
        };

        // fix the erratic tooltip position especially with containers that have overflow: auto or overflow: scroll like .table-responsive for example.
        // see: https://stackoverflow.com/questions/41602487/bootstrap-tooltip-in-wrong-position-on-initial-hover-then-in-correct-position
        // eslint-disable-next-line no-undef
        $(document).ready(function () {
            // eslint-disable-next-line no-undef
            $('[data-toggle="tooltip"]').tooltip({
                boundary: 'window',
            });
        });

        // clears the tooltips on route change. This allows for clicking on trips and using keyboard navigation to go back to the previous page.
        $scope.$on('$routeChangeStart', function () {
            // eslint-disable-next-line no-undef
            $('[data-toggle="tooltip"]').tooltip('hide');
        });

        /**
         * If we are in Simplified view, we toggle to Advanced view (isSimplifiedView becomes false)
         * If we are in Advanced view, we toggle to Simplified view (isSimplifiedView becomes true)
         */
        $scope.toggleView = function () {
            $scope.isSimplifiedView = !$scope.isSimplifiedView;
            $scope.routesToShow = [];
            $scope.loading = true;
            fetchRoutes();
            // eslint-disable-next-line no-undef
            $('[data-toggle="tooltip"]').tooltip('hide');
        };

        /** ******************************************************************************************************************* */
        //  FILTERING
        /** ******************************************************************************************************************* */

        // Routes
        $scope.applyFilters = function () {
            $scope.filteredRoutes = $scope.routes.filter((route) => isRouteInFilters(route));
            $scope.routesToShow = paginateRoutes();
            $scope.showCSVButton = $scope.routesToShow.length > 0 ? true : false;

            /*  If more than one route is selected and we sorted the table afterwards, 
                routes selected are shown at the top of the table when we sort again or filter
                If a route selection checkbox is clicked, areRoutesSortedBySelection is reset to false */
            if (checkIfAnyRouteSelected() && $scope.routeSelection.areRoutesSortedBySelection) {
                $scope.routesToShow = sortingTools.sortRoutesByBatchSettingsSelection($scope.routesToShow, true);
            }

            checkIfAllRoutesAreSelected();
            // Useful for the absolute positioned settings dropdowns' width
            $scope.currentLanguage = $rootScope.language;

            $timeout(function () {
                $scope.$apply();
            });
        };

        /**
         * This function will be given to the filter component to be executed each time options are changing.
         */
        $scope.onFilterChange = () => {
            $scope.applyFilters();
        };

        /**
         * This function will be given to the input of filter component to be executed each time the input value changes.
         * @param {String} searchValue - the string in the search input text
         */
        $scope.onInputChange = (searchValue) => {
            $scope.goToPage(1);
            $scope.searchValue = searchValue;
            $scope.applyFilters();
        };

        /**
         * This function will be given to the reset button in the filter component to be executed on reset.
         */
        $scope.onReset = () => {
            $scope.searchValue = '';
            $scope.applyFilters();
        };

        /**
         * Validate if the route can be shown (considering the filters button and search input)
         * @param {Object} route - the route to validate
         * @return {Boolean}
         */
        const isRouteInFilters = (route) => {
            // AM PM Filter
            const timeSection = $scope.filterSections.find((section) => section.id === 'time');
            if (
                (!timeSection.options.find((option) => option.id === 'am').value && isAM(route)) ||
                (!timeSection.options.find((option) => option.id === 'pm').value && !isAM(route))
            ) {
                return false;
            }

            if (['agent', 'manager'].includes($rootScope.loggedUserRole)) {
                // Carriers filter
                const carriersSection = $scope.filterSections.find((section) => section.id === 'carriers');
                if (
                    (!Object.hasOwn(route, 'carrier') &&
                        !carriersSection.options.find((option) => option.name === $translate.instant('noCarrier')).value) ||
                    (Object.hasOwn(route, 'carrier') &&
                        route.carrier.name != null &&
                        !carriersSection.options.find((option) => option.name === route.carrier.name).value)
                ) {
                    return false;
                }
            } else {
                // Client filter
                const clientSection = $scope.filterSections.find((section) => section.id === 'clients');
                if (
                    (!Object.hasOwn(route.client, 'name') &&
                        !clientSection.options.find((option) => option.name === $translate.instant('noClient')).value) ||
                    (Object.hasOwn(route.client, 'name') && !clientSection.options.find((option) => option.name === route.client.name).value)
                ) {
                    return false;
                }
            }

            // text filter
            const validations = [];

            // Institutions filter
            const routeInstitutions = route.institutions.map((institution) => institution.name);

            const institutionsSection = $scope.filterSections.find((section) => section.id === 'institutions');
            const institutionsFilter = new Set(institutionsSection.options.filter((option) => option.value === true).map((option) => option.name));
            // Filter for route with no institutions
            if (!institutionsFilter.has($translate.instant('noSchool')) && route.institutions.length === 0) {
                return false;
            }
            // Filter for route with institutions
            else if (routeInstitutions.every((institution) => !institutionsFilter.has(institution)) && route.institutions.length > 0) {
                return false;
            }

            const dataToCompare = {
                routeName: route.name,
                routeBusNum: route.bus.number,
                routeInstitutions: routeInstitutions.join(' '),
            };

            if (['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
                dataToCompare.routeClient = route.client.name;
            } else {
                dataToCompare.routeCarrier = route.carrier?.name ?? '';
            }

            validations.push($scope.containsSearchValue(dataToCompare, $scope.searchValue));

            // APPLYING FILTERS
            // Validate if the route if favorite is filter is on
            if ($scope.filterOptions.showOnlyFavourites) {
                validations.push(route.isFavourite === true);
            }

            return validations.every((validation) => validation === true);
        };

        /**
         * Define if trip is in the morning or not
         * @param {Object} route - the route to validate
         * @return {Boolean}
         */
        const isAM = (route) => {
            // route.schedule.departure is a string it has to match a regex so we can check if it is am
            const departureTime = route.schedule.departure;
            const departureHour = departureTime.split(':')[0];
            const amRegex = /0[1-9]|1[0-1]/gm;
            return departureHour.match(amRegex) && departureHour.length === 2;
        };
        /**
         * Refresh the filter options
         */
        const resetFilterOptions = () => {
            // Setup base filters.
            $scope.filterSections = [
                {
                    id: 'time',
                    name: $translate.instant('time'),
                    options: [
                        {
                            id: 'am',
                            name: $translate.instant('AM'),
                            value: true,
                        },
                        {
                            id: 'pm',
                            name: $translate.instant('PM'),
                            value: true,
                        },
                    ],
                },
                {
                    id: 'carriers',
                    name: $translate.instant('carriers'),
                    options: [],
                },
                {
                    id: 'clients',
                    name: $translate.instant('clients'),
                    options: [],
                },
                {
                    id: 'institutions',
                    name: $translate.instant('school_institution'),
                    options: [],
                },
            ];

            const routes = $scope.routes;

            const institutionsSection = $scope.filterSections.find((section) => section.id === 'institutions');
            routes.forEach((route) => {
                if (route.institutions != null && route.institutions.length > 0) {
                    const institutionsMap = new Map(institutionsSection.options.map((option) => [option.name, option.value]));

                    route.institutions.forEach((institution) => {
                        if (!institutionsMap.has(institution?.name)) {
                            institutionsSection.options.push({
                                name: institution?.name ?? '',
                                value: true,
                            });
                            institutionsMap.set(institution?.name, true);
                        }
                    });
                }
            });

            // sorting institutions
            institutionsSection.options = sortingTools.sortInstitutions([...institutionsSection.options], 'name', true);

            if (!institutionsSection.options.find((option) => option.name === $translate.instant('noSchool'))) {
                institutionsSection.options.unshift({
                    name: $translate.instant('noSchool'),
                    value: true,
                });
            }

            if (['agent', 'manager'].includes($rootScope.loggedUserRole)) {
                // Generating filters for carriers
                const carriersSection = $scope.filterSections.find((section) => section.id === 'carriers');
                routes.forEach((route) => {
                    if (Object.hasOwn(route, 'carrier')) {
                        if (!carriersSection.options.find((option) => option?.name === route.carrier?.name)) {
                            carriersSection.options.push({
                                name: route?.carrier?.name ?? '',
                                value: true,
                            });
                        }
                    } else if (!carriersSection.options.find((option) => option.name === $translate.instant('noCarrier'))) {
                        carriersSection.options.push({
                            name: $translate.instant('noCarrier'),
                            value: true,
                        });
                    }
                });
                // sorting carriers
                const carriers = carriersSection.options;
                carriersSection.options = sortingTools.sortCarriers([...carriers], 'name', true);
            } else {
                // Generating filters for clients
                const clientsSection = $scope.filterSections.find((section) => section.id === 'clients');
                routes.forEach((route) => {
                    if (Object.hasOwn(route.client, 'name')) {
                        if (!clientsSection.options.find((option) => option.name === route.client.name)) {
                            clientsSection.options.push({
                                name: route.client.name,
                                value: true,
                            });
                        }
                    } else if (!clientsSection.options.find((option) => option.name === $translate.instant('noClient'))) {
                        clientsSection.options.push({
                            name: $translate.instant('noClient'),
                            value: true,
                        });
                    }
                });
                // sorting clients
                const clients = clientsSection.options;
                clientsSection.options = sortingTools.sortClients([...clients], 'name', true);
            }
        };

        // Route details' trip history
        $scope.applyHistoryFilters = function (searchValue) {
            const tripsToShow = [];
            for (const trip of $scope.routeHistory.allTrips) {
                if (passesHistoryFilters(trip, searchValue)) {
                    tripsToShow.push(trip);
                }
            }
            $scope.routeHistory.tripsToShow = sortingTools.sortTripHistory(tripsToShow, $scope.orderType.orderBy, $scope.orderType.ascending);
        };

        /**
         * Verify if a trip respect the filter conditions
         * @param {Object} trip
         * @param {String} searchValue
         * @return {Boolean}
         */
        function passesHistoryFilters(trip, searchValue) {
            const stringToCompare = {
                tripDate: $scope.formatDate(trip.schedule),
                notes: trip.notes,
            };

            const statusSection = $scope.historyFilterSections.find((section) => section.id === 'status');
            if (!statusSection.options.find((option) => option.name === $translate.instant(getDisplayedComputedStatus(trip)))?.value) {
                return false;
            }

            return $scope.containsSearchValue(stringToCompare, searchValue);
        }

        /** ******************************************************************************************************************* */
        //  DROPDOWN
        /** ******************************************************************************************************************* */

        $scope.toggleFilterDropdown = function () {
            $scope.showFilterDropdown = !$scope.showFilterDropdown;
        };

        $scope.collapseFilterDropdown = function () {
            if ($scope.showFilterDropdown) {
                $scope.showFilterDropdown = false;
            }
        };

        $scope.selectNoFilters = function () {
            Object.keys($scope.filterOptions).forEach((key) => {
                $scope.filterOptions[key] = false;
            });
            $scope.applyFilters();
        };

        $scope.selectAllFilters = function () {
            Object.keys($scope.filterOptions).forEach((key) => {
                $scope.filterOptions[key] = true;
            });
            $scope.applyFilters();
        };

        /**
         * Updates the state of the favourite filter (on/off)
         * @param {Boolean} newState
         */
        $scope.setFavouriteFiltering = (newState) => {
            $scope.filterOptions.showOnlyFavourites = newState;
            $scope.applyFilters();
        };

        $scope.checkIfNoFilterOptionsSelected = function () {
            return Object.values($scope.filterOptions).every((option) => option === false);
        };

        $scope.checkIfAllFilterOptionsSelected = function () {
            return Object.values($scope.filterOptions).every((option) => option === true);
        };

        /** ******************************************************************************************************************* */
        //  Routes List Generation as csv
        /** ******************************************************************************************************************* */

        $scope.generateGenericCSV = function () {
            const routesList = $scope.filteredRoutes;

            if (routesList.length === 0) {
                return [];
            }

            const config = {
                header: true,
                quotes: true,
            };

            const header = [];
            // retrieving header table's name
            const tableHeaders = document.getElementsByClassName('table--sortable-header');
            // Adding the ID header independently
            header.push('ID');

            for (const tableHeader of tableHeaders) {
                const headerName = tableHeader.innerText.toString().trim();
                if (headerName.length) {
                    header.push(headerName.replace(/\n/g, ' '));
                }
            }

            const csvRows = [];

            // Iterate through routes and push them to CSV
            for (const route of routesList) {
                let row;
                const routeInstitutions = route.institutions
                    .map((institution) => institution?.name ?? '') // Extract institution name
                    .filter((institutionName) => institutionName !== '') // Filter out empty strings
                    .join(' | '); // Join the non-empty institution names with ' | ' delimiter

                const routeOwnerName = ['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)
                    ? route.client.name
                    : route.carrier?.name;
                if ($scope.isSimplifiedView) {
                    row = [
                        route.id,
                        route.name,
                        route.bus.number,
                        routeOwnerName,
                        routeInstitutions,
                        route.schedule.departure,
                        convertBooleanToHumanReadable(route.preferences.studentsBoardingRegistrations),
                        convertNotificationsToHumanReadable(route.preferences.notifications),
                        convertBooleanToHumanReadable(route.preferences.automaticDelayNotifications),
                    ];
                } else {
                    row = [
                        route.id,
                        route.name,
                        route.bus.number,
                        routeOwnerName,
                        routeInstitutions,
                        route.schedule.departure,
                        route.schedule.arrival,
                        route.studentCount,
                        route.watcherCount,
                        convertBooleanToHumanReadable(route.preferences.studentsBoardingRegistrations),
                        convertNotificationsToHumanReadable(route.preferences.notifications),
                        convertBooleanToHumanReadable(route.preferences.automaticDelayNotifications),
                    ];
                }
                csvRows.push(row);
            }

            const csv = Papa.unparse(
                {
                    fields: header,
                    data: csvRows,
                },
                config
            );

            // Create the filename based on table title and date
            const today = moment().format('YYYY-MM-DD');
            const tableTitle = $translate.instant('runs');
            const filename = tableTitle + ' - ' + today + '.csv';

            $scope.downloadCSV(csv, filename);
        };

        /** ******************************************************************************************************************* */
        //   Route direction generation
        /** ******************************************************************************************************************* */

        $scope.getRouteDirectionPDF = function () {
            $scope.pdfLoadingRoute = true;
            $scope.generateRouteDirectionPDF($scope.route.id, () => {
                $scope.pdfLoadingRoute = false;
                $scope.$apply();
            });
        };

        /**
         * Convert boolean value to a localized yes or no
         * @param {Boolean} answer
         * @return {String} - returns the localized yes or no
         */
        const convertBooleanToHumanReadable = function (answer) {
            return answer ? $translate.instant('yes') : $translate.instant('no');
        };

        /**
         * Convert notifications value to its localized translation
         * @param {String} value the notification value
         * @return {String} - returns the localized string
         */
        const convertNotificationsToHumanReadable = function (value) {
            switch (value) {
                case 'none':
                    return $translate.instant('no');
                case 'vehicleOnly':
                    return $translate.instant('vehicle');
                case 'vehicleAndStudents':
                    return $translate.instant('vehicleStudentsNotifications');
            }
        };

        /** ******************************************************************************************************************* */
        //   Routes table pagination
        /** ******************************************************************************************************************* */

        /**
         * Function to update the displayed routes based on pagination
         * @param {Array} routes
         * @return {Array}
         */
        const paginateRoutes = function () {
            const start = ($scope.currentPage - 1) * $scope.itemsPerPage;
            const end = start + $scope.itemsPerPage;

            // Recalculate the array for pagination
            $scope.totalPages = Math.ceil($scope.filteredRoutes.length / $scope.itemsPerPage);
            $scope.pageArray = Array.from({ length: $scope.totalPages }, (_, i) => i + 1);

            // Scroll to the top of the page
            // eslint-disable-next-line no-undef
            window.scrollTo(0, 0);

            return $scope.filteredRoutes.slice(start, end);
        };

        // Next Page function
        $scope.nextPage = function () {
            if ($scope.currentPage < Math.ceil($scope.filteredRoutes.length / $scope.itemsPerPage)) {
                $scope.currentPage++;
                $scope.applyFilters();
            }
        };

        // Previous Page function
        $scope.prevPage = function () {
            if ($scope.currentPage > 1) {
                $scope.currentPage--;
                $scope.applyFilters();
            }
        };

        // Function to go to a specific page
        $scope.goToPage = function (page) {
            $scope.currentPage = page;
            $scope.applyFilters();
        };
    },
]);
