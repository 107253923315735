const { STATUS, ASSIGNEES } = require('../utils/constants');

// eslint-disable-next-line no-undef
angular.module('mTransportApp').controller('AdminUserController', [
    '$scope',
    '$translate',
    'AssignmentsManager',
    function ($scope, $translate, AssignmentsManager) {
        /* =======================================================================================================================
       INITIALIZATION
    =======================================================================================================================  */
        $scope.initializeUser = function () {
            $scope.loadingUser = true;
            $scope.loadingFeedbacks = true;
            $scope.feedbacks = [];
            $scope.userId = $scope.findVariableOnURL('id');
            fetchUser($scope.userId);
            $scope.fetchUserFeedbacks($scope.userId);
            $scope.statusOptions = Object.values(STATUS);
            $scope.assigneeOptions = Object.values(ASSIGNEES);
        };

        /* =======================================================================================================================
       DATA FETCH
    =======================================================================================================================  */
        /**
         * fetch user information
         * @param {String} userId the user targeted
         */
        function fetchUser(userId) {
            $scope.requestAdminGetUserDetails(userId).then(
                function (answer) {
                    $scope.user = answer;
                    // Format routes with assignment period status
                    if ($scope.user.role === 'driver') {
                        $scope.user.routes = AssignmentsManager.formatRoutesWithAssignmentStatus($scope.user.routes, $scope.user.busAssignments);
                    }
                    $scope.loadingUser = false;
                    $scope.$apply();
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        }

        /**
         * fetch user feedbacks
         * @param {String} userId the user from whom we would like to get the feedbacks
         */
        $scope.fetchUserFeedbacks = (userId) => {
            $scope.loadingFeedbacks = true;
            $scope
                .requestAdminGetFeedbacksFromUser(userId)
                .then((data) => {
                    $scope.feedbacks = data.feedbacks.map((feedback) => ({
                        ...feedback,
                        statusDropdownOpen: false,
                        assigneeDropdownOpen: false,
                    }));
                    $scope.loadingFeedbacks = false;
                    $scope.$apply();
                })
                .catch((err) => $scope.catchErrorDefault(err));
        };

        /* =======================================================================================================================
        LAYOUT AND DATA MANIPULATION
     =======================================================================================================================  */
        $scope.formatDate = function (date, format = 'LLL') {
            if (date) {
                return moment(date).format(format);
            } else {
                return '- - - - - - - - - -';
            }
        };

        // Change and patch feedback's property (status, assignedTo) on event
        $scope.$on('selectDropdown', (event, payload) => {
            const body = {};
            body[payload.property] = payload.value;
            $scope.requestAdminUpdateFeedback(payload.entity, body);
        });

        $scope.$on('collapseDropdowns', () => {
            $scope.feedbacks.forEach((feedback) => {
                feedback.statusDropdownOpen = false;
                feedback.assigneeDropdownOpen = false;
            });
        });
    },
]);
