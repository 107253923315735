/**
 * Component: btnReply
 * Html tags: </btn-reply>
 * @returns {HTMLElement}
 */

angular.module('mTransportApp').component('btnReply', {
    templateUrl: './shared/btnAdminReply/btnAdminReply.html',
    bindings: {
        email: '<',
        message: '<',
    },
});
