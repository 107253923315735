const { parsePhoneNumber } = require('libphonenumber-js');
const { SchoolYear } = require('../../shared/utils/SchoolYear');
const { icons } = require('../../assets/img/constants');

angular.module('mTransportApp').controller('StudentsController', [
    '$scope',
    '$timeout',
    'sortingTools',
    '$rootScope',
    '$translate',
    function ($scope, $timeout, sortingTools, $rootScope, $translate) {
        /* =======================================================================================================================
        STUDENTS LISTING
        =======================================================================================================================  */

        $scope.errorNotifications = function (error) {
            // Server errors
            $scope.triggerNotification('errorUnknownError');
            $scope.loading = false;
            $scope.$apply();
        };

        /**
         * Entry point
         */
        $scope.initializeStudents = function () {
            initializeScopeVariablesForStudents();
            $scope.setFocus('search');
            fetchStudents();
        };

        /**
         * Initialize scope variables and students
         */
        function initializeScopeVariablesForStudents() {
            $scope.loading = true;
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
            $scope.searchValue = {
                value: '',
            };

            $scope.showCSVButton = true;
            $scope.orderType = {
                orderBy: 'internalID',
                ascending: true,
            };
            $scope.isRemoveNoteModalDisplayed = false;
            $scope.modalLoading = false;

            $scope.seeAllHistory = false;

            $scope.students = []; // Contains all the students
            $scope.filteredStudents = []; // Contains all the students that matches filters
            $scope.studentsToShow = []; // Contains sorted and paginated students to display
            $scope.currentPage = 1; // Initialize current page
            $scope.itemsPerPage = 100; // Items per page
        }

        // fix the erratic tooltip position especially with containers that have overflow: auto or overflow: scroll like .table-responsive for example.
        // see: https://stackoverflow.com/questions/41602487/bootstrap-tooltip-in-wrong-position-on-initial-hover-then-in-correct-position
        // eslint-disable-next-line no-undef
        $(document).ready(function () {
            // eslint-disable-next-line no-undef
            $('[data-toggle="tooltip"]').tooltip({
                boundary: 'window',
            });
        });

        // clears the tooltips on route change. This prevents the tooltip from staying on screen while using keyboard navigation.
        $scope.$on('$routeChangeStart', function () {
            // eslint-disable-next-line no-undef
            $('[data-toggle="tooltip"]').tooltip('hide');
        });

        const schoolYear = new SchoolYear();

        /**
         * Checks if a given date is within the current school year.
         * @param {String} date
         * @return {Boolean}
         */
        $scope.isInSchoolYear = function (date) {
            return schoolYear.isInSchoolYear(date);
        };

        /**
         * Data gathering
         */
        function fetchStudents() {
            // Original time for Request Students : between 2.539 seconds and 4.5 seconds
            $scope.angRequestStudents().then(
                function (data) {
                    // Original time for loadStudentsTable : 2.958 seconds OR BETWEEN 200 AND 400MS with caching
                    const students = data.data.students;

                    for (const i in students) {
                        if (!students[i].hasOwnProperty('institutionName')) {
                            const student = students[i];
                            let institutionName = '';
                            for (const j in student.institutions) {
                                institutionName += student.institutions[j].name + ',';
                            }
                            students[i].institutionName = institutionName;
                        }
                    }
                    $scope.students = students;
                    $scope.loadStudentsTable();
                },
                function (data, status) {
                    // Don't display error message if request was manually aborted
                    if (status !== 'abort') {
                        $rootScope.$broadcast('request-error-main', data);
                        $scope.loading = false;
                    }
                }
            );
        }

        /**
         * Load students table
         * @return {void}
         */
        $scope.loadStudentsTable = function () {
            if (!$scope.registeringDisplayFunctions) {
                $scope.registeringDisplayFunctions = true;
                $scope.filteredStudents = sortingTools.sortStudents(
                    $scope.applyFilters([...$scope.students]),
                    $scope.orderType.orderBy,
                    $scope.orderType.ascending
                );
                $scope.studentsToShow = paginateStudents();
                $scope.loading = false;
                $scope.sorting = false;
                $scope.registeringDisplayFunctions = false;
            }
        };

        /**
         * Sort students
         * @param {String} orderBy
         */
        $scope.sortStudents = function (orderBy) {
            $scope.sorting = true;

            // Makes sure the user is notified that the sorting is currently being run.
            if (orderBy) {
                if ($scope.orderType.orderBy === orderBy) {
                    const ascending = !$scope.orderType.ascending;
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: ascending,
                    };
                } else {
                    const ascending = true;
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: ascending,
                    };
                }
                $scope.loadStudentsTable();
            }
        };

        /**
         * Apply filters
         * @param {Array<Object>} students
         * @return {Array<Object>}
         */
        $scope.applyFilters = function (students) {
            if ($scope.searchValue.value?.length > 0 !== true) {
                return students;
            }

            return students.filter((student) => {
                const dataToCompare = {
                    studentID: student.internalId,
                    studentFirstName: student.firstName,
                    studentLastName: student.lastName,
                    studentInstitutions: student.institutionName,
                };

                return $scope.containsSearchValue(dataToCompare, $scope.searchValue.value);
            });
        };

        /* =======================================================================================================================
       STUDENT INFO
    =======================================================================================================================  */

        /**
         * Main function for student info page (called in ng-init in studentInfo.html)
         * Calls initializeScopeVariablesForStudentInfo()
         * Then AJAX call to get student details info
         */
        $scope.loadStudentInfo = function () {
            initializeScopeVariablesForStudentInfo();

            $scope.loading = true;

            $scope.loadingNotes = true;
            $scope.studentRoutesNotes = [];
            $scope.studentRoutesNotesToShow = [];
            $scope.studentRoutesNotesToggleStatus = 'effective';

            $scope.orderTypeReports = {
                orderBy: 'date',
                ascending: true,
            };
            $scope.studentId = $scope.getIdFromURL();
            $scope.requestStudentDetail($scope.studentId).then(
                (data) => {
                    $scope.student = data;
                    $scope.loadStudentStops();
                },
                (error) => {
                    $scope.loadStudentStops();
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /**
         * Initialize scope variables for studentInfo page
         */
        function initializeScopeVariablesForStudentInfo() {
            $scope.modalLoading = false;

            $scope.studentId = $scope.getIdFromURL();

            $scope.orderTypeReports = {
                orderBy: 'date',
                ascending: true,
            };

            // Add note modal : new note object
            $scope.newNote = {
                routeSelected: '',
                startDate: '',
                endDate: '',
                textContent: '',
                startDateModel: '',
                endDateModel: '',
            };

            $scope.isAddNoteModalDisplayed = false;
        }

        /* =======================================================================================================================
       STUDENT INFO : [ADD NOTE MODAL] 
    =======================================================================================================================  */

        $scope.openAddNoteModal = function () {
            $scope.isAddNoteModalDisplayed = true;
        };

        /**
         * ADD NOTE MODAL : Form submit and AJAX call
         * @param {Object} form the submitted newNote form
         */
        $scope.submitAddNoteForm = function (form) {
            if (form.$valid) {
                $scope.modalLoading = true;

                const promises = [];
                if ($scope.newNote.routeSelected.id !== 'all') {
                    const data = formatAddNoteData($scope.newNote.routeSelected);
                    promises.push($scope.angRequestAddStudentNote($scope.studentId, data));
                } else {
                    $scope.addNoteRoutesOptions.forEach((route) => {
                        if (route.id !== 'all') {
                            const routeData = formatAddNoteData(route);
                            promises.push($scope.angRequestAddStudentNote($scope.studentId, routeData));
                        }
                    });
                }

                Promise.all(promises)
                    .then(() => {
                        refreshStudentRoutes();
                        $scope.resetAddNoteModalVars();
                        $scope.isAddNoteModalDisplayed = false;
                    })
                    .catch((error) => {
                        switch (error.status) {
                            case 404:
                                $scope.isAddNoteModalDisplayed = false;
                                error.key = $translate.instant('error404');
                                // Triggers the error banner in the notes table
                                $rootScope.$broadcast('request-error-student-routes-notes', error);
                                break;
                            case 400:
                                switch (error.data.error.code) {
                                    case 1001:
                                        error.key = $translate.instant('messageErrorInvalidParameters');
                                        break;
                                    case 1022:
                                        error.key = $translate.instant('messageErrorStudentNotPartOfRun');
                                        break;
                                    case 1023:
                                        error.key = $translate.instant('messageErrorEndDateBeforeStartDate');
                                        break;
                                    default:
                                        break;
                                }
                                // Triggers the error banner inside modal
                                $rootScope.$broadcast('request-error-student-add-note', error);
                                break;
                            default:
                                $rootScope.$broadcast('request-error-main', error);
                                break;
                        }
                    })
                    .finally(() => {
                        $scope.modalLoading = false;
                    });
            }
        };

        /**
         * Formating add note body data as per expected by backend for a route
         * @param {Object} route
         * @param {String} route.id
         * @return {Object}
         */
        function formatAddNoteData(route) {
            const data = {
                note: $scope.newNote.textContent,
                routeId: route.id,
            };

            // Adding optional body params to data (checking both models to know whether the user has emptied the inputs manually or not)
            if ($scope.newNote.startDateToSend && $scope.newNote.startDateModel) data.startDate = $scope.newNote.startDateToSend;
            if ($scope.newNote.endDateToSend && $scope.newNote.endDateModel) data.endDate = $scope.newNote.endDateToSend;

            return data;
        }

        /**
         * Watching the scope variables that represent the start and end dates for a note
         * This replaces ng-change in the dates inputs in the html, as ng-change is only triggered when "valid" dates are inputted
         * And does not take into account user-inputted empty dates
         */
        $scope.$watchGroup(['newNote.startDateModel', 'newNote.endDateModel'], function () {
            validateNoteDates();
        });

        /**
         * Custom form validator
         * Checks if the note start date is before or after the note end date and sets validity of both inputs accordingly
         * (side note: needs to be a function expression with an arrow function for the "this" keyword to be bound to the "this" of its parent lexical scope, in our case the instance of controller)
         */
        const validateNoteDates = () => {
            if ($scope.newNote) {
                // If start date or end date is empty, date is valid
                if (this.studentInfoNoteAddForm && (!$scope.newNote.endDateModel || !$scope.newNote.startDateModel)) {
                    this.studentInfoNoteAddForm.endDate.$setValidity('datesValidity', true);
                    this.studentInfoNoteAddForm.startDate.$setValidity('datesValidity', true);
                }
                // If there is an end date (we don't need to validate anything if no end date was selected)
                else if ($scope.newNote.endDate) {
                    const isDateValid = $scope.newNote.startDate <= $scope.newNote.endDate;
                    this.studentInfoNoteAddForm.endDate.$setValidity('datesValidity', isDateValid);
                    this.studentInfoNoteAddForm.startDate.$setValidity('datesValidity', isDateValid);
                }
            }
        };

        /**
         * Create add user modal form date pickers (start date and end date) via jQuery dateRangerPicker lib
         */
        $scope.initializeDatePickers = () => {
            // Once Document is ready
            $(() => {
                // Initialize start date picker
                $('#student-info__note-add-form__start-date-picker').daterangepicker(
                    {
                        singleDatePicker: true,
                        opens: 'right',
                        autoUpdateInput: false,
                        showDropdowns: true,
                    },
                    // Once a date is selected
                    (selectedDate) => {
                        // We populate $scope.newNote.startDate with the formatted selectedDate
                        $scope.newNote.startDate = moment(selectedDate).format('YYYY-MM-DD');
                        $scope.newNote.startDateToSend = moment(selectedDate).startOf('day').utc();
                        // We update the input value with jquery and trigger input event so that the newNote.startDateModel is populated
                        $('#student-info__note-add-form__start-date-picker').val($scope.newNote.startDate).trigger('input');
                        // Calling custom form validator
                        validateNoteDates();
                    }
                );
                //  Adding an event listener for when the start date calendar is displayed
                $('#student-info__note-add-form__start-date-picker').on('show.daterangepicker', () => {
                    // If no start date has been selected yet(first time the calendar is displayed) we populate $scope.newNote.startDate with the formatted date of today
                    if (!$scope.newNote.startDate) {
                        $scope.newNote.startDate = moment().format('YYYY-MM-DD');
                        $scope.newNote.startDateToSend = moment().startOf('day').utc();
                    }

                    // We update the input value with jquery and trigger input event so that the newNote.startDateModel is populated
                    $('#student-info__note-add-form__start-date-picker').val($scope.newNote.startDate).trigger('input');
                    // Calling custom form validator
                    validateNoteDates();
                });

                // Initialize end date picker
                $('#student-info__note-add-form__end-date-picker').daterangepicker(
                    {
                        singleDatePicker: true,
                        opens: 'right',
                        autoUpdateInput: false,
                        showDropdowns: true,
                    },
                    // Once a date is selected
                    (selectedDate) => {
                        // We populate $scope.newNote.endDate with the formatted selectedDate
                        $scope.newNote.endDate = moment(selectedDate).format('YYYY-MM-DD');
                        $scope.newNote.endDateToSend = moment(selectedDate).endOf('day').utc();
                        // We update the input value with jquery and trigger input event so that the newNote.endDateModel is populated
                        $('#student-info__note-add-form__end-date-picker').val($scope.newNote.endDate).trigger('input');
                        // Calling custom form validator
                        validateNoteDates();
                    }
                );

                //  Adding an event listener for when the end date calendar is displayed
                $('#student-info__note-add-form__end-date-picker').on('show.daterangepicker', () => {
                    // If no end date has been selected yet(first time the calendar is displayed) we populate $scope.newNote.endDate with the formatted date of today
                    if (!$scope.newNote.endDate) {
                        $scope.newNote.endDate = moment().format('YYYY-MM-DD');
                        $scope.newNote.endDateToSend = moment().endOf('day').utc();
                    }
                    // We update the input value with jquery and trigger input event so that the newNote.endDateModel is populated
                    $('#student-info__note-add-form__end-date-picker').val($scope.newNote.endDate).trigger('input');
                    // Calling custom form validator
                    validateNoteDates();
                });
            });
        };

        /**
         * Function to reset Add Note modal variables
         */
        $scope.resetAddNoteModalVars = function () {
            $scope.newNote.routeSelected = '';
            $scope.newNote.startDate = '';
            $scope.newNote.startDateToSend = '';
            $scope.newNote.endDate = '';
            $scope.newNote.endDateToSend = '';
            $scope.newNote.textContent = '';
            $scope.newNote.startDateModel = '';
            $scope.newNote.endDateModel = '';
        };

        /* =======================================================================================================================
       STUDENT INFO : [/ADD NOTE MODAL]
        ======================================================================================================================= */

        /* =======================================================================================================================
       [STUDENT INFO : NOTES FUNCTIONS]
    =======================================================================================================================  */

        /**
         * Click handler for the radio-toggle-notes component
         * Populates $scope.studentRoutesNotesToggleStatus with the value passed as parameter (either 'all' or 'effective')
         * Loads the routes' notes of the student into $scope.studentRoutesNotesToShow (notes displayed in the view) after filtering them
         * @param {String} toggleStatus
         */
        $scope.handleNotesToggleClick = function (toggleStatus) {
            $scope.studentRoutesNotesToggleStatus = toggleStatus;
            $scope.studentRoutesNotesToShow = filterStudentRoutesNotes();
            if (toggleStatus === 'all') {
                $scope.openIfAvailableCountRouteNotes();
            } else if (toggleStatus === 'effective') {
                $scope.openIfEffectiveCountRouteNotes();
            }
        };

        /**
         * Filters an array of notes depending on $scope.studentRoutesNotesToggleStatus value (either 'all' or 'effective')
         * @return {Array} filtered route notes
         */
        function filterStudentRoutesNotes() {
            let filteredStudentRoutesNotes = $scope.studentRoutesNotes;

            if ($scope.studentRoutesNotesToggleStatus === 'effective') {
                filteredStudentRoutesNotes = $scope.studentRoutesNotes.filter((note) => note.isEffective && note.removedAt == null);
            }
            return filteredStudentRoutesNotes;
        }

        /**
         * Filter array of $scope.routeNotes and return count of notes not removed
         * @return {Number} Count of notes not removed
         */
        function availableCountRouteNotes() {
            const availableRouteNotes = $scope.studentRoutesNotes.filter((note) => note.removedAt == null);
            return availableRouteNotes.length;
        }

        /**
         *Filter array of $scope.routeNotes and return count of notes not removed and effective
         * @return {Number} Count of effective routes notes
         */
        function effectiveCountRouteNotes() {
            const effectiveRouteNotes = $scope.studentRoutesNotes.filter((note) => note.removedAt == null && note.isEffective);
            return effectiveRouteNotes.length;
        }

        /* =======================================================================================================================
        STUDENT INFO : [REMOVE NOTE MODAL]
        =======================================================================================================================  */

        $scope.openRemoveNoteModal = function (noteToRemove) {
            $scope.noteToRemove = noteToRemove;
            $scope.isRemoveNoteModalDisplayed = true;
        };

        /**
         * Remove note for a student
         * ==> Success: fetchStudentRoutes()
         * ==> Error: displays an error banner and hide modal
         * ==> Then removes loading mode and hide modal
         */
        $scope.removeStudentNote = function () {
            $scope.loadingNotes = true;
            $scope.angRequestRemoveNoteForAStudent($scope.studentId, $scope.noteToRemove.id).then(
                function () {
                    fetchStudentRoutes();
                },
                function (error) {
                    // Triggers the error banner in the notes table
                    $rootScope.$broadcast('request-error-student-routes-notes', error);
                }
            );
            $scope.loadingNotes = false;
            $scope.isRemoveNoteModalDisplayed = false;
        };

        /* =======================================================================================================================
    [/STUDENT INFO:  NOTES FUNCTIONS]
    =======================================================================================================================  */

        $scope.getFullDate = function (timestamp) {
            return moment(timestamp).format('LL') + ' ' + moment(timestamp).format('HH:mm:ss');
        };

        $scope.getFormatedDate = function (timestamp) {
            return moment(timestamp).format('LLL');
        };

        $scope.loadStudentStops = function () {
            if ($scope.studentId) {
                $scope.requestStudentStops($scope.studentId).then(
                    function (data) {
                        $scope.studentStops = data.stops;
                        $scope.loadStudentHistory($rootScope.loggedUserRole == 'guardian');
                        fetchStudentRoutes();
                        // Client and carrier users can see disciplinary reports section and see the stop icon if institution or transfer
                        $scope.authorizedRolesClientCarrier = ['dispatcher', 'observer', 'manager', 'agent', 'carrier_observer', 'carrier_manager'];

                        if ($scope.authorizedRolesClientCarrier.includes($rootScope.loggedUserRole)) {
                            $scope.loadStudentDisciplinaryReport();
                        }
                    },
                    function (error) {
                        $rootScope.$broadcast('request-error-student-stops', error);
                        $scope.loadStudentHistory($rootScope.loggedUserRole == 'guardian');
                        fetchStudentRoutes();
                        if ($scope.authorizedRolesClientCarrier.includes($rootScope.loggedUserRole)) {
                            $scope.loadStudentDisciplinaryReport();
                        }
                    }
                );
            }
        };

        $scope.loadStudentDisciplinaryReport = function () {
            if ($scope.studentId) {
                $scope.angRequestGetDispatchDisciplinaryReports($scope.studentId).then(
                    function (data) {
                        $scope.studentDisciplinaryReport = [...data.data.forms];
                        $scope.studentDisciplinaryReport.forEach(
                            (report) => (report.createdAt = `${moment(report.createdAt).format('L')} - ${moment(report.createdAt).format('HH:mm')}`)
                        );

                        $scope.countDisciplinaryReports = $scope.studentDisciplinaryReport.filter((report) => {
                            if (report.type !== 'disciplinary') return false;
                            return $scope.isInSchoolYear(report.createdAt);
                        }).length;
                        $scope.sortDisciplinaryReports('date');
                    },
                    function (error) {
                        $rootScope.$broadcast('request-error-student-disciplinary-report', error);
                    }
                );
            }
        };

        $scope.sortDisciplinaryReports = function (orderBy) {
            let ascending;
            if (orderBy) {
                if ($scope.orderTypeReports.orderBy === orderBy) {
                    ascending = !$scope.orderTypeReports.ascending;
                    $scope.orderTypeReports = {
                        orderBy: orderBy,
                        ascending: ascending,
                    };
                } else {
                    ascending = true;
                    $scope.orderTypeReports = {
                        orderBy: orderBy,
                        ascending: ascending,
                    };
                }
            }
            $scope.studentDisciplinaryReport = sortingTools.sortDisciplinaryReports(
                $scope.studentDisciplinaryReport,
                $scope.orderTypeReports.orderBy,
                $scope.orderTypeReports.ascending
            );
        };

        /**
         * If studentDisciplinaryReport is undefined, meaning there has been a bad Ajax request, display an error banner
         * @return {Boolean}
         */
        $scope.disciplinaryReportIsUndefined = function () {
            return angular.isUndefined($scope.studentDisciplinaryReport);
        };

        /**
         * Fetches the student routes and sorts them
         * Extracts the student notes from the routes and sorts them
         */
        function fetchStudentRoutes() {
            if ($rootScope.loggedUserRole != 'guardian') {
                $scope.requestStudentRoutes($scope.studentId).then(
                    function (data) {
                        $scope.studentRoutes = sortingTools.sortRoutes(data.routes, 'startTime', true);
                        $scope.addNoteRoutesOptions = $scope.studentRoutes.filter((route) => route.requestingUserIsAuthorized);
                        $scope.addNoteRoutesOptions = $scope.addNoteRoutesOptions.map((route) => ({
                            ...route,
                            displayName: `${route.name} (${route.schedule.departure})`,
                        }));
                        $scope.addNoteRoutesOptions.push({
                            id: 'all',
                            name: $translate.instant('allRuns'),
                            displayName: $translate.instant('allRuns'),
                        });
                        $scope.studentRoutesNotes = [];
                        $scope.studentRoutes.forEach((studentRoute) => {
                            studentRoute.studentNotes.forEach((studentNote) => {
                                $scope.studentRoutesNotes.push({ route: { id: studentRoute.id, name: studentRoute.name }, ...studentNote });
                            });
                        });
                        $scope.studentRoutesNotes = sortingTools.sortStudentNotes($scope.studentRoutesNotes, 'createdAt', false);
                        $scope.studentRoutesNotesToShow = filterStudentRoutesNotes();
                        $scope.availableCountRouteNotes = availableCountRouteNotes();
                        $scope.effectiveCountRouteNotes = effectiveCountRouteNotes();
                        $scope.loadingNotes = false;
                        $scope.openIfEffectiveCountRouteNotes();
                        fetchStudentGuardians();
                    },
                    function (error) {
                        $scope.catchErrorDefault(error, false);
                        $rootScope.$broadcast('request-error-student-routes-notes', error);
                        $scope.loadingNotes = false;
                        $scope.studentRoutes = null;
                        fetchStudentGuardians();
                    }
                );
            } else {
                $scope.studentRoutes = null;
                fetchStudentGuardians();
            }
        }

        /**
         * Refreshes the data for student routes notes
         */
        function refreshStudentRoutes() {
            $scope.requestStudentRoutes($scope.studentId).then(
                function (data) {
                    // No need to refresh the $scope.studentRoutes variable
                    const routes = data.routes;

                    routes.forEach((studentRoute) => {
                        studentRoute.studentNotes.forEach((studentNote) => {
                            // if the note is not already in $scope.studentRoutesNotes (in case someone else has added a note in the meantime)
                            if (!$scope.studentRoutesNotes.some((studentRouteNote) => studentRouteNote.id == studentNote.id))
                                $scope.studentRoutesNotes.push({ route: { id: studentRoute.id, name: studentRoute.name }, ...studentNote });
                        });
                    });

                    $scope.studentRoutesNotes = sortingTools.sortStudentNotes($scope.studentRoutesNotes, 'createdAt', false);
                    $scope.studentRoutesNotesToShow = filterStudentRoutesNotes();
                    $scope.availableCountRouteNotes = availableCountRouteNotes();
                    $scope.effectiveCountRouteNotes = effectiveCountRouteNotes();
                    $scope.openIfEffectiveCountRouteNotes();
                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-student-routes-notes', error);
                }
            );
        }

        /**
         * Opens the route notes table on click of the radio button 'effective', only if there is at least one route. Otherwise, nothing is changed.
         */
        $scope.openIfEffectiveCountRouteNotes = function () {
            if ($scope.effectiveCountRouteNotes > 0) {
                if (!$('#notesTab').hasClass('show') && !$('#notesTab').hasClass('collapsing')) {
                    $('#notesPanelHeader').triggerHandler('click');
                    $('#notesTab').collapse('show');
                }
            }
        };

        /**
         * Opens the route notes table on click of the radio button 'all', only if there is at least one route. Otherwise, nothing is changed.
         */
        $scope.openIfAvailableCountRouteNotes = function () {
            if ($scope.availableCountRouteNotes > 0) {
                if (!$('#notesTab').hasClass('show') && !$('#notesTab').hasClass('collapsing')) {
                    $('#notesPanelHeader').triggerHandler('click');
                    $('#notesTab').collapse('show');
                }
            }
        };

        /* =======================================================================================================================
    [/STUDENT INFO:  HISTORY FUNCTIONS]
    =======================================================================================================================  */
        $scope.toggleStudentHistory = () => {
            $scope.seeAllHistory = !$scope.seeAllHistory;
            $scope.seeAllHistory ? $scope.loadStudentHistory(true) : $scope.loadStudentHistory(false);
        };

        /**
         * Load student history for child events only or as a guardian would see it (with other events and notifications)
         * @param {Boolean} asGuardian
         * @param {String} startDate
         * @param {String} endDate
         */
        $scope.loadStudentHistory = function (asGuardian, startDate = null, endDate = null) {
            const query = {
                asGuardian: asGuardian, // Base params
            };

            if (asGuardian != true) {
                // When not querying as guardian, add startDate and endDate params to the query
                query.startDate = startDate ?? moment().startOf('day').subtract(31, 'days').toISOString();
                query.endDate = endDate ?? moment().startOf('day').add(14, 'days').toISOString();
            }

            if ($scope.studentId) {
                const promise =
                    $rootScope.loggedUserRole === 'guardian'
                        ? $scope.requestStudentHistoryForGuardians($scope.studentId)
                        : $scope.requestStudentHistory($scope.studentId, query);
                promise.then(
                    function (data) {
                        $scope.panelTitle = $scope.seeAllHistory ? $translate.instant('messagesToGuardians') : $translate.instant('studentStatus');
                        const unsortedHistory = data.history;
                        $scope.studentHistory = sortingTools.sortStudentHistory(unsortedHistory, 'timestamp', false);

                        $scope.loadStudentHistoryPage = false;
                        $scope.$apply();
                    },
                    function (error) {
                        const response = JSON.parse(error.responseText).error;
                        if (response.code === 1011) {
                            $scope.triggerNotification('errorInvalidDateRangeTooBig');
                        } else {
                            $rootScope.$broadcast('request-error-student-history', error);
                        }
                        $scope.loadStudentHistoryPage = false;
                        $scope.studentHistory = [];
                        $scope.$apply();
                    }
                );
            }
        };

        /**
         * Hide all loaders
         */
        function hideAllLoaders() {
            const stopLoaderElement = document.getElementById('loading-student-stops');
            const historyLoaderElement = document.getElementById('loading-student-history');
            const studentLoaderElement = document.getElementById('loading-student-info');
            const guardiansLoaderElement = document.getElementById('loading-student-guardians');
            const studentLoaderDisciplinaryReport = document.getElementById('loading-student-disciplinary-report');
            if (stopLoaderElement && historyLoaderElement && studentLoaderElement && guardiansLoaderElement) {
                stopLoaderElement.style.display = 'none';
                historyLoaderElement.style.display = 'none';
                studentLoaderElement.style.display = 'none';
                guardiansLoaderElement.style.display = 'none';
                studentLoaderDisciplinaryReport.style.display = 'none';
            }
        }

        /**
         * Fetch guardians of student
         */
        function fetchStudentGuardians() {
            if ($rootScope.loggedUserRole != 'guardian') {
                $scope.requestStudentGuardians($scope.studentId).then(
                    function (data) {
                        $scope.studentGuardians = data.guardians;

                        // For each student Guardian, if there is a phone number, call formatPhoneNumber on it and mutates the $scope.studentGuardians array
                        $scope.studentGuardians.forEach((guardian) => {
                            if (guardian.phoneNumber) {
                                guardian.phoneNumber = formatPhoneNumber(guardian.phoneNumber);
                            }
                        });

                        $timeout(function () {
                            $scope.loading = false;
                            $scope.$apply();
                            hideAllLoaders();
                        });
                    },
                    function (error) {
                        $rootScope.$broadcast('request-error-student-guardians', error);

                        $scope.displayGuardians = true;
                        $scope.studentGuardians = null;
                        $timeout(function () {
                            $scope.loading = false;
                            $scope.$apply();
                            hideAllLoaders();
                        });
                    }
                );
            } else {
                $scope.studentGuardians = null;
                $timeout(function () {
                    $scope.loading = false;
                    $scope.$apply();
                    hideAllLoaders();
                });
            }
        }

        /**
         * Function that parses and formats a phone number to CA National display
         * @param {string} phoneNumber Phone number
         * @return {string} Formatted phone number to CA National
         */
        function formatPhoneNumber(phoneNumber) {
            /* Parses the phone number and adds the international canada code to it
            If it cannot parse it, will return 'undefined'*/
            const parsedPhoneNumber = parsePhoneNumber(phoneNumber, 'CA');

            /* If parsedPhoneNumber is defined, then we return it formatted to National. 
            Else we return the phoneNumber untouched */
            return parsedPhoneNumber ? parsedPhoneNumber.formatNational() : phoneNumber;
        }

        /* Students List Generation as csv ======================================================================================================= */

        $scope.generateGenericCSV = function () {
            const studentsList = [...$scope.filteredStudents];
            // verify if studentsList is empty
            // TODO:: maybe display error notif ?
            if (!studentsList) {
                return;
            }

            const config = {
                header: true,
                quotes: true,
            };

            const header = [];
            // retrieving header table's name
            const tableHeaders = document.getElementsByClassName('table--sortable-header');
            // Adding the ID header independently
            header.push('ID');
            for (const tableHeader of tableHeaders) {
                const headerName = tableHeader.innerText.toString().trim();
                header.push(headerName);
            }

            const csv_rows = [];

            // Iterate through students and push them to CSV
            for (const student of studentsList) {
                const institutions = student.institutions;
                const institution_list = '';
                for (const j in institutions) {
                    institution_list += institutions[j].name;
                    if (j < institutions.length - 1) {
                        institution_list += +' & ';
                    }
                }
                const row = [student.id, student.internalId, student.lastName, student.firstName, institution_list];
                csv_rows.push(row);
            }

            const csv = Papa.unparse(
                {
                    fields: header,
                    data: csv_rows,
                },
                config
            );

            // Create the filename based on table title and date
            const today = moment().format('YYYY-MM-DD');
            const tableTitle = $translate.instant('students');
            const filename = tableTitle + ' - ' + today + '.csv';

            $scope.downloadCSV(csv, filename);
        };

        /* =======================================================================================================================
            STUDENT HISTORY
        =======================================================================================================================  */

        /**
         * Initialize student history
         */
        $scope.initStudentHistory = function () {
            $scope.studentId = $scope.getIdFromURL();
            $scope.loading = true;
            $scope.loadStudentHistoryPage = true;

            // By default, the date range is set to the last month
            const startDate = moment().startOf('day').subtract(1, 'month');
            const endDate = moment().endOf('day');
            $scope.dateRange = {
                start: startDate.format(),
                end: endDate.format(),
            };

            $scope.requestStudentDetail($scope.studentId).then(
                (data) => {
                    $scope.student = data;
                    $scope.loadStudentHistory(false, startDate.toISOString(), endDate.toISOString());
                    $scope.scrollToTop();
                },
                (error) => {
                    $scope.catchErrorDefault(error);
                }
            );
            $scope.loading = false;
        };

        $scope.initializeStudentHistoryDateRange = function () {
            $(function () {
                const startDate = $scope.dateRange.start ?? moment();
                const endDate = $scope.dateRange.end ?? moment();

                // Create the date range / date pickers through JQUERY, for it does not support angular.
                // Note that the dates are using moment.js instead of the default Javascript date object.
                $('#history-dateRangeInput').daterangepicker(
                    {
                        alwaysShowCalendars: true,
                        autoApply: true,
                        opens: 'right',
                        startDate: startDate,
                        endDate: endDate,
                        showCustomRangeLabel: false,
                        autoUpdateInput: false,
                    },
                    function (start, end, label) {
                        const dateRange = {
                            start: moment(start).format(),
                            end: moment(end).format(),
                        };
                        $scope.dateRange = dateRange;

                        $('#history-dateRangeInput').val(
                            moment($scope.dateRange.start, 'YYYY-MM-DD').format('YYYY-MM-DD') +
                                ' - ' +
                                moment($scope.dateRange.end, 'YYYY-MM-DD').format('YYYY-MM-DD')
                        );
                    }
                );

                $('#history-dateRangeInput').on('show.daterangepicker', function (ev, picker) {
                    // When there is no date range defined, set it to start and end of day
                    if (!$scope.dateRange?.start) {
                        const dateRange = {
                            start: moment().startOf('day').format(),
                            end: moment().endOf('day').format(),
                        };
                        $scope.dateRange = dateRange;

                        $('#history-dateRangeInput').val(
                            moment(dateRange.start, 'YYYY-MM-DD').format('YYYY-MM-DD') +
                                ' - ' +
                                moment(dateRange.end, 'YYYY-MM-DD').format('YYYY-MM-DD')
                        );
                    }
                });
            });
        };

        $scope.toggleDatePicker = function (selector) {
            $(selector).data('daterangepicker').show();
        };

        $scope.applyDateRange = function () {
            $scope.dismissNotification('errorInvalidDateRangeTooBig');
            $scope.loadStudentHistoryPage = true;
            $scope.loadStudentHistory(false, $scope.dateRange.start, $scope.dateRange.end);
        };

        /** ******************************************************************************************************************* */
        //   Students table pagination
        /** ******************************************************************************************************************* */

        /**
         * Function to update the displayed routes based on pagination
         * @param {Array} routes
         * @return {Array}
         */
        const paginateStudents = function () {
            // Recalculate the array for pagination
            $scope.totalPages = Math.ceil($scope.filteredStudents.length / $scope.itemsPerPage);
            $scope.pageArray = Array.from({ length: $scope.totalPages }, (_, i) => i + 1);

            if ($scope.currentPage > $scope.totalPages) {
                $scope.currentPage = 1;
            }

            const start = ($scope.currentPage - 1) * $scope.itemsPerPage;
            const end = start + $scope.itemsPerPage;

            // Scroll to the top of the page
            // eslint-disable-next-line no-undef
            window.scrollTo(0, 0);

            return $scope.filteredStudents.slice(start, end);
        };

        // Next Page function
        $scope.nextPage = function () {
            if ($scope.currentPage < Math.ceil($scope.filteredStudents.length / $scope.itemsPerPage)) {
                $scope.currentPage++;
                $scope.loadStudentsTable();
            }
        };

        // Previous Page function
        $scope.prevPage = function () {
            if ($scope.currentPage > 1) {
                $scope.currentPage--;
                $scope.loadStudentsTable();
            }
        };

        // Function to go to a specific page
        $scope.goToPage = function (page) {
            $scope.currentPage = page;
            $scope.loadStudentsTable();
        };
    },
]);
