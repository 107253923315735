const STATUS = {
    UNPROCESSED: 'unprocessed',
    INPROGRESS: 'inProgress',
    PROCESSED: 'processed',
};
const ASSIGNEES = {
    UNASSIGNED: 'unassigned',
    ANAIS: 'Anaïs',
    ERICK: 'Erick',
    SOUTIEN: 'Soutien',
};

module.exports = { STATUS, ASSIGNEES };
