app.controller('AnalyticsController', [
    '$scope',
    'Analytics',
    function ($scope, Analytics) {
        $scope.sendUserInfo = function () {
            /* let role = getCookie('role');
        Analytics.set('dimension0', role, 'accountRole');

        let lang = localStorage.getItem('userLanguage');
        Analytics.set('dimension0', lang, 'userLanguage');

        Analytics.pageView(); */
        };
    },
]);
