const { validatePassword } = require('../../tools/helpers/passwordValidation');

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('passwordRequirementsView', {
    templateUrl: './shared/layout/passwordRequirementsView/passwordRequirementsView.html',
    bindings: {
        addDrivers: '<',
    },
    controller: function ($rootScope, $scope) {
        this.$onInit = () => {
            $scope.addDrivers = this.addDrivers ?? false;
            $scope.passwordRequirements = {
                length: { label: 'passwordRequirementLength', isValid: false },
            };
            if (!$scope.addDrivers) {
                $scope.passwordRequirements['lowercase'] = { label: 'passwordRequirementLowercase', isValid: false };
                $scope.passwordRequirements['uppercase'] = { label: 'passwordRequirementCapital', isValid: false };
                $scope.passwordRequirements['number'] = { label: 'passwordRequirementsNumber', isValid: false };
            }
        };

        $scope.$on('onChangePassword', (event, newPassword) => {
            // Prevent undefined or null
            newPassword = newPassword ?? '';

            // Update the password requirements on input change
            const requirements = validatePassword(newPassword);
            for (const requirementKey of Object.keys($scope.passwordRequirements)) {
                $scope.passwordRequirements[requirementKey].isValid = requirements[requirementKey];
            }

            // Check if all password requirements are met
            const isValidPassword = !Object.values(requirements).includes(false);
            $scope.$emit('validatePassword', { isValidPassword: isValidPassword, passwordRequirements: $scope.passwordRequirements });
        });
    },
});
