const QRCode = require('qrcode');

angular.module('mTransportApp').controller('DriversController', [
    '$scope',
    '$translate',
    'sortingTools',
    '$rootScope',
    '$controller',
    function ($scope, $translate, sortingTools, $rootScope, $controller) {
        angular.extend(this, $controller('ToolsController', { $scope: $scope }));

        initializeScopeVariables();

        if (['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)) {
            fetchDrivers();
        }

        /**
         * Initialize the scope variables
         */
        function initializeScopeVariables() {
            $scope.loading = true;
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = true;
            $scope.drivers = null;
            $scope.driversToDisplay = null;

            $scope.isAddDriverModalDisplayed = false;
            $scope.isAddDriverConfirmationModalDisplayed = false;
            $scope.modalLoading = false;
            $scope.eyeIconModal = '../../assets/img/ui/eye_open.svg';

            // Add driver modal : Driver to create
            $scope.newDriver = {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
            };
            $scope.emailExistsAlready = false;
            $scope.passwordValidation = {};

            // Deletion modal
            $scope.isRemovalModalDisplayed = false;

            // Delete and update password
            $scope.driverToUpdate = {
                id: '',
                email: '',
                password: '',
            };

            $scope.searchValue = {
                value: '',
            };
            $scope.orderType = {
                orderBy: 'email',
                ascending: true,
            };

            // Add bus number assignment modal
            $scope.isAddBusNumberAssignmentModalDisplayed = false;
            $scope.showSelectClientError = false;
            $scope.showNoClientErrorBanner = false;
        }

        /**
         * Fetch the drivers of the user's carrier
         */
        function fetchDrivers() {
            $scope.requestGetCarrier('mine').then(
                function (carrier) {
                    Promise.all([$scope.requestGetDriversForCarrier(carrier.id), $scope.angRequestAdminGetSettingsForCarrier('mine')]).then(
                        function ([
                            driversData,
                            {
                                data: { settings },
                            },
                        ]) {
                            $scope.driversCount = driversData.driverCount;
                            $scope.drivers = driversData.drivers;
                            $scope.driversToDisplay = sortDrivers(angular.copy(driversData.drivers));
                            // Sort bus numbers from A-Z
                            $scope.driversToDisplay.forEach((driver) => {
                                driver.assignments = sortingTools.sortBusNumberAssignments(driver.assignments, 'busNumber', true);
                            });
                            $scope.registeringDisplayFunctions = false;
                            $scope.driverManagementEnabled = settings.driverAccountManagement.isEnabled;
                            $scope.loading = false;
                            $scope.$apply();
                        }
                    );
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loading = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Fetch clients (school organizations)
         * @return {Promise}
         */
        function fetchClients() {
            $scope.clients = [];
            return $scope.requestGetClientsLinkedToCarrier('mine').then(
                function (data) {
                    $scope.clients = sortingTools.sortClients(data.clients);
                    $scope.loadingClients = false;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingClients = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Fetch bus numbers
         */
        function fetchBusNumbers() {
            $scope.busNumbers = [];
            $scope.requestRoutes(true, false, false).then(
                function (data) {
                    const busNumbersSet = new Set();

                    data.routes.forEach((route) => {
                        const clientId = route.client.id;
                        const busNumber = route.bus.number;

                        if (!busNumbersSet.has(busNumber)) {
                            busNumbersSet.add(busNumber);
                            const busOption = { name: busNumber, client: clientId };
                            $scope.busNumbers.push(busOption);
                        }
                    });

                    $scope.busNumbers = sortingTools.sortBusNumbers($scope.busNumbers);
                    $scope.loadingBusNumbers = false;
                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loadingBusNumbers = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Defines which fields the driversToDisplay array should be sorted by
         * Then sorts a copy of $scope.driversToDisplay
         * Then loads sorted drivers into $scope.driversToDisplay
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortDriversByFields = function (orderBy) {
            $scope.sorting = true;
            $scope.registeringDisplayFunctions = true;

            if (orderBy) {
                if ($scope.orderType.orderBy === orderBy) {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: !$scope.orderType.ascending,
                    };
                } else {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            const sortedDrivers = sortDrivers([...$scope.driversToDisplay]);
            $scope.driversToDisplay = sortedDrivers;

            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
        };

        /**
         * Sort the drivers
         * @param {Array} drivers
         * @return {Array} sorted array
         */
        function sortDrivers(drivers) {
            return sortingTools.sortDrivers(drivers, $scope.orderType.orderBy, $scope.orderType.ascending);
        }

        $scope.applyFilter = (dataType) => {
            if (dataType === 'drivers') {
                const filteredDrivers = $scope.drivers.filter(isDriverInFilter);
                $scope.driversToDisplay = filteredDrivers;
            }
        };

        /**
         * Check if driver matches filter
         * @param {Array} drivers
         * @return {Array}
         */
        function isDriverInFilter(drivers) {
            const busNumbers = drivers.assignments.map((assignment) => assignment.bus.number).join(' ');
            const clientsNames = drivers.assignments.map((assignment) => assignment.client.name).join(' ');
            const dataToCompare = {
                driversLastName: drivers.lastName,
                driversFirstName: drivers.firstName,
                busNumbers: busNumbers,
                clientsNames: clientsNames,
            };
            return $scope.containsSearchValue(dataToCompare, $scope.searchValue.value);
        }

        /**
         * Show or hide password in driver creation modal
         */
        $scope.showOrHidePassword = function () {
            // eslint-disable-next-line no-undef
            const passwordInput = document.querySelector('#passwordBox');
            const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
            // Display crossed or open eye depending of input type
            $scope.eyeIconModal = type === 'password' ? '../../assets/img/ui/eye_open.svg' : '../../assets/img/ui/eye_crossed.svg';
            // Change type of input
            passwordInput.setAttribute('type', type);
        };

        // ADD DRIVER ========================================================================================= //
        /**
         * Open driver creation modal
         */
        $scope.openAddDriverModal = () => {
            $scope.isAddDriverModalDisplayed = true;
        };

        /**
         * Function to remove the "email already exists" message and remove the red borders when we type in the driverEmail input
         */
        $scope.onEmailInputChangeHandler = () => {
            if ($scope.emailExistsAlready) {
                $scope.emailExistsAlready = false;
                $("input[name='userEmail']").removeClass('ng-invalid ng-touched');
            }
        };

        // Update the password on input change
        $scope.onPasswordInputChangeHandler = (password) => {
            $rootScope.$broadcast('onChangePassword', password);
        };

        $scope.$on('validatePassword', (event, value) => {
            $scope.passwordValidation = value;
        });

        /**
         * Form submit function
         * Checks if the driver form is valid
         * If so, format data as per expected by backend
         * then makes an ajax call to create the driver
         * ==> Catches errors and displays an error banner when that is the case TODO
         * Then refresh the drivers's list and open the driver created confirmation modal
         * @param {Object} form the submitted user form
         */
        $scope.submitDriverAddForm = async function (form) {
            if (form.$valid && $scope.passwordValidation.passwordRequirements.length.isValid) {
                $scope.modalLoading = true;

                // Formating data as per expected by backend
                const data = {
                    email: $scope.newDriver.email,
                    firstName: $scope.newDriver.firstName,
                    lastName: $scope.newDriver.lastName,
                    password: $scope.newDriver.password,
                };

                // Call to backend
                try {
                    await createDriver(data);

                    // Get drivers list
                    fetchDrivers();

                    $scope.isAddDriverModalDisplayed = false;
                    $scope.isAddDriverConfirmationModalDisplayed = true;
                } catch (error) {
                    $scope.catchErrorDefault(error);
                    if (error.status === 400) {
                        switch (error.data.error.code) {
                            case 1002:
                                // Display a error message in modal in case of email already exists
                                $scope.emailExistsAlready = true;
                                break;
                            case 1001:
                            default:
                                // Display an error banner in the modal in case of invalid parameters
                                error.key = $translate.instant('messageErrorInvalidParameters');
                                $rootScope.$broadcast('request-error-driver-creation-modal', error);
                                break;
                        }
                    }
                } finally {
                    $scope.modalLoading = false;
                    $scope.$apply();
                }
            } else if (!$scope.passwordValidation.passwordRequirements.length.isValid) {
                document.getElementById('passwordBox').focus();
            }
        };

        /**
         * AJAX call to create a driver for our current carrier
         * ==> Error : Throws the error back to the next catch block
         * @param {Object} data properly formatted data object
         * @return {Promise}
         */
        function createDriver(data) {
            return $scope.requestAdminCreateDriverForCarrier('mine', data).catch((error) => {
                throw error;
            });
        }

        /**
         * Close the driver creation confirmation modal, reset the new drivers vars
         */
        $scope.closeAddDriverConfirmationModal = () => {
            $scope.isAddDriverConfirmationModalDisplayed = false;
            $scope.resetAddDriverModalVars();
        };

        /**
         * Reset of the driver creation modal variables
         */
        $scope.resetAddDriverModalVars = function () {
            $scope.newDriver = {
                firstName: '',
                lastName: '',
                email: '',
                password: '',
            };
            $scope.emailExistsAlready = false;
        };

        // ADD BUS NUMBER ASSIGNMENT ========================================================================================= //

        /**
         * Open bus number assignment modal
         * @param {Object} driver - Driver to which we will assign a bus number
         */
        $scope.openAddBusNumberAssignmentModal = (driver) => {
            $scope.driverToUpdate = driver;
            $scope.isAddBusNumberAssignmentModalDisplayed = true;
            $scope.clientSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedClient: null,
            };
            $scope.busNumberSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedBusNumber: null,
            };
            $scope.showSelectClientError = false;

            // Fetch updated data
            $scope.loadingClients = true;
            $scope.loadingBusNumbers = true;
            fetchClients().then(() => {
                if ($scope.clients.length > 0) {
                    fetchBusNumbers();
                    $scope.filteredBusNumbers = $scope.busNumbers;
                } else {
                    // If there is no clients, show error banner
                    $scope.isAddBusNumberAssignmentModalDisplayed = false;
                    $scope.showNoClientErrorBanner = true;
                }
                $scope.$apply();
            });
        };

        $scope.closeAddBusNumberAssignmentModal = function () {
            // Reset modal state when closing modal
            $scope.isAddBusNumberAssignmentModalDisplayed = false;
            $scope.clientSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedClient: null,
            };

            $scope.busNumberSelect = {
                optionsOpen: false,
                searchValue: '',
                selectedBusNumber: null,
            };
            $scope.showSelectClientError = false;
        };

        $scope.$watch('clientSelect.selectedClient', function (newValue) {
            if (newValue != null) {
                $scope.filteredBusNumbers = $scope.busNumbers?.filter((bus) => {
                    return bus.client === newValue.id;
                });
            }
        });

        /**
         * Submits the Add Bus Number Assignment form
         * Validates the form, shows warning options not selected, formats the data,
         *          and sends a request to create a BN-assignment.
         * @param {Object} form - The submitted form object.
         */
        $scope.submitAddBusNumberAssignmentForm = async function (form) {
            // Update warning if no option selected user has many clients to choose from
            if ($scope.clients.length > 1) {
                $scope.showSelectClientError = $scope.clientSelect?.selectedClient == null;
            } else {
                // If only one client, set it as selected
                $scope.clientSelect.selectedClient = $scope.clients[0];
            }

            if (form.$valid) {
                try {
                    if ($scope.clientSelect?.selectedClient != null && $scope.busNumberSelect?.searchValue != '') {
                        $scope.loading = true;
                        $scope
                            .requestCreateBusNumberAssignment('mine', $scope.driverToUpdate.id, {
                                clientId: $scope.clientSelect.selectedClient.id,
                                busNumber: $scope.busNumberSelect.searchValue.value,
                            })
                            .then(() => fetchDrivers());
                    }
                } catch (error) {
                    $rootScope.$broadcast('request-error-users', error);
                } finally {
                    $scope.closeAddBusNumberAssignmentModal();
                    $scope.loading = false;
                }
            }
        };

        // UPDATE DRIVER PASSWORD ========================================================================================= //
        /**
         * Populates the $scope.driverToUpdate object
         * And shows the update password modal
         * @param {Object} driver driver on which we clicked
         */
        $scope.modifyPasswordButtonHandler = function (driver) {
            $scope.driverToUpdate.email = driver.email;
            $scope.driverToUpdate.id = driver.id;

            $scope.isUpdatePasswordModalDisplayed = true;
        };

        /**
         * Submit the driver password update
         * @param {Object} form
         */
        $scope.submitUpdatePasswordForm = (form) => {
            if (form.$valid) {
                const data = {
                    newPassword: $scope.driverToUpdate.password,
                };

                $scope
                    .requestUpdateDriverPasswordForCarrier('mine', $scope.driverToUpdate.id, data)
                    .then(
                        function () {
                            $scope.isUpdatePasswordModalDisplayed = false;
                            $scope.isUpdatePasswordConfirmationModalDisplayed = true;
                        },
                        function (error) {
                            $rootScope.$broadcast('request-error-password-update-modal', error);
                        }
                    )
                    .then(() => {
                        $scope.modalLoading = false;
                        $scope.$apply();
                    });
            }
        };

        /**
         * Close the driver updated confirmation modal, reset the driverToUpdate vars
         */
        $scope.closeUpdatePasswordConfirmationModal = () => {
            $scope.isUpdatePasswordConfirmationModalDisplayed = false;

            $scope.resetUpdatePasswordModalVars();
        };

        /**
         * Reset driverToUpdate modal variables
         */
        $scope.resetUpdatePasswordModalVars = function () {
            $scope.driverToUpdate = {
                id: '',
                email: '',
                password: '',
            };
        };

        // DEACTIVATE DRIVER ========================================================================================= //
        /**
         * Populates the $scope.driverToUpdate object
         * And shows the removal modal
         * @param {Object} driver driver on which we clicked
         */
        $scope.removeButtonHandler = function (driver) {
            $scope.driverToUpdate.email = driver.email;
            $scope.driverToUpdate.id = driver.id;

            $scope.isRemovalModalDisplayed = true;
        };

        /**
         * AJAX call to deactivate driver
         * Puts the modal into loading mode (loading dots displayed)
         * ==> Success: refreshes the drivers data
         * ==> Error: displays an error banner
         * ==> Then removes loading mode and hides modal
         */
        $scope.deactivateDriver = async () => {
            $scope.modalLoading = true;
            $scope
                .requestDeactivateDriverForCarrier('mine', $scope.driverToUpdate.id)
                .then(() => {
                    fetchDrivers();
                })
                .catch((error) => {
                    $rootScope.$broadcast('request-error-drivers', error);
                })
                .then(() => {
                    $scope.modalLoading = false;
                    $scope.isRemovalModalDisplayed = false;
                    $scope.$apply();
                });
        };

        // QR CODE ========================================================================================= //
        /**
         * Downloading the QR code for the new driver
         * @param {Object} driver
         */
        $scope.downloadQRCode = (driver) => {
            const fileName = `${driver.email.split('@')[0]}`;
            const fileNameSanitized = fileName.replace(/[^a-z0-9]/gi, '_');
            const qrData = generateDriverQrPayload(driver.email, driver.password);

            QRCode.toDataURL(qrData, {
                width: 200,
                height: 200,
            }).then((url) => {
                downloadQRCode(url, `${fileNameSanitized}.png`);
            });
        };

        /**
         * Generate QR payload
         * @param {String} username
         * @param {String} password
         * @return {String} QR code payload
         */
        function generateDriverQrPayload(username, password) {
            const payload = { email: username, password: password };
            const encoded = Buffer.from(JSON.stringify(payload)).toString('base64');
            return 'YmFzZTY0#' + encoded;
        }

        /**
         * Download QRcode PNG using its URI by simulating a link click
         * @param {String} uri
         * @param {String} filename
         */
        function downloadQRCode(uri, filename) {
            // eslint-disable-next-line no-undef
            const link = document.createElement('a');
            link.download = filename;
            link.href = uri;
            // eslint-disable-next-line no-undef
            document.body.appendChild(link);
            link.click();
            // eslint-disable-next-line no-undef
            document.body.removeChild(link);
        }

        // CSV GENERATION ======================================================================================= //
        /**
         * Generate driver accounts CSV
         */
        $scope.generateGenericCSV = function () {
            const driversList = $scope.driversToDisplay;
            // verify if driversList is empty
            if (!driversList) {
                return;
            }

            const config = {
                header: true,
                quotes: true,
            };

            const header = [
                $translate.instant('email'),
                $translate.instant('firstName'),
                $translate.instant('lastName'),
                $translate.instant('busNumberAssignment'),
                $translate.instant('otherRunAssignments'),
                $translate.instant('runs'),
            ];
            const csvRows = driversList.map((driver) => {
                const busNumberAssignments = driver.assignments
                    .map((assignment) => assignment.bus?.number ?? '') // Extract bus numbers
                    .filter((busNumber) => busNumber !== '') // Filter out empty strings
                    .join(' | '); // Join the non-empty bus numbers with ' | ' delimiter
                return [driver.email, driver.firstName, driver.lastName, busNumberAssignments, driver.directAssignmentRouteCount, driver.routeCount];
            });

            // eslint-disable-next-line no-undef
            const csv = Papa.unparse(
                {
                    fields: header,
                    data: csvRows,
                },
                config
            );

            // Create the filename
            // eslint-disable-next-line no-undef
            const today = moment().format('YYYY-MM-DD');
            const filename = `${$translate.instant('driverAccounts')} - ${today}.csv`;

            $scope.downloadCSV(csv, filename);
        };
    },
]);
