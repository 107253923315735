angular.module('mTransportApp').controller('CarriersController', [
    '$scope',
    '$rootScope',
    '$translate',
    '$timeout',
    'carriersAdminList',
    'sortingTools',
    '$location',
    function ($scope, $rootScope, $translate, $timeout, carriersAdminList, sortingTools, $location) {
        $scope.orderRoutesType = {
            orderBy: '',
            ascending: true,
        };

        $scope.showChangeConfirmationModal = false;
        $scope.confirmationModalAction = () => {};

        /* =======================================================================================================================
		ALL CARRIERS
	 =======================================================================================================================  */
        $scope.initializeCarriers = function () {
            $scope.loading = true;
            $scope.csvIsLoading = false;
            $scope.carriers = [];
            $scope.searchValue = {
                value: '',
            };
            $scope.orderType = {
                orderBy: 'name',
                ascending: true,
            };
            $scope.displayWarnings = [];
            $scope.requestAdminGetAllCarriers().then(
                function (answer) {
                    $scope.carriers = sortingTools.sortCarriers(answer.carriers, 'name', true);
                    $scope.loading = false;

                    $scope.allCarriersSave = $scope.carriers;
                    carriersAdminList.setList($scope.carriers);
                    $scope.$apply();
                    $scope.fetchAllRoutesForCarriersAndTestDriver($scope.carriers);
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /**
         * Returns the total of a field for all carriers or the filtered carriers
         * @param {string} fieldPath
         * @return {number} the total
         */
        $scope.getTotal = function (fieldPath) {
            return $scope.carriers.reduce((total, carrier) => {
                return total + parseFloat(carrier[fieldPath]);
            }, 0);
        };

        /**
         * Decodes a string that was encoded and that contains characters like the & ampersand
         * This method avoids adding XSS vulnerabilities into the code :
         * https://stackoverflow.com/questions/1147359/how-to-decode-html-entities-using-jquery/1395954#1395954
         * Used with $translate, which encodes its characters
         * @param {string} encodedString
         * @return {string} the decoded string
         */
        function decodeString(encodedString) {
            const textArea = document.createElement('textarea');
            textArea.innerHTML = encodedString;
            return textArea.value;
        }

        $scope.fetchAllRoutesForCarriersAndTestDriver = function (carriers) {
            // Count routes that have no drivers for each carrier and display alerts
            for (let key in carriers) {
                $scope.angRequestAdminGetRoutesForCarrier(carriers[key].id).then(function (response) {
                    let parcoursNonRelies = 0;
                    for (let routeKey in response.data.routes) {
                        if (response.data.routes[routeKey].driverCount === 0) {
                            parcoursNonRelies++;
                        }
                    }
                    if (parcoursNonRelies) {
                        $scope.displayWarnings.push({
                            text: decodeString(
                                $translate.instant('carrierRunsWithoutDrivers', {
                                    carrierName: carriers[key].name,
                                    runsWithoutDrivers: parcoursNonRelies,
                                })
                            ),
                            visible: true,
                        });
                    }
                });
            }
        };

        /* =======================================================================================================================
    	FILTERS AND SEARCH + SORTING
	=======================================================================================================================  */
        // Filters and search for carriers
        $scope.applyFilters = function () {
            $scope.carriersToShow = [];
            $scope.carriers = $scope.allCarriersSave;
            const carriers = $scope.carriers;
            for (const r in carriers) {
                let carrier = carriers[r];
                if (passesFilters(carrier)) {
                    $scope.carriersToShow.push(carrier);
                }
            }
            $scope.carriers = $scope.carriersToShow;
            $scope.sortCarriersByFields();

            $timeout(function () {
                $scope.$apply();
            });
        };

        function passesFilters(carrier) {
            let passes = false;
            let dataToCompare = {
                carrierName: carrier.name,
            };
            // To complete
            if ($scope.containsSearchValue(dataToCompare, $scope.searchValue.value)) {
                passes = true;
            }
            return passes;
        }

        /**
         * Sort the carriers by calling the sortCarriers function from the sortingTools controller
         * @param {Array} carriers the array to be sorted
         * @return {Array} sorted array
         */
        function sortCarriers(carriers) {
            return sortingTools.sortCarriers(carriers, $scope.orderType.orderBy, $scope.orderType.ascending);
        }

        /**
         * Defines which fields the carriers array should be sorted by
         * Then sorts a copy of $scope.carriers
         * Then loads sorted carriers into $scope.carriers
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortCarriersByFields = function (orderBy = null) {
            if (orderBy) {
                if ($scope.orderType.orderBy === orderBy) {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: !$scope.orderType.ascending,
                    };
                } else {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            const sortedCarriers = sortCarriers([...$scope.carriers]);
            $scope.carriers = sortedCarriers;
        };

        /* =======================================================================================================================
       CREATE CARRIER
    =======================================================================================================================  */
        $scope.goToAddCarrier = function () {
            $scope.goToPage('carriers/createCarrier');
        };

        $scope.initializeCreateCarrier = function () {
            $scope.newCarrier = null;
        };

        $scope.validateField = function (inputID, value, labelID) {
            let error;
            if (!value) {
                value = '';
            }
            if (inputID === 'carrierNameInput') {
                error = $scope.validateStandardTextInput(inputID, value, true);
            } else if (inputID === 'carrierContactMailInput') {
                error = $scope.validateEmailInput(inputID, value, false);
            } else if (inputID === 'carrierTimezoneInput') {
                error = $scope.validateTimezoneInput(inputID, value, false);
            } else {
                error = $scope.validateStandardTextInput(inputID, value, false);
            }

            if (error) {
                $scope.scrollToElement(labelID);
                $('#' + inputID).popover({ title: $translate.instant('error'), content: error, placement: 'right', trigger: 'focus' });
                $('#' + inputID).popover('show');
                $scope.setFocus(inputID);
                document.getElementById(labelID).classList.add('status-red');
                document.getElementById(labelID).classList.remove('status-green');
            } else {
                if (value.length <= 0) {
                    document.getElementById(labelID).classList.remove('status-red');
                    document.getElementById(labelID).classList.remove('status-green');
                } else {
                    document.getElementById(labelID).classList.remove('status-red');
                    document.getElementById(labelID).classList.add('status-green');
                }
            }

            return error;
        };

        $scope.confirmCreation = function (carrier) {
            $scope.newCarrier = null;
            if (carrier) {
                let toValidate = [
                    { inputID: 'carrierNameInput', value: carrier.name, labelID: 'carrierNameLabel' },
                    { inputID: 'carrierAddressInput', value: carrier.address, labelID: 'carrierAddressLabel' },
                    { inputID: 'carrierPhoneInput', value: carrier.phoneNumber, labelID: 'carrierPhoneLabel' },
                    { inputID: 'carrierTimezoneInput', value: carrier.timezone, labelID: 'carrierTimezoneLabel' },
                ];

                if (carrier.contact) {
                    toValidate.push(
                        { inputID: 'carrierContactFirstNameInput', value: carrier.contact.firstName, labelID: 'carrierContactFirstNameLabel' },
                        { inputID: 'carrierContactLastNameInput', value: carrier.contact.lastName, labelID: 'carrierContactLastNameLabel' },
                        { inputID: 'carrierContactTitleInput', value: carrier.contact.title, labelID: 'carrierContactTitleLabel' },
                        { inputID: 'carrierContactPhoneInput', value: carrier.contact.phoneNumber, labelID: 'carrierContactPhoneLabel' },
                        { inputID: 'carrierContactMailInput', value: carrier.contact.email, labelID: 'carrierContactMailLabel' }
                    );
                }

                for (const entryIndex in toValidate) {
                    let inputID = toValidate[entryIndex].inputID;
                    let value = toValidate[entryIndex].value;
                    let labelID = toValidate[entryIndex].labelID;
                    if ($scope.validateField(inputID, value, labelID)) {
                        // Error is returned
                        return;
                    }
                }

                $scope.scrollToTop();
                $scope.newCarrier = carrier;
            } else {
                $scope.validateField('carrierNameInput', '', 'carrierNameLabel');
            }
        };

        $scope.addCarrier = function (newCarrier) {
            $scope.requestAdminCreateCarrier(newCarrier).then(
                function (answer) {
                    if (answer.error) {
                        $scope.scrollToTop();
                        $scope.triggerCustomNotification(
                            'createCarrierNotificationError',
                            'createCarrierNotificationErrorMessage',
                            answer.error.message
                        );
                    } else {
                        $scope.goToPage('/carriers');
                    }
                },
                function (error) {
                    $scope.scrollToTop();
                    $scope.triggerCustomNotification(
                        'createCarrierNotificationError',
                        'createCarrierNotificationErrorMessage',
                        error.responseJSON.error.message
                    );
                }
            );
        };

        /* =======================================================================================================================
       SHOW A CARRIER'S DETAILS
    =======================================================================================================================  */
        $scope.initializeCarrier = function () {
            $scope.loading = true;
            const carrierID = $scope.getIdFromURL();
            // Set carriers list if it doesn't exist
            if (!carriersAdminList.hasList()) {
                $scope.requestAdminGetAllCarriers().then(
                    function (response) {
                        $scope.carriers = sortingTools.sortCarriers(response.carriers, 'name', true);
                        carriersAdminList.setList($scope.carriers);
                        $scope.nextCarrier = carriersAdminList.getNextCarrier(carrierID);
                    },
                    function (error) {
                        $scope.catchErrorDefault(error);
                    }
                );
            } else {
                $scope.nextCarrier = carriersAdminList.getNextCarrier(carrierID);
            }
            $scope.requestGetCarrier(carrierID).then(
                function (answer) {
                    $scope.carrier = answer;
                    $scope.fetchDispatchers(carrierID);
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );

            $scope.angRequestAdminGetAvailableFormsForCarrier(carrierID).then(
                function (answer) {
                    answer.data.availableForms.forEach((type) => {
                        const formType = Object.values(type).toString();

                        if (formType === 'photos') {
                            $scope.hasPhotosForm = true;
                        } else if (formType === 'disciplinary') {
                            $scope.hasDisciplinaryForm = true;
                        }
                    });
                },
                function (error) {
                    $rootScope.$broadcast('request-error-carrier-available-forms', error);
                }
            );

            $scope.angRequestAdminGetAvailableVehicleInpectionTemplatesForCarrier(carrierID).then(
                function (answer) {
                    answer.data.availableVehicleInspectionTemplates.forEach((availableVehicleInspectionTemplate) => {
                        if (availableVehicleInspectionTemplate === 'SAAQ') {
                            $scope.hasSAAQVehicleInspectionTemplate = true;
                        }
                    });
                },

                function (error) {
                    $rootScope.$broadcast('request-error-carrier-available-forms', error);
                }
            );
        };

        $scope.fetchDispatchers = function (carrierID) {
            $scope.requestGetDispatchersForCarrier(carrierID).then(
                function (answer) {
                    $scope.dispatchersForCarrier = answer.dispatchers.sort((a, b) => b.isActive - a.isActive || a.lastName.localeCompare(b.lastName));
                    $scope.fetchDrivers(carrierID);
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        $scope.fetchDrivers = function (carrierID) {
            $scope.requestGetDriversForCarrier(carrierID).then(
                function (answer) {
                    const drivers = [];
                    for (const driver of answer.drivers) {
                        driver._sortName = driver.lastName + driver.firstName;
                        drivers.push(driver);
                    }

                    $scope.driversForCarrier = sortingTools.sortUnassignedDrivers(drivers, '_sortName', true);
                    $scope.fetchRoutes(carrierID);
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        $scope.fetchRoutes = function (carrierID) {
            $scope.drivers = [];
            $scope.requestAdminGetRoutesForCarrier(carrierID).then(
                function (answer) {
                    const routes = [];
                    for (const route of answer.routes) {
                        route._sortName = route.name + route.internalId;
                        routes.push(route);
                    }
                    $scope.routesForCarrier = sortingTools.sortUnassignedRoutes(routes, '_sortName', true);
                    $scope.loading = false;
                    $scope.$apply();
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        $scope.fetchRoutesAndDriversForCarrier = function (carrierID) {
            // fetch routes
            $scope.drivers = [];
            $scope.requestAdminGetRoutesForCarrier(carrierID).then(
                function (answer) {
                    const routes = [];
                    for (const route of answer.routes) {
                        route._sortName = route.name + route.internalId;
                        routes.push(route);
                    }
                    $scope.routesForCarrier = sortingTools.sortUnassignedRoutes(routes, '_sortName', true);
                    $scope.routesToShowForCarrier = $scope.routesForCarrier;
                    $scope.loading = false;
                    $scope.$apply();
                    // fetch drivers for each route
                    for (const route of $scope.routesForCarrier) {
                        $scope.requestAdminGetDriversForRoute(route.id).then(
                            function (answer) {
                                $scope.driversForRoute = answer.drivers;
                                // Creating drivers attribute for each route and filling drivers
                                route.drivers = $scope.driversForRoute;
                                $scope.routeLoading = false;
                                $scope.getAllAddableDriversForRoute();
                                $scope.$apply();
                                $scope.scrollToTop();
                            },
                            function (error) {
                                // NANI?
                            }
                        );
                    }
                    $scope.routesForCarrierSave = $scope.routesForCarrier;
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /* =======================================================================================================================
       EDIT A CARRIER'S DETAILS
    =======================================================================================================================  */
        $scope.closeAllEditBoxes = function () {
            $scope.closeEditCarrierCoordinates();
            $scope.closeEditCarrierContact();
        };

        /* Coordinates ========================================================================= */
        $scope.openEditCarrierCoordinates = function () {
            setCoordinates();
            $scope.closeAllEditBoxes();
            $scope.editingCarrierCoordinates = true;
            $timeout(function () {
                $scope.setFocus('carrierCoordinatesPhoneInput');
            });
        };

        $scope.closeEditCarrierCoordinates = function () {
            $scope.editingCarrierCoordinates = false;
            setCoordinates();
            $scope.resetInputState('carrierCoordinatesAddressInput');
            $scope.resetInputState('carrierCoordinatesPhoneInput');
            $scope.resetInputState('carrierCoordinatesGuardiansContactInput');
            $scope.resetInputState('carrierCoordinatesLocaleIdentifier');
        };

        function setCoordinates() {
            if ($scope.carrier.guardiansContact != null) {
                $scope.coordinates = {
                    address: $scope.carrier.address,
                    phone: $scope.carrier.phoneNumber,
                    guardiansContact: $scope.carrier.guardiansContact.phoneNumber,
                    localeIdentifier: $scope.carrier.localeIdentifier,
                };
            } else {
                $scope.coordinates = {
                    address: $scope.carrier.address,
                    phone: $scope.carrier.phoneNumber,
                    localeIdentifier: $scope.carrier.localeIdentifier,
                };
            }
        }

        $scope.confirmCoordinatesChanges = function (coordinates) {
            if (coordinates != null) {
                const address = coordinates.address;
                const phone = coordinates.phone;
                const guardiansContact = coordinates.guardiansContact;
                const localeIdentifier = coordinates.localeIdentifier;

                const addressError = $scope.validateStandardTextInput('carrierCoordinatesAddressInput', address, false);
                const phoneError = $scope.validateStandardTextInput('carrierCoordinatesPhoneInput', phone, false);
                const guardiansContactError = $scope.validateStandardTextInput('carrierCoordinatesGuardiansContactInput', guardiansContact, false);
                const localeIdentifierError = $scope.validateStandardTextInput('carrierCoordinatesLocaleIdentifier', localeIdentifier, false);

                if (!addressError && !phoneError && !guardiansContactError && !localeIdentifierError) {
                    updateCarrierCoordinates($scope.carrier.id, phone, guardiansContact, address, localeIdentifier);
                }
            } else {
                $scope.closeEditCarrierCoordinates();
            }
        };

        function updateCarrierCoordinates(carrierId, phone, guardiansContact, address, localeIdentifier) {
            const data = {
                address: address,
                phoneNumber: phone,
                guardiansContact: { phoneNumber: guardiansContact },
                localeIdentifier,
            };

            $scope.requestAdminUpdateCarrier(carrierId, data).then(
                function (answer) {
                    $scope.refreshPage();
                },
                function (error) {
                    // Trigger notifications
                }
            );
        }

        /* Contact ============================================================================= */
        $scope.openEditCarrierContact = function () {
            setContact();
            $scope.closeAllEditBoxes();
            $scope.editingCarrierContact = true;
            $timeout(function () {
                $scope.setFocus('carrierContactFirstName');
            });
        };

        $scope.closeEditCarrierContact = function () {
            $scope.editingCarrierContact = false;
            setContact();
            $scope.resetInputState('carrierContactFirstName');
            $scope.resetInputState('carrierContactLastName');
            $scope.resetInputState('carrierContactTitle');
            $scope.resetInputState('carrierContactEmail');
            $scope.resetInputState('carrierContactPhoneNumber');
            if ($('#carrierContactEmail').popover()) {
                $('#carrierContactEmail').popover('dispose');
            }
        };

        function setContact() {
            $scope.contact = {
                firstName: $scope.carrier.contact.firstName,
                lastName: $scope.carrier.contact.lastName,
                title: $scope.carrier.contact.title,
                email: $scope.carrier.contact.email,
                phone: $scope.carrier.contact.phoneNumber,
            };
        }

        $scope.confirmContactChanges = function (contact) {
            const firstName = contact.firstName;
            const lastName = contact.lastName;
            const title = contact.title;
            const email = contact.email;
            const phoneNumber = contact.phone;

            const firstNameError = $scope.validateStandardTextInput('carrierContactFirstName', firstName, false);
            const lastNameError = $scope.validateStandardTextInput('carrierContactLastName', lastName, false);
            const titleError = $scope.validateStandardTextInput('carrierContactTitle', title, false);
            const emailError = $scope.validateEmailInput('carrierContactEmail', email, true);
            const phoneNumberError = $scope.validateStandardTextInput('carrierContactPhoneNumber', phoneNumber, false);

            let error = false;
            if (firstNameError) {
                error = true;
            }
            if (lastNameError) {
                error = true;
            }
            if (titleError) {
                error = true;
            }
            if (emailError) {
                $('#carrierContactEmail').popover({ title: $translate.instant('error'), content: emailError, placement: 'top', trigger: 'focus' });
                $('#carrierContactEmail').popover('show');
                error = true;
            }
            if (phoneNumberError) {
                error = true;
            }

            if (!error) {
                updateCarrierContact($scope.carrier.id, firstName, lastName, title, email, phoneNumber);
                if ($('#carrierContactEmail').popover()) {
                    $('#carrierContactEmail').popover('dispose');
                }
            }
        };

        function updateCarrierContact(carrierId, firstName, lastName, title, email, phone) {
            let data = {
                contact: {
                    firstName: firstName,
                    lastName: lastName,
                    title: title,
                    phoneNumber: phone,
                    email: email,
                },
            };

            $scope.requestAdminUpdateCarrier(carrierId, data).then(
                function (answer) {
                    $scope.refreshPage();
                },
                function (error) {
                    // Trigger notifications
                }
            );
        }

        /* Routes */
        $scope.goToUrl = function (url) {
            window.location.href = url;
        };

        /* =======================================================================================================================
       ADD / DELETE DISPATCHER
    =======================================================================================================================  */
        $scope.goBackToCarrier = function () {
            $scope.showDispatcherCreation = false;
            $scope.scrollToTop();
        };

        $scope.goToAddDispatchers = function () {
            $scope.showDispatcherCreation = true;
            $scope.newDispatchersAreaOpen = true;
            $('#carrier-newDispatchersArea').collapse('show');
            $('#carrier-addDispatcherHeader').find('.custom-chevron').addClass('fa-chevron-up').removeClass('fa-chevron-down');
            $scope.newDispatchers = [];
            $scope.newDispatcher = {
                gender: 'X',
                localeIdentifier: $scope.carrier.localeIdentifier,
            };
        };

        $scope.goBackToCarrierFromCreateDispatcher = function (deleteAllNewDispatchers) {
            if ($scope.newDispatchers.length > 0) {
                if (deleteAllNewDispatchers) {
                    $scope.newDispatchers = [];
                    $scope.dismissNotification('carrier-exitDispatcherCreationWarning');
                    $scope.goBackToCarrier();
                    $scope.newDispatchersAreaOpen = false;
                } else {
                    $scope.triggerNotification('carrier-exitDispatcherCreationWarning');
                    $scope.scrollToTop();
                }
            } else {
                $scope.goBackToCarrier();
                $scope.newDispatchersAreaOpen = false;
            }
        };

        $scope.validateNewDispatcherForm = function (newDispatcher) {
            if (newDispatcher) {
                let error = false;

                const errorFirstName = $scope.validateStandardTextInput('carrier-newDispatcherFirstNameInput', newDispatcher.firstName, true);
                if (errorFirstName) {
                    error = true;
                    if ($('#carrier-newDispatcherFirstNameInput').popover()) {
                        $('#carrier-newDispatcherFirstNameInput').popover('dispose');
                    }
                    $('#carrier-newDispatcherFirstNameInput').popover({
                        title: $translate.instant('error'),
                        content: errorFirstName,
                        placement: 'top',
                        trigger: 'focus',
                    });
                    $scope.setFocus('carrier-newDispatcherFirstNameInput');
                }

                const errorLastName = $scope.validateStandardTextInput('carrier-newDispatcherLastNameInput', newDispatcher.lastName, true);
                if (errorLastName) {
                    error = true;
                    if ($('#carrier-newDispatcherLastNameInput').popover()) {
                        $('#carrier-newDispatcherLastNameInput').popover('dispose');
                    }
                    $('#carrier-newDispatcherLastNameInput').popover({
                        title: $translate.instant('error'),
                        content: errorLastName,
                        placement: 'top',
                        trigger: 'focus',
                    });
                    $scope.setFocus('carrier-newDispatcherLastNameInput');
                }

                const errorEmail = $scope.validateEmailInput('carrier-newDispatcherEmailInput', newDispatcher.email, true);
                if (errorEmail) {
                    error = true;
                    if ($('#carrier-newDispatcherEmailInput').popover()) {
                        $('#carrier-newDispatcherEmailInput').popover('dispose');
                    }
                    $('#carrier-newDispatcherEmailInput').popover({
                        title: $translate.instant('error'),
                        content: errorEmail,
                        placement: 'top',
                        trigger: 'focus',
                    });
                    $scope.setFocus('carrier-newDispatcherEmailInput');
                }

                if (!error) {
                    $scope.createDispatcher(newDispatcher);
                }
            }
        };

        $scope.deleteNewDispatcher = function (index) {
            $scope.newDispatchers.splice(index, 1);
        };

        $scope.createDispatcher = function (newDispatcher) {
            if (
                $scope.arrayContains($scope.dispatchersForCarrier, newDispatcher.email, 'email') ||
                $scope.arrayContains($scope.newDispatchers, newDispatcher.email, 'email')
            ) {
                if ($('#carrier-newDispatcherEmailInput').popover()) {
                    $('#carrier-newDispatcherEmailInput').popover('dispose');
                }
                $('#carrier-newDispatcherEmailInput').popover({
                    title: $translate.instant('error'),
                    content: $translate.instant('messageErrorDispatcherExists'),
                    placement: 'top',
                    trigger: 'focus',
                });
                $scope.setInputError('carrier-newDispatcherEmailInput');
                $scope.setFocus('carrier-newDispatcherEmailInput');
            } else {
                $scope.newDispatchers.push(newDispatcher);
                $scope.newDispatcher = {};
            }
        };

        $scope.saveAllNewDispatchers = function () {
            $scope.saveNewDispatcher(0);
        };

        $scope.saveNewDispatcher = function (index) {
            if (index === 0) {
                $scope.inError = [];
            }
            if (index < $scope.newDispatchers.length) {
                const dispatcher = $scope.newDispatchers[index];
                const carrierId = $scope.getIdFromURL();
                $scope.requestCreateDispatcherForCarrier(carrierId, dispatcher).then(
                    function (answer) {
                        if (answer.error) {
                            $scope.inError.push({
                                dispatcher: dispatcher,
                                message: answer.error.message,
                                code: answer.error.code,
                            });
                            $scope.saveNewDispatcher(index + 1);
                        } else {
                            $scope.saveNewDispatcher(index + 1);
                        }
                    },
                    function (error) {
                        $scope.inError.push({
                            dispatcher: dispatcher,
                            message: error.message,
                            code: error.code,
                        });
                        $scope.saveNewDispatcher(index + 1);
                    }
                );
            } else if ($scope.inError && $scope.inError.length > 0) {
                const errorCount = $scope.inError.length;
                let errorMessage = '( ' + errorCount + ' ) ' + $translate.instant('dispatchersCouldNotBeCreated') + ' (';

                for (const errorIndex in $scope.inError) {
                    if (errorIndex > 0) {
                        errorMessage += ', ';
                    }
                    errorMessage += $scope.inError[errorIndex].dispatcher.email;
                }

                errorMessage += ')';
                $scope.triggerCustomNotification('carrier-genericError', 'carrierGenericErrorMessage', errorMessage);
                $scope.newDispatchers = [];
                $scope.$apply();
            } else {
                $scope.refreshPage();
            }
        };

        /**
         * Displays a modal to confirm the deactivation of the user and make the request
         * Upon success, page is refreshed
         *
         * @param {Object} user
         */
        $scope.deactivateUser = (user) => {
            $scope.changeConfirmationModalEmail = user.email;

            $scope.showChangeConfirmationModal = true;
            $scope.confirmationModalAction = async () => {
                $scope.showChangeConfirmationModal = false;

                $scope.requestDeactivateUser(user.id).then(
                    () => {
                        // Redirect to the same page
                        $scope.refreshPage();
                    },
                    (error) => {
                        if (error.message) {
                            $scope.triggerCustomNotification('client-genericError', 'clientGenericErrorMessage', $translate.instant(error.message));
                        } else {
                            $scope.triggerCustomNotification(
                                'client-genericError',
                                'clientGenericErrorMessage',
                                $translate.instant('unknownErrorMessage')
                            );
                        }
                    }
                );
            };
        };

        /**
         * Update communication setting for carrier
         */
        $scope.toggleCommunicationModule = () => {
            const isEnabled = !$scope.carrier.settings.communication.isEnabled;
            const data = {
                communication: {
                    isEnabled: isEnabled,
                },
            };

            $scope.requestAdminSettingsUpdateForCarrier($scope.carrier.id, data).then(
                function () {
                    $scope.carrier.settings.communication.isEnabled = isEnabled;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-carrier-communication', error);
                }
            );
        };

        /**
         * Update directions editing setting for carrier
         */
        $scope.toggleDirectionsEditing = () => {
            const isEnabled = !$scope.carrier.settings.directionsEditing.isEnabled;
            const data = {
                directionsEditing: {
                    isEnabled: isEnabled,
                },
            };

            $scope.requestAdminSettingsUpdateForCarrier($scope.carrier.id, data).then(
                function () {
                    $scope.carrier.settings.directionsEditing.isEnabled = isEnabled;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-carrier-directions-editing', error);
                }
            );
        };

        /**
         * Update automatic stop skip setting for carrier
         */
        $scope.toggleAutomaticStopSkip = () => {
            const isEnabled = !$scope.carrier.settings.automaticStopSkip.isEnabled;
            const data = {
                automaticStopSkip: {
                    isEnabled: isEnabled,
                },
            };

            $scope.requestAdminSettingsUpdateForCarrier($scope.carrier.id, data).then(
                function () {
                    $scope.carrier.settings.automaticStopSkip.isEnabled = isEnabled;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-carrier-automatic-stop', error);
                }
            );
        };

        /**
         * Update automatic departure setting for carrier
         */
        $scope.toggleAutomaticDeparture = () => {
            const isEnabled = !$scope.carrier.settings.automaticDepartureWithAttendanceTracking.isEnabled;
            const data = {
                automaticDepartureWithAttendanceTracking: {
                    isEnabled: isEnabled,
                },
            };

            $scope.requestAdminSettingsUpdateForCarrier($scope.carrier.id, data).then(
                function () {
                    $scope.carrier.settings.automaticDepartureWithAttendanceTracking.isEnabled = isEnabled;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-carrier-automatic-stop', error);
                }
            );
        };

        /**
         * Update driver's accounts management setting for carrier
         */
        $scope.toggleDriverAccountManagement = () => {
            const isEnabled = !$scope.carrier.settings.driverAccountManagement.isEnabled;
            const data = {
                driverAccountManagement: {
                    isEnabled: isEnabled,
                },
            };

            $scope.requestAdminSettingsUpdateForCarrier($scope.carrier.id, data).then(
                function () {
                    $scope.carrier.settings.driverAccountManagement.isEnabled = isEnabled;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-carrier-driver-account-management', error);
                }
            );
        };

        /**
         * Update vehicles list management setting for carrier
         */
        $scope.toggleVehiclesListManagement = () => {
            const isEnabled = !$scope.carrier.settings.vehiclesListManagement.isEnabled;
            const data = {
                vehiclesListManagement: {
                    isEnabled: isEnabled,
                },
            };

            $scope.requestAdminSettingsUpdateForCarrier($scope.carrier.id, data).then(
                function () {
                    $scope.carrier.settings.vehiclesListManagement.isEnabled = isEnabled;
                },
                function (error) {
                    $rootScope.$broadcast('request-error-carrier-vehicles-list-management', error);
                }
            );
        };

        /**
         * Update vehicle inspection setting for carrier
         */
        $scope.toggleVehicleInspection = () => {
            $scope.hasSAAQVehicleInspectionTemplate = !$scope.hasSAAQVehicleInspectionTemplate;
            $scope.updateAvailableTemplates();
        };

        /**
         * Update available templates for carrier
         */
        $scope.updateAvailableTemplates = () => {
            const dataToSend = [];

            if ($scope.hasSAAQVehicleInspectionTemplate) {
                dataToSend.push('SAAQ');
            }

            const data = {
                availableVehicleInspectionTemplates: dataToSend,
            };

            $scope.requestAdminAvailableVehicleInpectionTemplatesUpdateForCarrier($scope.carrier.id, data).catch(function (error) {
                $rootScope.$broadcast('request-error-carrier-available-templates', error);
            });
        };

        /**
         * Update photo form option for carrier
         */
        $scope.togglePhotosFormOption = () => {
            $scope.hasPhotosForm = !$scope.hasPhotosForm;
            $scope.updateAvailableForms();
        };

        /**
         * Update disciplinary form option for carrier
         */
        $scope.toggleDisciplinaryFormOption = () => {
            $scope.hasDisciplinaryForm = !$scope.hasDisciplinaryForm;
            $scope.updateAvailableForms();
        };

        /**
         * Update available forms for carrier
         */
        $scope.updateAvailableForms = () => {
            const dataToSend = [];

            if ($scope.hasPhotosForm) {
                dataToSend.push('photos');
            }
            if ($scope.hasDisciplinaryForm) {
                dataToSend.push('disciplinary');
            }

            const data = {
                availableForms: dataToSend,
            };

            $scope.angRequestAdminAvailableFormsUpdateForCarrier($scope.carrier.id, data).catch(function (error) {
                $rootScope.$broadcast('request-error-carrier-available-forms', error);
            });
        };

        /**
         * Update manual skip option for carrier
         * @param {"manualSkipDropoff"|"manualSkipPickup"|"postponedStops"} skipType
         */
        $scope.toggleManualSkipOption = (skipType) => {
            const isEnabled = !$scope.carrier.settings[skipType].isEnabled;
            const data = {
                [skipType]: {
                    isEnabled: isEnabled,
                },
            };
            $scope.requestAdminSettingsUpdateForCarrier($scope.carrier.id, data).then(
                function () {
                    $scope.carrier.settings[skipType].isEnabled = isEnabled;
                    // if "manualSkipDropoff" and "manualSkipPickup" are disabled, disable postponedStops
                    if (
                        !$scope.carrier.settings['manualSkipDropoff'].isEnabled &&
                        !$scope.carrier.settings['manualSkipPickup'].isEnabled &&
                        $scope.carrier.settings['postponedStops'].isEnabled &&
                        skipType !== 'postponedStops'
                    ) {
                        $scope.toggleManualSkipOption('postponedStops');
                    }
                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-carrier-manual-skip', error);
                }
            );
        };

        /**
         * Convert boolean value to a localized yes or no
         * @param {boolean} boolean
         * @return {string} the localized yes or no
         */
        const convertBooleanToHumanReadable = (boolean) => {
            return boolean ? $translate.instant('yes') : $translate.instant('no');
        };

        /**
         * Generate and download a list of carriers in CSV
         */
        $scope.generateCSVofCarriersForAdmin = async () => {
            try {
                $scope.csvIsLoading = true;

                const headers = [
                    $translate.instant('carriersCSVheader_internalid'),
                    $translate.instant('carriersCSVheader_carrier'),
                    $translate.instant('carriersCSVheader_clients'),
                    $translate.instant('carriersCSVheader_contact'),
                    $translate.instant('carriersCSVheader_dispatchers'),
                    $translate.instant('carriersCSVheader_driverCount'),
                    $translate.instant('carriersCSVheader_busNumberCount'),
                    $translate.instant('carriersCSVheader_routeCount'),
                    $translate.instant('carriersCSVheader_vehicleCount'),
                    $translate.instant('carriersCSVheader_communicationModule'),
                    $translate.instant('carriersCSVheader_photosForm'),
                    $translate.instant('carriersCSVheader_disciplinaryForm'),
                    $translate.instant('carriersCSVheader_directions'),
                    $translate.instant('carriersCSVheader_automaticSkip'),
                    $translate.instant('carriersCSVheader_driverManagement'),
                    $translate.instant('carriersCSVheader_vehiclesListManagement'),
                    $translate.instant('carriersCSVheader_automatic_departure'),
                    $translate.instant('carriersCSVheader_availableVehicleInspectionTemplates'),
                    $translate.instant('carriersCSVheader_manualSkip_pickupStop'),
                    $translate.instant('carriersCSVheader_manualSkip_dropoffStop'),
                ];

                const csvCarriers = await Promise.all(
                    $scope.carriers.map(async (carrier) => {
                        const [forms, dispatchers, details, routes] = await Promise.all([
                            $scope.angRequestAdminGetAvailableFormsForCarrier(carrier.id),
                            $scope.requestGetDispatchersForCarrier(carrier.id),
                            $scope.requestGetCarrier(carrier.id),
                            $scope.angRequestAdminGetRoutesForCarrier(carrier.id),
                        ]);

                        const csvCarrier = [
                            carrier.id,
                            carrier.name,
                            [...new Set(routes.data.routes.map((route) => route.client.name))].join(' | '),
                            details.contact.email ? details.contact.email : '',
                            dispatchers.dispatchers.filter((dispatcher) => dispatcher.isActive).length.toString(),
                            carrier.driverCount,
                            carrier.busCount,
                            carrier.routeCount,
                            carrier.vehicleCount,
                            convertBooleanToHumanReadable(details.settings.communication.isEnabled),
                            convertBooleanToHumanReadable(forms.data.availableForms.map((form) => form.formType).includes('photos')),
                            convertBooleanToHumanReadable(forms.data.availableForms.map((form) => form.formType).includes('disciplinary')),
                            convertBooleanToHumanReadable(details.settings.directionsEditing.isEnabled),
                            convertBooleanToHumanReadable(details.settings.automaticStopSkip.isEnabled),
                            convertBooleanToHumanReadable(details.settings.driverAccountManagement.isEnabled),
                            convertBooleanToHumanReadable(details.settings.vehiclesListManagement.isEnabled),
                            convertBooleanToHumanReadable(details.settings.automaticDepartureWithAttendanceTracking.isEnabled),
                            convertBooleanToHumanReadable(details.settings.vehicleInspection.isEnabled),
                            convertBooleanToHumanReadable(details.settings.manualSkipPickup.isEnabled),
                            convertBooleanToHumanReadable(details.settings.manualSkipDropoff.isEnabled),
                        ];

                        return csvCarrier;
                    })
                );

                const csv = Papa.unparse(
                    {
                        fields: headers,
                        data: csvCarriers,
                    },
                    {
                        header: true,
                        quotes: true,
                    }
                );

                const filename = moment().format('YYYY-MM-DD') + ' mTransport carriers.csv';

                $scope.downloadCSV(csv, filename);
            } catch (error) {
                $scope.catchErrorDefault(error);
            } finally {
                $scope.csvIsLoading = false;
            }
        };

        /* =======================================================================================================================
       LAYOUT
    =======================================================================================================================  */
        $(window).on('resize', function (e) {
            $scope.$apply();
        });
    },
]);
