/*
 * {Number} progress - the progress in percentage of the loader (0 to 100), default to 0
 * {Number} diameter - the size (diameter) of the circle, default to 20
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('circleProgression', {
    templateUrl: './shared/circleProgression/circleProgression.html',
    transclude: true,
    bindings: {
        progress: '<',
        diameter: '<',
    },
    controller: function ($scope) {
        this.$onInit = function () {
            this.progress = this.progress || 0;
            this.diameter = this.diameter || 20;
            this.radius = this.diameter / 2;
            this.ajustedRadius = this.diameter / 2.5; // Ajusted for the circle to not be cropped.
            $scope.elementId = Math.random().toString(36).substring(2, 15);
            setProgress();
        };

        $scope.$watch(
            () => this.progress,
            () => {
                setProgress();
            }
        );

        const setProgress = () => {
            // eslint-disable-next-line no-undef
            const circle = document.getElementById($scope.elementId);
            if (circle) {
                const circumference = this.ajustedRadius * 2 * Math.PI;

                circle.style.strokeDasharray = `${circumference} ${circumference}`;
                circle.style.strokeDashoffset = `${circumference}`;

                const offset = circumference - (this.progress / 100) * circumference;
                circle.style.strokeDashoffset = offset;
            }
        };
    },
});
