const { isPast } = require('./dateComparator');

/**
 * Returns the status of the trip to translate, to display to user
 * @param {Object} trip - The trip object
 * @param {String} trip.computedStatus - The status of the trip computed by backend { 'planned', 'inProgress', 'completed', 'cancelled', 'notUsed', 'incomplete' }
 * @return {String} Returns the status of the trip { 'planned', 'inProgress', 'completed', 'cancelled', 'mTransportNotUsed', 'incomplete' }
 */
function getDisplayedComputedStatus(trip) {
    if (isPast(trip.schedule)) {
        if (trip.computedStatus === 'inProgress') {
            return 'incomplete';
        } else if (trip.computedStatus === 'planned') {
            return 'mTransportNotUsed';
        }
    }

    if (trip.computedStatus === 'notUsed') {
        return 'mTransportNotUsed';
    }

    return trip.computedStatus;
}

module.exports = { getDisplayedComputedStatus };
