angular.module('mTransportApp').controller('TagsInputController', [
    '$attrs',
    '$scope',
    '$translate',
    '$interval',
    '$timeout',
    '$controller',
    '$window',
    function ($attrs, $scope, $translate, $interval, $timeout, $controller, $window) {
        angular.extend(this, $controller('ToolsController', { $scope: $scope }));

        // The dataset object must be of type TagsInputDataset
        $scope.doneLoading = true;
        $scope.searchInputValue = '';
        $scope.losingFocusTimeout = null;
        $scope.scrollDownListTimer = null;
        $scope.scrollUpListTimer = null;
        $scope.focusedElementId = -1;
        $scope.allowFocusOut = true;
        $scope.focusedElementScrollTimeout = null;
        $scope.dropdownHeight = 0;

        // Filter search results on searchInputValue change
        $scope.$watch(
            function ($scope) {
                return $scope.searchInputValue;
            },
            function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.filterSearchResults();
                }
            }
        );

        // Watch when theres a change in the list selected element
        $scope.$watch('focusedElementId', function (newValue, oldValue) {
            // Don't run multiple times at once
            if ($scope.focusedElementScrollTimeout !== null) {
                $timeout.cancel($scope.focusedElementScrollTimeout);
            }

            // Timeout to allow class to have changed before getting element positions values
            $scope.focusedElementScrollTimeout = $timeout(function () {
                let selectedItem = document.querySelector('.selected');

                // If an item is selected
                if (selectedItem != null) {
                    // Item height
                    let selectedItemHeight = selectedItem.clientHeight;
                    // Item top position relative to parent
                    let selectedItemOffset = selectedItem.offsetTop;
                    // Item bottom position relative to parent
                    let selectedItemBottomPosition = selectedItemHeight + selectedItemOffset;
                    // Current scroll position of the list container (starting at zero)
                    let inputTagListScrollTop = angular.element('.search-input-data')[0].scrollTop;
                    // Current scrolling position (starting at list bottom)
                    let currentScrollPosition = $scope.dropdownHeight + inputTagListScrollTop;

                    if (selectedItemOffset === 0 || selectedItemOffset == selectedItemHeight) {
                        // For the 2 first elements, skip tests and scroll top
                        angular.element('.search-input-data')[0].scrollTop = 0;
                    } else if (
                        $scope.focusedElementId == $scope.dataset.searchResults.length - 1 ||
                        $scope.focusedElementId == $scope.dataset.searchResults.length - 2
                    ) {
                        // For last 2 elements, skip tests and scroll to bottom
                        angular.element('.search-input-data')[0].scrollTop = selectedItemBottomPosition;
                    } else if (selectedItemBottomPosition > currentScrollPosition - selectedItemHeight) {
                        // Scroll down (increment scroll top) if the bottom of selected element is lower than current scroll position
                        angular.element('.search-input-data')[0].scrollTop += selectedItemHeight + 10;
                    } else if (selectedItemOffset < inputTagListScrollTop) {
                        // Scroll up if the top of current element is higher (so value is less) than current scroll position
                        angular.element('.search-input-data')[0].scrollTop -= selectedItemHeight + 10;
                    }
                }
                $scope.focusedElementScrollTimeout = null;
            }, 100);
        });

        // Save dropdownHeight on .search-input-data to avoid computing on each scroll
        $scope.$watch(
            function () {
                return angular.element('.search-input-data').attr('class');
            },
            function (newValue) {
                if (newValue.match(/ng-hide/) === null) {
                    $scope.dropdownHeight = angular.element('.search-input-data')[0].clientHeight;
                }
            }
        );

        angular.element($window).bind('resize', function () {
            if ($scope.dropdownHeight !== 0) {
                $scope.dropdownHeight = angular.element('.search-input-data')[0].clientHeight;
            }
        });

        // Destroy resize listener on TagsInput destruction
        $scope.$on('$destroy', function () {
            angular.element($window).unbind('resize');
        });

        /*** Event Listeners ***/
        $scope.onKeyPressListener = function (evt) {
            switch (evt.key) {
                case 'Escape':
                    $scope.dataset.hideResults();
                    break;
                case 'ArrowUp':
                    if ($scope.focusedElementId > 0) {
                        $scope.focusedElementId--;
                    }
                    break;
                case 'ArrowDown':
                    if ($scope.focusedElementId < $scope.dataset.searchResults.length - 1) {
                        $scope.focusedElementId++;
                    }
                    break;
                case 'Enter':
                    evt.stopPropagation();
                    evt.preventDefault();
                    if ($scope.focusedElementId !== -1) {
                        $scope.addToSelectedSearchResults($scope.dataset.searchResults[$scope.focusedElementId]);
                    }
                    break;
                case 'Backspace':
                    if ($scope.searchInputValue === '') {
                        $scope.removeLastElementFromSelectedResults();
                    }
                    break;
                default:
                    if (!$scope.dataset.displayResults) {
                        $scope.dataset.showResults();
                    }
                    break;
            }
        };

        $scope.onKeyUpListener = function (evt) {
            if ($scope.scrollDownListTimer !== null) {
                $timeout.cancel($scope.scrollDownListTimer);
                $scope.scrollDownListTimer = null;
            }
            if ($scope.scrollUpListTimer !== null) {
                $timeout.cancel($scope.scrollUpListTimer);
                $scope.scrollUpListTimer = null;
            }
        };

        $scope.addItemClickListener = function (data) {
            $scope.cancelOnFocusOutEvt();
            $scope.addToSelectedSearchResults(data);
        };

        $scope.deleteItemClickListener = function (data) {
            $scope.cancelOnFocusOutEvt();
            $scope.deleteFromSelectedResults(data);
        };

        $scope.onFocusListener = function (evt) {
            evt.target.focus();
            $scope.dataset.showResults();
        };

        $scope.disableFocusOutListener = function (evt) {
            $scope.allowFocusOut = false;
        };

        $scope.enableFocusOutListener = function (evt) {
            document.getElementById($scope.inputId).focus();
            $scope.allowFocusOut = true;
        };

        $scope.onFocusOutListener = function (evt) {
            if ($scope.allowFocusOut) {
                $scope.losingFocusTimeout = $timeout(function () {
                    $scope.dataset.hideResults();
                }, 200);
            }
        };

        $scope.cancelOnFocusOutEvt = function () {
            if ($scope.losingFocusTimeout !== null) {
                $timeout.cancel($scope.losingFocusTimeout);
                $scope.losingFocusTimeout = null;
            }
        };

        $scope.scrollDownList = function () {
            $scope.scrollDownListTimer = $timeout(function () {
                if ($scope.focusedElementId < $scope.dataset.searchResults.length - 1) {
                    $scope.focusedElementId++;
                }
            }, 500);
        };

        $scope.scrollUpList = function () {
            $scope.scrollUpListTimer = $timeout(function () {
                if ($scope.focusedElementId > 0) {
                    $scope.focusedElementId--;
                }
            }, 500);
        };

        /*** Controller ***/
        $scope.initTagInput = function () {};

        $scope.removeLastElementFromSelectedResults = function () {
            $scope.focusedElementId = -1;
            $scope.dataset.removeLastElementFromSelectedResults();
        };

        $scope.toggleSelectAll = function () {
            if ($scope.dataset.selectAll === true) {
                $scope.dataset.selectAllFunc();
            } else {
                $scope.dataset.resetSelectedResults();
            }
        };

        $scope.selectAll = function () {
            $scope.dataset.selectAll();
        };

        $scope.focusOnSearchInput = function () {
            $scope.focusedElementId = -1;
            $scope.cancelOnFocusOutEvt();
            document.getElementById($scope.inputId).focus();
        };

        $scope.addToSelectedSearchResults = function (data) {
            $scope.cancelOnFocusOutEvt();
            $scope.dataset.addToSelectedResult(data);
        };

        $scope.deleteFromSelectedResults = function (data) {
            $scope.focusedElementId = -1;
            $scope.dataset.removeFromSelectedResults(data);
        };

        // Filters search results on inputs keyup
        $scope.filterSearchResults = function () {
            $scope.focusedElementId = -1;
            $scope.dataset.setSearchInputValue($scope.searchInputValue);
            $scope.dataset.refreshSearchResults();
        };
    },
]);
