/*
 * {data} data - get data from api to create a an autocomplete search
 *
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('autoCompleteInput', {
    templateUrl: './shared/autoCompleteInput/autoCompleteInput.html',
    bindings: {
        result: '=',
        inputValue: '=',
        searchFn: '&',
        label: '@',
    },
    controller: function ($scope) {
        this.$onInit = function () {
            this.result = [];
        };

        $scope.selectStudent = function (lastName, firstName, _) {
            this.inputValue = `${lastName} ${firstName}`;
            this.result = [];
        }.bind(this);
    },
});
