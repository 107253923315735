angular.module('mTransportApp').controller('FeedbackController', [
    '$scope',
    '$translate',
    function ($scope, $translate) {
        $scope.initializeFeedback = function () {
            $scope.feedbackSent = false;
            $scope.currentLang = localStorage.getItem('userLanguage');
        };

        // When the page is loaded, focus on the textArea.
        // eslint-disable-next-line no-undef
        $(document).ready(function () {
            $('#textArea').focus();
        });

        // Show a leaving confirmation alert if the user write something in the textArea and if Backward, Forward, or Change vue
        $scope.$on('$routeChangeStart', function (event) {
            const message = $translate.instant('leavingConfirmation');
            const isThereATextArea = document.querySelector('textarea') != null;
            if ($scope.feedbackSent != true || isThereATextArea) {
                if (document.querySelector('textarea').value.length == 0) {
                    event.returnValue = '';
                } else {
                    const okClick = confirm(message);
                    // If user clicks on Cancel
                    if (!okClick) {
                        event.preventDefault();
                        event.returnValue = '';
                    }
                }
            }
        });

        $scope.sendFeedback = function (feedbackText) {
            if (feedbackText && feedbackText.length > 0) {
                let data = {};
                data.message = feedbackText;
                $scope.postFeedbacks(data).then(
                    function (returnedData) {
                        $scope.feedbackSent = true;
                        $scope.$apply();
                    },
                    function (error) {
                        $scope.catchErrorDefault(error);
                    }
                );
            }
        };

        $scope.clickOnNewFeedback = function () {
            $scope.feedbackSent = false;
        };
    },
]);
