/**
 * Controller to manage the favourites, since they are managed in the cookies.
 * Note that this features hould be refactored to be managed in the backend.
 */

// eslint-disable-next-line no-undef
app.controller('FavouriteController', [
    '$rootScope',
    '$scope',
    function ($rootScope, $scope) {
        /**
         * Get the route favourites from the cookies
         * @return {String[]} Array of the id of the favourite routes
         */
        $scope.getRouteFavourites = () => {
            // eslint-disable-next-line no-undef
            const mailHash = sha256($rootScope.loggedUserMail);
            let favourites = [];
            // eslint-disable-next-line no-undef
            const favouritesRaw = window.localStorage.getItem('favouriteRoutes-' + mailHash);
            if (favouritesRaw) {
                favourites = JSON.parse(favouritesRaw);
            }

            return favourites;
        };

        /**
         * Add or Remove the specified favourite.
         * If the route is already in the favourites it will get removed,
         * if it's not, it will get added.
         * @param {String} routeId
         */
        $scope.addOrRemoveRouteFavourite = (routeId) => {
            // eslint-disable-next-line no-undef
            const mailHash = sha256($rootScope.loggedUserMail);
            const favourites = $scope.getRouteFavourites();

            // Search for current trip's route in favourites and add/remove.
            const index = favourites.indexOf(routeId);
            if (index > -1) {
                favourites.splice(index, 1);
            } else {
                favourites.push(routeId);
            }

            // Save favourites.
            // eslint-disable-next-line no-undef
            window.localStorage.setItem('favouriteRoutes-' + mailHash, JSON.stringify(favourites));
        };
    },
]);
