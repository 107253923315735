/**
 * Checks if the password is valid.
 * @param {String} password - The password to validate.
 * @return {Object} Returns validation
 */
function validatePassword(password) {
    const validation = {
        length: password.length >= 8,
        lowercase: /[a-z]/.test(password),
        uppercase: /[A-Z]/.test(password),
        number: /[0-9]/.test(password),
    };
    return validation;
}

module.exports = { validatePassword };
