/**
 * Get the last announcement for a trip
 * @param {Object} trip
 * @return {Object} the last announcement
 */
function getLastAnnouncement(trip) {
    // Compare dates of lastAnnouncedDelay, lastVariousAnnouncement and lastCancellationAnnouncement and return the most recent
    const lastAnnouncements = [];
    if (trip.lastAnnouncedDelay) {
        lastAnnouncements.push(trip.lastAnnouncedDelay);
    }
    if (trip.lastVariousAnnouncement) {
        lastAnnouncements.push(trip.lastVariousAnnouncement);
    }
    if (trip.lastCancellationAnnouncement) {
        lastAnnouncements.push(trip.lastCancellationAnnouncement);
    }

    if (lastAnnouncements.length === 0) {
        return null;
    }
    return lastAnnouncements.reduce((a, b) => (new Date(a.timestamp) > new Date(b.timestamp) ? a : b));
}

/**
 * Get announcement string message for translation
 * @param {Object} announcement
 * @return {String} the announcement string for translation
 */
function getAnnouncementString(announcement) {
    if (announcement) {
        let message = '';
        if (announcement) {
            if (announcement.delayType) {
                switch (announcement.delayType) {
                    case 'none':
                        message = 'announcedDelayNone';
                        break;
                    case 'fewMinutes':
                        message = 'announcedDelayFewMinutes';
                        break;
                    case '15min':
                        message = 'announcedDelay15min';
                        break;
                    case '30min':
                        message = 'announcedDelay30min';
                        break;
                    case '45min':
                        message = 'announcedDelay45min';
                        break;
                    case '60min':
                        message = 'announcedDelay60min';
                        break;
                }
            } else if (announcement.announcement) {
                if (announcement.announcement === 'cancelled') {
                    message = 'cancelled_transport';
                } else if (announcement.announcement === 'restored') {
                    message = 'restored_transport';
                }
            } else if (announcement.message) {
                message = 'erroneousNotifications';
            }
        }

        return message;
    }
    return '';
}

module.exports = { getLastAnnouncement, getAnnouncementString };
