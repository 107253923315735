// eslint-disable-next-line no-undef
angular.module('mTransportApp').controller('ResourcesController', [
    '$scope',
    '$rootScope',
    function ($scope, $rootScope) {
        this.$onInit = () => {
            // eslint-disable-next-line no-undef

            const tableHeaders = [
                { label: 'description', index: 0 },
                { label: 'lang_fr', index: $rootScope.language === 'fr' ? 1 : 2 },
                { label: 'lang_en', index: $rootScope.language === 'en' ? 1 : 2 },
            ];
            $scope.tableHeaders = tableHeaders.sort((a, b) => a.index - b.index);

            const sections = [
                {
                    key: 'driver',
                    name: 'driverApp',
                    resources: [
                        {
                            key: 'manualRoute',
                            description: 'manualRoute',
                            date: '(2023-12-12)',
                            urls: {
                                en: './assets/resources/2023-12-12 mTransport driver app - step by step.pdf',
                                fr: './assets/resources/2023-12-12 App conducteur mTransport - pas à pas.pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'driver', 'carrier_manager', 'carrier_observer', 'carrier_mechanic'],
                        },
                        {
                            key: 'faq',
                            description: 'faq',
                            date: '(2020-01-16)',
                            urls: {
                                en: './assets/resources/2020-01-16 mTransport FAQ Driver.pdf',
                                fr: './assets/resources/2020-01-16 mTransport FAQ Conducteur.pdf',
                            },
                            roles: ['dispatcher', 'driver', 'carrier_manager', 'carrier_observer', 'carrier_mechanic'],
                        },
                        {
                            key: 'driverScript',
                            description: 'driverScript',
                            dateEng: '(2023-08-14)',
                            dateFr: '(2023-08-14)',
                            urls: {
                                en: './assets/resources/2023-08-14 mTransport Trainers.pdf',
                                fr: './assets/resources/2023-08-14 mTransport Formateurs.pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'driver', 'carrier_manager', 'carrier_observer', 'carrier_mechanic'],
                        },
                        {
                            key: 'driverVideoWithoutAttendance',
                            description: 'driverVideoWithoutAttendance',
                            date: '',
                            urls: {
                                en: 'https://www.youtube.com/watch?v=P--bGWfghH4',
                                fr: 'https://www.youtube.com/watch?v=qW5CGxFj-Lo',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'driver', 'carrier_manager', 'carrier_observer', 'carrier_mechanic'],
                        },
                        {
                            key: 'driverVideoRegistration',
                            description: 'driverVideoRegistration',
                            date: '',
                            urls: {
                                en: 'https://youtu.be/PZb_kiwhzJE',
                                fr: 'https://youtu.be/AJGaiZp_nIs',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'driver', 'carrier_manager', 'carrier_observer', 'carrier_mechanic'],
                        },
                        {
                            key: 'accessCardsForDemoRuns',
                            description: 'accessCardsForDemoRuns',
                            date: '',
                            urls: {
                                en: './assets/resources/Demo Cards-mTransport driver app.pdf',
                                fr: './assets/resources/Cartes Demo-app conducteur mTransport.pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'driver', 'carrier_manager', 'carrier_observer', 'carrier_mechanic'],
                        },
                    ],
                },
                {
                    key: 'driver',
                    name: 'driverAppTechnical',
                    resources: [
                        {
                            key: 'popUpMessageDocument',
                            description: 'popUpMessageDocument',
                            date: '(2021-09-22)',
                            urls: {
                                en: './assets/resources/2021-09-22 Pop-up messages.pdf',
                                fr: './assets/resources/2021-09-22 Messages pop-up.pdf',
                            },
                            roles: ['dispatcher', 'carrier_manager', 'carrier_observer'],
                        },
                    ],
                },
                {
                    key: 'portal',
                    name: 'portal',
                    resources: [
                        {
                            key: 'portalManualForSchools',
                            description: 'portalManual',
                            date: '(2023-06-08)',
                            urls: {
                                en: './assets/resources/2023-06-08 Portal mTransport - schools.pdf',
                                fr: './assets/resources/2023-06-08 Portail mTransport - écoles.pdf',
                            },
                            roles: ['observer'],
                        },
                        {
                            key: 'portalManual',
                            description: 'portalManual',
                            date: '(2024-07-15)',
                            urls: {
                                en: './assets/resources/2024-07-15 Portal mTransport.pdf',
                                fr: './assets/resources/2024-07-15 Portail mTransport.pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        },
                        {
                            key: 'portalManualForSchools',
                            description: 'portalManual_schoolsVersion',
                            date: '(2023-06-08)',
                            urls: {
                                en: './assets/resources/2023-06-08 Portal mTransport - schools.pdf',
                                fr: './assets/resources/2023-06-08 Portail mTransport - écoles.pdf',
                            },
                            roles: ['manager', 'agent'],
                        },
                        {
                            key: 'driverAccountCreation',
                            description: 'driverAccountCreation',
                            date: '(2024-05-06)',
                            urls: {
                                en: './assets/resources/2024-05-06 Driver account creation.pdf',
                                fr: "./assets/resources/2024-05-06 Création d'un compte conducteur.pdf",
                            },
                            roles: ['carrier_manager', 'dispatcher'],
                        },
                    ],
                },
                {
                    key: 'guardian',
                    name: 'guardian',
                    resources: [
                        {
                            key: 'parentInfoSheetFirstSteps',
                            description: 'parentInfoSheetFirstSteps',
                            date: '(2021-02-16)',
                            urls: {
                                en: './assets/resources/2021-02-16 First steps with mTransport Parent.pdf',
                                fr: './assets/resources/2021-02-16 Premiers pas - mTransport Parent.pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'observer', 'carrier_manager', 'carrier_observer'],
                        },
                        {
                            key: 'parentInfoSheetAttendance',
                            description: 'parentInfoSheetAttendance',
                            date: '(2021-01-12)',
                            urls: {
                                en: './assets/resources/2021-01-12 mTransport_Parents - WITH attendance tracking.pdf',
                                fr: './assets/resources/2021-01-12 mTransport_Parents - AVEC Contrôle des présences.pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'observer', 'carrier_manager', 'carrier_observer'],
                        },
                        {
                            key: 'parentInfoSheetNoAttendance',
                            description: 'parentInfoSheetNoAttendance',
                            date: '(2021-01-12)',
                            urls: {
                                en: './assets/resources/2021-01-12 mTransport_Parents - WITHOUT attendance tracking.pdf',
                                fr: './assets/resources/2021-01-12 mTransport_Parent - SANS Contrôle des présences.pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'observer', 'carrier_manager', 'carrier_observer'],
                        },
                        {
                            key: 'guardiansNotificationsChoices',
                            description: 'guardiansNotificationsChoices',
                            date: '(2022-03-24)',
                            urls: {
                                en: './assets/resources/2022-03-24 Portal setting - guardians notifications.pdf',
                                fr: './assets/resources/2022-03-24 Réglage portail Notification répondants.pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'observer', 'carrier_manager', 'carrier_observer'],
                        },
                    ],
                },
                {
                    key: 'optionalFunctions',
                    name: 'optionalFunctions',
                    resources: [
                        {
                            key: 'directions',
                            description: 'resourceDirections',
                            date: '(2022-01-05)',
                            urls: {
                                en: './assets/resources/2022-01-05 Adding directions on the portal.pdf',
                                fr: './assets/resources/2022-01-05 Ajouts des directions sur le portail.pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        },
                        {
                            key: 'disciplinaryReports',
                            description: 'disciplinaryReports',
                            dateEng: '(2022-06-01)',
                            dateFr: '(2023-04-06)',
                            urls: {
                                en: './assets/resources/2022-06-01 Disciplinary Reports.pdf',
                                fr: './assets/resources/2023-04-06 Rapports de comportement (discipline) (documentation).pdf',
                            },
                            roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        },
                    ],
                },
            ];

            $scope.mySections = sections
                // Sorting urls according to user language: if fr, fr goes first. if en, en goes first
                .map((section) => {
                    return {
                        ...section,
                        resources: section.resources.map((sectionResources) => {
                            const urls = [
                                {
                                    label: sectionResources.urls.fr ? 'frenchVersion' : 'notCurrentlyAvailable',
                                    index: $rootScope.language === 'fr' ? 0 : 1,
                                    url: sectionResources.urls.fr,
                                },
                                {
                                    label: sectionResources.urls.en ? 'englishVersion' : 'notCurrentlyAvailable',
                                    index: $rootScope.language === 'en' ? 0 : 1,
                                    url: sectionResources.urls.en,
                                },
                            ];
                            return {
                                ...sectionResources,
                                urls: urls.sort((a, b) => a.index - b.index),
                            };
                        }),
                    };
                })
                // Filtering all the sections/resources to only get the ones the user is allowed to see
                .filter((section) => {
                    section.resources = section.resources.filter((resource) => resource.roles.includes($rootScope.loggedUserRole));
                    return section.resources.length > 0;
                });
        };
    },
]);
