angular.module('mTransportApp').controller('ProfileController', [
    '$scope',
    '$rootScope',
    '$controller',
    function ($scope, $rootScope, $controller) {
        angular.extend(this, $controller('ToolsController', { $scope: $scope }));

        $scope.fetchUserSettings = function () {
            $scope.loading = true;
            $scope.notificationSettings = null;
            $scope.hideProfile = true;
            $scope.userDetails = {
                localeIdentifier: null,
                profile: {
                    displayName: null,
                    firstName: null,
                    lastName: null,
                    gender: null,
                },
                role: null,
            };
            $scope.passwordValidation = {};

            $scope.requestSelfDetails().then(
                function (answer) {
                    $scope.userDetails.localeIdentifier = answer.localeIdentifier;
                    $scope.userDetails.profile.displayName = answer.profile.displayName;
                    $scope.userDetails.profile.firstName = answer.profile.firstName;
                    $scope.userDetails.profile.lastName = answer.profile.lastName;
                    $scope.userDetails.profile.gender = answer.profile.gender ? answer.profile.gender : '';
                    $scope.userDetails.role = answer.role;
                    $rootScope.loggedUserName = $scope.userDetails.profile.displayName;
                    if (!$rootScope.loggedUserName) {
                        $rootScope.loggedUserName = '------';
                    }

                    if (Object.keys(answer.profile).length > 1) {
                        $scope.hideProfile = false;
                    }

                    if (['dispatcher', 'carrier_manager'].includes(answer.role)) {
                        fetchCarrierSettings();
                    }

                    if (['manager', 'agent'].includes(answer.role)) {
                        fetchClientSettings();
                    }

                    if (['guardian'].includes(answer.role)) {
                        fetchUserNotificationSettings();
                    }
                    if (['admin', 'helpdesk', 'driver', 'carrier_observer', 'observer', 'carrier_mechanic'].includes(answer.role)) {
                        $scope.loading = false;
                        $scope.$apply();
                    }
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        $scope.initializeProfileView = function () {
            const eyeIconPath = '../../assets/img/ui/eye_open.svg';
            $scope.eyeIcon = eyeIconPath;
            $scope.eyeIconConfirmation = eyeIconPath;
        };

        /**
         * Fetch user notifications settings
         */
        function fetchUserNotificationSettings() {
            $scope.requestUserNotificationPref('me').then(
                function (data) {
                    $scope.notificationSettings = data.channels;
                    $scope.notificationSettings['email'] = data.emailNotification;
                    $scope.loading = false;
                    $scope.$apply();
                },
                function (error) {
                    if (error.status === 403) {
                        $scope.triggerNotification('errorAccessDenied');
                    } else {
                        $scope.logout();
                        $scope.$apply();
                    }
                }
            );
        }

        /**
         * Fetch carrier settings
         */
        function fetchCarrierSettings() {
            Promise.all([$scope.angRequestAdminGetAvailableFormsForCarrier('mine'), $scope.angRequestAdminGetSettingsForCarrier('mine')]).then(
                function ([
                    {
                        data: { availableForms },
                    },
                    {
                        data: { settings },
                    },
                ]) {
                    $scope.carrierSettings = [
                        {
                            name: 'communications',
                            settings: {
                                profileSettingsCommunication: settings.communication.isEnabled,
                                profileSettingsPhotoForm: availableForms.some((form) => form.formType === 'photos'),
                                profileSettingsDisciplinaryForm: availableForms.some((form) => form.formType === 'disciplinary'),
                            },
                        },
                        {
                            name: 'trip',
                            settings: {
                                profileSettingsDirectionsEditing: settings.directionsEditing.isEnabled,
                            },
                        },
                        {
                            name: 'stop',
                            settings: {
                                profileSettingsSkippedStop: settings.automaticStopSkip.isEnabled,
                                profileSettingsAutomaticDeparture: settings.automaticDepartureWithAttendanceTracking.isEnabled,
                                profileSettingsManualSkipPickup: settings.manualSkipPickup.isEnabled,
                                profileSettingsManualSkipDropoff: settings.manualSkipDropoff.isEnabled,
                                profileSettingsPostponedStops: settings.postponedStops.isEnabled,
                            },
                        },
                        {
                            name: 'drivers',
                            settings: {
                                profileSettingsAccountsManagement: settings.driverAccountManagement.isEnabled,
                            },
                        },
                        {
                            name: 'vehicle',
                            settings: {
                                profileSettingsVehicleInspection: settings.vehicleInspection.isEnabled,
                                profileSettingsVehiclesListManagement: settings.vehiclesListManagement.isEnabled,
                            },
                        },
                    ];
                    $scope.loading = false;
                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loading = false;
                    $scope.$apply();
                }
            );
        }

        /**
         * Fetch client's settings
         */
        function fetchClientSettings() {
            Promise.all([$scope.angRequestAdminGetAvailableFormsForClient('mine'), $scope.angRequestAdminGetAvailableSettingsForClient('mine')]).then(
                function ([
                    {
                        data: { availableForms },
                    },
                    {
                        data: { settings },
                    },
                ]) {
                    $scope.clientSettings = {
                        directionsSource: settings.directionsSource,
                        formValidationRequiredForObserver: settings.formValidationRequiredForObserver,
                        emailInstitutionsWhenTripIsCancelled: settings.emailInstitutionsWhenTripIsCancelled,
                        guestPassengers: settings.guestPassengers,
                        plannedRouteChanges: settings.plannedRouteChanges,
                        disciplinaryForm: availableForms.some((form) => form.formType === 'disciplinary'),
                        photoForm: availableForms.some((form) => form.formType === 'photos'),
                        tripNotStarted: settings.tripNotStartedEmailNotification,
                    };
                    $scope.loading = false;
                    $scope.$apply();
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                    $scope.loading = false;
                    $scope.$apply();
                }
            );
        }

        // Show/hide password section
        $scope.onChangePasswordToggle = function () {
            $scope.dismissNotification('notifyPasswordChanged');
            $scope.passwordOpen = !$scope.passwordOpen;
            if ($scope.passwordOpen) {
                $scope.setFocus('newPassword');
            }
        };

        $scope.showOrHidePassword = function (id) {
            const passwordInput = document.querySelector(`#${id}`);
            const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
            passwordInput.setAttribute('type', type); // Show/Hide real chars

            const isOpenEye = type === 'password';
            const eyeIconPath = isOpenEye ? '../../assets/img/ui/eye_open.svg' : '../../assets/img/ui/eye_crossed.svg';

            // Display crossed or open eye depending on input type
            switch (id) {
                case 'newPassword':
                    $scope.eyeIcon = eyeIconPath;
                    break;
                case 'newPasswordConfirmation':
                    $scope.eyeIconConfirmation = eyeIconPath;
                    break;
            }
        };

        // Update the password on input change
        $scope.onPasswordInputChangeHandler = (password) => {
            $rootScope.$broadcast('onChangePassword', password);
        };

        $scope.$on('validatePassword', (event, value) => {
            $scope.passwordValidation = value;
        });

        $scope.modifyPassword = function (newPassword, newPasswordConfirmation) {
            if (!newPassword || newPassword.length <= 0) {
                $scope.triggerNotification('errorRequiredFieldMissing');
                $scope.setFocus('newPassword');
            } else if (!newPasswordConfirmation || newPasswordConfirmation.length <= 0) {
                $scope.triggerNotification('errorRequiredFieldMissing');
                $scope.setFocus('newPasswordConfirmation');
            } else if (newPassword != newPasswordConfirmation) {
                $scope.triggerNotification('errorPasswordNotSame');
                $scope.setFocus('newPasswordConfirmation');
            } else if ($scope.passwordValidation.isValidPassword === false) {
                $scope.triggerNotification('errorPasswordNotValid');
                $scope.setFocus('newPassword');
            } else {
                $scope.onPasswordInputChangeHandler(''); // reset validation indicators
                let dataSent = {};
                dataSent.newPassword = newPassword;
                $scope.requestAuthPassChange(dataSent).then(
                    function (data) {
                        $scope.setupNewCookies(data.token);
                        $scope.triggerNotification('notifyPasswordChanged');
                        $('.collapse').collapse('hide');
                        document.getElementById('newPassword').value = '';
                        document.getElementById('newPasswordConfirmation').value = '';
                    },
                    function (error) {
                        if (error.status === 401) {
                            $scope.logout();
                            $scope.$apply();
                        } else if (error.status === 403) {
                            $scope.triggerNotification('errorAccessDenied');
                        } else {
                            $scope.triggerNotification('errorCannotChange');
                        }
                    }
                );
            }
        };

        $scope.toggleNotification = function (type) {
            let currentState = $scope.notificationSettings[type];
            $scope.notificationSettings[type] = !currentState;

            let updateData = {};
            if (type === 'email') {
                updateData['emailNotification'] = $scope.notificationSettings['email'];
            } else {
                updateData['channels'] = {};
                updateData.channels[type] = $scope.notificationSettings[type];
            }

            $scope.requestUpdateUserNotificationPref('me', updateData).then(
                function (answer) {
                    fetchUserNotificationSettings();
                },
                function (error) {
                    $scope.triggerNotification('errorUnknown');
                }
            );
        };

        $scope.onChangeProfileToggle = function () {
            $scope.dismissNotification('notifyProfileChanged');
            $scope.profileOpen = !$scope.profileOpen;
            if ($scope.profileOpen) {
                $scope.setFocus('newName');
            }
        };

        $scope.modifyUserInfo = function (user) {
            let errors = [];
            let firstNameError = $scope.validateStandardTextInput('newFirstName', $scope.userDetails.profile.firstName, true);
            if (firstNameError) {
                errors.push(firstNameError);
            }
            let lastNameError = $scope.validateStandardTextInput('newLastName', $scope.userDetails.profile.lastName, true);
            if (lastNameError) {
                errors.push(lastNameError);
            }

            if (errors.length <= 0) {
                let data = {
                    profile: {
                        firstName: $scope.userDetails.profile.firstName,
                        lastName: $scope.userDetails.profile.lastName,
                    },
                    localeIdentifier: $scope.userDetails.localeIdentifier,
                };
                $scope.requestUpdateUserDetails(data).then(
                    function (answer) {
                        $scope.refreshPage();
                    },
                    function (error) {
                        const message = error.responseJSON?.error?.code === 1001 ? 'invalidParameters' : 'errorUnknown';
                        $scope.triggerNotification(message);
                    }
                );
            }
        };
    },
]);
