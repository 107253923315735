app.controller('AjaxRequest', [
    '$scope',
    '$rootScope',
    '$cookies',
    '$http',
    '$location',
    function ($scope, $rootScope, $cookies, $http, $location) {
        $scope.BASE_URL = APP_BASE_URL;
        $rootScope.version = APP_VERSION;
        const DEFAULT_LOCALE_IDENTIFIER = 'fr';

        /* Connection and validation tokens */
        $scope.checkTokenConnexion = function () {
            const token = $cookies.get('token');
            if (token) {
                const username = $cookies.get('userName');
                if (username) {
                    $rootScope.loggedUserName = username;
                } else {
                    $rootScope.loggedUserName = '------';
                }
                const role = $cookies.get('role');
                if (role) {
                    $rootScope.loggedUserRole = role;
                }
                const email = $cookies.get('email');
                if (email) {
                    $rootScope.loggedUserMail = email;
                }
                $scope.token = token;
            } else {
                $scope.logout($location.url());
            }
        };

        $scope.validateLoginToken = function () {
            $scope.validateToken();
        };

        $scope.setupNewCookies = function (token) {
            const expiration = new Date($scope.lookupExpiration(token));
            document.cookie = 'token=' + token + '; expires=' + expiration;
            const newMidlife = $scope.lookupMidlife(token);
            document.cookie = 'midlife=' + newMidlife + '; expires=' + expiration;

            if (!$rootScope.loggedUserMail) {
                const email = $cookies.get('email');
                if (email) {
                    $rootScope.loggedUserMail = email;
                    document.cookie = 'email=' + $rootScope.loggedUserMail + '; expires=' + expiration;
                }
            } else {
                document.cookie = 'email=' + $rootScope.loggedUserMail + '; expires=' + expiration;
            }

            if (!$rootScope.loggedUserRole) {
                const role = $cookies.get('role');
                if (role) {
                    $rootScope.loggedUserRole = role;
                    document.cookie = 'role=' + $rootScope.loggedUserRole + '; expires=' + expiration;
                }
            } else {
                document.cookie = 'role=' + $rootScope.loggedUserRole + '; expires=' + expiration;
            }

            if (!$rootScope.loggedUserName) {
                const username = $cookies.get('userName');
                if (username) {
                    $rootScope.loggedUserName = username;
                } else {
                    $rootScope.loggedUserName = '------';
                }
                document.cookie = 'userName=' + $rootScope.loggedUserName + '; expires=' + expiration;
            } else {
                document.cookie = 'userName=' + $rootScope.loggedUserName + '; expires=' + expiration;
            }

            $scope.token = token;
        };

        $scope.validateToken = function () {
            const token = $cookies.get('token');
            if (token) {
                const username = $cookies.get('userName');
                if (username) {
                    $rootScope.loggedUserName = username;
                } else {
                    $rootScope.loggedUserName = '------';
                }
                const role = $cookies.get('role');
                if (role) {
                    $rootScope.loggedUserRole = role;
                }
                const email = $cookies.get('email');
                if (email) {
                    $rootScope.loggedUserMail = email;
                }
                $scope.token = token;
                let midlife;
                const cookieMidlife = $cookies.get('midlife');
                if (cookieMidlife) {
                    midlife = cookieMidlife;
                } else {
                    midlife = $scope.lookupMidlife(token);
                }
                const pastMidlife = isItPastMidlife(midlife);
                if (pastMidlife) {
                    // Ask to renew the token.
                    const data = {};
                    data.email = $cookies.get('email');
                    if (data.email && !$scope.renewingToken) {
                        $scope.renewingToken = true;
                        $scope.requestAuthRenew(data).then(
                            function (answer) {
                                $scope.setupNewCookies(answer.token);
                                $scope.renewingToken = false;
                            },
                            function (error) {
                                $scope.logout();
                            }
                        );
                    }
                }
            } else {
                // if there is no token in cookies set $scope.token to undefined
                $scope.token = undefined;
            }
        };

        function isItPastMidlife(midlife) {
            const today = moment();
            const mid = moment(midlife);
            const pastMidlife = today.isAfter(mid);
            return pastMidlife;
        }

        $scope.lookupMidlife = function (token) {
            const partToDecrypt = token.split('.')[1];
            const decodedToken = window.atob(partToDecrypt);
            const creationDate = decodedToken.split(',')[2];
            const expirationDate = decodedToken.split(',')[3];
            const unixCreationTime = creationDate.split(':')[1];
            const unixExpirationTime = expirationDate.split(':')[1].split('}')[0];

            // Convert to miliseconds
            const convertedCreation = new Date(unixCreationTime * 1000);
            const convertedExpiration = new Date(unixExpirationTime * 1000);
            const difference = convertedExpiration - convertedCreation;
            return moment(convertedCreation.setMilliseconds(convertedCreation.getMilliseconds() + Math.floor(difference / 2))).format();
        };

        $scope.lookupExpiration = function (token) {
            const partToDecrypt = token.split('.')[1];
            const decodedToken = window.atob(partToDecrypt);
            const creationDate = decodedToken.split(',')[2];
            const expirationDate = decodedToken.split(',')[3];
            const unixExpirationTime = expirationDate.split(':')[1].split('}')[0];

            // Convert to miliseconds
            const convertedExpiration = new Date(unixExpirationTime * 1000);
            return convertedExpiration;
        };

        /* Requests */

        /**
         * API call with $http() angular service
         * Same function as baseRequest(), but an angular $http request updates bindings without the need
         * for a $scope.$apply() or a $timeout() hack afterwards, unlike the jQuery $.ajax request
         * @param {String} nameRequest
         * @param {Object} data
         * @param {String} type
         * @param {Object} queryParams
         * @return {Function}
         */
        $scope.baseAngularRequest = function (nameRequest, data, type, queryParams = null) {
            $scope.validateToken();
            const language = $rootScope.language ? $rootScope.language : DEFAULT_LOCALE_IDENTIFIER;
            const requestConfig = {
                method: type,
                url: $scope.BASE_URL + nameRequest,
                headers: {
                    Authorization: $scope.token,
                    'locale-identifier': language,
                    'Content-Type': 'application/json; charset=utf-8',
                },
                params: queryParams,
                data: type === 'POST' || type === 'PUT' || type === 'PATCH' ? JSON.stringify(data) : null,
                dataType: 'json',
            };
            const request = $http(requestConfig).finally(function () {
                if (type === 'GET') {
                    $scope.cleanRequest(request);
                }
            });
            if (type === 'GET') {
                $rootScope.cancelableRequests.push(request);
            }
            return request;
        };

        if (!$rootScope.cancelableRequests) {
            $rootScope.cancelableRequests = [];
        }

        /**
         * API call with jQuery.ajax() request
         * @param {String} nameRequest
         * @param {Object} data
         * @param {String} type
         * @param {Object} queryParams
         * @return {Function}
         */
        $scope.baseRequest = function (nameRequest, data, type, queryParams = null) {
            // TODO use customBaseRequest with BASE_URL in params
            $scope.validateToken();
            const language = $rootScope.language ? $rootScope.language : DEFAULT_LOCALE_IDENTIFIER;
            const url = queryParams ? $scope.BASE_URL + nameRequest + '?' + $.param(queryParams) : $scope.BASE_URL + nameRequest;
            const request = $.ajax({
                method: type,
                url: url,
                headers: {
                    Authorization: $scope.token,
                    'locale-identifier': language,
                },
                data: type === 'POST' || type === 'PUT' || type === 'PATCH' ? JSON.stringify(data) : null,
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                complete: function () {
                    if (type === 'GET') {
                        $scope.cleanRequest(request);
                    }
                },
            });
            if (type === 'GET') {
                $rootScope.cancelableRequests.push(request);
            }
            return request;
        };

        $scope.customBaseRequest = function (baseURL, nameRequest, data, type) {
            $scope.validateToken();
            const request = $.ajax({
                method: type,
                url: baseURL + nameRequest,
                headers: {
                    Authorization: $scope.token,
                    'locale-identifier': 'fr',
                },
                data: type === 'POST' || type === 'PUT' || type === 'PATCH' ? JSON.stringify(data) : null,
                contentType: 'application/json; charset=utf-8',
                dataType: 'json',
                complete: function () {
                    if (type === 'GET') {
                        $scope.cleanRequest(request);
                    }
                },
            });
            if (type === 'GET') {
                $rootScope.cancelableRequests.push(request);
            }
            return request;
        };

        $scope.cancelAllRequests = function () {
            for (const index in $rootScope.cancelableRequests) {
                let request = $rootScope.cancelableRequests[index];
                request.abort();
            }
            $rootScope.cancelableRequests = [];
        };

        $scope.cleanRequest = function (request) {
            const index = $rootScope.cancelableRequests.indexOf(request);
            if (index > -1) {
                $rootScope.cancelableRequests.splice(index, 1);
            }
        };

        $scope.requestAuthLogin = function (data) {
            return $scope.baseRequest('/auth/login', data, 'POST');
        };

        $scope.requestAuthPassReset = function (data) {
            return $scope.baseRequest('/auth/password/reset', data, 'POST');
        };

        $scope.requestAuthPassResetConfirm = function (data) {
            return $scope.baseRequest('/auth/password/reset/confirm', data, 'POST');
        };

        $scope.requestAuthPassChange = function (data) {
            return $scope.baseRequest('/auth/password/change', data, 'POST');
        };

        $scope.requestAuthSignup = function (data) {
            return $scope.baseRequest('/auth/signup', data, 'POST');
        };

        $scope.requestAuthEmailVerify = function (data) {
            return $scope.baseRequest('/auth/email/verify', data, 'POST');
        };

        $scope.requestAuthEmailVerifyConfirm = function (data) {
            return $scope.baseRequest('/auth/email/verify/confirm', data, 'POST');
        };

        $scope.requestAuthRenew = function (data) {
            return $scope.baseRequest('/auth/renew', data, 'POST');
        };

        $scope.requestAuthMe = function () {
            return $scope.baseRequest('/auth/me', '', 'GET');
        };

        $scope.requestAuthLogoutAll = function (data) {
            return $scope.baseRequest('/auth/logout-all', data, 'POST');
        };

        $scope.requestAuthIsVerificationCodeValid = async function (data) {
            return await $scope.baseRequest('/auth/email/verify/code', data, 'POST');
        };

        $scope.requestSelfDetails = function () {
            return $scope.baseRequest('/users/me/info', '', 'GET');
        };

        $scope.requestUpdateUserDetails = function (data) {
            return $scope.baseRequest('/users/me/profile', data, 'PUT');
        };

        $scope.requestUserNotificationPref = function (userId) {
            return $scope.baseRequest('/users/' + userId + '/preferences/notification', '', 'GET');
        };

        $scope.requestUpdateUserNotificationPref = function (userId, data) {
            return $scope.baseRequest('/users/' + userId + '/preferences/notification', data, 'PUT');
        };

        $scope.requestStudents = function () {
            return $scope.baseRequest('/students', '', 'GET');
        };

        $scope.angRequestStudents = function () {
            return $scope.baseAngularRequest('/students', '', 'GET');
        };

        $scope.requestStudentDetail = function (studentId) {
            return $scope.baseRequest('/students/' + studentId, '', 'GET');
        };

        $scope.requestStudentInstitutions = function (studentId) {
            return $scope.baseRequest('/students/' + studentId + '/institutions', '', 'GET');
        };

        $scope.requestStudentRoutes = function (studentId) {
            return $scope.baseRequest('/students/' + studentId + '/routes', '', 'GET');
        };

        $scope.requestStudentStops = function (studentId) {
            return $scope.baseRequest('/students/' + studentId + '/stops', '', 'GET');
        };

        $scope.requestStudentHistory = function (studentId, queryParams) {
            return $scope.baseRequest('/students/' + studentId + '/history', '', 'GET', queryParams);
        };

        $scope.requestStudentHistoryForGuardians = function (studentId) {
            return $scope.baseRequest('/students/' + studentId + '/history', '', 'GET');
        };

        $scope.requestStudentGuardians = function (studentId) {
            return $scope.baseRequest('/students/' + studentId + '/guardians', '', 'GET');
        };

        $scope.requestStudentSearch = function (query) {
            const queryParams = { query: query };
            return $scope.baseRequest('/students/search', '', 'GET', queryParams);
        };

        /**
         * @param {Boolean} isSimplifiedView
         * @param {Boolean} includePreferences
         * @param {Boolean} includeDrivers
         * @return {Promise}
         */
        $scope.requestRoutes = function (isSimplifiedView = true, includePreferences = true, includeDrivers = true) {
            const queryParams = {
                watcherCount: !isSimplifiedView,
                studentAndDirectionCount: !isSimplifiedView,
                preferences: includePreferences,
                drivers: includeDrivers,
            };
            return $scope.baseRequest('/routes', '', 'GET', queryParams);
        };

        $scope.angRequestRoutes = function (institutionId) {
            if (institutionId) {
                const queryParams = { institutionId: institutionId };
                return $scope.baseAngularRequest('/routes', '', 'GET', queryParams);
            }
            return $scope.baseAngularRequest('/routes', '', 'GET');
        };

        /**
         * Request the count of unassigned routes
         * @return {Promise}
         */
        $scope.angRequestUnassignedRouteCount = function () {
            return $scope.baseAngularRequest('/routes/unassigned', '', 'GET');
        };

        $scope.requestRouteDetail = function (routeId) {
            return $scope.baseRequest('/routes/' + routeId, '', 'GET');
        };

        $scope.requestRouteDetailTrips = function (routeId) {
            return $scope.baseRequest('/routes/' + routeId + '/trips', '', 'GET');
        };

        $scope.requestRouteDetailStops = function (routeId) {
            return $scope.baseRequest('/routes/' + routeId + '/stops', '', 'GET');
        };

        $scope.requestRouteDetailStopId = function (routeId, stopId) {
            return $scope.baseRequest('/routes/' + routeId + '/stops/' + stopId, '', 'GET');
        };

        /**
         * Get routes list with information on directions
         * @return {Promise}
         */
        $scope.requestRoutesDirections = function () {
            return $scope.baseRequest('/routes/directions', '', 'GET');
        };

        /**
         * Update directions for a route
         * @param {String} routeId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.requestRouteDirectionsUpdate = function (routeId, data) {
            return $scope.baseRequest('/routes/' + routeId + '/directions', data, 'PATCH');
        };

        $scope.requestRouteStopRadiusUpdate = function (routeId, stopId, data) {
            return $scope.baseRequest('/routes/' + routeId + '/stops/' + stopId, data, 'PATCH');
        };

        $scope.requestRouteNotificationsUpdate = function (routeId, data) {
            return $scope.baseRequest('/routes/' + routeId + '/preferences', data, 'PATCH');
        };

        $scope.requestSurroundingTrips = function (tripId) {
            return $scope.baseRequest('/trips/' + tripId + '/surroundings/', '', 'GET');
        };

        $scope.requestRelatedTrips = function (tripId) {
            return $scope.baseRequest('/trips/' + tripId + '/related/', '', 'GET');
        };

        $scope.requestTrips = function (date) {
            if (date) {
                const queryParams = { date: date };
                return $scope.baseRequest('/trips', '', 'GET', queryParams);
            } else return $scope.baseRequest('/trips', '', 'GET');
        };

        $scope.requestTVTrips = function (date, filter, unplanned = null) {
            const queryParams = { date: date };
            if (filter) {
                queryParams.filter = filter;
            }
            if (unplanned != null) {
                queryParams.unplanned = unplanned;
            }
            return $scope.baseRequest('/dashboard/tv', '', 'GET', queryParams);
        };

        $scope.requestTripDetails = function (tripId) {
            return $scope.baseRequest('/trips/' + tripId, '', 'GET');
        };

        $scope.angRequestTripDetails = function (tripId) {
            return $scope.baseAngularRequest('/trips/' + tripId, '', 'GET');
        };

        $scope.requestTripNotificationsUpdate = function (tripId, data) {
            return $scope.baseRequest('/trips/' + tripId + '/preferences', data, 'PATCH');
        };

        $scope.postTripAnnouncedDelay = function (tripId, data) {
            return $scope.baseRequest('/trips/' + tripId + '/update/announceddelays', data, 'POST');
        };

        $scope.postTripCancellationAnnouncements = function (tripId, data) {
            return $scope.baseRequest('/trips/' + tripId + '/update/cancellationAnnouncements', data, 'POST');
        };

        $scope.postTrip = function (routeId, data) {
            return $scope.baseRequest('/routes/' + routeId + '/trips', data, 'POST');
        };

        /**
         * Send new various announcement notification for a trip
         * @param {String} tripId
         * @param {Object} data
         * @param {String} data.message type of announcement
         * @return {Promise}
         */
        $scope.postTripVariousAnnouncements = function (tripId, data) {
            return $scope.baseRequest('/trips/' + tripId + '/update/variousannouncements', data, 'POST');
        };

        /**
         * Create a location request to locate devices
         * @param {Object} data
         * @return {Promise}
         */
        $scope.angRequestCreateLocationRequest = function (data) {
            return $scope.baseAngularRequest('/locations/request/', data, 'POST');
        };

        /**
         * Get device locations with a request id
         * @param {String} requestId the request id
         * @return {Promise}
         */
        $scope.angRequestGetDeviceLocations = function (requestId) {
            return $scope.baseAngularRequest(`/locations/request/${requestId}`, '', 'GET');
        };

        $scope.postFeedbacks = function (data) {
            return $scope.baseRequest('/feedbacks', data, 'POST');
        };

        /**
         * Request changes details for a trip
         * @param {String} tripId
         * @return {Promise}
         */
        $scope.requestTripChanges = function (tripId) {
            return $scope.baseRequest(`/trips/${tripId}/changes`, '', 'GET');
        };

        /* Reports requests */
        $scope.requestReportTabletUsage = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/buses/usage', '', 'GET', queryParams);
        };
        $scope.requestReportTabletUsageOperator = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/carriers/usage', '', 'GET', queryParams);
        };

        $scope.requestReportUnusedStops = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/stops/unused', '', 'GET', queryParams);
        };

        $scope.requestReportRunsPerBus = function () {
            return $scope.baseRequest('/reports/buses/routes', '', 'GET');
        };

        $scope.requestReportCancelledRuns = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/routes/cancelled', '', 'GET', queryParams);
        };

        $scope.requestReportDevicesUsage = function (date) {
            const queryParams = { date: date };
            return $scope.baseRequest('/reports/devices/usage', '', 'GET', queryParams);
        };

        $scope.requestReportVehiclesUsage = function (date) {
            const queryParams = { date: date };
            return $scope.baseRequest('/reports/vehicles/usage', '', 'GET', queryParams);
        };

        $scope.requestReportSpecialConditions = function (date) {
            const queryParams = { date: date };
            return $scope.baseRequest('/reports/buses/specialconditions', '', 'GET', queryParams);
        };

        $scope.requestReportDistances = function (startDate, endDate, busNo) {
            const queryParams = { startDate: startDate, endDate: endDate };
            if (busNo) {
                queryParams.busNumber = busNo;
            }
            return $scope.baseRequest('/reports/routes/distances', '', 'GET', queryParams);
        };

        $scope.requestReportDailyDistances = function (date) {
            const queryParams = { date: date };
            return $scope.baseRequest('/reports/routes/dailydistances', '', 'GET', queryParams);
        };

        $scope.requestReportRoutesDuration = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/routes/duration', '', 'GET', queryParams);
        };

        /**
         * Fetch the report for the institution arrival
         * @param {String} startDate
         * @param {String} endDate
         * @param {String} [institutionId]
         * @return {Promise}
         */
        $scope.requestReportInstitutionArrival = function (startDate, endDate, institutionId) {
            const queryParams = { startDate: startDate, endDate: endDate };
            if (institutionId) {
                queryParams.institutionId = institutionId;
            }
            return $scope.baseRequest('/reports/routes/institutionarrival', '', 'GET', queryParams);
        };

        $scope.requestReportMisuse = function (date) {
            const queryParams = { date: date };
            return $scope.baseRequest('/reports/trips/misuse', '', 'GET', queryParams);
        };

        $scope.requestReportAbsenceAnnounced = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/students/absences', '', 'GET', queryParams);
        };

        $scope.requestReportMisuseBus = function (startDate, endDate, busNo) {
            const queryParams = { startDate: startDate, endDate: endDate, busNumber: busNo };
            return $scope.baseRequest('/reports/trips/misusebus', '', 'GET', queryParams);
        };

        $scope.requestInstitution = function (institutionId) {
            const requestURL = '/institutions/' + institutionId;
            return $scope.baseRequest(requestURL, '', 'GET');
        };

        $scope.requestReportStudentsAbsenteeism = function (startDate, endDate, param, paramType) {
            const queryParams = { startDate: startDate, endDate: endDate };
            if (param) {
                if (paramType === 'institution') {
                    queryParams.institutionId = param;
                } else {
                    queryParams.busNumber = param;
                }
            }
            return $scope.baseRequest('/reports/students/absenteeism', '', 'GET', queryParams);
        };

        $scope.requestReportVehiclesOccupancy = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/routes/vehicleoccupancy', '', 'GET', queryParams);
        };

        $scope.requestReportStudentRegisteredInSchoolTransportation = function (institutionId) {
            const queryParams = { institutionId: institutionId };
            return $scope.baseRequest('/reports/students/transportation', '', 'GET', queryParams);
        };

        $scope.requestReportGuardianAccountsNotActivated = function () {
            return $scope.baseRequest('/reports/students/guardiansnotactivated', '', 'GET');
        };

        $scope.requestReportDisciplinary = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/students/disciplinaryform', '', 'GET', queryParams);
        };

        $scope.requestReportCardsRead = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/students/wrongcardread', '', 'GET', queryParams);
        };

        $scope.requestReportHistoryOfDriversNotes = function (startDate, endDate, driverId) {
            const queryParams = { startDate: startDate, endDate: endDate };
            if (driverId != null) {
                queryParams.driverId = driverId;
            }
            return $scope.baseRequest('/reports/trips/notes', '', 'GET', queryParams);
        };

        $scope.requestReportTripsDrivers = function (date) {
            const queryParams = { date: date };
            return $scope.baseRequest('/reports/trips/drivers', '', 'GET', queryParams);
        };

        $scope.requestReportTripsAnalysis = function (date, busNo, analysisParams) {
            const queryParams = {
                date: date,
                completedInLessThanTenMin: analysisParams.completedInLessThanTenMin || false,
                wrongStartTime: analysisParams.wrongStartTime || false,
                moreThanThirtyMinLateAtLastStop: analysisParams.moreThanThirtyMinLateAtLastStop || false,
                studentRegisteredInBetweenStops: analysisParams.studentRegisteredInBetweenStops || false,
                waypointNotRespected: analysisParams.waypointNotRespected || false,
                stopPostponedByDriver: analysisParams.stopPostponedByDriver || false,
                wrongStopLocation: analysisParams.wrongStopLocation || false,
            };

            if (busNo != null) {
                queryParams.busNumber = busNo;
            }

            return $scope.baseRequest('/reports/trips/analysis', '', 'GET', queryParams);
        };

        /**
         * Fetch the Students Stop Misuse report
         * @param {String} startDate - String of Date ISO
         * @param {String} endDate - String of Date ISO
         * @return {Function }
         */
        $scope.requestReportStudentsStopMisuse = function (startDate, endDate) {
            const queryParams = { startDate: startDate, endDate: endDate };
            return $scope.baseRequest('/reports/students/stopmisuse', '', 'GET', queryParams);
        };

        /**
         * Fetch the Students Card Misuse at school pickups report
         * @param {String} startDate String of Date ISO
         * @param {String} endDate String of Date ISO
         * @param {String} vehicleTypes JSON of array of strings
         * @return {Function}
         */
        $scope.requestReportStudentsCardMisuse = function (startDate, endDate, vehicleTypes) {
            // As the vehicleTypes array is already stringyfied, we send the params via the request URL
            // to avoid further encoding of vehicleTypes parameter by $.param() in $scope.baseRequest
            const requestURL =
                '/reports/students/cardmisuse?startDate=' +
                encodeURIComponent(startDate) +
                '&endDate=' +
                encodeURIComponent(endDate) +
                '&vehicleTypes=' +
                vehicleTypes;
            return $scope.baseRequest(requestURL, '', 'GET');
        };

        $scope.requestReportClientBuses = function () {
            return $scope.baseRequest('/reports/admin/clientsbuses', '', 'GET');
        };

        $scope.requestInstitutions = function () {
            return $scope.baseRequest('/institutions/', 'GET');
        };

        /* Admin requests */
        $scope.requestAdminAddClientAPI = function (publicKey, privateKey, clientId) {
            const data = {
                publicKey: publicKey,
                privateKey: privateKey,
            };
            return $scope.baseRequest('/clients/' + clientId + '/api', data, 'POST');
        };

        /**
         * Get user details by email
         * @param {String} email
         * @return {Promise}
         */
        $scope.requestAdminRequestUserSearch = function (email) {
            const queryParams = { email: email };
            return $scope.baseRequest('/users/search', '', 'GET', queryParams);
        };

        $scope.requestAdminGetUserDetails = function (userId) {
            return $scope.baseRequest('/users/' + userId + '/profile', '', 'GET');
        };

        $scope.angRequestAdminGetUserDetails = function (userId) {
            return $scope.baseAngularRequest('/users/' + userId + '/info', '', 'GET');
        };

        /**
         * Get all clients
         * @param {Boolean} [includesRoutesSummary=false]
         * @param {Boolean} [includesTripsSummary=true]
         * @return {Promise}
         */
        $scope.requestAdminGetAllClients = function (includesRoutesSummary = false, includesTripsSummary = true) {
            const queryParams = { includesTripsSummary, includesRoutesSummary };
            return $scope.baseAngularRequest('/clients', '', 'GET', queryParams);
        };

        /**
         * Get client's operations statistics
         * @param {String} date - YYYY-MM-DD
         * @return {Promise}
         */
        $scope.requestReportClientsTrips = function (date) {
            const queryParams = { date };
            return $scope.baseRequest('/reports/admin/clientstrips', '', 'GET', queryParams);
        };

        $scope.requestAdminGetClient = function (clientId) {
            return $scope.baseRequest('/clients/' + clientId, '', 'GET');
        };

        /**
         * Sends a POST request to create a new manager for a client.
         * @param {string} clientId - The ID of the client for which to create the manager.
         * @param {Object} data - The data for the new manager.
         * @return {Promise} - A promise that resolves with the server's response.
         */
        $scope.requestCreateManagerForClient = function (clientId, data) {
            return $scope.baseRequest('/clients/' + clientId + '/managers', data, 'POST');
        };

        /**
         * Get a list a managers from a client
         * @param {String} clientId
         * @return {Promise}
         */
        $scope.requestAdminGetManagersForClient = function (clientId) {
            return $scope.baseRequest('/clients/' + clientId + '/managers', '', 'GET');
        };

        $scope.requestAdminGetCarriersForClient = function (clientId) {
            return $scope.baseRequest('/clients/' + clientId + '/carriers', '', 'GET');
        };

        $scope.requestAdminGetInstitutionsForClient = function (clientId) {
            return $scope.baseAngularRequest('/clients/' + clientId + '/institutions', '', 'GET');
        };

        $scope.requestAddAgentForInstitution = function (institutionId, data) {
            return $scope.baseRequest('/institutions/' + institutionId + '/agents', data, 'POST');
        };

        $scope.requestAddObserverForInstitution = function (institutionId, data) {
            return $scope.baseRequest('/institutions/' + institutionId + '/observers', data, 'POST');
        };
        $scope.angRequestAddAgentForInstitution = function (institutionId, data) {
            return $scope.baseAngularRequest('/institutions/' + institutionId + '/agents', data, 'POST');
        };

        $scope.angRequestAddObserverForInstitution = function (institutionId, data) {
            return $scope.baseAngularRequest('/institutions/' + institutionId + '/observers', data, 'POST');
        };

        $scope.requestAdminClientSettingsUpdate = function (clientId, data) {
            return $scope.baseRequest('/clients/' + clientId + '/settings', data, 'PATCH');
        };

        /**
         * Get the available forms for a specific client
         * @param {String} clientId
         * @return {Promise}
         */
        $scope.angRequestAdminGetAvailableFormsForClient = function (clientId) {
            return $scope.baseAngularRequest('/clients/' + clientId + '/forms', '', 'GET');
        };

        /**
         * Get the available settings for a specific client
         * @param {String} clientId or mine
         * @return {Promise}
         */
        $scope.angRequestAdminGetAvailableSettingsForClient = function (clientId) {
            return $scope.baseAngularRequest('/clients/' + clientId + '/settings', '', 'GET');
        };

        /**
         * Update the available forms for a specific client
         * @param {String} clientId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.angRequestAdminAvailableFormsUpdateForClient = function (clientId, data) {
            return $scope.baseAngularRequest('/clients/' + clientId + '/forms', data, 'PATCH');
        };

        $scope.requestAdminUpdateClient = function (clientId, data) {
            return $scope.baseRequest('/clients/' + clientId, data, 'PUT');
        };

        $scope.requestAdminUpdateCarrier = function (carrierId, data) {
            return $scope.baseRequest('/carriers/' + carrierId, data, 'PUT');
        };

        $scope.requestAdminClientAddCarrier = function (clientId, data) {
            return $scope.baseRequest('/clients/' + clientId + '/carriers', data, 'POST');
        };

        $scope.requestAdminCreateClient = function (clientData) {
            return $scope.baseRequest('/clients/', clientData, 'POST');
        };

        $scope.requestAdminFTPFetch = function (clientId) {
            return $scope.baseRequest('/clients/' + clientId + '/data/fetch', '', 'GET');
        };

        $scope.requestAdminScheduleJobs = function (clientId) {
            return $scope.baseRequest('/clients/' + clientId + '/jobs/schedule', '', 'GET');
        };

        $scope.requestAdminGetAllCarriers = function () {
            return $scope.baseRequest('/carriers', '', 'GET');
        };

        $scope.requestGetCarrier = function (carrierId) {
            return $scope.baseRequest('/carriers/' + carrierId, '', 'GET');
        };

        $scope.requestGetDriverInfoForCarrier = function (carrierId, driverId) {
            return $scope.baseRequest('/carriers/' + carrierId + '/drivers/' + driverId);
        };

        /**
         * Get clients linked to a carrier
         * @param {String} carrierId or 'mine'
         * @return {Promise}
         */
        $scope.requestGetClientsLinkedToCarrier = function (carrierId) {
            return $scope.baseRequest('/carriers/' + carrierId + '/clients', 'GET');
        };

        $scope.requestGetDriverBusAssignmentsForCarrier = function (carrierId, driverId) {
            return $scope.baseRequest('/carriers/' + carrierId + '/drivers/' + driverId + '/assignments');
        };

        $scope.requestAdminCreateCarrier = function (carrierData) {
            return $scope.baseRequest('/carriers/', carrierData, 'POST');
        };

        $scope.requestGetUsersForCarrier = function (carrierId) {
            return $scope.baseRequest('/carriers/' + carrierId + '/users', '', 'GET');
        };

        $scope.requestGetDispatchersForCarrier = function (carrierId) {
            return $scope.baseRequest('/carriers/' + carrierId + '/dispatchers', '', 'GET');
        };

        $scope.requestGetDriversForCarrier = function (carrierId) {
            return $scope.baseRequest('/carriers/' + carrierId + '/drivers', '', 'GET');
        };

        $scope.requestDeactivateDriverForCarrier = function (carrierId, driverId) {
            return $scope.baseRequest('/carriers/' + carrierId + '/drivers/' + driverId, '', 'DELETE');
        };

        $scope.angRequestGetRoutesForDriver = function (carrierId, driverId) {
            return $scope.baseAngularRequest('/carriers/' + carrierId + '/drivers/' + driverId + '/routes', '', 'GET');
        };

        $scope.requestCreateBusNumberAssignment = function (carrierId, driverId, data) {
            return $scope.baseRequest('/carriers/' + carrierId + '/drivers/' + driverId + '/assignments', data, 'POST');
        };

        $scope.angRequestGetDriversForCarrier = function (carrierId) {
            return $scope.baseAngularRequest('/carriers/' + carrierId + '/drivers', '', 'GET');
        };

        $scope.requestAdminGetRoutesForCarrier = function (carrierId) {
            return $scope.baseRequest('/carriers/' + carrierId + '/routes', '', 'GET');
        };

        $scope.angRequestAdminGetRoutesForCarrier = function (carrierId) {
            return $scope.baseAngularRequest('/carriers/' + carrierId + '/routes', '', 'GET');
        };

        $scope.angRequestAdminGetSettingsForCarrier = function (carrierId) {
            return $scope.baseAngularRequest('/carriers/' + carrierId + '/settings', '', 'GET');
        };

        /**
         * Delete a disciplinary form report
         * @param {String} formID
         * @return {Promise}
         */
        $scope.requestArchiveDisciplinaryFormReport = function (formID) {
            return $scope.baseRequest('/dispatch/forms/' + formID, '', 'DELETE');
        };

        /**
         * Get the available forms for a specific carrier
         * @param {String} carrierId
         * @return {Promise}
         */
        $scope.angRequestAdminGetAvailableFormsForCarrier = function (carrierId) {
            return $scope.baseAngularRequest('/carriers/' + carrierId + '/forms', '', 'GET');
        };

        /**
         * Update the available forms for a specific carrier
         * @param {String} carrierId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.angRequestAdminAvailableFormsUpdateForCarrier = function (carrierId, data) {
            return $scope.baseAngularRequest('/carriers/' + carrierId + '/forms', data, 'PATCH');
        };

        $scope.requestAdminGetDriversForRoute = function (routeId) {
            return $scope.baseRequest('/routes/' + routeId + '/drivers', '', 'GET');
        };

        $scope.angRequestAdminGetDriversForRoute = function (routeId) {
            return $scope.baseAngularRequest('/routes/' + routeId + '/drivers', '', 'GET');
        };

        $scope.requestAdminGetRoutesForDriver = function (email) {
            return $scope.baseRequest('/routes/drivers' + email, '', 'GET');
        };

        $scope.requestAdminLinkDriverToRoute = function (routeId, data) {
            return $scope.baseRequest('/routes/' + routeId + '/drivers', data, 'POST');
        };

        $scope.angRequestAdminLinkDriverToRoute = function (routeId, data) {
            return $scope.baseRequest('/routes/' + routeId + '/drivers', data, 'POST');
        };

        $scope.requestAdminPatchDriverToRoute = function (routeId, driverID, data) {
            return $scope.baseRequest('/routes/' + routeId + '/drivers/' + driverID, data, 'PATCH');
        };

        $scope.requestAdminCreateDriverForCarrier = function (carrierId, data) {
            return $scope.baseRequest('/carriers/' + carrierId + '/drivers', data, 'POST');
        };

        /**
         * Update password for driver
         * @param {String} carrierId
         * @param {String} driverId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.requestUpdateDriverPasswordForCarrier = function (carrierId, driverId, data) {
            return $scope.baseRequest('/carriers/' + carrierId + '/drivers/' + driverId + '/password/change', data, 'POST');
        };

        $scope.requestCreateUserForCarrier = function (carrierId, data) {
            return $scope.baseRequest('/carriers/' + carrierId + '/users', data, 'POST');
        };

        $scope.requestEditUserRoleForCarrier = function (carrierId, userId, data) {
            return $scope.baseRequest('/carriers/' + carrierId + '/users/' + userId + '/role', data, 'PATCH');
        };

        $scope.requestCreateDispatcherForCarrier = function (carrierId, data) {
            return $scope.baseRequest('/carriers/' + carrierId + '/dispatchers', data, 'POST');
        };

        $scope.requestPatchUserIsCommunicationContactForCarrier = function (carrierId, userId, data) {
            return $scope.baseRequest('/carriers/' + carrierId + '/users/' + userId + '/isCommunicationContact', data, 'PATCH');
        };

        /**
         * Update settings for carrier
         * @param {String} carrierId
         * @param {Object} data - communication, directionsEditing, automaticStopSkip
         * @return {Promise}
         */
        $scope.requestAdminSettingsUpdateForCarrier = function (carrierId, data) {
            return $scope.baseRequest('/carriers/' + carrierId + '/settings', data, 'PATCH');
        };

        /**
         * Get the available templates for a specific carrier - availableVehicleInspectionTemplates
         * @param {String} carrierId
         * @return {Promise}
         */
        $scope.angRequestAdminGetAvailableVehicleInpectionTemplatesForCarrier = function (carrierId) {
            return $scope.baseAngularRequest('/carriers/' + carrierId + '/vehicleInspections/templates', '', 'GET');
        };

        /**
         * Update the available templates for a specific carrier - availableVehicleInspectionTemplates
         * @param {String} carrierId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.requestAdminAvailableVehicleInpectionTemplatesUpdateForCarrier = function (carrierId, data) {
            return $scope.baseRequest('/carriers/' + carrierId + '/vehicleInspections/templates', data, 'PATCH');
        };

        $scope.requestAdminGetAllFeedback = function () {
            return $scope.baseRequest('/feedbacks', '', 'GET');
        };

        /**
         * Get list of vehicles
         * @return {Promise}
         */
        $scope.requestVehicles = function () {
            return $scope.baseRequest('/vehicles', '', 'GET');
        };

        /**
         * Get vehicle details
         * @param {String} vehicleId
         * @return {Promise}
         */
        $scope.requestVehicleDetails = function (vehicleId) {
            return $scope.baseRequest('/vehicles/' + vehicleId, '', 'GET');
        };

        /**
         * Update vehicle
         * @param {String} vehicleId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.requestUpdateVehicle = function (vehicleId, data) {
            return $scope.baseRequest('/vehicles/' + vehicleId, data, 'PATCH');
        };

        /**
         * Create a new vehicle
         * @param {Object} data
         * @return {Promise}
         */
        $scope.requestCreateVehicle = function (data) {
            return $scope.baseRequest('/vehicles', data, 'POST');
        };

        /**
         * Get Vehicle Inpections
         * @param {String} vehicleIdentifier
         * @return {Promise}
         */
        $scope.requestVehicleInspections = function (vehicleIdentifier = null, startDate = null) {
            if (vehicleIdentifier || startDate) {
                const queryParams = { ...(vehicleIdentifier && { vehicleIdentifier }), ...(startDate && { startDate }) };
                return $scope.baseRequest('/vehicleInspections/inspections', '', 'GET', queryParams);
            }
            return $scope.baseRequest('/vehicleInspections/inspections', '', 'GET');
        };

        /**
         * Get Vehicle Inspection Details for Dispatcher only
         * @param {String} inspectionId
         * @return {Promise}
         */
        $scope.requestVehicleInspectionDetails = function (inspectionId) {
            return $scope.baseRequest('/vehicleInspections/inspections/' + inspectionId, '', 'GET');
        };

        /**
         * Add repaired note for an inspection
         * @param {String} inspectionId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.angRequestRepairedNote = function (inspectionId, data) {
            return $scope.baseAngularRequest('/vehicleInspections/inspections/' + inspectionId, data, 'PATCH');
        };

        /**
        /**
         * request all feedbacks from a specific user
         * @param {String} userId the user from whom we are requesting feedbacks
         * @return {Promise}
         */
        $scope.requestAdminGetFeedbacksFromUser = function (userId) {
            const queryParams = { userId: userId };
            return $scope.baseRequest('/feedbacks', '', 'GET', queryParams);
        };

        /**
         * Update the specific feedback
         * @param {String} feedbackId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.requestAdminUpdateFeedback = function (feedbackId, data) {
            return $scope.baseRequest('/feedbacks/' + feedbackId, data, 'PATCH');
        };

        $scope.requestAdminGetAgentsForInstitution = function (institutionId) {
            return $scope.baseRequest('/institutions/' + institutionId + '/agents', '', 'GET');
        };

        $scope.requestAdminGetObserversForInstitution = function (institutionId) {
            return $scope.baseRequest('/institutions/' + institutionId + '/observers', '', 'GET');
        };

        $scope.requestAdminRemoveDriverFromRoute = function (routeId, driverId) {
            return $scope.baseRequest('/routes/' + routeId + '/drivers/' + driverId, '', 'DELETE');
        };

        $scope.angRequestAdminRemoveDriverFromRoute = function (routeId, driverId) {
            return $scope.baseAngularRequest('/routes/' + routeId + '/drivers/' + driverId, '', 'DELETE');
        };

        /**
         * Remove a bus number for a driver
         * @param {string} carrierId
         * @param {string} driverId
         * @param {string} assignmentId
         * @return {Function}
         **/
        $scope.angRequestRemoveDriverBusNumberAssignment = function (carrierId, driverId, assignmentId) {
            return $scope.baseAngularRequest('/carriers/' + carrierId + '/drivers/' + driverId + '/assignments/' + assignmentId, '', 'DELETE');
        };

        $scope.requestDeactivateUser = function (userId) {
            return $scope.baseAngularRequest(`/users/${userId}`, '', 'DELETE');
        };

        $scope.angRequestInstitutions = function () {
            return $scope.baseAngularRequest('/institutions/', '', 'GET');
        };

        $scope.angRequestGetDispatchMessages = function () {
            return $scope.baseAngularRequest('/dispatch/messages/', '', 'GET');
        };

        $scope.angRequestGetDispatchMessagesForDriver = function (driverId) {
            const queryParams = { driverId: driverId };
            return $scope.baseAngularRequest('/dispatch/messages/', '', 'GET', queryParams);
        };

        $scope.angRequestGetDispatchMessage = function (id) {
            return $scope.baseAngularRequest('/dispatch/messages/' + id, '', 'GET');
        };

        $scope.angRequestPostDispatchMessages = function (data) {
            return $scope.baseAngularRequest('/dispatch/messages/', data, 'POST');
        };

        $scope.angRequestGetDispatchForms = function (tripId = null) {
            if (tripId) {
                const queryParams = { tripId: tripId };
                return $scope.baseAngularRequest('/dispatch/forms', '', 'GET', queryParams);
            }

            return $scope.baseAngularRequest('/dispatch/forms/', '', 'GET');
        };

        $scope.angRequestGetDispatchFormsForDriver = function (driverId) {
            const queryParams = { driverId: driverId };
            return $scope.baseAngularRequest('/dispatch/forms', '', 'GET', queryParams);
        };

        /**
         * Request the count of unread form
         * @return {Promise}
         */
        $scope.angRequestGetFormsUnreadCount = function () {
            return $scope.baseAngularRequest('/dispatch/forms/unread', '', 'GET');
        };

        $scope.angRequestGetDispatchForm = function (id) {
            return $scope.baseAngularRequest('/dispatch/forms/' + id, '', 'GET');
        };

        /**
         * Validate disciplinary form
         * @param {String} id
         * @return {Promise}
         */
        // TODO Benjamin check "undefined"
        $scope.angRequestValidateDispatchDisciplinaryForm = function (id) {
            return $scope.baseAngularRequest('/dispatch/forms/' + id + '/validate', undefined, 'POST');
        };

        /**
         * Add a follow-up to the disciplinary form
         * @param {String} id
         * @param {Object} data
         * @return {Promise}
         */
        $scope.angRequestFollowUpDispatchDisciplinaryForm = function (id, data) {
            return $scope.baseAngularRequest('/dispatch/forms/' + id + '/followup', data, 'POST');
        };

        /**
         * Update disciplinary form
         * @param {String} id
         * @param {Object} data
         * @return {Promise}
         */
        $scope.angRequestUpdateDispatchDisciplinaryForm = function (id, data) {
            return $scope.baseAngularRequest('/dispatch/forms/' + id, data, 'PATCH');
        };

        $scope.angRequestGetDispatchDisciplinaryReports = function (studentId) {
            const queryParams = { studentId: studentId };
            return $scope.baseAngularRequest(`/dispatch/forms`, '', 'GET', queryParams);
        };

        /**
         * Requests trip data from the server based on the provided parameters.
         * This function can filter trips by date and/or scope, where scope
         * could alter the response to fit a specific context or data requirement.
         *
         * @param {Object} [params={}] - The parameters for the request.
         * @param {string} [params.date] - The date to filter trips by. Expected format: "YYYY-MM-DD".
         * @param {string} [params.scope] - The scope of the request, which can alter the returned data based on the context (e.g., "communications").
         * @return {Promise<Object>} A promise that resolves to the response from the server. The promise resolves to an object containing the trip data.
         */
        $scope.angRequestTrips = function ({ date, scope } = {}) {
            const queryParams = {
                date: date ?? undefined,
                scope: scope ?? undefined,
            };
            return $scope.baseAngularRequest('/trips', '', 'GET', queryParams);
        };

        $scope.angRequestTripsForDriverId = function (driverId, startDate, endDate) {
            const queryParams = { driverId: driverId };
            if (startDate != null && endDate != null) {
                queryParams.startDate = startDate;
                queryParams.endDate = endDate;
            }
            return $scope.baseAngularRequest('/trips', '', 'GET', queryParams);
        };

        $scope.angRequestAllCarriers = function () {
            return $scope.baseAngularRequest('/carriers', '', 'GET');
        };

        $scope.angRequestNotesForARoute = function (routeId) {
            return $scope.baseAngularRequest('/routes/' + routeId + '/notes', '', 'GET');
        };

        /**
         * Add note for a route
         * @param {String} routeId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.angRequestAddRouteNote = function (routeId, data) {
            return $scope.baseAngularRequest('/routes/' + routeId + '/notes', data, 'POST');
        };

        /**
         * Remove note for a route
         * @param {String} routeId
         * @param {String} noteId
         * @return {Promise}
         */
        $scope.angRequestRemoveNoteForARoute = function (routeId, noteId) {
            return $scope.baseAngularRequest('/routes/' + routeId + '/notes/' + noteId, '', 'DELETE');
        };

        /**
         * Update the client's user's role
         * @param {String} clientId
         * @param {String} userId
         * @param {Object} data - data in the PATCH request
         * @return {Function}
         */
        $scope.requestUpdateClientUser = function (clientId, userId, data) {
            return $scope.baseRequest('/clients/' + clientId + '/users/' + userId, data, 'PATCH');
        };

        $scope.angRequestUpdateClientUser = function (clientId, userId, data) {
            return $scope.baseAngularRequest('/clients/' + clientId + '/users/' + userId, data, 'PATCH');
        };

        /**
         * Add note for a student
         * @param {String} studentId
         * @param {Object} data
         * @return {Promise}
         */
        $scope.angRequestAddStudentNote = function (studentId, data) {
            return $scope.baseAngularRequest('/students/' + studentId + '/notes', data, 'POST');
        };

        /**
         * Remove note for a student
         * @param {String} studentId
         * @param {String} noteId
         * @return {Promise}
         */
        $scope.angRequestRemoveNoteForAStudent = function (studentId, noteId) {
            return $scope.baseAngularRequest('/students/' + studentId + '/notes/' + noteId, '', 'DELETE');
        };
    },
]);
