const { getUrlName } = require('../tools/helpers/urlValidation');

// eslint-disable-next-line no-undef
app.controller('NavigationController', [
    '$scope',
    '$rootScope',
    '$route',
    '$cookies',
    '$controller',
    '$routeParams',
    '$location',
    '$interval',
    '$timeout',
    '$window',
    function ($scope, $rootScope, $route, $cookies, $controller, $routeParams, $location, $interval, $timeout, $window) {
        // Extands generic controllers for which scope must be accessible from everywhere in the app.
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('MultilanguageController', { $scope: $scope }));
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('AjaxRequest', { $scope: $scope }));
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('ToolsController', { $scope: $scope }));
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('AnalyticsController', { $scope: $scope }));
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('ErrorMessagesController', { $scope: $scope }));
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('GetFormCount', { $scope: $scope }));
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('GetUnassignedRouteCount', { $scope: $scope }));

        $scope.$on('$viewContentLoaded', function () {
            // Only when view is done loading
            // Fetch the count of forms
            $scope.fetchCountOfForms();
            // Display footer
            $scope.displayFooter = true;
        });

        /* Navigation bar and menu ================================================================================================================================= */
        $scope.initializeTopNavigation = function () {
            $scope.showUserMenu = false;
            // eslint-disable-next-line no-undef
            $scope.today = moment().format('YYYY-MM-DD');
            $scope.checkLanguage();
            $scope.checkUnits();

            // Fetch the count of unassigned routes, when user refresh page
            $scope.fetchCountOfUnassignedRoutes();

            // Fetch the carrier user settings
            $rootScope.fetchCarrierUserSettings()?.then((data) => ($rootScope.userSettings = data.data.settings));
        };

        /**
         * Executed when the user click on the user menu (user icon on top nav bar)
         */
        $scope.clickOnUserMenu = () => {
            $scope.collapseHamburgerMenu();
            $scope.showUserMenu = !$scope.showUserMenu;
        };

        /**
         * Close the user menu
         */
        $scope.collapseUserMenu = () => {
            $scope.showUserMenu = false;
        };

        /**
         * Close the hamburger menu
         */
        $scope.collapseHamburgerMenu = () => {
            $rootScope.mobileSideMenuOpen = false;
        };

        /**
         * Toggle the hamburger menu (on/off)
         */
        $scope.toggleHamburgerMenu = () => {
            $rootScope.mobileSideMenuOpen = !$scope.mobileSideMenuOpen;
            $scope.collapseUserMenu();
            if ($rootScope.mobileSideMenuOpen) {
                $scope.expandMenu();
            }
        };

        /**
         * Toggle the collapsed menu (on/off)
         */
        $scope.toggleCollapsedMenu = () => {
            $rootScope.sideMenuCollapsed = !$rootScope.sideMenuCollapsed;
            const nav = document.querySelector('.side-nav-selector');
            if ($rootScope.sideMenuCollapsed) {
                setTimeout(function () {
                    angular.element(nav.classList.add('open-side-bar'));
                }, 200);
            } else {
                angular.element(nav.classList.remove('open-side-bar'));
            }
        };

        /**
         * Expand the menu (remove collapsed class)
         */
        $scope.expandMenu = () => {
            $rootScope.sideMenuCollapsed = false;
        };

        $rootScope.fetchCarrierUserSettings = function () {
            if (['dispatcher', 'carrier_manager', 'carrier_observer', 'carrier_mechanic'].includes($rootScope.loggedUserRole)) {
                return $scope.angRequestAdminGetSettingsForCarrier('mine');
            }
        };

        $scope.login = function (serverData, email) {
            // The 0ms delay for the timeout forces the callback to happen on the next angular's cycle.
            // This keeps the manual scope refresh from bumping into another unfinished refresh and crashing.
            $timeout(function () {
                const role = serverData.role;
                $rootScope.loggedUserName = serverData.profile.displayName;
                if (!$rootScope.loggedUserName) {
                    $rootScope.loggedUserName = '------';
                }
                $rootScope.loggedUserRole = role;
                $rootScope.loggedUserMail = email;
                $scope.setupNewCookies(serverData.token);
                $scope.loginWithToken();

                // First time fetching the count of forms, if user role is dispatcher, manager or agent
                $scope.fetchCountOfForms();

                // Fetch logged user settings if user role is dispatcher or carrier manager or carrier observer
                $rootScope.fetchCarrierUserSettings()?.then((data) => ($rootScope.userSettings = data.data.settings));

                // Fetch the count of unassigned routes, if user role is dispatcher
                $scope.fetchCountOfUnassignedRoutes();

                $scope.$apply();
            });
        };

        $scope.loginWithToken = function () {
            const currentUrl = $location.absUrl();
            // if the currentUrl includes params for redirection route get the route
            const hasParam = currentUrl.includes('?');
            let redirectRoute = null;
            if (hasParam) {
                const encodedQueryParamsString = currentUrl.split('?')[1];
                const queryParamsString = decodeURIComponent(encodedQueryParamsString);
                if (queryParamsString != null && queryParamsString.startsWith('continue=')) {
                    redirectRoute = queryParamsString.split('continue=')[1];
                }
            }
            const currentRoute = currentUrl.split('/#!/')[1];
            // Refresh page if it isnt login, password or email-verification page
            const routes = ['login', 'password', 'email-verification', 'password-verify'];
            if (currentRoute?.length >= 0 && routes.every((route) => currentRoute.indexOf(route) < 0)) {
                $scope.refreshPage();
            } else if (redirectRoute) {
                $scope.goToRedirectPage(redirectRoute);
            } else {
                $scope.goToPage('default');
            }
            $scope.hideLoginMenu();
        };

        $scope.toggleDistanceUnit = function () {
            const unit = $scope.userDistanceUnit === 'KM' ? 'MI' : 'KM';
            $scope.setUserDistanceUnit(unit);
        };

        /**
         * logs the user out. The param redirect is set to true when there are redirect params in the URL. When set to true, the redirect param
         * keeps the URL as is and doesn't redirect the user to another page. When set to false, the redirect param takes the user back to the
         * home page.
         * @param {string} redirect, the route to redirect to
         */
        $scope.logout = function (redirect) {
            $scope.clearUserSession();

            if (redirect) {
                $scope.goToPage(redirect);
            } else {
                $scope.goToPage('');
            }
        };

        $scope.clearUserSession = function () {
            $cookies.remove('token');
            $cookies.remove('midlife');
            $cookies.remove('role');
            $cookies.remove('userName');
            $cookies.remove('email');
            $window.localStorage.removeItem('sideMenuCollapsed');
            $rootScope.loggedUserName = null;
            $rootScope.loggedUserRole = null;
            $rootScope.loggedUserMail = null;
            $rootScope.selectedDate = null;
            $scope.showUserMenu = false;
        };

        $scope.clickOnChangeLanguage = function () {
            $scope.toggleLanguages();
            if ($location.path() === '/email-verification' || $location.path() === '/password-reset') {
                $location.search('localeIdentifier', $rootScope.language).replace();
            } else {
                location.reload();
            }
        };

        $scope.$on('$routeChangeSuccess', function () {
            const url = $location.path();
            $scope.currentView = getUrlName(url);

            const token = $cookies.get('token');
            if (!token) {
                $scope.clearUserSession();
            }
        });

        /* Layout ================================================================================================================================================== */
        $scope.fetchSideMenuStatus = function () {
            const sideMenuStatus = $window.localStorage.getItem('sideMenuCollapsed');
            $rootScope.sideMenuCollapsed = sideMenuStatus;
        };

        /* Routing ================================================================================================================================================= */
        $scope.goToPage = function (path) {
            $timeout(function () {
                $scope.cancelPromiseDash();
                $scope.cancelPromiseMap();
                $scope.cancelPromiseOverview();
                $location.url($location.path());
                $location.path(path);
                $scope.$apply();
            });
        };

        $scope.goToRedirectPage = function (path) {
            $timeout(function () {
                $scope.cancelPromiseDash();
                $scope.cancelPromiseMap();
                $scope.cancelPromiseOverview();
                $location.url(path);
                $scope.$apply();
            });
        };

        $scope.goToPageWithParam = function (path, param, value) {
            $scope.cancelPromiseDash();
            $scope.cancelPromiseMap();
            $scope.cancelPromiseOverview();
            $location.url($location.path());
            $location.path(path).search(param, value);
        };

        $scope.refreshPage = function () {
            $window.location.reload();
        };

        $scope.getWhichPageToShow = function () {
            return $routeParams.currentView;
        };

        $scope.initPage = function () {
            $scope.currentYear = new Date().getFullYear();
            $scope.sendUserInfo(); // Google analytics
            $scope.cancelAllRequests();
            const currentUrl = $location.absUrl();
            const currentRoute = currentUrl.split('/#!/')[1];
            const name = currentRoute ? currentRoute.split('/').slice(-1)[0].split('?')[0] : '';

            if (currentRoute === 'default') {
                $scope.goToPage(name);
            } else {
                $scope.cancelPromiseDash();
                $scope.cancelPromiseMap();
                $scope.cancelPromiseOverview();
                $scope.hideLoginMenu();
                switch (name) {
                    case 'home':
                        $scope.validateLoginToken();
                        $rootScope.showLoginTranslateMenu = true;
                        $rootScope.validatedLogin = false;
                        break;
                    case '':
                    case 'login':
                        $rootScope.showLoginTranslateMenu = true;
                        $rootScope.validatedLogin = false;
                        break;
                    case 'password-verify':
                    case 'email-verification':
                        $rootScope.passwordRoutingType = 'verify';
                        break;
                    case 'password-reset':
                        $rootScope.passwordRoutingType = 'reset';
                        break;
                    default:
                        $scope.checkTokenConnexion();
                        break;
                }
            }
        };

        $scope.redirectToDashboardWithDate = function (selectDate) {
            if (selectDate != $scope.today) {
                $scope.goToDashWithDate('dashboard', selectDate);
            } else {
                $scope.goToDashWithDate('dashboard', '');
            }
        };

        $scope.redirectToMapWithDate = function (selectDate) {
            if (selectDate != $scope.today) {
                $scope.goToMapWithDate('map', selectDate);
            } else {
                $scope.goToMapWithDate('map', '');
            }
        };

        $scope.goToDashWithDate = function (path, date) {
            $scope.cancelPromiseDash();
            $scope.cancelPromiseMap();
            $scope.cancelPromiseOverview();
            $location.path(path).search('date', date);
        };

        $scope.goToMapWithDate = function (path, date) {
            $scope.cancelPromiseDash();
            $scope.cancelPromiseMap();
            $scope.cancelPromiseOverview();
            $location.path(path).search('date', date);
        };

        $scope.goToReport = function (type) {
            $location.path('reports/report').search('type', type);
        };

        $scope.goToFullReport = function (category, type, parameters, currentURL) {
            const searchData = {};
            searchData['category'] = category;
            searchData['type'] = type;
            for (const entry in parameters) {
                searchData[entry] = parameters[entry];
            }

            $location.path('reports/report').search(searchData);

            // Force reload page if the request url didn't change
            if ($location.absUrl() === currentURL) {
                $window.location.reload();
            }
        };

        $scope.cancelPromiseDash = function () {
            $interval.cancel($rootScope.promiseDashboard);
        };

        $scope.cancelPromiseMap = function () {
            $interval.cancel($rootScope.promiseMap);
        };

        $scope.cancelPromiseOverview = function () {
            $interval.cancel($rootScope.promiseOverview);
        };

        /* Error routing =========================================================================================================================================== */
        $scope.catchErrorDefault = function (error, redirectOn404Page = true) {
            switch (error.status) {
                case 401:
                    // checking if are requesting a page
                    // length <= 1 is the root page
                    if ($location.url().length > 1 && (!$location.url().includes('continue=') || $location.url().includes('/login?'))) {
                        // catch error is being called for every server request
                        // if location.url already has /login? this means we already went through the process of manipulating the redirect route.
                        if ($location.url().includes('/login?')) {
                            return;
                        }
                        $scope.logout($location.url());
                    } else {
                        $scope.logout();
                    }
                    break;
                case 403:
                    $location.url('403');
                    break;
                case 404:
                    if (redirectOn404Page) {
                        $location.url('404');
                    }
                    break;
                case 500:
                    $location.url('500');
                    break;
                case 400:
                    break;
                case 0:
                    break;
                default:
                    // checking if we are requesting a page
                    // length <= 1 is the root page
                    if ($location.url().length > 1 && (!$location.url().includes('continue=') || $location.url().includes('/login?'))) {
                        // catch error is being called for every server request
                        // if location.url already has /login? this means we already went through the process of manipulating the redirect route.
                        if ($location.url().includes('/login?')) {
                            return;
                        }
                        $location.url();
                    } else {
                        $location.url('404');
                    }
                    break;
            }

            $timeout(function () {
                $scope.$apply();
            });
        };

        /* URL Analysis ============================================================================================================================================ */
        $scope.findVariableOnURL = function (name) {
            const url = $location.absUrl();
            name = name.replace(/[\[\]]/g, '\\$&');
            const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
            const results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        };

        $scope.getIdFromURL = function () {
            return $scope.findVariableOnURL('id');
        };

        $scope.getStepFromURL = function () {
            return $scope.findVariableOnURL('step');
        };

        $scope.getDateFromURL = function () {
            return $scope.findVariableOnURL('date');
        };

        $scope.getEmailFromURL = function () {
            return $scope.findVariableOnURL('email');
        };

        $scope.findAllVariablesOnURL = function () {
            const params = [];
            const href = $location.absUrl();
            const regex = /([?&])(\w)*(=([^&#]*)|&|#|$)/g;
            const results = href.match(regex);

            if (results) {
                for (const result of results) {
                    const key = result.split('=')[0].substr(1);
                    const value = result.split('=')[1];
                    params[key] = value;
                }
            }

            return params;
        };

        $scope.setVariableOnURL = function (key, value) {
            let href = $location.absUrl();
            const pattern = new RegExp('\\b(' + key + '=).*?(&|$)');

            if (href.search(pattern) >= 0) {
                return href.replace(pattern, '$1' + value + '$2');
            }

            href = href.replace(/\?$/, '');
            return href + (href.indexOf('?') > 0 ? '&' : '?') + key + '=' + value;
        };

        /* External Calls ========================================================================================================================================== */
        $scope.hideLoginMenu = function () {
            $rootScope.showLoginTranslateMenu = false;
        };

        $scope.scrollToTop = function () {
            $window.scrollTo(0, 0);
        };

        $scope.scrollToElement = function (id) {
            // eslint-disable-next-line no-undef
            document.getElementById(id).scrollIntoView();
        };
    },
]);
