angular.module('mTransportApp').controller('DisciplineController', [
    '$scope',
    'sortingTools',
    '$translate',
    '$rootScope',
    function ($scope, sortingTools, $translate, $rootScope) {
        initializeScopeVariables();
        fetchDisciplinaryReports();
        $scope.fetchCountOfForms();
        initializeButtonGroupToggle();

        /**
         * Initialize scope variables
         */
        function initializeScopeVariables() {
            $scope.loading = true;
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = true;

            $scope.disciplinaryReports = [];
            $scope.disciplinaryReportsToDisplay = [];
            $scope.firstSort = true;
            $scope.notValidatedOnly = false;
            $scope.filterSections = [];
            $scope.searchValue = '';

            $scope.orderType = {
                orderBy: 'received',
                ascending: false,
            };
        }

        /**
         * Initialize the button group toggle
         */
        function initializeButtonGroupToggle() {
            const allOption = '0';
            const notValidatedOption = '1';
            const mailHash = sha256($rootScope.loggedUserMail);
            const selectedOption = window.localStorage.getItem('disciplineTableSelectedOption-' + mailHash);

            switch (selectedOption) {
                case allOption:
                    $scope.buttonsGroupSelectedOption = parseInt(allOption);
                    break;
                case notValidatedOption:
                default:
                    if ($rootScope.loggedUserRole === 'observer') {
                        $scope.buttonsGroupSelectedOption = parseInt(allOption);
                    } else {
                        $scope.buttonsGroupSelectedOption = parseInt(notValidatedOption);
                        $scope.notValidatedOnly = true;
                    }
            }

            $scope.buttonsGroupOptions = [
                {
                    name: $translate.instant('all'),
                    onClick: () => {
                        window.localStorage.setItem('disciplineTableSelectedOption-' + mailHash, allOption);
                        $scope.notValidatedOnly = false;
                        $scope.applyFilters();
                    },
                },
                {
                    name: $translate.instant('notValidated'),
                    onClick: () => {
                        window.localStorage.setItem('disciplineTableSelectedOption-' + mailHash, notValidatedOption);
                        $scope.notValidatedOnly = true;
                        $scope.applyFilters();
                    },
                },
            ];
        }

        /**
         * Get all the disciplinary reports, manipulate them and sort them by date
         */
        function fetchDisciplinaryReports() {
            $scope
                .angRequestGetDispatchForms()
                .then(
                    function (data) {
                        // Filter to get disciplinary forms only
                        $scope.disciplinaryReports = data.data.forms.filter((report) => report.type === 'disciplinary');

                        $scope.disciplinaryReports.map((report) => {
                            const studentInstitutions = [];

                            report.student.institutions?.forEach((institution) => {
                                studentInstitutions.push(institution.name);
                            });
                            // InternalId of students added manualy by drivers are not displayed
                            report.fileNumber = report.student.id != null ? report.student.internalId : null;
                            report.studentInstitutions = studentInstitutions.join(' | ');
                            report.studentName = report.student.firstName + ' ' + report.student.lastName;
                            report.busNumber = report.trip != null ? report.trip.route.bus.number : '';
                            report.carrierName = report.carrier.name;
                            report.validatedAt = report.validatedAt ?? null;
                            report.followUp = report.followUp?.note ?? '';
                        });
                    },
                    function (error) {
                        $rootScope.$broadcast('request-error-student-discipline', error);
                        $scope.loading = false;
                    }
                )
                .then(() => {
                    initializeButtonGroupToggle();
                    initializeFilters();
                    $scope.registeringDisplayFunctions = false;
                    $scope.loading = false;
                    $scope.applyFilters();
                });
        }

        /**
         * Determine if the disciplinary report must be displayed or not
         * @param {Object} disciplinaryReport
         * @return {Boolean} True if report must be displayed, false if it's filtered out
         */
        function isDisciplinaryReportInFilters(disciplinaryReport) {
            // Is validated or not
            if ($scope.notValidatedOnly === true && disciplinaryReport.validatedAt != null) {
                return false;
            }

            // School filters
            const schoolSections = $scope.filterSections.find((section) => section.id === 'school');
            if (disciplinaryReport.student.institutions?.length > 0) {
                const isExcluded = disciplinaryReport.student.institutions.every((institution) =>
                    schoolSections.options.some((option) => option.name === institution.name && option.value === false)
                );
                if (isExcluded === true) {
                    return false;
                }
            } else {
                const showUnknownSchools = schoolSections.options.find((option) => option.name === $translate.instant('unknownSchools')).value;
                if (showUnknownSchools === false) {
                    return false;
                }
            }

            // carrier filters
            const carrierSection = $scope.filterSections.find((section) => section.id === 'carrier');
            if (carrierSection.options.some((option) => option.name === disciplinaryReport.carrierName && option.value === false)) {
                return false;
            }

            // Text filters
            const dataToCompareWithSearch = {
                studentFirstName: disciplinaryReport.student.firstName,
                studentLastName: disciplinaryReport.student.lastName,
                studentInstitutions: disciplinaryReport.studentInstitutions,
                busNumber: disciplinaryReport.busNumber,
                carrierName: disciplinaryReport.carrierName,
            };

            if (disciplinaryReport.followUp != null) {
                dataToCompareWithSearch.followUpNote = disciplinaryReport.followUp.note;
            }

            return $scope.containsSearchValue(dataToCompareWithSearch, $scope.searchValue);
        }

        /**
         * Initialize filters
         */
        function initializeFilters() {
            const institutions = $scope.disciplinaryReports.reduce((acc, disciplinaryReport) => {
                const list = [...acc];
                disciplinaryReport.student.institutions?.forEach((institution) => {
                    if (institution.name !== '' && list.some((listItem) => listItem.name === institution.name) === false) {
                        list.push({
                            name: institution.name,
                            value: true,
                        });
                    }
                });
                return list;
            }, []);

            const carriers = $scope.disciplinaryReports.reduce((acc, disciplinaryReport) => {
                const list = [...acc];
                if (list.some((listItem) => listItem.name === disciplinaryReport.carrier.name) === false && disciplinaryReport.carrier.name !== '') {
                    list.push({
                        name: disciplinaryReport.carrier.name,
                        value: true,
                    });
                }
                return list;
            }, []);

            $scope.filterSections = [
                {
                    id: 'carrier',
                    name: $translate.instant('carriers'),
                    options: carriers,
                },
                {
                    id: 'school',
                    name: $translate.instant('school'),
                    options: [
                        {
                            name: $translate.instant('unknownSchools'),
                            value: true,
                        },
                        ...institutions,
                    ],
                },
            ];
        }

        /**
         * Sort discplinary reports by field
         * @param {String} [orderBy] orderBy // Optional parameter
         * @param {Boolean} [firstSort]
         */
        $scope.sortDisciplinaryReportsByField = function (orderBy, firstSort = false) {
            $scope.sorting = true;
            $scope.registeringDisplayFunctions = true;
            $scope.firstSort = firstSort;

            if (orderBy != null) {
                if ($scope.orderType.orderBy === orderBy) {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: !$scope.orderType.ascending,
                    };
                } else {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }
            $scope.applyFilters();
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
        };

        /**
         * Update form states
         * @param {Object} selectedReport report on which we clicked
         */
        $scope.openReport = function (selectedReport) {
            if (selectedReport.read == false) {
                // Mark read true for the selected report and update count
                $scope.disciplinaryReportsToDisplay = $scope.disciplinaryReportsToDisplay.map((report) => ({
                    ...report,
                    read: report.id === selectedReport.id ? true : report.read,
                }));
                $rootScope.$broadcast('reduceCountOfUnreadForms');

                // Sort the disciplinary reports according to current orderBy selected by the user (date by default)
                $scope.sortDisciplinaryReportsByField();
            }
        };

        /**
         * This function will be given to the input of filter component to be executed each time the input value changes.
         * @param {String} searchValue - the string in the search input text
         */
        $scope.onInputChange = function (searchValue) {
            $scope.searchValue = searchValue;
            $scope.applyFilters();
        };

        /**
         * This function will trigger applyFilters function
         */
        $scope.onFilterChange = function () {
            $scope.applyFilters();
        };

        /**
         * This function will apply filters on forms to show and handle sorting
         */
        $scope.applyFilters = function () {
            const filteredDisciplinaryReports = $scope.disciplinaryReports.filter(isDisciplinaryReportInFilters);
            const reportsUnread = filteredDisciplinaryReports.filter((element) => element.read === false);
            const reportsRead = filteredDisciplinaryReports.filter((element) => element.read == null || element.read === true);

            const sortedDisciplinaryReports = $scope.firstSort
                ? reportsUnread.concat(sortingTools.sortDisciplinaryReports(reportsRead, $scope.orderType.orderBy, $scope.orderType.ascending))
                : sortingTools.sortDisciplinaryReports(filteredDisciplinaryReports, $scope.orderType.orderBy, $scope.orderType.ascending);

            $scope.disciplinaryReportsToDisplay = sortedDisciplinaryReports;
        };

        /**
         * This function will reset all filters and search value except the validation status which won't change
         */
        $scope.onReset = function () {
            $scope.filterSections = $scope.filterSections.map((section) => ({
                ...section,
                options: section.options.map((option) => ({ ...option, value: true })),
            }));
            $scope.searchValue = '';
            $scope.applyFilters();
        };
    },
]);
