export const icons = {
    leftArrow:
        '<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48px" height="48px"><path d="M 7.2734375 3.8027344 C 7.1547969 3.8200625 7.03875 3.8727969 6.9375 3.9667969 L 3.2441406 7.390625 C 2.9191406 7.715625 2.9191406 8.2433594 3.2441406 8.5683594 L 6.9355469 11.992188 C 7.3415469 12.369188 8 12.081297 8 11.529297 L 8 9 L 16 9 C 17.668484 9 19 10.331516 19 12 L 19 20 A 1.0001 1.0001 0 1 0 21 20 L 21 12 C 21 9.2504839 18.749516 7 16 7 L 8 7 L 8 4.4296875 C 8 4.0149375 7.6293594 3.75075 7.2734375 3.8027344 z"/></svg>',
    rightArrow:
        '<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48px" height="48px"><path d="M 16.726562 3.8027344 C 16.370641 3.75075 16 4.0149375 16 4.4296875 L 16 7 L 8 7 C 5.2504839 7 3 9.2504839 3 12 L 3 20 A 1.0001 1.0001 0 1 0 5 20 L 5 12 C 5 10.331516 6.3315161 9 8 9 L 16 9 L 16 11.529297 C 16 12.081297 16.6575 12.369187 17.0625 11.992188 L 20.755859 8.5683594 C 21.080859 8.2433594 21.080859 7.715625 20.755859 7.390625 L 17.0625 3.9667969 C 16.96125 3.8727969 16.845203 3.8200625 16.726562 3.8027344 z"/></svg>',
    straightArrow:
        '<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48px" height="48px"><path d="M 12 1 C 11.87625 1 11.752703 1.0480781 11.658203 1.1425781 L 8.625 4.1757812 C 8.32 4.4797813 8.5357969 5 8.9667969 5 L 11 5 L 11 22 A 1.0001 1.0001 0 1 0 13 22 L 13 5 L 15.033203 5 C 15.464203 5 15.68 4.4797813 15.375 4.1757812 L 12.341797 1.1425781 C 12.247297 1.0480781 12.12375 1 12 1 z"/></svg>',
    turnaroundArrow:
        '<svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve"> <path d="M6,35.6c0,0.2,0.2,0.5,0.3,0.6l5.1,5.1c0.5,0.5,1.2,0.5,1.7,0l5.1-5.1c0.8-0.8,0.2-2-0.8-2h-3.1v-14c0-5.5,4.4-10,10-10 s10,4.4,10,10v18c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2c0,0,0,0,0-0.1v-18c0-7.7-6.3-14-14-14s-14,6.3-14,14v14H7.2 C6.4,34.2,5.9,35,6,35.6z"/> </svg>',
    circle:
        '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><circle cx="12" cy="12" r="10" stroke="black" stroke-width="3" fill="white" />',
    hexagon:
        '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="96pt" height="96pt" viewBox="0 0 96 96" version="1.1"><g id="surface6827236"><path style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;" d="M 27.398438 12 L 6.820312 48 L 27.398438 84 L 68.601562 84 L 89.179688 48 L 68.601562 12 Z M 32.039062 20 L 63.960938 20 L 79.96875 48 L 63.960938 76 L 32.039062 76 L 16.03125 48 Z M 32.039062 20 "/> </g> </svg>',
    square:
        '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20"><rect x="3" y="3" width="15" height="15" stroke="black" stroke-width="3" fill="white" />',
    leftSligth:
        '<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" width="32px" height="32px"><path d="M23,18.642V28.5c0,0.829-0.671,1.5-1.5,1.5S20,29.329,20,28.5v-9.858c0-2.271-0.884-4.405-2.489-6.01l-4.071-4.071	l-3.227,3.227C9.765,12.235,9,11.918,9,11.286V2h9.286c0.633,0,0.949,0.765,0.502,1.212l-3.227,3.227l4.071,4.071	C21.804,12.683,23,15.57,23,18.642z"/></svg>',
    rightSligth:
        '<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 32 32" width="32px" height="32px"><path d="M12.368,10.511l4.071-4.071l-3.227-3.227C12.765,2.765,13.082,2,13.714,2H23v9.286c0,0.633-0.765,0.949-1.212,0.502	l-3.227-3.227l-4.071,4.071C12.884,14.237,12,16.372,12,18.642V28.5c0,0.829-0.671,1.5-1.5,1.5S9,29.329,9,28.5v-9.858	C9,15.57,10.196,12.683,12.368,10.511z"/></svg>',
};
