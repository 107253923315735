/**
 * icon Toggle Component.
 * @param {boolean} status icon status on/off.
 * @param {string} classIconOne css class.
 * @param {string} classIconTwo css class.
 * @param {Boolean} restriction users restriction
 * @param {Boolean} isImage if we using icons from svg or png ...
 * @param {function} onClick function to call when clicking on button inside component
 * @returns {HTMLElement}
 */

angular.module('mTransportApp').component('iconToggle', {
    templateUrl: './shared/iconToggle/iconToggle.html',
    transclude: true,
    bindings: {
        status: '=',
        classIconOne: '@',
        restriction: '<',
        classIconTwo: '@',
        isImage: '<',
        tooltipTitle: '<',
        onClick: '&',
    },
});
