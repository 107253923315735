app.controller('GetUnassignedRouteCount', [
    '$rootScope',
    '$controller',
    '$scope',
    function ($scope, $controller, $rootScope) {
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('AjaxRequest', { $scope: $scope }));

        $scope.unassignedRouteCount = null;
        $scope.isDefaultAgent = false;

        /**
         * Get the current count of unassigned routes if the user is a dispatcher
         */
        $scope.fetchCountOfUnassignedRoutes = () => {
            if (['dispatcher', 'manager', 'agent', 'carrier_manager'].includes($rootScope.loggedUserRole)) {
                $scope.angRequestUnassignedRouteCount().then(
                    function (data) {
                        $scope.unassignedRouteCount = data.data.unassignedRouteCount;
                        if ($rootScope.loggedUserRole === 'agent') {
                            $scope.isDefaultAgent = true;
                        }
                    },
                    function (error) {
                        $rootScope.$broadcast('request-error-main', error);
                    }
                );
            }
        };
    },
]);
