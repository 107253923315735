angular.module('mTransportApp').controller('AdminDashboardController', [
    '$scope',
    '$translate',
    function ($scope, $translate) {
        /* =======================================================================================================================
       INITIALIZATION
    =======================================================================================================================  */
        $scope.initializeDashboard = function () {
            $scope.loading = true;
            $scope.defaultDisplayedFeedbacksCount = 30;
            $scope.feedbacks = [];

            $scope.displayUnprocessedOnly = true;
            $scope.filterSelectedOption = $scope.displayUnprocessedOnly ? 0 : 1;

            $scope.fetchAllFeedbacks();
        };

        /**
         * Initializes the text in the unprocessed toggle in the right language
         * Called when fetching feedbacks information so that every time the page refreshes, this info does too
         */
        function initializeUnprocessedToggleText() {
            $scope.filterOptions = [
                {
                    name: $translate.instant('unprocessed'),
                    onClick: () => {
                        $scope.displayUnprocessedOnly = true;
                        $scope.filterSelectedOption = 0;
                        $scope.filterFeedbacks();
                    },
                },
                {
                    name: $translate.instant('all'),
                    onClick: () => {
                        $scope.displayUnprocessedOnly = false;
                        $scope.filterSelectedOption = 1;
                        $scope.filterFeedbacks();
                    },
                },
            ];
        }

        /* =======================================================================================================================
       DATA FETCH
    =======================================================================================================================  */
        $scope.fetchAllFeedbacks = function () {
            $scope.requestAdminGetAllFeedback().then(
                function (answer) {
                    initializeUnprocessedToggleText();

                    $scope.defaultFeedbacks = answer.feedbacks.map((feedback) => ({
                        ...feedback,
                        clientsDisplay: feedback.clients?.map((client) => client.name).join(', '),
                    }));

                    $scope.filterFeedbacks();

                    $scope.loading = false;
                    $scope.$apply();
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /**
         * Toggle (true/false) the state isProcessed of the spectified feedback
         * @param {Object} feedback The feedback to change the state
         */
        $scope.toggleFeedbackState = (feedback) => {
            $scope
                .requestAdminUpdateFeedback(feedback.id, {
                    isProcessed: feedback.isProcessed,
                })
                .then(
                    function () {
                        $scope.filterFeedbacks();
                        $scope.$apply();
                    },
                    function (error) {
                        $scope.catchErrorDefault(error);
                    }
                );
        };

        /**
         * Apply the filters to the feedbacks
         */
        $scope.filterFeedbacks = () => {
            if ($scope.displayUnprocessedOnly) {
                $scope.feedbacks = $scope.defaultFeedbacks.filter((feedback) => feedback.isProcessed === false);
            } else {
                $scope.feedbacks = $scope.defaultFeedbacks;
            }
        };

        /* =======================================================================================================================
        LAYOUT AND DATA MANIPULATION
     =======================================================================================================================  */
        $scope.formatDate = function (date) {
            return moment(date).format('LLL');
        };
    },
]);
