const { pdfMake } = require('pdfMakeGenerator');
const { generateDocDefinition, generateTitle } = require('./templates/index');
const { icons } = require('../../assets/img/constants');

// eslint-disable-next-line no-undef
angular.module('mTransportApp').controller('PDFDirectionController', [
    '$scope',
    '$rootScope',
    '$translate',
    function ($scope, $rootScope, $translate) {
        /**
         * Generate a Directions PDF for a specific route
         * @param {String} routeId
         * @param {Function} callback - optional
         */
        $scope.generateRouteDirectionPDF = (routeId, callback = () => {}) => {
            // Fetching details about the specified route
            $scope.requestRouteDetail(routeId).then(
                function (data) {
                    const route = data;

                    $scope.requestRouteDetailStops(routeId).then(
                        function ({ stops }) {
                            return generateDirectionPDF(route, stops, callback);
                        },
                        function (error) {
                            $rootScope.$broadcast('request-error-main', error);
                        }
                    );
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
        };

        /**
         * Generate a Directions PDF for a specific trip, this will take the trip stops to generate the directions, not the route stops.
         * @param {String} tripId
         * @param {Function} callback - optional
         */
        $scope.generateTripDirectionPDF = (tripId, callback = () => {}) => {
            // Fetching details about the specified trip
            $scope.requestTripDetails(tripId).then(
                function ({ stops, route }) {
                    return generateDirectionPDF(route, stops, callback);
                },
                function (error) {
                    $rootScope.$broadcast('request-error-main', error);
                }
            );
        };

        /**
         * Generate the Directions PDF for the specified route and stops and prompt download window to the user
         * @param {Object} route
         * @param {Object[]} stops
         * @param {Function} callback - executed when the pdf is generated
         */
        const generateDirectionPDF = (route, stops, callback = () => {}) => {
            // Generating file header, footer and content table
            const docDefinition = generateDocDefinition(
                $translate.instant('time'),
                $translate.instant('directionAction'),
                $translate.instant('directionStreet'),
                $translate.instant('directionDistance')
            );

            const passengersAndBusData = [
                {
                    title: $translate.instant('passengers'),
                    text: stops.filter((stop) => stop.kind === 'pickup').reduce((a, b) => a + b.studentCount, 0),
                },
                {
                    title: $translate.instant('bus'),
                    text: route.bus.number,
                },
            ];

            docDefinition.content = [
                // Addin the information header to the content
                ...generateTitle(`${route.name} (${route.schedule.departure} - ${route.schedule.arrival})`, [
                    {
                        title: $translate.instant('client'),
                        text: route.client.fullName ? route.client.fullName : route.client.name,
                    },
                    ...(['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)
                        ? [
                              {
                                  title: $translate.instant('carrier'),
                                  // The carrier name is accessed differently for a trip and a route
                                  text: route.carrier?.name ?? route.carrier,
                              },
                          ]
                        : [{ text: '' }]),
                    ...(['dispatcher', 'carrier_manager', 'carrier_observer'].includes($rootScope.loggedUserRole)
                        ? passengersAndBusData
                        : [...passengersAndBusData].reverse()),
                ]),
                ...docDefinition.content,
            ];

            stops.forEach((stop) => {
                // Generating one line per direction for this stop
                stop.directions.forEach(({ time, action, street, distance }) => {
                    if (distance > 1000) {
                        distance = distance / 1000.0 + $translate.instant('directionDistanceKM');
                    } else if (distance > 0) {
                        distance = distance + $translate.instant('directionDistanceM');
                    }
                    const timeData = time != null ? time : '';

                    const directionLine = [
                        {
                            text: timeData,
                            margin: [0, 5, 0, 0],
                        },
                    ];

                    const iconToAdd = {
                        width: 25,
                        height: 25,
                        alignment: 'center',
                    };
                    switch (action) {
                        case 'left':
                            iconToAdd.svg = icons.leftArrow;
                            break;
                        case 'right':
                            iconToAdd.svg = icons.rightArrow;
                            break;
                        case 'straight':
                            iconToAdd.svg = icons.straightArrow;
                            break;
                        case 'turnaround':
                            iconToAdd.svg = icons.turnaroundArrow;
                            break;
                        case 'slight_left':
                            iconToAdd.svg = icons.leftSligth;
                            break;
                        case 'slight_right':
                            iconToAdd.svg = icons.rightSligth;
                            break;
                        default:
                            iconToAdd.text = ''; // No icon
                    }

                    directionLine.push(iconToAdd);
                    directionLine.push({ text: street || '' });
                    directionLine.push({ text: distance || '' });

                    docDefinition.content[docDefinition.content.length - 1].table.body.push(directionLine);
                });

                // Generating stop line (after its directions)
                const stopLine = [{ text: `${stop.schedule} (${$translate.instant('stop').toUpperCase()})`, bold: false, margin: [0, 5, 0, 0] }];

                const iconToAdd = {
                    width: 25,
                    height: 25,
                    alignment: 'center',
                };
                if (stop.stopTransfer) {
                    iconToAdd.svg = icons.hexagon;
                } else if (stop.institution) {
                    iconToAdd.svg = icons.square;
                } else {
                    iconToAdd.svg = icons.circle;
                }

                stopLine.push(iconToAdd);
                stopLine.push({ text: stop.name, bold: true });
                stopLine.push({ text: '' }); // Nothing in the last colum for stop rows.

                docDefinition.content[docDefinition.content.length - 1].table.body.push(stopLine);
            });

            pdfMake.createPdf(docDefinition).download(`${route.name}.pdf`, callback);
        };
    },
]);
