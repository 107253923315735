/* {String} title - title of the section
 * {Boolean} forceDefault - force the default collapse state (open), the user won't be able to change it
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('collapsableSection', {
    templateUrl: './shared/layout/collapsableSection/collapsableSection.html',
    transclude: true,
    bindings: {
        title: '@',
        forceDefault: '<',
        collapsedByDefault: '<',
    },
    controller: function () {
        // On initialization of component, if collapsedByDefault binding is true, then we collapse the section, else we expand it
        this.$onInit = () => {
            this.collapsed = this.collapsedByDefault ? true : false;
        };

        /**
         * Toggle (inverse) the collapse state of the section
         */
        this.toggleCollapse = function () {
            if (this.forceDefault !== true) {
                this.collapsed = !this.collapsed;
            }
        };
    },
});
