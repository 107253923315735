angular.module('mTransportApp').factory('AssignmentsManager', [
    'sortingTools',
    function (sortingTools) {
        /**
         * Format the routes with the assignment period status (isFuture, isExpired) and the isAssignedByBus
         * @param {Object[]} routes
         * @param {Object[]} [busAssignments=[]]
         * @return {Object[]} routes
         */
        function formatRoutesWithAssignmentStatus(routes, busAssignments = []) {
            const currentDate = moment();

            return routes.map((route) => {
                if (isAssignedByBus(route, busAssignments)) {
                    // No need to check the assignment period
                    return { ...route, isAssignedByBus: true };
                }

                // Check if the user is assigned to the route
                const assignmentPeriod = route.assignmentPeriod;
                if (!assignmentPeriod) {
                    return route;
                }

                // Update assignment period status in-place
                updateAssignmentPeriodStatus(assignmentPeriod, currentDate);

                return route;
            });
        }

        /**
         * Update the assignment period status (isFuture, isExpired)
         * @param {Object} assignmentPeriod
         * @param {Object} currentDate
         */
        function updateAssignmentPeriodStatus(assignmentPeriod, currentDate) {
            const startDate = assignmentPeriod.startDate ?? null;
            const endDate = assignmentPeriod.endDate ?? null;

            if (isAfterCurrentDate(currentDate, startDate)) {
                assignmentPeriod.isFuture = true;
            } else if (isBeforeCurrentDate(currentDate, endDate)) {
                assignmentPeriod.isExpired = true;
            }
        }

        /**
         * Check if the driver is assigned to the route by bus assignment
         * @param {Object} route
         * @param {Object[]} [busAssignments=[]]
         * @return {Boolean}
         */
        function isAssignedByBus(route, busAssignments = []) {
            return busAssignments.some((busAssignment) => busAssignment.bus.number === route.bus.number);
        }

        /**
         * Check if the route has assigned drivers but none for today
         * Route has no assigned drivers for today if it has assigned drivers but none have an active period and no drivers assigned by bus number
         * @param {Object} route
         * @return {Boolean}
         */
        function hasNoAssignedDriverToday(route) {
            return !hasAssignedDriversToday(route) && !hasAssignedDriversByBusNumber(route) && hasDrivers(route);
        }

        /**
         * Check if the route has drivers (assigned or not)
         * @param {Object} route
         * @return {Boolean}
         */
        function hasDrivers(route) {
            return route.drivers.length > 0;
        }

        /**
         * Check if the route has assigned drivers today
         * @param {Object} route
         * @return {Boolean}
         */
        function hasAssignedDriversByBusNumber(route) {
            return route.assignedDriversByBusNumber.length > 0;
        }

        /**
         * Check if the route has assigned drivers today
         * Route has assigned drivers today if at least one driver has an active period
         * @param {Object} route
         * @return {Boolean}
         */
        function hasAssignedDriversToday(route) {
            return route.drivers.some((driver) => driver.period?.isActive);
        }

        /**
         * Sort drivers depending of date period assignment
         * 1- Drivers without date range period
         * 2- Drivers with date range period
         * 3- Drivers with future date range period
         * 4- Drivers with past date range period
         * @param {Object[]} drivers
         * @return {Object[]} sorted drivers
         */
        function sortDriversByStateAndDate(drivers) {
            const { activeNoPeriodArray, activeWithPeriodArray, isFutureArray, isPastArray } = categorizeDrivers(drivers);

            return [
                ...sortingTools.sortDrivers(activeNoPeriodArray, 'lastName', true),
                ...sortingTools.sortDrivers(activeWithPeriodArray, 'lastName', true),
                ...sortingTools.sortDrivers(isFutureArray, 'lastName', true),
                ...sortingTools.sortDrivers(isPastArray, 'lastName', true),
            ];
        }

        /**
         * Categorize drivers depending of the state and the date period assignment
         * @param {Object[]} drivers
         * @return {Object[]} categorized drivers
         */
        function categorizeDrivers(drivers) {
            const currentDate = moment();

            const categories = {
                activeNoPeriodArray: [],
                activeWithPeriodArray: [],
                isFutureArray: [],
                isPastArray: [],
            };

            for (const driver of drivers) {
                // Check if the driver has a period attribute
                if (!driver.period) {
                    categories.activeNoPeriodArray.push(driver);
                    continue;
                }
                const { startDate, endDate, isActive } = driver.period;

                // Driver has no startDate
                if (!startDate) {
                    categories.activeNoPeriodArray.push(driver);
                    continue;
                }

                // Driver has an active period
                if (isActive) {
                    categories.activeWithPeriodArray.push(driver);
                    continue;
                }

                // Driver has a period in the future
                if (isAfterCurrentDate(currentDate, startDate)) {
                    categories.isFutureArray.push(driver);
                    continue;
                }

                // Driver has a period in the past
                if (endDate && isBeforeCurrentDate(currentDate, endDate)) {
                    categories.isPastArray.push(driver);
                }
            }

            return categories;
        }

        /**
         * Check if the date is after the current date
         * @param {Object} currentDate
         * @param {Object} date
         * @return {Boolean}
         */
        function isAfterCurrentDate(currentDate, date) {
            return moment(date).isAfter(currentDate);
        }

        /**
         * Check if the date is before the current date
         * @param {Object} currentDate
         * @param {Object} date
         * @return {Boolean}
         */
        function isBeforeCurrentDate(currentDate, date) {
            return moment(date).isBefore(currentDate);
        }

        return {
            formatRoutesWithAssignmentStatus,
            hasNoAssignedDriverToday,
            sortDriversByStateAndDate,
        };
    },
]);
