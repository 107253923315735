app.config([
    '$translateProvider',
    function ($translateProvider) {
        $translateProvider.useStaticFilesLoader({
            prefix: './shared/tools/translations/main/',
            suffix: '.json',
        });
        $translateProvider.preferredLanguage('fr');
        $translateProvider.useSanitizeValueStrategy('escape');
    },
]);

app.controller('MultilanguageController', [
    '$scope',
    '$translate',
    '$rootScope',
    '$window',
    '$cookies',
    function ($scope, $translate, $rootScope, $window) {
        $rootScope.language;

        $scope.checkLanguage = function () {
            let cookieLang = localStorage.getItem('userLanguage');
            if (cookieLang) {
                $scope.changeLanguage(cookieLang);
            } else {
                cookieLang = $scope.getBrowserLanguage();
                $scope.changeLanguage(cookieLang);
            }
        };

        $scope.checkUnits = function () {
            let cookieUnit = localStorage.getItem('userDistanceUnit');
            if (cookieUnit != null) {
                $scope.setUserDistanceUnit(cookieUnit);
            } else {
                $scope.setUserDistanceUnit('KM');
            }
        };

        $scope.getBrowserLanguage = function () {
            let language = $window.navigator.language || $window.navigator.userLanguage;
            return language;
        };

        $scope.setUserDistanceUnit = function (distanceUnit) {
            localStorage.setItem('userDistanceUnit', distanceUnit);
            $scope.userDistanceUnit = distanceUnit;
        };

        $scope.toChosenUnit = function (value, digits) {
            let milesPerKilometre = 0.62137119;
            let conversion;
            if ($scope.userDistanceUnit === 'MI') {
                conversion = value * milesPerKilometre;
                conversion = conversion.toFixed(digits);
                conversion += ' ' + $translate.instant('MI');
            } else {
                conversion = value.toFixed(digits);
                conversion += ' ' + $translate.instant('KM');
            }
            return conversion;
        };

        $scope.changeLanguage = function (language) {
            if (language === 'fr' || language === 'fr-ca') {
                if ($rootScope.language != 'fr') {
                    moment.locale(['fr-ca', 'fr']);
                    $rootScope.language = 'fr';
                    localStorage.setItem('userLanguage', 'fr');
                    $translate.use($rootScope.language);
                }
            } else if ($rootScope.language != 'en') {
                moment.locale(['en-ca', 'en-us', 'en']);
                $rootScope.language = 'en';
                localStorage.setItem('userLanguage', 'en');
                $translate.use($rootScope.language);
            }
        };

        $scope.toggleLanguages = function () {
            let newLang = $rootScope.language === 'fr' ? 'en' : 'fr';
            $scope.changeLanguage(newLang);
        };
    },
]);
