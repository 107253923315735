/**
 * @param {Function} kind pickup or dropoff
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('stopInstitutionArrow', {
    templateUrl: './shared/stopArrow/stopInstitutionArrow.html',
    bindings: {
        kind: '<',
    },
});
