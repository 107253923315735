/**
 * @param {String} status status of the component to know which toggle should be enabled ('off', 'vehicleOnly', 'vehicleAndStudents')
 * @param {Boolean} isVehicleAndStudentsDisabled should the toggle for vehicleAndStudents be disabled
 * @param {Function} toggleOff function to call when clicking on "off toggle"
 * @param {Function} toggleVehicleOnly function to call when clicking on "vehicleOnly toggle"
 * @param {Function} toggleVehicleAndStudents function to call when clicking on "vehicleAndStudents toggle"
 * @param {Boolean} isRestricted should all the toggles be disabled
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('radioToggleNotifications', {
    templateUrl: './shared/radioToggleNotifications/radioToggleNotifications.html',
    bindings: {
        status: '<',
        isVehicleAndStudentsDisabled: '<',
        toggleOff: '&',
        toggleVehicleOnly: '&',
        toggleVehicleAndStudents: '&',
        isRestricted: '<',
    },
});
