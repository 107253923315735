/**
 * Component: selectDropdown
 * Html tags: <select-dropdown>
 * @param {Array} options to put in the dropdown
 * @param {Object} entity - Object which will be updated
 * @param {String} property - Property of entity which will be updated
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('selectDropdown', {
    templateUrl: './shared/selectDropdown/selectDropdown.html',
    bindings: {
        options: '<',
        entity: '=',
        property: '=',
        dropdownOpen: '=',
    },
    controller: function ($scope) {
        this.$onInit = function () {
            this.dropdownOpen = this.dropdownOpen ?? false;
            this.entity = this.entity ?? {};
            this.property = this.property ?? '';
            this.options = this.options ?? [];
        };

        // When selecting an option, emit event to parent with updated value in payload
        this.select = function (option) {
            this.entity[this.property] = option;
            $scope.$emit('selectDropdown', { entity: this.entity.id, property: this.property, value: option });
            this.dropdownOpen = false;
        };

        this.toggleDropdown = function (value) {
            $scope.$emit('collapseDropdowns');
            this.dropdownOpen = value;
        };
    },
});
