/**
 * Component: csvButton
 * Html tags: <csv-button>
 * @param {Boolean} show whether we should show the csv-button or not
 * @param {Boolean} onClick the function to be called when clicking on csv-button
 * @param {Boolean} isDisabled disabled access to the button if true
 * @param {Boolean} isLoading disabled access to the button if true and icon is replaced with a spinner if true
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('csvButton', {
    templateUrl: './shared/csvButton/csvButton.html',
    bindings: {
        show: '<',
        onClick: '<',
        isDisabled: '<',
        isLoading: '<',
    },
    controller: function () {
        this.$onInit = () => {
            this.show = this.show ?? true;
            this.isDisabled = this.isDisabled ?? true;
            this.isLoading = this.isLoading ?? false;
        };
    },
});
