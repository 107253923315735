import { generatePDFHeader, generatePDFooter } from '../../../shared/pdfTemplates/layout';

/**
 * Generate pdf document definition for directions
 * @param {String} sequenceNumber - Column title
 * @param {String} action - Column title
 * @param {String} street - Column title
 * @param {String} distance - Column title
 * @param {String} stop - Column title
 * @return {Object} return object contains document defintion of the pdf (template)
 */

export const generateDocDefinition = (sequenceNumber, action, street, distance) => {
    return {
        header: function (withLogo) {
            return generatePDFHeader(withLogo);
        },
        content: [
            {
                table: {
                    headerRows: 1,
                    dontBreakRows: true,
                    widths: ['auto', 'auto', '*', 'auto'],
                    body: [
                        [
                            {
                                text: sequenceNumber,
                                bold: true,
                                fillColor: '#dedede',
                            },
                            {
                                text: action,
                                bold: true,
                                fillColor: '#dedede',
                            },
                            {
                                text: street,
                                bold: true,
                                fillColor: '#dedede',
                            },
                            {
                                text: distance,
                                bold: true,
                                fillColor: '#dedede',
                            },
                        ],
                    ],
                },
                margin: [0, 10, 0, 10], // left, top, right, bottom
            },
        ],
        footer: function (page, pages, pageFooterTitle) {
            pageFooterTitle = 'mTransport';
            return generatePDFooter(page, pages, pageFooterTitle);
        },
    };
};

/**
 * Generate Directions title and information above the table
 * @param {String} pageTitle
 * @param {Object[]} infos
 * @param {String} infos.title
 * @param {String} infos.text
 * @return {Object}
 */

export function generateTitle(pageTitle, infos) {
    const formating = [{ text: `${pageTitle}`, fontSize: 20, bold: true, alignment: 'center', margin: [0, 10, 0, 10] }];

    for (const info of infos) {
        // If the previous row of info is not completed, we are adding it to the row. If not, we are creating a new row.
        if (formating[formating.length - 1].columns && formating[formating.length - 1].columns.length < 2) {
            // Check if info is complete
            if (info.title != null && info.text != null) {
                formating[formating.length - 1].columns.push({
                    text: [{ text: `${info.title}:`, bold: true }, ` ${info.text}`],
                    width: '50%',
                });
            }
        } else {
            formating.push({
                columns: [
                    {
                        text: [{ text: `${info.title}:`, bold: true }, ` ${info.text}`],
                        width: '50%',
                    },
                ],
                fontSize: 12,
                columnGap: 15,
                margin: [0, 5, 0, 0],
            });
        }
    }

    return formating;
}
