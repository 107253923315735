// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('tripDashboardList', {
    templateUrl: './shared/layout/tripDashboardList/tripDashboardList.html',
    transclude: true,
    bindings: {
        trips: '<',
        showCarrier: '<',
        showDriver: '<',
        showProgress: '<',
        showStatus: '<',
        showStats: '<',
        showMoreIcons: '<',
        hideDetails: '<',
        showTooltip: '<',
        favouriteClickEvent: '=',
        isTripInLateSection: '<',
        displayBars: '<',
        customClass: '<',
        useGrid: '<',
        loadMoreCount: '<',
    },
    controller: function ($scope, $window) {
        const vm = this;

        // Load initial trips
        this.$onInit = function () {
            this.visibleTrips = [];

            // Default values for bindings
            this.showCarrier = this.showCarrier || false;
            this.showDriver = this.showDriver || false;
            this.showProgress = this.showProgress || false;
            this.showStatus = this.showStatus || false;
            this.showStats = this.showStats || false;
            this.hideDetails = this.hideDetails || false;
            this.showTooltip = this.showTooltip || false;
            this.showMoreIcons = this.showMoreIcons || false;
            this.isTripInLateSection = this.isTripInLateSection || false;
            this.loadMoreCount = this.loadMoreCount ?? 50;

            this.useGrid = this.useGrid ?? true;
            this.customClass = this.customClass || 'tv__grid__item';

            this.loadMoreTrips();
        };

        let initialRender = true;
        $scope.$watch(
            () => vm.trips,
            function () {
                if (initialRender === false) {
                    vm.visibleTrips = [];
                    vm.loadMoreTrips();
                }
                initialRender = false;
            },
            true
        );

        angular.element($window).on('scroll', () => {
            if (vm.visibleTrips.length === vm.trips.length || vm.loadingMore === true) {
                return;
            }
            const windowHeight = 'innerHeight' in $window ? $window.innerHeight : document.documentElement.offsetHeight;
            const bodyScrollTop = 'pageYOffset' in $window ? $window.pageYOffset : document.documentElement.scrollTop || document.body.scrollTop;
            const bodyScrollHeight = document.body.scrollHeight || document.documentElement.scrollHeight;

            const threshold = windowHeight * 0.8;

            // Calculate the distance from the bottom of the page
            const distanceToBottom = bodyScrollHeight - (bodyScrollTop + windowHeight);

            // Check if scrolled close to the bottom of the page (within the threshold)
            if (distanceToBottom <= threshold) {
                vm.loadingMore = true;
                this.loadMoreTrips();
            }
        });

        // Function to load more trips
        this.loadMoreTrips = function () {
            const currentLength = this.visibleTrips.length;
            const newTrips = this.trips.slice(currentLength, currentLength + this.loadMoreCount);
            this.visibleTrips = this.visibleTrips.concat(newTrips);

            // Reset the loading flag after loading is complete
            setTimeout(() => {
                vm.loadingMore = false;
            }, 500);
        };
    },
});
