const { images } = require('./constants');

/**
 * Generate pdf header
 *  @param {Boolean} withLogo //Display logo when used in params
 * @return {Object} return object contains document defintion of the pdf header (template)
 */

export function generatePDFHeader(withLogo) {
    let logo;
    if (withLogo) {
        logo = {
            image: images.mTransportLogo,
            width: 125,
            margin: [5, 5, 5, 5], // left, top, right, bottom
        };
    }
    return {
        columns: [
            logo,
            {
                width: '*',
                text: '',
                alignment: 'center',
            },
            {
                width: 100,
                text: [{ text: moment().format('LL'), fontSize: 8 }],
                alignment: 'right',
            },
        ],
        margin: [10, 10, 10, 10], // left, top, right, bottom
    };
}

/**
 * Generate pdf header
 *  @param {String} pageHeaderTitle //Display pageHeaderTitle when used in params
 *  @param {String} pageHeaderSubTitle //Display pageHeaderSubTitle when used in params
 * @return {Object} return object contains document defintion of the pdf header (template)
 */

export function generatePDFHeaderWithTitles(pageHeaderTitle, pageHeaderSubTitle) {
    let headerTitle;
    if (pageHeaderTitle != null && pageHeaderSubTitle != null) {
        headerTitle = {
            text: pageHeaderTitle + '\n' + pageHeaderSubTitle,
            style: {
                fontSize: 12,
                bold: true,
            },
        };
    }
    return {
        columns: [
            {
                width: '*',
                text: headerTitle,
                alignment: 'center',
                margin: [100, 0, 0, 0],
            },
            {
                width: 100,
                text: [{ text: moment().format('LL'), fontSize: 8 }],
                alignment: 'right',
            },
        ],
        margin: [10, 10, 10, 10], // left, top, right, bottom
    };
}

/**
 * Generate pdf footer
 * @param {Number} page //Current page
 * @param {Number} pages //Total Pages
 * @param {String} pageFooterTitle // Title of the footer
 * @return {Object} return object contains document defintion of the pdf footer (template)
 */

export function generatePDFooter(page, pages, pageFooterTitle) {
    return {
        columns: [
            {
                width: 90,
                text: '',
            },
            {
                width: '*',
                text: [{ text: `${pageFooterTitle}` }],
                alignment: 'center',
            },
            {
                width: 90,
                text: [{ text: page.toString() }, { text: ' / ', style: 'footer' }, { text: pages.toString() }],
                alignment: 'right',
            },
        ],
        margin: [10, 0, 10, 0], // left, top, right, bottom
    };
}
