// Factory + Model example : http://www.webdeveasy.com/angularjs-data-model/;
angular.module('mTransportApp').factory('TagsInputDataset', [
    function () {
        function TagsInputDataset(data) {
            // TODO:: creating data model in tag-input type would be easier
            this.initOrResetData();
            if (typeof data !== 'undefined') {
                this.setNewData(data);
            }
        }

        TagsInputDataset.prototype = {
            setNewData: function (data) {
                this.loading = true;
                this.initOrResetData();
                this.results = data;
                this.searchResults = this.objectDiffById(data, this.selectedResults);
                this.loading = false;
                this.searchInputValue = '';
            },
            initOrResetData: function () {
                this.displayResults = false;
                this.results = [];
                this.searchResults = [];
                this.selectedResults = [];
                this.loading = false;
                this.errors = [];
                this.selectAll = false;
            },
            copy: function (o) {
                let output, v, key;
                output = Array.isArray(o) ? [] : {};
                for (const key in o) {
                    v = o[key];
                    output[key] = typeof v === 'object' ? this.copy(v) : v;
                }
                return output;
            },
            objectDiffById: function (a, b) {
                let result = this.copy(a);
                let arr_to_substract = this.copy(b);
                for (const element of arr_to_substract) {
                    for (let j = 0; j < result.length; j++) {
                        if (result[j].id == element.id) {
                            result.splice(j, 1);
                        }
                    }
                }
                return result;
            },
            getIdArrayObj: function (key) {
                let arr = [];
                this.selectedResults.forEach(function (value) {
                    let obj = {};
                    obj[key] = value[key];
                    arr.push(obj);
                });
                return arr;
            },
            // Get available results matching array of ids
            setSelectionByIds: function (arr) {
                arr.forEach(
                    function (k, v) {
                        for (let i in this.results) {
                            if (this.results[i].id == k.id) {
                                this.addToSelectedResult(this.results[i]);
                            }
                        }
                    }.bind(this)
                );
            },
            selectAllFunc: function () {
                this.errors = [];
                this.searchResults = [];
                this.selectedResults = angular.copy(this.results);
            },
            resetSelectedResults: function () {
                this.errors = [];
                this.searchResults = angular.copy(this.results);
                this.selectedResults = [];
                this.selectAll = false;
            },
            hideResults: function () {
                this.displayResults = false;
            },
            showResults: function () {
                this.displayResults = true;
            },
            isAllSelected: function () {
                this.selectAll = this.selectedResults.length === this.results.length;
                return this.selectAll;
            },
            addToSelectedResult: function (data) {
                this.errors = [];
                // Prevent duplicates before refiltering of search results
                if (this.selectedResults.indexOf(data) === -1) {
                    // Push data to search results
                    this.selectedResults.push(data);
                }
                this.refreshSearchResults();
                this.isAllSelected();
            },
            removeLastElementFromSelectedResults: function () {
                this.errors = [];
                this.selectedResults.pop();
                this.refreshSearchResults();
                this.isAllSelected();
            },
            setSearchInputValue: function (str) {
                this.searchInputValue = str;
            },
            refreshSearchResults: function () {
                let filtered_array = this.searchValueInDataset(this.searchInputValue);
                this.searchResults = this.objectDiffById(filtered_array, this.selectedResults);
            },
            setSelectedResults: function (data) {
                this.errors = [];
                this.selectedResults = data;
                this.refreshSearchResults();
            },
            removeFromSelectedResults: function (data) {
                let selected_results = this.selectedResults;
                for (let i = 0; i < selected_results.length; i++) {
                    if (data.id === selected_results[i].id) {
                        selected_results.splice(i, 1);
                    }
                }
                this.refreshSearchResults();
                this.isAllSelected();
            },
            searchInString: function (str, value) {
                return value == '' || str.toUpperCase().indexOf(value.toUpperCase()) != -1;
            },
            searchValueInDataset: function (value) {
                if (value === '') {
                    return this.results;
                }
                let filtered_dataset = [];
                // Filter search results
                for (const element of this.results) {
                    if (this.searchInString(element.name, value)) {
                        filtered_dataset.push(element);
                    }
                }
                // Remove selected items from search data
                return filtered_dataset;
            },
        };

        return TagsInputDataset;
    },
]);
