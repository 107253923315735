app.controller('ErrorMessagesController', [
    '$scope',
    '$timeout',
    '$location',
    function ($scope, $timeout, $location) {
        /**
         *
         * Usage : put <load-error-messages notifType="[type]"></load-error-messages> in html template.
         * To display notification, call $rootScope.$broadcast('request-error-'+[type], error);, where error
         * is the error object returned by ajax
         *
         * To hide a notification, use $rootScope.$broadcast('request-error-'+[type], {status: 'hide'});
         */

        $scope.domID = '';
        $scope.type = '';
        $scope.unknownError = false;
        $scope.errorMessageKey = 'unknownErrorMessage';

        $scope.initializeErrorMessages = function () {};

        $scope.launchAjaxError = function (error) {
            //Server errors
            $scope.triggerNotification($scope.domID);
        };

        $scope.setNotifType = function (type) {
            $scope.type = type;
            // Used to remove event listener if duplicated
            if (typeof $scope.deregisterListener != 'undefined') {
                $scope.deregisterListener();
            }
            // Listen to error events
            $scope.deregisterListener = $scope.$on('request-error-' + $scope.type, function (e, error) {
                switch (error.status) {
                    case 'hide':
                        $scope.unknownError = false;
                        break;
                    case 401:
                        // checking if we are requesting a page
                        // length <= 1 is the root page
                        if ($location.url().length > 1 && (!$location.url().includes('continue=') || $location.url().includes('/login?'))) {
                            // if location.url already has /login? this means we already went through the process of manipulating the redirect route.
                            if ($location.url().includes('/login?')) {
                                return;
                            }
                            const redirect = $location.url();
                            $scope.logout(`/login?continue=${redirect}`);
                        } else {
                            $scope.logout();
                        }
                        break;
                    default:
                        $scope.showUnknownError(error);
                        break;
                }
            });
        };

        $scope.setDomID = function (id) {
            $scope.domID = id;
        };

        $scope.setErrorMessageKey = function (key) {
            if (key != null) {
                $scope.errorMessageKey = key;
            }
        };

        $scope.showUnknownError = function (error) {
            $scope.unknownError = true;
            if (error.hasOwnProperty('key')) {
                $scope.errorMessageKey = error.key;
            } else {
                $scope.errorMessageKey = 'unknownErrorMessage';
            }
            // Apply scope to prevent loader hiding before error message is displayed
            $timeout(function () {
                $scope.$apply();
            });
            //Hides loader associated with notification type
            let loaderElement = document.getElementById('loading-' + $scope.type);
            if (loaderElement) {
                loaderElement.style.display = 'none';
            }
        };

        $scope.getClass = function () {
            return 'error-message';
        };
    },
]);
