/**
 * Component: panelHeader
 * Html tags: <panel-header>
 * @param {Boolean} loading
 * @param {Boolean} sorting
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('panelHeader', {
    templateUrl: './shared/panelHeader/panelHeader.html',
    transclude: {
        icon: '?icon',
        spinnerIcon: '?spinnerIcon',
        titleText: 'titleText',
        simplifiedView: '?simplifiedView',
        favorites: '?favorites',
        filters: '?filters',
        searchBar: '?searchBar',
        csvButton: '?csvButton',
        pdfButton: '?pdfButton',
        button: '?button',
        a: '?a',
        embeddedButton: '?embeddedButton',
        plusButton: '?plusButton',
        favorite: '?favorite',
        watcher: '?watcher',
        batteryGauge: '?batteryGauge',
        batchSettings: '?batchSettings',
        dateNavigationButtons: '?dateNavigationButtons',
    },
    bindings: {
        loading: '<',
        sorting: '<',
        pdfBeforeButtons: '<?',
        datePickerConfig: '<?',
    },
    controller: function () {
        this.$onInit = () => {
            this.loading = this.loading ?? false;
            this.sorting = this.sorting ?? false;
            this.pdfBeforeButtons = this.pdfBeforeButtons ?? false;
            this.datePickerConfig = this.datePickerConfig ?? { isEnabled: false };
        };

        this.$postLink = () => {
            if (this.datePickerConfig.isEnabled) {
                const { startDate, minDate, onDatePickerChange } = this.datePickerConfig;

                $('#panel-header__calendar-button').daterangepicker(
                    {
                        singleDatePicker: true,
                        opens: 'center',
                        autoApply: true,
                        minDate: minDate,
                        startDate: startDate,
                    },
                    (newSelectedDate) => {
                        onDatePickerChange(newSelectedDate);
                    }
                );
            }
        };
    },
});
