/**
 * @todo Remove tools and components from main.js and loads them from mTransportApp.js route when needs.
 * @todo Test.
 */

/**
 * Config
 */
import './config/config.js';

/**
 * Backend services
 */
import './shared/api/AjaxRequest.js';

/**
 * Components Controllers
 */
import './components/students/StudentsController.js';
import './components/students/discipline/DisciplineController.js';
import './components/routes/RoutesController.js';
import './components/trips/TripInfoController.js';
import './components/profile/ProfileController.js';
import './components/dashboard/mainMap/MainMapController.js';
import './components/reports/ReportsController.js';
import './components/resources/ResourcesController.js';
import './components/resources/faq/faqController.js';
import './components/feedback/FeedbackController.js';
import './components/admin/feedbacks/FeedbacksController.js';
import './components/admin/users/AdminUserController.js';
import './components/admin/clients/ClientsController.js';
import './components/admin/clients/InstitutionsController.js';
import './components/admin/carriers/CarriersController.js';
import './components/admin/AdminDashboardController.js';
import './components/dashboard/tv/TvController.js';
import './components/routes/assignment/AssignmentController.js';
import './components/drivers/DriversController.js';
import './components/drivers/DriversInfoController.js';

/**
 * Shared Tools controllers
 */
import './shared/tools/FavouriteController.js';
import './shared/tools/ToolsController.js';
import './shared/tools/AnalyticsController.js';
import './shared/tools/translations/MultiLanguage.js';
import './shared/tools/GetFormCount.js';
import './shared/tools/GetUnassignedRouteCount.js';
import './shared/map/GlobalMapController.js';
import './shared/tools/SortingTools.js';
import './shared/layout/error-messages/ErrorMessages.js';
import './shared/tools/LayoutManipulationTools.js';
import './shared/navigation/NavigationController.js';
import './shared/layout/tags-input/models/TagsInputDataset.js';
import './shared/layout/tags-input/TagsInputController.js';
import './shared/tools/helpers/AssignmentsManager.js';
import './shared/map/helpers/MapManager.js';
import './shared/tools/helpers/dateComparator.js';
import './shared/tools/helpers/announcementHelpers.js';
import './shared/tools/helpers/passwordValidation.js';
import './shared/tools/helpers/urlValidation.js';

/**
 * Ui Components
 */
import './shared/circleProgression/circleProgression.component.js';
import './shared/layout/collapsableSection/collapsableSection.component.js';
import './shared/layout/tripDashboardList/tripDashboardList.component.js';
import './shared/layout/tripDashboardView/tripDashboardView.component.js';
import './shared/layout/passwordRequirementsView/passwordRequirementsView.component.js';
import './shared/iconToggle/iconToggle.component.js';
import './shared/toggleConfirmation/toggleConfirmation.component.js';
import './shared/tableHead/tableHead.component.js';
import './shared/panelHeader/panelHeader.component.js';
import './shared/panelFooter/panelFooter.component.js';
import './shared/csvButton/csvButton.component.js';
import './shared/validationCode/validationCode.component.js';
import './shared/modal/modal.component.js';
import './shared/filters/filters.component.js';
import './shared/btnGroupToggle/btnGroupToggle.component.js';
import './shared/loadingDots/loadingDots.component.js';
import './shared/radioToggleNotifications/radioToggleNotifications.component.js';
import './shared/radioToggleNotes/radioToggleNotes.component.js';
import './shared/autoCompleteInput/autoCompleteInput.component.js';
import './shared/btnAdminReply/btnAdminReply.component.js';
import './shared/statusBox/statusBox.component.js';
import './shared/stopArrow/stopArrow.component.js';
import './shared/stopArrow/stopInstitutionArrow.component.js';
import './shared/selectInput/selectInput.component.js';
import './shared/selectDropdown/selectDropdown.component.js';

/**
 * PDF
 */
import './pdf/directions/PDFDirectionController.js';
