angular.module('mTransportApp').controller('InstitutionsController', [
    '$scope',
    '$timeout',
    '$controller',
    'sortingTools',
    '$routeParams',
    '$translate',
    function ($scope, $timeout, $controller, sortingTools, $routeParams, $translate) {
        angular.extend(this, $controller('ToolsController', { $scope: $scope }));

        /* =======================================================================================================================
       INITIALISATION
    =======================================================================================================================  */

        $scope.initializeInstitutions = function () {
            $scope.loading = true;
            const clientID = $routeParams.clientID;
            $scope.institutionsForClient = [];

            // Sort institutions by default
            $scope.orderType = {
                orderBy: 'institution',
                ascending: true,
            };

            $scope.searchValue = {
                value: '',
            };

            $scope.allInspectionsSave = $scope.institutionsForClient;

            $scope.requestAdminGetClient(clientID).then(
                function (answer) {
                    $scope.client = answer;
                    $scope.fetchInstitutions(clientID);
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /* =======================================================================================================================
       DATA FETCH
    =======================================================================================================================  */

        /**
         * Fetch all Institutions of a specific client
         * @param {String} clientID
         */
        $scope.fetchInstitutions = function (clientID) {
            $scope.requestAdminGetInstitutionsForClient(clientID).then(
                function (answer) {
                    let defaultInstitution;
                    const institutions = answer.data.institutions;

                    // Store default institution if it exists
                    const index = institutions.findIndex(function (institution) {
                        return institution.internalId === 'default';
                    });

                    if (index !== -1) {
                        defaultInstitution = institutions.splice(index, 1)[0];
                    }

                    $scope.institutionsForClient = sortingTools.sortInstitutions(institutions);

                    // Put default institution at beginning of the array if it exists
                    // ChildInstitutions are not displayed in case of default institution
                    if (defaultInstitution != null) {
                        defaultInstitution.childInstitutions = [];
                        $scope.institutionsForClient.unshift(defaultInstitution);
                    }

                    // Format the institution
                    $scope.institutionsForClient = $scope.institutionsForClient.map((institution) => {
                        return formatInstitution(institution);
                    });

                    $scope.allInstitutions = $scope.institutionsForClient;

                    $scope.loading = false;
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        // Format the institution and its child institutions
        const formatInstitution = function (institution) {
            const formattedInstitution = {
                ...institution,
                displayedName: `${institution.name} (id ${institution.internalId})`,
                childInstitutionsName: institution.childInstitutions
                    .map((childInstitution) => `${childInstitution.name} (id ${childInstitution.internalId})`)
                    .join(', '),
            };

            return formattedInstitution;
        };

        /* =======================================================================================================================
    	FILTERS AND SEARCH + SORTING FOR INSTITUTIONS
	=======================================================================================================================  */
        $scope.applyFilters = function () {
            // Used new Set() to get only unique values in institutionsToShow
            const institutionsToShow = new Set();

            for (const institution of $scope.allInstitutions) {
                if (isInstitutionInFilter(institution)) {
                    institutionsToShow.add(institution);
                }
            }

            $scope.institutionsForClient = institutionsToShow;

            $scope.sortInstitutionsByFields();

            $timeout(function () {
                $scope.$apply();
            });
        };

        /**
         * Determines if an institution passes the specified filters
         *
         * @param {Object} institution - The institution object to be filtered.
         * @return {boolean} - Returns true if the institution passes the filters, otherwise false.
         */
        function isInstitutionInFilter(institution) {
            const dataToCompare = {
                institutionName: institution.displayedName,
                childInstitutionsName: institution.childInstitutionsName,
            };

            return $scope.containsSearchValue(dataToCompare, $scope.searchValue.value);
        }

        /**
         * Sort the institutions by calling the sortInstitutions inside the sortingTools controller
         * @param {Array} institutions the array to be sorted
         * @return {Array} sorted array
         */
        function sortInstitutions(institutions) {
            return sortingTools.sortInstitutions(institutions, $scope.orderType.orderBy, $scope.orderType.ascending);
        }

        /**
         * Defines which fields the institutionsForClient array should be sorted by
         * Then sorts a copy of $scope.institutionsForClient
         * Then loads sorted institutions into $scope.institutionsForClient
         * @param {string} orderBy the fields the array should be sorted by
         */
        $scope.sortInstitutionsByFields = function (orderBy) {
            // Makes sure the user is notified that the sorting is currently being run and that filter is disabled while doing so
            $scope.sorting = true;
            $scope.registeringDisplayFunctions = true;
            if (orderBy) {
                if ($scope.orderType.orderBy === orderBy) {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: !$scope.orderType.ascending,
                    };
                } else {
                    $scope.orderType = {
                        orderBy: orderBy,
                        ascending: true,
                    };
                }
            }

            const sortedInstitutions = sortInstitutions([...$scope.institutionsForClient]);
            $scope.institutionsForClient = sortedInstitutions;

            // Sorting glyph hidden and filter field enabled
            $scope.sorting = false;
            $scope.registeringDisplayFunctions = false;
        };

        /* =======================================================================================================================
            [CSV : GENERATION FUNCTION]
        =======================================================================================================================  */
        /* Institutions List Generation as csv */
        $scope.generateGenericCSV = function () {
            const institutionsList = $scope.institutionsForClient;
            // verify if institutionsList is empty
            if (!institutionsList) {
                return;
            }

            const config = {
                header: true,
                quotes: true,
            };

            const header = [
                'ID',
                $translate.instant('institution'),
                $translate.instant('internalId'),
                $translate.instant('childInstitution'),
                $translate.instant('agentsNumber'),
                $translate.instant('observersNumber'),
            ];

            // Iterate through institutionsList and push them to CSV
            const csvRows = institutionsList.map((institution) => [
                institution.id,
                institution.name,
                institution.internalId,
                institution.childInstitutionsName,
                institution.agentCount,
                institution.observerCount,
            ]);

            // eslint-disable-next-line no-undef
            const csv = Papa.unparse(
                {
                    fields: header,
                    data: csvRows,
                },
                config
            );

            // eslint-disable-next-line no-undef
            const today = moment().format('YYYY-MM-DD');
            // Create the filename based on client name and date
            const filename = `mTransport - ${$scope.client.name} - Institutions - ${today}.csv`;
            $scope.downloadCSV(csv, filename);
        };
    },
]);
