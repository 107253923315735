const { STATUS, ASSIGNEES } = require('../utils/constants');

// eslint-disable-next-line no-undef
angular.module('mTransportApp').controller('FeedbacksController', [
    '$scope',
    '$translate',
    function ($scope, $translate) {
        /* =======================================================================================================================
        INITIALIZATION
     =======================================================================================================================  */
        $scope.initializeFeedbacks = function () {
            $scope.loading = true;
            $scope.feedbacks = [];
            $scope.statusOptions = Object.values(STATUS);
            $scope.assigneeOptions = Object.values(ASSIGNEES);
            // Initializing filters sections
            $scope.filterSections = [
                {
                    id: 'status',
                    name: $translate.instant('status'),
                    options: [],
                },
                {
                    id: 'assignedTo',
                    name: $translate.instant('assignments'),
                    options: [],
                },
            ];
            // Adding status options in filters sections
            $scope.statusOptions.forEach((status) => {
                $scope.filterSections
                    .find((section) => section.id == 'status')
                    .options.push({
                        id: status,
                        name: $translate.instant(status),
                        value: status == 'processed' ? false : true,
                    });
            });
            // Adding assignee options in filters sections
            // Sort assignee filters [A-Z] without mutating assigneeOptions by using toSorted()
            $scope.assigneeOptions.toSorted().forEach((assignee) => {
                $scope.filterSections
                    .find((section) => section.id == 'assignedTo')
                    .options.push({
                        id: assignee,
                        name: assignee == 'unassigned' ? $translate.instant(assignee) : `${$translate.instant('assignedTo')} ${assignee}`,
                        value: true,
                    });
            });

            $scope.fetchAllFeedbacks();
        };

        $scope.fetchAllFeedbacks = function () {
            $scope.requestAdminGetAllFeedback().then(
                function (answer) {
                    $scope.defaultFeedbacks = answer.feedbacks.map((feedback) => ({
                        ...feedback,
                        clientsDisplay: feedback.clients?.map((client) => client.name).join(', '),
                        statusDropdownOpen: false,
                        assigneeDropdownOpen: false,
                    }));
                    $scope.feedbacks = angular.copy($scope.defaultFeedbacks);
                    $scope.filterFeedbacks();
                    $scope.loading = false;
                    $scope.$apply();
                },
                function (error) {
                    $scope.catchErrorDefault(error);
                }
            );
        };

        /**
         * Apply the filters to the feedbacks
         */
        $scope.filterFeedbacks = () => {
            // Start filtering by status and by assignee
            $scope.feedbacks = $scope.defaultFeedbacks.filter((feedback) => isFeedbackInFilters(feedback));
        };

        const isFeedbackInFilters = function (feedback) {
            const statusSection = $scope.filterSections.find((section) => section.id === 'status');
            const assigneeSection = $scope.filterSections.find((section) => section.id === 'assignedTo');

            const statusFilter = statusSection.options.filter((option) => option.value === true);
            const assigneeFilter = assigneeSection.options.filter((option) => option.value === true);
            if (statusFilter.find((option) => option.id === feedback.status) && assigneeFilter.find((option) => option.id === feedback.assignedTo)) {
                return true;
            } else {
                return false;
            }
        };

        /* =======================================================================================================================
        LAYOUT AND DATA MANIPULATION
     =======================================================================================================================  */
        $scope.formatDate = function (date) {
            return moment(date).format('LLL');
        };

        // Change and patch feedback's property (status, assignedTo) on event
        $scope.$on('selectDropdown', (event, payload) => {
            const body = {};
            body[payload.property] = payload.value;
            $scope.requestAdminUpdateFeedback(payload.entity, body);
        });

        $scope.$on('collapseDropdowns', () => {
            $scope.feedbacks.forEach((feedback) => {
                feedback.statusDropdownOpen = false;
                feedback.assigneeDropdownOpen = false;
            });
        });
    },
]);
