/**
 * Checks if a string begins with search value.
 * @param {String} searchValue - Search value
 * @param {String} compareTo - String to check if it begins with search value or has search value after a whitespace or hyphen
 * @return {Boolean} Returns true if beginning of string matches search value
 */
function startingWith(searchValue, compareTo) {
    const startingWithRegex = new RegExp(`(^|-|\\s)${searchValue}`, 'i');
    return startingWithRegex.test(compareTo);
}

module.exports = { startingWith };
