/**
 * Extracts the name from the given URL.
 * @param {string} url - The URL to extract the name from.
 * @return {string} The extracted name.
 */
function getUrlName(url) {
    // Extract the path from the URL
    let name = url.split('?')[0];
    // Regex that matches a valid MongoDB ObjectID (24 hexadecimal characters)
    const idRegex = /\b[a-f\d]{24}\b/gi;

    // Extract the id if it exists and replace it by :id (example here : /carriers/6633d945c5cd59300df3df9e/users => carriers/:id/users)
    name = name.replace(idRegex, ':id');

    // Remove the first character ('/') if present
    return name.startsWith('/') ? name.substring(1) : name;
}

module.exports = { getUrlName };
