// eslint-disable-next-line no-undef
angular.module('mTransportApp').controller('faqController', [
    '$scope',
    '$rootScope',
    function ($scope, $rootScope) {
        // eslint-disable-next-line no-undef
        $scope.currentLang = localStorage.getItem('userLanguage');

        const sections = [
            {
                key: 'guardians',
                name: 'guardians',
                faqResources: [
                    {
                        key: 'faq_guardians_registerNotifications',
                        question: 'faq_guardians_registerNotifications_Q',
                        answerParagraph1: 'faq_guardians_registerNotifications_A',
                        roles: ['manager', 'agent', 'observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_guardians_typeOfNotifications',
                        question: 'faq_guardians_typeOfNotifications_Q',
                        answerParagraph1: 'faq_guardians_typeOfNotifications_A_paragraph1',
                        answerLine1: 'faq_guardians_typeOfNotifications_A_paragraph1_line1',
                        answerLine2: 'faq_guardians_typeOfNotifications_A_paragraph1_line2',
                        answerParagraph2: 'faq_guardians_typeOfNotifications_A_paragraph2',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        answersWithIcon: {
                            answersWithEyeIcon_boldText: 'faq_guardians_typeOfNotifications_A_icon_eye_boldText',
                            answersWithEyeIcon: 'faq_guardians_typeOfNotifications_A_icon_eye',
                            answersWithVehiculeIcon_boldText: 'faq_guardians_typeOfNotifications_A_icon_vehicle_boldText',
                            answersWithVehiculeIcon: 'faq_guardians_typeOfNotifications_A_icon_vehicle',
                            answersWithIconVehiculeAndStudentsIcon_boldText: 'faq_guardians_typeOfNotifications_A_icon_vehicleAndStudent_boldText',
                            answersWithIconVehiculeAndStudentsIcon: 'faq_guardians_typeOfNotifications_A_icon_vehicleAndStudent',
                            answerParagraph3: 'faq_guardians_typeOfNotifications_A_paragraph3',
                            answerParagraph4: 'faq_guardians_typeOfNotifications_A_paragraph4',
                        },
                        style: 'icons',
                        type: 'notificationIcons',
                    },
                    {
                        key: 'faq_guardians_automaticDelayNotifications',
                        question: 'faq_guardians_automaticDelayNotifications_Q',
                        answerParagraph1: 'faq_guardians_automaticDelayNotifications_A_paragraph1',
                        answerParagraph2: 'faq_guardians_automaticDelayNotifications_A_paragraph2',
                        answerParagraph3: 'faq_guardians_automaticDelayNotifications_A_paragraph3',
                        answerWithWatchIcon: 'faq_guardians_automaticDelayNotifications_A_watch_icon',
                        answerParagraph4: 'faq_guardians_automaticDelayNotifications_A_paragraph4',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'icons',
                    },
                    {
                        key: 'faq_guardians_notificationsBusNotLate',
                        question: 'faq_guardians_notificationsBusNotLate_Q',
                        answerParagraph1: 'faq_guardians_notificationsBusNotLate_A_paragraph1',
                        answerParagraph2: 'faq_guardians_notificationsBusNotLate_A_paragraph2',
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_guardians_notificationsWhenTransportIsCancelled',
                        question: 'faq_guardians_notificationsWhenTransportIsCancelled_Q',
                        answerParagraph1: 'faq_guardians_notificationsWhenTransportIsCancelled_A_paragraph1',
                        answerLines: {
                            answerLine1: 'faq_guardians_notificationsWhenTransportIsCancelled_A_paragraph1_line1',
                            answerLine2: 'faq_guardians_notificationsWhenTransportIsCancelled_A_paragraph1_line2',
                            answerLine3: 'faq_guardians_notificationsWhenTransportIsCancelled_A_paragraph1_line3',
                        },
                        pictureCancelledBus: {
                            en: '../../assets/img/ui/faq/faq_guardians_notificationsWhenTransportIsCancelled_EN.png',
                            fr: '../../assets/img/ui/faq/faq_guardians_notificationsWhenTransportIsCancelled_FR.png',
                        },
                        roles: ['manager', 'agent'],
                        style: 'picture',
                        type: 'cancelledBus',
                    },
                    {
                        key: 'faq_guardians_erroneousNotifications',
                        question: 'faq_guardians_erroneousNotifications_Q',
                        answerParagraph1: 'faq_guardians_erroneousNotifications_A_paragraph1',
                        answerParagraph2: 'faq_guardians_erroneousNotifications_A_paragraph2',
                        pictureCommunicationSettings: {
                            en: '../../assets/img/ui/faq/faq_guardians_erroneousNotifications_EN.jpg',
                            fr: '../../assets/img/ui/faq/faq_guardians_erroneousNotifications_FR.jpg',
                        },
                        answerParagraph3: 'faq_guardians_erroneousNotifications_A_paragraph3',
                        roles: ['manager', 'agent'],
                        style: 'picture',
                        type: 'erroneousNotifications',
                    },
                    {
                        key: 'faq_guardians_messages',
                        question: 'faq_guardians_messages_Q',
                        answerParagraph1: 'faq_guardians_messages_A_paragraph1',
                        answerParagraph2: 'faq_guardians_messages_A_paragraph2',
                        answerParagraph3: 'faq_guardians_messages_A_paragraph3',
                        answerLines: {
                            answerLine1: 'faq_guardians_messages_A_paragraph3_line1',
                            answerLine2: 'faq_guardians_messages_A_paragraph3_line2',
                        },
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                ],
            },
            {
                key: 'dashboard',
                name: 'navDashboard',
                faqResources: [
                    {
                        key: 'faq_dashboard_redOrOrangeTrips',
                        question: 'faq_dashboard_redOrOrangeTrips_Q',
                        answerParagraph1: 'faq_dashboard_redOrOrangeTrips_A_paragraph1',
                        redOrOrangeTripsLine1: 'faq_dashboard_redOrOrangeTrips_A_line1',
                        redOrOrangeTripsLine2: 'faq_dashboard_redOrOrangeTrips_A_line2',
                        redOrOrangeTripsLine3: 'faq_dashboard_redOrOrangeTrips_A_line3',
                        redOrOrangeTripsLine4: 'faq_dashboard_redOrOrangeTrips_A_line4',
                        redOrOrangeTripsLine5: 'faq_dashboard_redOrOrangeTrips_A_line5',
                        answerParagraph2: 'faq_dashboard_redOrOrangeTrips_A_paragraph2',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'lines',
                    },
                    {
                        key: 'faq_dashboard_tripsIcons',
                        question: 'faq_dashboard_tripsIcons_Q',
                        answerParagraph1: 'faq_dashboard_tripsIcons_A',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        answersWithIcon: {
                            answersWithLateIcon: 'faq_dashboard_tripsIcons_A_icon_late',
                            answersWithLostConnectionIcon: 'faq_dashboard_tripsIcons_A_icon_lostConnection',
                            answersWithDropOffIcon: 'faq_dashboard_tripsIcons_A_icon_dropOff',
                            answerWithIconLowBattery: 'faq_dashboard_tripsIcons_A_icon_lowBattery',
                            answerWithUnknownStudentIcon: 'faq_dashboard_tripsIcons_A_icon_cardWrongVehicle',
                        },
                        style: 'icons',
                        type: 'tripIcons',
                    },
                    {
                        key: 'faq_dashboard_wontCompleteMean',
                        question: 'faq_dashboard_wontCompleteMean_Q',
                        answerParagraph1: 'faq_dashboard_wontCompleteMean_A_paragraph1',
                        answerParagraph2: 'faq_dashboard_wontCompleteMean_A_paragraph2',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_dashboard_displayATrip',
                        question: 'faq_dashboard_displayATrip_Q',
                        answerParagraph1: 'faq_dashboard_displayATrip_A_paragraph1_Start',
                        answerParagraph1BoldText: 'faq_dashboard_displayATrip_A_paragraph1_boldText',
                        answerParagraph1End: 'faq_dashboard_displayATrip_A_paragraph1_End',
                        answerParagraph2: 'faq_dashboard_displayATrip_A_paragraph2',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                ],
            },
            {
                key: 'generalMap',
                name: 'generalMap',
                faqResources: [
                    {
                        key: 'faq_generalMap_whatDifferentVehicleIconsRepresent',
                        question: 'faq_generalMap_whatDifferentVehicleIconsRepresent_Q',
                        answerParagraph1: 'faq_generalMap_whatDifferentVehicleIconsRepresent_A_paragraph1',
                        answersWithIcon: {
                            answersWithBusIcon: 'faq_generalMap_vehicleIcon_A_icon_bus',
                            answersWithMinibusIcon: 'faq_generalMap_vehicleIcon_A_icon_minibus',
                            answersWithMinivanIcon: 'faq_generalMap_vehicleIcon_A_icon_minivan',
                        },
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'icons',
                        type: 'vehicleIcons',
                    },
                ],
            },

            {
                key: 'runsSettings',
                name: 'runsSettings',
                faqResources: [
                    {
                        key: 'faq_runsList_whoCanChangeSettings',
                        question: 'faq_runsList_whoCanChangeSettings_Q',
                        answerParagraph1: 'faq_runsList_whoCanChangeSettings_A_paragraph1',
                        answerParagraph2: 'faq_runsList_whoCanChangeSettings_A_paragraph2',
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                ],
            },
            {
                key: 'tripMap',
                name: 'tripMap',
                faqResources: [
                    {
                        key: 'faq_tripMap_markersInMap',
                        question: 'faq_tripMap_markersInMap_Q',
                        answerParagraph1: 'faq_tripMap_markersInMap_A_paragraph1',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        answersWithIcon: {
                            answersWithSquareIcon: 'faq_tripMap_markersInMap_A_icon_square',
                            answersWithCircleIcon: 'faq_tripMap_markersInMap_A_icon_circle',
                            answersWithHexagonIcon: 'faq_tripMap_markersInMap_A_icon_hexagon',
                            answersWithCircleWhiteIconWithNumber: 'faq_tripMap_markersInMap_A_icon_circleWhite',
                            answersWithCircleRedIcon: 'faq_tripMap_markersInMap_A_icon_circleRed',
                            answersWithCircleEmptyIcon: 'faq_tripMap_markersInMap_A_icon_circleEmpty',
                        },
                        answerParagraph2: 'faq_tripMap_markersInMap_A_paragraph2',
                        answerParagraph3: 'faq_tripMap_markersInMap_A_paragraph3',
                        style: 'icons',
                        type: 'formsIcons',
                    },
                    {
                        key: 'faq_tripMap_circleRed',
                        question: 'faq_tripMap_circleRed_Q',
                        answerParagraph1: 'faq_tripMap_circleRed_A_paragraph1',
                        answerParagraph2: 'faq_tripMap_circleRed_A_paragraph2',
                        answerParagraph3: 'faq_tripMap_circleRed_A_paragraph3',
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_tripMap_pinkLine',
                        question: 'faq_tripMap_pinkLine_Q',
                        answerParagraph1: 'faq_tripMap_pinkLine_A_paragraph1',
                        answerParagraph2: 'faq_tripMap_pinkLine_A_paragraph2',
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_tripMap_dottedLine',
                        question: 'faq_tripMap_dottedLine_Q',
                        answerParagraph1: 'faq_tripMap_dottedLine_A_paragraph1',
                        answerParagraph2: 'faq_tripMap_dottedLine_A_paragraph2',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_tripMap_redLineWider',
                        question: 'faq_tripMap_redLineWider_Q',
                        answerParagraph1: 'faq_tripMap_redLineWider_A_paragraph1',
                        answerParagraph2: 'faq_tripMap_redLineWider_A_paragraph2',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_tripMap_busSpeed',
                        question: 'faq_tripMap_busSpeed_Q',
                        answerParagraph1: 'faq_tripMap_busSpeed_A_paragraph1',
                        answerParagraph2: 'faq_tripMap_busSpeed_A_paragraph2',
                        speedTiers: [
                            { color: '#3D9CE6', desc: `faq_tripMap_busSpeed_A_blueSpeed` },
                            { color: '#00AC41', desc: 'faq_tripMap_busSpeed_A_greenSpeed' },
                            { color: '#DEF209', desc: 'faq_tripMap_busSpeed_A_greenYellowSpeed' },
                            { color: '#FFF10E', desc: 'faq_tripMap_busSpeed_A_yellowSpeed' },
                            { color: '#FAB007', desc: 'faq_tripMap_busSpeed_A_orangeSpeed' },
                            { color: '#EA3203', desc: 'faq_tripMap_busSpeed_A_redSpeed' },
                            { color: '#B02A08', desc: 'faq_tripMap_busSpeed_A_darkRedSpeed' },
                        ],
                        pictureSpeedDots: {
                            en: '../../assets/img/ui/faq/faq_tripMap_busSpeed_EN.png',
                            fr: '../../assets/img/ui/faq/faq_tripMap_busSpeed_FR.png',
                        },
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'picture',
                        type: 'speedDots',
                    },
                    {
                        key: 'faq_tripMap_skip_stop',
                        question: 'faq_tripMap_skip_stop_Q',
                        answerParagraph1: 'faq_tripMap_skip_stop_A_paragraph1',
                        answerParagraph2BoldText: 'faq_tripMap_skip_stop_A_paragraph2bold',
                        answerParagraph3: 'faq_tripMap_skip_stop_A_paragraph3',
                        answerLinesOrdered: {
                            answerLine1: 'faq_tripMap_skip_stop_A_paragraph3_line1',
                            answerLine2: 'faq_tripMap_skip_stop_A_paragraph3_line2',
                        },
                        answerParagraph4: 'faq_tripMap_skip_stop_A_paragraph4',
                        answerParagraph5BoldText: 'faq_tripMap_skip_stop_A_paragraph5bold',
                        answerParagraph6: 'faq_tripMap_skip_stop_A_paragraph6',
                        answerParagraph7: 'faq_tripMap_skip_stop_A_paragraph7',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_tripMap_locate_device',
                        question: 'faq_tripMap_locate_device_Q',
                        answerParagraph1: 'faq_tripMap_locate_device_A_paragraph1',
                        answerParagraph2: 'faq_tripMap_locate_device_A_paragraph2',
                        answerParagraph3: 'faq_tripMap_locate_device_A_paragraph3',
                        answerParagraph4: 'faq_tripMap_locate_device_A_paragraph4',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                ],
            },

            {
                key: 'driversInformation',
                name: 'driversInformation',
                faqResources: [
                    {
                        key: 'faq_informationToDrivers_displayNoteToDriver',
                        question: 'faq_informationToDrivers_displayNoteToDriver_Q',
                        answerParagraph1: 'faq_informationToDrivers_displayNoteToDriver_A',
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_informationToDrivers_studentsNotes',
                        question: 'faq_informationToDrivers_studentsNotes_Q',
                        answerParagraph1: 'faq_informationToDrivers_studentsNotes_A',
                        specialConditionTitle: 'faq_informationToDrivers_studentsNotes_A_specialCondition_title',
                        specialConditionLine1: 'faq_informationToDrivers_studentsNotes_A_specialCondition_line1',
                        specialConditionLine2: 'faq_informationToDrivers_studentsNotes_A_specialCondition_line2',
                        specialConditionLine3: 'faq_informationToDrivers_studentsNotes_A_specialCondition_line3',
                        studentStopNoteTitle: 'faq_informationToDrivers_studentsNotes_A_studentStopNote_title',
                        studentStopNoteLine1: 'faq_informationToDrivers_studentsNotes_A_studentStopNote_line1',
                        studentStopNoteLine2: 'faq_informationToDrivers_studentsNotes_A_studentStopNote_line2',
                        studentStopNoteLine3: 'faq_informationToDrivers_studentsNotes_A_studentStopNote_line3',
                        studentRouteNoteTitle: 'faq_informationToDrivers_studentsNotes_A_studentRunNote_title',
                        studentRouteNoteLine1: 'faq_informationToDrivers_studentsNotes_A_studentRunNote_line1',
                        studentRouteNoteLine2: 'faq_informationToDrivers_studentsNotes_A_studentRunNote_line2',
                        studentRouteNoteLine3: 'faq_informationToDrivers_studentsNotes_A_studentRunNote_line3',
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'lines',
                    },
                    {
                        key: 'faq_informationToDrivers_runChanges',
                        question: 'faq_informationToDrivers_runChanges_Q',
                        answerParagraph1: 'faq_informationToDrivers_runChanges_A_paragraph1',
                        answerParagraph2: 'faq_informationToDrivers_runChanges_A_paragraph2',
                        routeChangesLine1: 'faq_informationToDrivers_runChanges_A_line1',
                        routeChangesLine2: 'faq_informationToDrivers_runChanges_A_line2',
                        routeChangesLine3: 'faq_informationToDrivers_runChanges_A_line3',
                        routeChangesLine4: 'faq_informationToDrivers_runChanges_A_line4',
                        routeChangesLine5: 'faq_informationToDrivers_runChanges_A_line5',
                        routeChangesLine6: 'faq_informationToDrivers_runChanges_A_line6',
                        routeChangesLine7: 'faq_informationToDrivers_runChanges_A_line7',
                        routeChangesLine8: 'faq_informationToDrivers_runChanges_A_line8',
                        answerParagraph3: 'faq_informationToDrivers_runChanges_A_paragraph3',
                        pictureRouteChanges: {
                            en: '../../assets/img/ui/faq/faq_informationToDrivers_routeChanges_EN.png',
                            fr: '../../assets/img/ui/faq/faq_informationToDrivers_routeChanges_FR.png',
                        },
                        answerParagraph4: 'faq_informationToDrivers_runChanges_A_paragraph4',
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'lines',
                    },
                ],
            },

            {
                key: 'faq_directionsEditing',
                name: 'faq_directionsEditing',
                faqResources: [
                    {
                        key: 'faq_directionsEdition_DirectionsEditing',
                        question: 'faq_directionsEdition_DirectionsEditing_Q',
                        answerParagraph1: 'faq_directionsEdition_DirectionsEditing_A_paragraph1',
                        answerParagraph2: 'faq_directionsEdition_DirectionsEditing_A_paragraph2',
                        answerLines: {
                            answerLine1: 'faq_directionsEdition_DirectionsEditing_A_line1',
                            answerLine2: 'faq_directionsEdition_DirectionsEditing_A_line2',
                        },
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_directionsEdition_DirectionsEditingType',
                        question: 'faq_directionsEdition_DirectionsEditingType_Q',
                        answerParagraph1: 'faq_directionsEdition_DirectionsEditingType_A_paragraph1',
                        answerParagraph2: 'faq_directionsEdition_DirectionsEditingType_A_paragraph2',
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_directionsEdition_DirectionsEditingVisibles',
                        question: 'faq_directionsEdition_DirectionsEditingVisibles_Q',
                        answerParagraph1: 'faq_directionsEdition_DirectionsEditingVisibles_A_paragraph1',
                        answerParagraph2: 'faq_directionsEdition_DirectionsEditingVisibles_A_paragraph2',
                        roles: ['manager', 'agent', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                ],
            },

            {
                key: 'disciplinaryReports',
                name: 'disciplinaryReports',
                faqResources: [
                    {
                        key: 'faq_disciplinaryReports_disciplinaryReportsDriversEdit',
                        question: 'faq_disciplinaryReports_disciplinaryReportsDriversEdit_Q',
                        answerParagraph1: 'faq_disciplinaryReports_disciplinaryReportsDriversEdit_A_paragraph1',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_disciplinaryReports_disciplinaryReportsVisibility',
                        question: 'faq_disciplinaryReports_disciplinaryReportsVisibility_Q',
                        answerParagraph1: 'faq_disciplinaryReports_disciplinaryReportsVisibility_A_paragraph1',
                        answerParagraph2: 'faq_disciplinaryReports_disciplinaryReportsVisibility_A_paragraph2',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'general',
                    },
                    {
                        key: 'faq_disciplinaryReports_disciplinaryReportsEdit',
                        question: 'faq_disciplinaryReports_disciplinaryReportsEdit_Q',
                        answerParagraph1: 'faq_disciplinaryReports_disciplinaryReportsEdit_A_paragraph1',
                        answerLine1: 'faq_disciplinaryReports_disciplinaryReportsEdit_A_line1',
                        answerLine2: 'faq_disciplinaryReports_disciplinaryReportsEdit_A_line2',
                        answerParagraph2: 'faq_disciplinaryReports_disciplinaryReportsEdit_A_paragraph2',
                        answerParagraph3: 'faq_disciplinaryReports_disciplinaryReportsEdit_A_paragraph3',
                        roles: ['manager', 'agent', 'observer', 'dispatcher', 'carrier_manager', 'carrier_observer'],
                        style: 'lines',
                    },
                ],
            },
            {
                key: 'account',
                name: 'account',
                faqResources: [
                    {
                        key: 'faq_account_settings_available',
                        question: 'faq_account_settings_available_Q',
                        answerParagraph1: 'faq_account_settings_available_A_paragraph1',
                        answerParagraph2BoldText: 'faq_account_settings_available_A_paragraph2',
                        answerParagraph3: 'faq_account_settings_available_A_paragraph3',
                        answerParagraph4: 'faq_account_settings_available_A_paragraph4',
                        answerParagraph5BoldText: 'faq_account_settings_available_A_paragraph5',
                        answerParagraph6: 'faq_account_settings_available_A_paragraph6',
                        answerParagraph7BoldText: 'faq_account_settings_available_A_paragraph7',
                        answerParagraph8: 'faq_account_settings_available_A_paragraph8',
                        answerParagraph9BoldText: 'faq_account_settings_available_A_paragraph9',
                        answerParagraph10: 'faq_account_settings_available_A_paragraph10',
                        answerParagraph11BoldText: 'faq_account_settings_available_A_paragraph11',
                        answerParagraph12: 'faq_account_settings_available_A_paragraph12',
                        answerParagraph13BoldText: 'faq_account_settings_available_A_paragraph13',
                        answerParagraph14: 'faq_account_settings_available_A_paragraph14',
                        roles: ['manager', 'agent'],
                        style: 'general',
                    },
                    {
                        key: 'faq_account_settings_available',
                        question: 'faq_account_settings_available_Q',
                        answerParagraph1: 'faq_account_settings_available_A_paragraph1',
                        answerParagraph2BoldText: 'faq_account_settings_available_A_paragraph2_dispatcher',
                        answerParagraph3: 'faq_account_settings_available_A_paragraph3_dispatcher',
                        answerParagraph4: 'faq_account_settings_available_A_paragraph4_dispatcher',
                        answerParagraph5: 'faq_account_settings_available_A_paragraph5_dispatcher',
                        answerParagraph6BoldText: 'trip',
                        answerParagraph7: 'faq_account_settings_available_A_paragraph6_dispatcher',
                        answerParagraph8BoldText: 'stop',
                        answerParagraph8: 'faq_account_settings_available_A_paragraph8_dispatcher',
                        answerParagraph9: 'faq_account_settings_available_A_paragraph9_dispatcher',
                        answerParagraph10: 'faq_account_settings_available_A_paragraph10_dispatcher',
                        answerParagraph11: 'faq_account_settings_available_A_paragraph11_dispatcher',
                        answerParagraph12BoldText: 'driver',
                        answerParagraph12: 'faq_account_settings_available_A_paragraph12_dispatcher',
                        answerParagraph13BoldText: 'vehicle',
                        answerParagraph13: 'faq_account_settings_available_A_paragraph13_dispatcher',
                        answerParagraph14: 'faq_account_settings_available_A_paragraph14_dispatcher',
                        roles: ['dispatcher', 'carrier_manager'],
                        style: 'lines',
                    },
                    {
                        key: 'faq_account_operator_roles',
                        question: 'faq_account_operator_roles_Q',
                        answerParagraph1: 'faq_account_operator_roles_A_paragraph1',
                        answerParagraph2BoldText: 'faq_account_operator_roles_A_paragraph2_manager',
                        answerParagraph3: 'faq_account_operator_roles_A_paragraph3_manager',
                        answerParagraph4BoldText: 'faq_account_operator_roles_A_paragraph4_dispatcher',
                        answerParagraph5: 'faq_account_operator_roles_A_paragraph5_dispatcher',
                        dispatcherLine1: 'faq_account_operator_roles_A_dispatcher_line1',
                        dispatcherLine2: 'faq_account_operator_roles_A_dispatcher_line2',
                        dispatcherLine3: 'faq_account_operator_roles_A_dispatcher_line3',
                        dispatcherLine4: 'faq_account_operator_roles_A_dispatcher_line4',
                        answerParagraph7: 'faq_account_operator_roles_A_paragraph7_dispatcher',
                        dispatcherLine5: 'faq_account_operator_roles_A_dispatcher_line5',
                        dispatcherLine6: 'faq_account_operator_roles_A_dispatcher_line6',
                        dispatcherLine7: 'faq_account_operator_roles_A_dispatcher_line7',
                        dispatcherLine8: 'faq_account_operator_roles_A_dispatcher_line8',
                        dispatcherLine9: 'faq_account_operator_roles_A_dispatcher_line9',
                        dispatcherLine10: 'faq_account_operator_roles_A_dispatcher_line10',
                        answerParagraph9BoldText: 'faq_account_operator_roles_A_paragraph9_observer',
                        answerParagraph10: 'faq_account_operator_roles_A_paragraph10_observer',
                        answerParagraph11BoldText: 'faq_account_operator_roles_A_paragraph11_mechanic',
                        answerParagraph12: 'faq_account_operator_roles_A_paragraph12_mechanic',
                        answerParagraph13: 'faq_account_operator_roles_A_paragraph13',
                        roles: ['carrier_manager', 'dispatcher', 'carrier_observer'],
                        style: 'lines',
                    },
                    {
                        key: 'faq_account_client_roles',
                        question: 'faq_account_client_roles_Q',
                        answerParagraph1: 'faq_account_client_roles_A_paragraph1',
                        answerParagraph2BoldText: 'manager',
                        answerParagraph3: 'faq_account_client_roles_A_paragraph3_manager',
                        answerParagraph4BoldText: 'agent',
                        answerParagraph5: 'faq_account_client_roles_A_paragraph5_agent',
                        agentLine1: 'faq_account_client_roles_A_agent_line1',
                        agentLine2: 'faq_account_client_roles_A_agent_line2',
                        agentLine3: 'faq_account_client_roles_A_agent_line3',
                        agentLine4: 'faq_account_client_roles_A_agent_line4',
                        agentLine5: 'faq_account_client_roles_A_agent_line5',
                        agentLine6: 'faq_account_client_roles_A_agent_line6',
                        agentLine7: 'faq_account_client_roles_A_agent_line7',
                        answerParagraph2: 'faq_account_client_roles_A_paragraph2',
                        agentLine8: 'faq_account_client_roles_A_agent_line8',
                        agentLine9: 'faq_account_client_roles_A_agent_line9',
                        answerParagraph6BoldText: 'observer',
                        answerParagraph7: 'faq_account_client_roles_A_paragraph7_observer',
                        agentLine10: 'faq_account_client_roles_A_agent_line10',
                        agentLine11: 'faq_account_client_roles_A_agent_line11',
                        agentLine12: 'faq_account_client_roles_A_agent_line12',
                        roles: ['manager', 'agent', 'observer'],
                        style: 'lines',
                    },
                ],
            },
        ];

        // Filtering all the sections/resources to only get the ones the user is allowed to see
        $scope.mySections = sections.filter((section) => {
            section.faqResources = section.faqResources.filter((resource) => resource.roles.includes($rootScope.loggedUserRole));
            return section.faqResources.length > 0;
        });
    },
]);
