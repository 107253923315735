/**
 * @param {Boolean} show  controling the modal element show/hide. Required.
 * @param {Function} onClose function called when the modal is closed by Cancel or X button. Optional.
 * @param {Boolean} withCancelBtn controlling whether we display the "Cancel" button. If not specified, "Cancel" button is displayed by default.
 * @param {Boolean} loading controlling whether we display the loadingDots or not.
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('modal', {
    templateUrl: './shared/modal/modal.html',
    transclude: {
        title: '?modalTitle',
        bodyContent: '?modalBodyContent',
        footerButtons: '?modalFooterButtons',
    },
    bindings: {
        show: '=',
        onClose: '<',
        withCancelBtn: '<',
        loading: '<',
    },
    controller: function ($scope, $translate) {
        this.$onInit = () => {
            this.show = this.show ?? false;
            this.loading = this.loading ?? false;
            this.onClose = this.onClose ?? function () {};
            this.withCancelBtn = this.withCancelBtn ?? true;
        };

        /**
         * Closes the modal.
         */
        $scope.closeModal = () => {
            this.show = false;
            this.onClose();
        };
    },
});
