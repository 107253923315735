/**
 * @param {String} status status of the component to know which toggle should be enabled ('All', 'Effective')
 * @param {Function} onClickAll function to call when clicking on "All"
 * @param {Function} onClickEffective function to call when clicking on "Effective"
 * @param {Boolean} isRestricted should all the toggles be disabled
 * @param {String} titleAll button title with count : All (#)
 * @param {String} titleEffective button title with count : Effective (#)
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('radioToggleNotes', {
    templateUrl: './shared/radioToggleNotes/radioToggleNotes.html',
    bindings: {
        status: '<',
        onClickAll: '&',
        onClickEffective: '&',
        isRestricted: '<',
        titleAll: '@',
        titleEffective: '@',
    },
});
