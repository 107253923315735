const moment = require('moment');
/**
 * Checks if the given date is today.
 * @param {Date|string} date - The date to check. It can be a Date object or a string representing a date (e.g., '2024-04-08').
 * @return {boolean} Returns true if the given date is today, otherwise false.
 */
function dateIsToday(date) {
    return moment(date).isSame(moment(), 'day');
}

/**
 * Verify if the specified date is in the past
 * @param {Date} date
 * @return {Boolean} true if the date is in the past
 */
function isPast(date) {
    const today = moment().startOf('day');
    return moment(date).startOf('day').isBefore(today);
}

module.exports = { dateIsToday, isPast };
