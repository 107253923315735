/**
 * Component: panelFooter
 * Html tags: <panel-footer>
 * @param {Boolean} loading
 * @param {Boolean} sorting
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('panelFooter', {
    templateUrl: './shared/panelFooter/panelFooter.html',
    transclude: {
        icon: '?icon',
        spinnerIcon: '?spinnerIcon',
        titleText: '?titleText',
        button: '?button',
    },
    bindings: {
        loading: '<',
        sorting: '<',
    },
    controller: function () {
        this.$onInit = () => {
            this.loading = this.loading ?? false;
            this.sorting = this.sorting ?? false;
        };
    },
});
