export class SchoolYear {
    constructor() {
        this.schoolYearStartDay = 1;
        this.schoolYearStartMonth = 7; // August (0-indexed in JavaScript Date objects)
        this.currentSchoolYearStartDate = this.computeCurrentSchoolYearStartDate();
    }

    computeCurrentSchoolYearStartDate() {
        // Get the current date
        const currentDate = moment();
        // Initialize school year start date to this year's start date
        let schoolYearStartDate = moment().set({
            month: this.schoolYearStartMonth,
            date: this.schoolYearStartDay,
        });

        if (currentDate.isBefore(schoolYearStartDate)) {
            // If before this year's start date, it means we are in the previous school year
            schoolYearStartDate = schoolYearStartDate.subtract(1, 'years');
        }

        return schoolYearStartDate.startOf('day');
    }

    isInSchoolYear(date) {
        // Get the start date of the current school year
        const reportDate = moment(date, 'L - HH:mm');
        return reportDate.isSameOrAfter(this.currentSchoolYearStartDate);
    }
}
