/**
 * Confirmation Toggle Component.
 * @param {Boolean} status toggle status on/off.
 * @param {String} title title for modal.
 * @param {String} statusTrueMessage the text to display in the modal when the status is true
 * @param {String} statusFalseMessage the text to display in the modal when the status is false
 * @param {Boolean} show  controling the modal element show/hide.
 * @returns {HTMLElement}
 */

angular.module('mTransportApp').component('toggleConfirmation', {
    templateUrl: './shared/toggleConfirmation/toggleConfirmation.html',
    transclude: true,
    bindings: {
        status: '=',
        title: '@',
        statusTrueMessage: '@',
        statusFalseMessage: '@',
        show: '=',
    },
    controller: function ($scope) {
        this.$onInit = () => {
            this.show = this.show ?? false;
        };

        /**
         * Open the modal.
         * @returns {void}
         */
        $scope.openConfirmationModal = () => {
            this.show = true;
        };

        /**
         * When the modal is closed by cancel button or X
         * @returns {void}
         */
        $scope.onClose = () => {
            this.status = !this.status;
        };
    },
});
