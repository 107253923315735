angular.module('mTransportApp').factory('MapManager', [
    function () {
        /**
         * Format a route for map use
         * @param {Object} routeToFormat
         * @param {Object[]} stops
         * @return {Object} formatted route
         */
        function formatRouteForSinglePlannedTrip(routeToFormat, stops) {
            // Edit the route to give it a data structure that can be used with GlobalMapController
            const route = [];
            const routeForMap = angular.copy(routeToFormat);
            // This is necessary for the map, as some variables that are in a route are needed in this form : routeForMap.route.x
            routeForMap.route = angular.copy(routeToFormat);

            routeForMap.status = 'planned';
            routeForMap.stops = stops ? angular.copy(stops) : [];

            route.push(routeForMap);

            return route;
        }

        return {
            formatRouteForSinglePlannedTrip,
        };
    },
]);
