/*
 * {Array} sections - the sections with their options, see data stucture bellow. Required.
 * {String} searchInputPlaceholder - the placeholder on the search input text. Optional, default to "Search...".
 * {String} searchInputDefaultValue - the default search value in the search input text. Optional, default to ''.
 * {Boolean} showResetButton - if we must display the reset button or not, false will hide it. Optional, default to true.
 * {Boolean} showSearchInput - if we must display the search text input or not, false will hide it. Optional, default to true.
 * {Function} onChange - function executed when the value of an option changes.
 * {Function} onReset - function executed when the reset button is clicked. Required.
 * {Function} onInput - function executed when the value of the input field changes. Will be called with one parameter, being the search value string. Required.
 */

/*
 Data Structure for prop 'sections'
    [
        {
            name: 'Section 1', // Required
            opened: false, // Optional, default to true
            disabled: false, // Optional, default to false
            options: [ // Required
                {
                    name: 'Option 1.1', // Required
                    value: false, // Required, true is checked, false is not
                },
                {
                    name: 'Option 1.2', // Required
                    value: false, // Required, true is checked, false is not
                }
            ]
        },
        {
            name: 'Section 2',
            options: [
                {
                    name: 'Option 2.1',
                    value: true,
                }
            ]
        }
    ]
*/

angular.module('mTransportApp').component('filters', {
    templateUrl: './shared/filters/filters.html',
    transclude: true,
    bindings: {
        sections: '=',
        searchInputPlaceholder: '@',
        searchInputDefaultValue: '@',
        showResetButton: '<',
        showSearchInput: '<',
        showFilterButton: '<',
        show: '<',
        onChange: '<',
        onInput: '<',
        onReset: '<',
    },
    controller: function ($scope, $translate) {
        $scope.search = {
            value: '',
        };
        $scope.dropdownCollapsed = false; // Filter dropdown is closed by default

        this.$onInit = function () {
            this.sections = this.sections ?? []; // Default section to empty array
            this.searchInputPlaceholder = this.searchInputPlaceholder ?? $translate.instant('searchGeneric');
            $scope.search.value = this.searchInputDefaultValue = this.searchInputDefaultValue ?? '';
            this.showResetButton = this.showResetButton ?? true; // We show the reset button by default
            this.showSearchInput = this.showSearchInput ?? true; // We show the search input by default
            this.showFilterButton = this.showFilterButton ?? true; // We show the filter button by default
            this.show = this.show ?? true; // We show the filter by default
        };

        $scope.$watch(
            () => this.sections,
            () => {
                // Making copy of the default data, so we can verify if they changed, reset them, etc.
                this.defaultSections = angular.copy(this.sections);

                // All sections will be opened by default, can be overwritten by props
                for (const section of this.sections) {
                    section.opened = section.opened ?? true;
                }
                // Useful to disabled section, false by default
                for (const section of this.sections) {
                    section.disabled = section.disabled ?? false;
                }
            }
        );

        // **********************
        // *** LAYOUT CHANGES ***
        // **********************

        /**
         * Modify the value of dropdownCollapsed (if the dropdown is collapsed or not)
         * @param {Boolean} newValue
         */
        $scope.setDropdownCollapsed = (newValue) => {
            $scope.dropdownCollapsed = newValue;
        };

        // ***********************
        // *** SECTION CHANGES ***
        // ***********************

        /**
         * Toggle the opening state of the specific section
         * @param {Object} section
         */
        $scope.toggleSection = (section) => {
            section.opened = !section.opened;
        };

        // **********************
        // *** OPTION CHANGES ***
        // **********************

        /**
         * Reset all options to their default value.
         */
        $scope.resetOptions = () => {
            $scope.setDropdownCollapsed(false);
            $scope.search.value = '';
            for (const [sectionIndex, section] of this.sections.entries()) {
                for (const [optionIndex, option] of section.options.entries()) {
                    option.value = this.defaultSections[sectionIndex].options[optionIndex].value;
                }
            }
            this.onChange();
            this.onReset();
        };

        /**
         * Modify all options in all sections to true
         */
        $scope.selectAllFilters = () => {
            if (!$scope.areAllStatesTrue()) {
                for (const section of this.sections) {
                    if (!section.disabled) {
                        for (const option of section.options) {
                            option.value = true;
                        }
                    } else {
                        for (const option of section.options) {
                            option.value = option.id === 'inProgress' ? true : false;
                        }
                    }
                }
                this.onChange();
            }
        };

        /**
         * Modify all options of specific section to true
         * @param {Object} section
         */
        $scope.selectAllFiltersInSection = (section) => {
            if (!$scope.areAllStatesTrue(section)) {
                for (const option of section.options) {
                    option.value = true;
                }
                this.onChange();
            }
        };

        /**
         * Modify all options in all sections to false
         */
        $scope.selectNoFilters = () => {
            if (!$scope.areAllStatesFalse()) {
                for (const section of this.sections) {
                    if (!section.disabled) {
                        for (const option of section.options) {
                            option.value = false;
                        }
                    } else {
                        for (const option of section.options) {
                            option.value = option.id === 'inProgress' ? true : false;
                        }
                    }
                }
                this.onChange();
            }
        };

        /**
         * Modify all options of specific section to false
         * @param {Object} section
         */
        $scope.selectNoFiltersInSection = (section) => {
            if (!$scope.areAllStatesFalseInSection(section)) {
                for (const option of section.options) {
                    option.value = false;
                }
                this.onChange();
            }
        };

        /**
         * Toggle the value of the specific option
         * @param {Object} option
         */
        $scope.toggleOption = (option) => {
            option.value = !option.value;
            this.onChange();
        };

        // ***************
        // *** QUERIES ***
        // ***************

        /**
         * Verify if the states of the options changed (compared to their default state).
         * @return {Boolean} true if at least one state changed. False if all the states are the same.
         */
        $scope.didOptionsChanged = () => {
            // If there is sections
            if (this.defaultSections.length > 0) {
                // For each sections
                for (const [defaultSectionIndex, defaultSection] of this.defaultSections.entries()) {
                    // For each options in this section
                    for (const [defaultOptionIndex, defaultOption] of defaultSection.options.entries()) {
                        // If the value of this option changed
                        if (this.sections[defaultSectionIndex].options[defaultOptionIndex].value != defaultOption.value) {
                            return true;
                        }
                    }
                }
            }

            return false;
        };

        /**
         * Verify if all the options are set to true
         * @return {Boolean} true if all options are true
         */
        $scope.areAllStatesTrue = () => {
            if (this.sections.length > 0) {
                for (const section of this.sections) {
                    if (!section.disabled) {
                        // Try to find a false value in the options
                        if (section.options.find((option) => option.value !== true)) {
                            return false;
                        }
                    }
                }
                // If all states are false returns true, then this function returns false
                return $scope.areAllStatesFalse() ? false : true;
            } else {
                return false;
            }
        };

        /**
         * Verify if all the options are set to true in a specific section
         * @param {Object} section
         * @return {Boolean} true if all options are true
         */
        $scope.areAllStatesTrueInSection = (section) => {
            // Try to find a false value in the options
            return !section.options.find((option) => option.value !== true);
        };

        /**
         * Verify if all the options are set to false
         * @return {Boolean} true if all options are false
         */
        $scope.areAllStatesFalse = () => {
            if (this.sections.length > 0) {
                for (const section of this.sections) {
                    if (!section.disabled) {
                        // Try to find a true value in the options
                        if (section.options.find((option) => option.value !== false)) {
                            return false;
                        }
                    }
                }
                return true;
            } else {
                return false;
            }
        };

        /**
         * Verify if at least one option is set to false
         * @return {Boolean}
         * True if sections array is not empty and if at least one option is false, or false if sections array is not empty and if all options are true, or false if the sections array is empty
         */
        $scope.isOneStateFalse = () => {
            let oneStateFalseDetected = false;

            if (this.sections.length > 0) {
                for (const section of this.sections) {
                    // Try to find a false value in the options
                    if (section.options.find((option) => option.value !== true)) {
                        oneStateFalseDetected = true;
                        break;
                    }
                }
            }

            return oneStateFalseDetected;
        };

        /**
         * Verify if all the options are set to false in a specific section
         * @param {Object} section
         * @return {Boolean} true if all options are false
         */
        $scope.areAllStatesFalseInSection = (section) => {
            // Try to find a false value in the options
            return !section.options.find((option) => option.value !== false);
        };
    },
});
