/**
 * {Object} trip - the trip to display, the object must already be in the proper format
 * {Boolean} show-carrier - Show the carrier name or not. Default to false.
 * {Boolean} show-driver - Show the driver name or not. Default to false.
 * {Boolean} show-progress - Show the progress (and current/next stop) or not. Default to false.
 * {Boolean} show-status - Show the status grid or not. Default to false.
 * {Boolean} show-stats - Show the student/watcher count or not. Default to false.
 * {Boolean} hide-details - Hide the trip details. Default to false.
 * {Boolean} show-tooltip - True will enable the Route name tooltip when the mouse hover the thumbnail. Default to false.
 * {Function} favourite-click-event - Function to execute when the star "favourite" is clicked. The function will receive the trip as first arg.
 * {Boolean} is-trip-in-late-section - To know whether the trip is in amLate or pmLate sections
 * {Boolean} displayBars - Display bars instead of circles to display more stops
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('tripDashboardView', {
    templateUrl: './shared/layout/tripDashboardView/tripDashboardView.html',
    transclude: true,
    bindings: {
        trip: '<',
        showCarrier: '<',
        showDriver: '<',
        showProgress: '<',
        showStatus: '<',
        showStats: '<',
        showMoreIcons: '<',
        hideDetails: '<',
        showTooltip: '<',
        favouriteClickEvent: '=',
        isTripInLateSection: '<',
        displayBars: '<',
    },
    controller: function ($rootScope, $scope, $timeout, $controller) {
        angular.extend(this, $controller('FavouriteController', { $scope: $scope }));
        $scope.selectedDate = $rootScope.selectedDate; // The date selected for TV Dashboard (in URL)
        $scope.showAllStops = undefined; // undefined: we do not know yet, will be calculated later.
        const MAX_MORE_ICONS = 8; // Maximum number of icons to show if showMoreIcons is true
        const MAX_ICONS = 4; // Maximum number of icons to show if showMoreIcons is false

        this.$onInit = () => {
            // Default values for bindings
            this.trip = this.trip || false;
            this.showCarrier = this.showCarrier || false;
            this.showDriver = this.showDriver || false;
            this.driverName = this.showDriver ? `${this.trip.driver.firstName} ${this.trip.driver.lastName}` : '';
            this.showProgress = this.showProgress || false;
            this.showStatus = this.showStatus || false;
            this.showStats = this.showStats || false;
            this.hideDetails = this.hideDetails || false;
            this.showTooltip = this.showTooltip || false;
            this.showMoreIcons = this.showMoreIcons || false;
            this.isTripInLateSection = this.isTripInLateSection || false;
            this.numberOfMinutesLate = 0;
            this.iconsShownCount = $scope.getIconsShownCount();

            this.favouriteClickEvent = (trip) => {
                $scope.addOrRemoveRouteFavourite(trip.route.id);
                trip.isFavourite = !trip.isFavourite;
            };

            if (this.showProgress && this.trip.stops) {
                // $timeout will make sure this code will be executed after the rendering
                // TODO: If we refactor to another framework, use component proper life-cycle method.
                $timeout(() => {
                    checkEnoughPlace();
                    this.displayBars = !$scope.showAllStops;

                    // eslint-disable-next-line no-undef
                    window.addEventListener('resize', () => {
                        checkEnoughPlace();
                        this.displayBars = !$scope.showAllStops;
                    });
                });
            }

            // Adding "true" as the last parameter for $scope.$watch() will enable deep watching of $ctrl.trip.states (non-primitive array)
            $scope.$watch(
                '$ctrl.trip.states',
                () => {
                    if (this.isTripInLateSection) {
                        // Find trip late state object whithin states array
                        const tripLateState = this.trip.states.find((state) => state.state === 'late');

                        if (tripLateState) this.numberOfMinutesLate = tripLateState.value;
                        else {
                            // eslint-disable-next-line no-undef
                            const duration = moment.duration(moment().diff(moment(this.trip.schedule)));
                            this.numberOfMinutesLate = duration.asMinutes();
                        }
                    }
                },
                true
            );
        };

        // clears the tooltips on route change. This allows for clicking on trips and using keyboard navigation to go back to the previous page.
        $scope.$on('$routeChangeStart', function () {
            // eslint-disable-next-line no-undef
            $('[data-toggle="tooltip"]').tooltip('hide');
        });

        $scope.$watch(
            () => this.trip.currentStopSequenceNumber,
            () => {
                let width = ((this.trip.currentStopSequenceNumber - 1) * 100) / (this.trip.stops.length - 1);
                if (width > 100 || this.trip.currentStopSequenceNumber === this.trip.stops.length) {
                    width = 100;
                }
                $scope.completedProgressBarWidth = width;
            }
        );

        /**
         * Verify if there is enough space between the stop icons to show them all with circle/square/hexagon
         * If not, we are only showing them using bars
         * @return {Boolean}
         */
        const checkEnoughPlace = () => {
            // eslint-disable-next-line no-undef
            const progressZoneWidth = angular.element(document.getElementById('details' + this.trip.id)).width() - 30; // Total width of all the progress zone bar (details zone width - 30px (margins))
            const stopsWidth = this.trip.stops.length * 15; // The total width all the stop icons are taking
            const widthBetweenStops = (progressZoneWidth - stopsWidth) / this.trip.stops.length - 1; // Space between each stop icon
            return ($scope.showAllStops = !(widthBetweenStops < 5));
        };

        /**
         * Verify if the specified date is one in the past
         * @param {Date} date
         * @return {Boolean} true if the date is in the past
         */
        $scope.isPast = (date) => {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            now.setUTCHours(0);
            return Date.parse(date) < now.getTime();
        };

        /**
         * When the mouse enter the thumbnail
         * @param {Object} event
         */
        $scope.mouseEnter = (event) => {
            if (this.showTooltip === true) {
                // Show the route name tooltip
                // eslint-disable-next-line no-undef
                $(event.delegateTarget).tooltip('show');
            }
        };

        /**
         * Get the number of state icons to show
         * @return {Number} the number of icons to show
         */
        $scope.getIconsShownCount = () => {
            const statesCount = this.trip.states.length;
            return this.showMoreIcons ? Math.min(statesCount, MAX_MORE_ICONS) : Math.min(statesCount, MAX_ICONS);
        };

        /**
         * Show the state icon or not depending on the maximum number of icons to show
         * @param {Number} index in the grid of states
         * @return {Boolean} true if the icon should be shown
         */
        $scope.showState = (index) => {
            return index < MAX_ICONS || (this.showMoreIcons && index < MAX_MORE_ICONS);
        };

        /**
         * Show the filler div or not depending on the number of icons to show
         * @return {Boolean} true if the filler div should be shown
         */
        $scope.showFiller = () => {
            return this.iconsShownCount > 2 && this.iconsShownCount % 2 === 1; // Show filler if there is an odd number of icons
        };
    },
});
