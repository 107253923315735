/**
 * @param {Number} radius the radius of the stop if automatic detection activated
 * @param {Function} kind pickup or dropoff
 * @returns {HTMLElement}
 */

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('stopArrow', {
    templateUrl: './shared/stopArrow/stopArrow.html',
    bindings: {
        radius: '<?',
        kind: '<',
    },
});
