/**
 * @param {Boolean} selectedOption true = first option selected. False = second option. Optional. Default to 0.
 * @param {Function} onChange  function executed when the status changes. Has new status as param. Optional.
 * @param {String} options the possible options (buttons), see data stucture bellow. Required.
 * @returns {HTMLElement}
 */

/*
 Data Structure for prop 'options'
    [
        {
            name: 'Option 1', // Required
            onClick: () => {},
        },
        // ...
    ]
*/

// eslint-disable-next-line no-undef
angular.module('mTransportApp').component('btnGroupToggle', {
    templateUrl: './shared/btnGroupToggle/btnGroupToggle.html',
    bindings: {
        selectedOption: '=',
        onChange: '<',
        options: '<',
    },
    controller: function ($scope) {
        this.$onInit = () => {
            this.selectedOption = this.selectedOption ?? 0;
            this.onChange = this.onChange ?? function () {};
        };

        /**
         * Update the selected option to the specified index
         * @param {Boolean} newSelectedOption
         */
        $scope.updateSelectedOption = (newSelectedOption) => {
            this.selectedOption = newSelectedOption;
            this.options[newSelectedOption].onClick();
            this.onChange(newSelectedOption);
        };
    },
});
