/* Component: tableHead
 * Props: @text
 * Html tags: </table-head>
 * Example: <table-head text='hello world'>....</table-head>
 */

angular.module('mTransportApp').component('tableHead', {
    templateUrl: './shared/tableHead/tableHead.html',
    transclude: {
        checkBoxSlot: '?label',
        sortingIconSlot: '?sortingIcon',
    },
    bindings: {
        text: '@',
    },
});
