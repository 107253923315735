app.controller('GetFormCount', [
    '$rootScope',
    '$controller',
    '$scope',
    '$timeout',
    function ($scope, $controller, $rootScope, $timeout) {
        // eslint-disable-next-line no-undef
        angular.extend(this, $controller('AjaxRequest', { $scope: $scope }));

        $scope.formsCount = null;

        let fetchCountOfFormsInterval;

        /**
         * Get the current count of unread forms and refresh every 5 minutes
         */
        $scope.fetchCountOfForms = () => {
            // Delay for the interval of 5 minutes (300 000 milliseconds)
            const delay = 300000;
            // Fetch the count of unread forms if user is a dispatcher, a manager or an agent
            if (['manager', 'agent', 'dispatcher', 'observer', 'carrier_observer', 'carrier_manager'].includes($rootScope.loggedUserRole)) {
                $scope.angRequestGetFormsUnreadCount().then(
                    function (data) {
                        clearInterval(fetchCountOfFormsInterval);
                        $scope.formsCount = data.data;
                        // Rerun the function every 5 minutes - Initiate the interval only when the user logins or refreshes the page
                        fetchCountOfFormsInterval = setInterval($scope.fetchCountOfForms, delay);
                    },
                    function (error) {
                        clearInterval(fetchCountOfFormsInterval);
                        $rootScope.$broadcast('request-error-dispatch-form-details', error);
                    }
                );
            }
        };

        $rootScope.$on('reduceCountOfUnreadForms', function () {
            $timeout(() => {
                $scope.fetchCountOfForms();
                $scope.$apply();
            }, 2500); // When we open the link to a form, this gives time for the form to be considered unread
        });
    },
]);
