const { dateIsToday } = require('./helpers/dateComparator');
const { startingWith } = require('./helpers/startingWith');

app.controller('ToolsController', [
    '$scope',
    '$translate',
    '$timeout',
    function ($scope, $translate, $timeout) {
        /* DOM Manipulations ============================================================================================ */
        $scope.setFocus = function (id) {
            let element = document.getElementById(id);
            if (element) {
                element.focus();
            }
        };

        $scope.triggerNotification = function (id) {
            let element = document.getElementById(id);
            if (element) {
                element.style.display = 'block';
            }
        };

        $scope.triggerCustomNotification = function (id, messageId, message) {
            let element = document.getElementById(id);
            if (element) {
                element.style.display = 'block';
            }

            let messageElement = document.getElementById(messageId);
            if (messageElement) {
                messageElement.innerHTML = message;
            }
        };

        $scope.dismissNotification = function (id) {
            let element = document.getElementById(id);
            if (element) {
                element.style.display = 'none';
            }
        };

        $scope.dismissAllNotifications = function () {
            const elements = document.getElementsByClassName('alert');
            if (elements) {
                for (const element of elements) {
                    element.style.display = 'none';
                }
            }
        };

        /**
         * Toggles the caret in the header of a collapsable element
         * @param {Object} e
         */
        $scope.onChangeCaretToggle = function (e) {
            if (!e.currentTarget.nextElementSibling.classList.contains('collapsing')) {
                const caret = e.currentTarget.querySelector('.custom-caret');
                e.currentTarget.nextElementSibling.classList.contains('show')
                    ? caret.classList.replace('fa-caret-down', 'fa-caret-right')
                    : caret.classList.replace('fa-caret-right', 'fa-caret-down');
            }
        };

        $scope.stopClickPropagation = function ($event) {
            $event.stopPropagation();
        };

        /**
         * Toggles the chevron in the header of a collapsable element
         * @param {Object} e
         */
        $scope.onChangeChevronToggle = function (e) {
            if (!e.currentTarget.nextElementSibling.classList.contains('collapsing')) {
                const chevron = e.currentTarget.querySelector('.custom-chevron');
                e.currentTarget.nextElementSibling.classList.contains('show')
                    ? chevron.classList.replace('fa-chevron-up', 'fa-chevron-down')
                    : chevron.classList.replace('fa-chevron-down', 'fa-chevron-up');
            }
        };

        /**
         * Starts file download
         * @param csv : file data generated by PapaParse
         * @param filename
         */
        $scope.downloadCSV = function downloadCSV(csv, filename) {
            // Create BLOB with the csv.
            let blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' });

            // Create a false link element and append it to the page.
            let tempLink = document.createElement('a');
            tempLink.id = 'downloadCSVLink';
            document.body.appendChild(tempLink);

            // Verify which browser user is using
            if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                window.navigator.msSaveBlob(blob, filename);
            } else {
                // Add attributes to the tempLink.
                const CSVDownloadURL = URL.createObjectURL(blob);
                $('#downloadCSVLink').attr({
                    download: filename,
                    href: CSVDownloadURL,
                });
            }

            // Timeout avoids conflict with inprogess angular digest.
            $timeout(function () {
                // Trick the page into starting the download of the CSV file by triggering a click event on the tempLink.
                $('#downloadCSVLink')[0].click();
                // Unbind tempLink from page.
                document.body.removeChild(tempLink);
            });
        };

        /* Browser compensation ========================================================================================= */
        $scope.usingInternetExplorer = function () {
            let ua = window.navigator.userAgent;
            let msie = ua.indexOf('MSIE ');

            return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
        };

        $scope.usingEdge = function () {
            return window.navigator.userAgent.indexOf('Edge') > -1;
        };

        /* Arrays & collection manipulation ========================================================================================= */
        $scope.arrayContains = function (array, value, key) {
            let answer = -1;
            if (key) {
                for (const entryIndex in array) {
                    let entry = array[entryIndex];
                    if (entry[key] === value) {
                        answer = 1;
                    }
                }
            } else {
                answer = $.inArray(value, array);
            }

            return answer > -1;
        };

        $scope.arrayIntersection = function (a, b) {
            const result = [];
            for (const element of b) {
                if (a.indexOf(element) > -1) {
                    result.push(element);
                }
            }
            return result;
        };

        // https://www.codementor.io/avijitgupta/deep-copying-in-js-7x6q8vh5d
        // Deep copy an array
        $scope.copy = function (o) {
            let output, v, key;
            output = Array.isArray(o) ? [] : {};
            for (key in o) {
                v = o[key];
                output[key] = typeof v === 'object' ? $scope.copy(v) : v;
            }
            return output;
        };

        $scope.objectDiffById = function (a, b) {
            let result = $scope.copy(a);
            let arr_to_substract = $scope.copy(b);
            for (const element of arr_to_substract) {
                for (let j = 0; j < result.length; j++) {
                    if (result[j].id == element.id) {
                        result.splice(j, 1);
                    }
                }
            }
            return result;
        };

        /*
            a = array of ids
            b = object with key id to intersect with
         */
        $scope.objectIntersectionById = function (a, b) {
            const result = [];
            for (const element of b) {
                if (a.indexOf(element.id) > -1) {
                    result.push(element);
                }
            }
            return result;
        };

        $scope.countPropertiesInJSONObject = function (JSONObject) {
            let count = 0;
            for (const propertyIndex in JSONObject) {
                count++;
            }
            return count;
        };

        /* Filters ======================================================================================================= */
        $scope.containsSearchValue = function (dataObject, search) {
            // Create the String.includes function for browser which doesn't support it.
            if (!String.prototype.includes) {
                String.prototype.includes = function () {
                    'use strict';
                    return String.prototype.indexOf.apply(this, arguments) !== -1;
                };
            }
            // bus number filtering
            const regExForBus = /^bus:/gi;
            const filterOnlyOnBusNumber = search?.match(regExForBus);

            if (!search || search.length <= 0) {
                // No search value, no point in comparing.
                return true;
                // logic to return exact bus number when user search "bus:{busNumber}"
            } else if (filterOnlyOnBusNumber) {
                const busNumber = search.split(':')[1];
                // tripBusnumber is the key to search by when the dataObject comes from the dashboard page,
                // routeBusNum is the key to search by when the dataObject comes frome the routes page,
                // reportDailyDistanceBus is the key for the Daily distances report
                const tripBusNumber =
                    dataObject['tripBusNumber'] || dataObject['routeBusNum'] || dataObject['busNumber'] || dataObject['reportDailyDistanceBus'];
                if (busNumber.toString() === tripBusNumber.toString()) {
                    return true;
                }
            } else {
                let result = false;
                let firstNameMatch = false;
                let lastNameMatch = false;
                Object.entries(dataObject).forEach(([key, value]) => {
                    // student internal ID search
                    if (key === 'studentID' && value) {
                        if (value.length > 0) {
                            result = startingWith(search, value);
                        }
                    }
                    // firstName and lastName search
                    else if (key.toLowerCase().includes('firstname') && value) {
                        firstNameMatch = startingWith(search, value);
                    } else if (key.toLowerCase().includes('lastname') && value) {
                        lastNameMatch = startingWith(search, value);
                    }
                    // Other searches
                    else if (value) {
                        // Make sure you're comparing strings.
                        if (value.length > 0) {
                            value = value.toString().toUpperCase();
                            if (value.includes(search.toString().toUpperCase())) {
                                result = true;
                            }
                        }
                    }
                });
                return firstNameMatch || lastNameMatch || result;
            }
        };

        /* Date manipulations ============================================================================================ */
        /**
         * Takes 2 times in hh:mm or h:mm format and returns their delta
         * in hh:mm format
         * @param real
         * @param planned
         * @param addSign (display + or - sign before resulting string)
         * @returns {string}
         */
        $scope.timeDelta = function (real, planned, addSign) {
            const realArr = real.split(':');
            const plannedArr = planned.split(':');

            const realArrMoment = moment().hours(realArr[0]).minutes(realArr[1]);
            const plannedArrMoment = moment().hours(plannedArr[0]).minutes(plannedArr[1]);

            const deltaHours = plannedArrMoment.diff(realArrMoment, 'hours');
            const deltaMinutes = plannedArrMoment.diff(realArrMoment, 'minutes') % 60;
            let delta = moment().hours(Math.abs(deltaHours)).minutes(Math.abs(deltaMinutes)).format('H:mm');

            if (addSign) {
                delta = deltaHours < 0 || deltaMinutes < 0 ? '(+' + delta + ')' : '(-' + delta + ')';
            }

            return delta;
        };

        $scope.dateIsToday = function (date) {
            return dateIsToday(date);
        };

        $scope.dateIsTomorrow = function (date) {
            const tomorrow = moment().add(1, 'days').format('LL');

            const dateToCompare = moment(date).format('LL');
            return tomorrow === dateToCompare;
        };

        $scope.makeDateRelative = function (dateToChange) {
            const now = moment();
            if (moment(dateToChange).get('date') < now.get('date') || moment(dateToChange).get('date') > now.get('date')) {
                if (moment(dateToChange).get('year') != now.get('year')) {
                    return moment(dateToChange).format('DD MMMM YYYY HH:mm');
                }
                return moment(dateToChange).format('DD MMM HH:mm');
            } else if (moment(dateToChange).add(2, 'hours').isAfter(now)) {
                return moment(dateToChange).fromNow();
            } else {
                return moment(dateToChange).format('DD MMMM HH:mm');
            }
        };

        /**
         * Converts time to a readable date
         * @param {Date} dateTime
         * @param {String} format date format (other formats than 'dash' can be added later)
         * @return {String} formatted date
         */
        $scope.convertToReadableDate = function (dateTime, format = null) {
            if (dateTime) {
                switch (format) {
                    case 'timeOnly':
                        return moment(dateTime).format('HH[:]mm');
                    case 'shortDash':
                        return moment(dateTime).format('YYYY[-]MM[-]DD');
                    case 'long':
                        return moment(dateTime).format('dddd') + ' ' + moment(dateTime).format('LL');
                    case 'dash':
                    default:
                        return moment(dateTime).format('YYYY[-]MM[-]DD[ ]HH[:]mm');
                }
            } else {
                return null;
            }
        };

        /**
         * Verify if the specified date is in the future
         * @param {Date} date
         * @return {Boolean} true if the date is in the future
         */
        $scope.isFuture = (date) => {
            const today = moment().startOf('day');
            return moment(date).startOf('day').isAfter(today);
        };

        $scope.getBatteryPercentage = function (value) {
            if (value <= 0) {
                return '0%';
            }
            let uppedValue = value * 100 + '';
            let percentage = uppedValue.split('.')[0];
            return percentage + '%';
        };

        /* Stops types manipulation ========================================================================================== */
        /**
         * Returns a translation of stop arrival or departure type
         * If there is no stop time, returns standard empty stop box field text
         * @param {Date} stopTime
         * @param {String} isStopAutomatic
         * @return {String}
         */
        $scope.stopDepartureArrivalType = function (stopTime, isStopAutomatic) {
            if (stopTime) {
                return $translate.instant(isStopAutomatic ? 'autoDetected' : 'manual');
            }
            return '- - - - - -';
        };

        /* Stops tools ====================================================================================================== */
        /**
         * Detects if there are directions in an array of stops
         * @param {Array} data stops
         * @return {Boolean} if there are directions or not in the stops
         */
        $scope.isDirectionsExist = function (data) {
            const { stops } = data;
            return stops.some(function ({ directions }) {
                return directions.length !== 0;
            });
        };

        /**
         * Check if stop radius is valid
         * @param {Object} radius
         * @return {Boolean}
         */
        $scope.isStopRadiusValid = (radius) => {
            return radius === 0 || (radius && radius > 0 && radius <= 200);
        };

        /* String manipulations ========================================================================================== */
        $scope.shortenString = function (string, maxLength, maxScreenWidth, minScreenWidth) {
            if (string) {
                let width = $(window).width();
                if (maxScreenWidth) {
                    if (width > maxScreenWidth) {
                        return string;
                    }
                }

                if (minScreenWidth) {
                    if (width < minScreenWidth) {
                        return string;
                    }
                }

                if (string.length < maxLength) {
                    return string;
                } else {
                    let shortString = string.substring(0, maxLength) + '...';
                    return shortString;
                }
            }
        };

        $scope.capitalizeFirstLetter = function (string, translate) {
            if (string && string.length > 0) {
                let newString;
                if (translate) {
                    newString = $translate.instant(string);
                    newString = newString.charAt(0).toUpperCase() + newString.slice(1);
                } else {
                    newString = string.charAt(0).toUpperCase() + string.slice(1);
                }
                return newString;
            }
        };

        /* Form validation =============================================================================================== */
        $scope.validateStandardTextInput = function (inputID, value, required) {
            let passes = true;
            let error;

            if (required) {
                if (!value || value.length <= 0) {
                    passes = false;
                    error = $translate.instant('messageErrorRequiredFieldEmpty');
                }
            } else {
                // If we want to do something when it's not required, it will go here.
            }

            if (passes) {
                if (value?.length <= 0) {
                    $scope.resetInputState(inputID);
                } else {
                    $scope.setInputValidated(inputID);
                }
            } else {
                $scope.setInputError(inputID);
            }
            return error;
        };

        $scope.validateURLInput = function (inputID, value, required) {
            let passes = true;
            let error;

            if (required) {
                if (value && value.length >= 0) {
                    const validUrl = isUrlValid(value);
                    if (!validUrl) {
                        passes = false;
                        error = $translate.instant('messageErrorNotAValidURL');
                    }
                } else {
                    passes = false;
                    error = $translate.instant('messageErrorRequiredFieldEmpty');
                }
            } else if (value && value.length >= 0) {
                const validUrl = isUrlValid(value);
                if (!validUrl) {
                    passes = false;
                    error = $translate.instant('messageErrorNotAValidURL');
                }
            }

            if (passes) {
                if (value.length <= 0) {
                    $scope.resetInputState(inputID);
                } else {
                    $scope.setInputValidated(inputID);
                }
            } else {
                $scope.setInputError(inputID);
            }
            return error;
        };

        function isUrlValid(url) {
            return /^(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
                url
            );
        }

        $scope.validateNumberInput = function (inputID, value, min, max, required) {
            let passes = true;
            let error;
            if (required) {
                if (value != null) {
                    if (isNaN(value)) {
                        passes = false;
                        error = $translate.instant('messageErrorValueMustBeNum');
                    }

                    if (value < min) {
                        passes = false;
                        error = $translate.instant('messageErrorValueTooShort');
                    }

                    if (value > max) {
                        passes = false;
                        error = $translate.instant('messageErrorValueTooBig');
                    }
                } else {
                    passes = false;
                    error = $translate.instant('messageErrorRequiredFieldEmpty');
                }
            } else if (value !== null) {
                // Edited to differentiate null input from type=number from undefined one
                // undefined is set when the input doesn't respect the min-max values of the input
                if (isNaN(value) || typeof value == 'undefined') {
                    passes = false;
                    error = $translate.instant('messageErrorValueMustBeNum');
                }

                if (value < min) {
                    passes = false;
                    error = $translate.instant('messageErrorValueTooShort');
                }

                if (value > max) {
                    passes = false;
                    error = $translate.instant('messageErrorValueTooBig');
                }
            }

            if (passes && value != null) {
                if (value.length <= 0) {
                    $scope.resetInputState(inputID);
                } else {
                    $scope.setInputValidated(inputID);
                }
            } else {
                $scope.setInputError(inputID);
            }
            return error;
        };

        $scope.validateAlphanumericalInput = function (inputID, value, minLength, maxlength, required) {
            let passes = true;
            let error;

            if (required) {
                if (value) {
                    if (value.length < minLength) {
                        passes = false;
                        error = $translate.instant('messageErrorTooShort');
                    }

                    if (maxlength >= minLength && value.length > maxlength) {
                        passes = false;
                        error = $translate.instant('messageErrorTooBig');
                    }

                    regex = /^[a-z0-9]+$/i;
                    if (!regex.test(value)) {
                        passes = false;
                        error = $translate.instant('messageErrorNotAlphanumeric');
                    }
                } else {
                    passes = false;
                    error = $translate.instant('messageErrorRequiredFieldEmpty');
                }
            } else if (value && value.length > 0) {
                if (value.length < minLength) {
                    passes = false;
                    error = $translate.instant('messageErrorTooShort');
                }

                if (maxlength >= minLength && value.length > maxlength) {
                    passes = false;
                    error = $translate.instant('messageErrorTooBig');
                }

                regex = /^[a-z0-9]+$/i;
                if (!regex.test(value)) {
                    passes = false;
                    error = $translate.instant('messageErrorNotAlphanumeric');
                }
            }

            if (passes) {
                if (value.length <= 0) {
                    $scope.resetInputState(inputID);
                } else {
                    $scope.setInputValidated(inputID);
                }
            } else {
                $scope.setInputError(inputID);
            }
            return error;
        };

        $scope.validatePasswordInput = function (inputID, value, minLength, maxlength, requiresCapital, requiresSymbol, required) {
            let passes = true;
            let error;

            if (required) {
                if (value) {
                    if (value.length < minLength) {
                        passes = false;
                        error = $translate.instant('messageErrorPasswordTooShort');
                    }

                    if (maxlength >= minLength && value.length > maxlength) {
                        passes = false;
                        error = $translate.instant('messageErrorPasswordTooBig');
                    }

                    if (requiresCapital) {
                        let upperCase = new RegExp('[A-Z]');
                        if (!value.match(upperCase)) {
                            passes = false;
                            error = $translate.instant('messageErrorPasswordRequiresCapital');
                        }
                    }

                    if (requiresSymbol) {
                        let symbols = new RegExp('(?=.*[!@#$%^&*])');
                        if (!value.match(symbols)) {
                            passes = false;
                            error = $translate.instant('messageErrorPasswordRequiresSymbol');
                        }
                    }
                } else {
                    passes = false;
                    error = $translate.instant('messageErrorRequiredFieldEmpty');
                }
            } else if (value && value.length > 0) {
                if (value.length < minLength) {
                    passes = false;
                    error = $translate.instant('messageErrorPasswordTooShort');
                }

                if (maxlength >= minLength && value.length > maxlength) {
                    passes = false;
                    error = $translate.instant('messageErrorPasswordTooBig');
                }

                if (requiresCapital) {
                    const upperCase = new RegExp('[A-Z]');
                    if (!value.match(upperCase)) {
                        passes = false;
                        error = $translate.instant('messageErrorPasswordRequiresCapital');
                    }
                }

                if (requiresSymbol) {
                    const symbols = new RegExp('(?=.*[!@#$?%^&*])');
                    if (!value.match(symbols)) {
                        passes = false;
                        error = $translate.instant('messageErrorPasswordRequiresSymbol');
                    }
                }
            }

            if (passes) {
                if (value.length <= 0) {
                    $scope.resetInputState(inputID);
                } else {
                    $scope.setInputValidated(inputID);
                }
            } else {
                $scope.setInputError(inputID);
            }
            return error;
        };

        $scope.validatePasswordConfirmationInput = function (
            passwordInputID,
            PasswordConfirmationInputID,
            value,
            minLength,
            maxlength,
            requiresCapital,
            requiresSymbol,
            required
        ) {
            let passes = true;
            let error;

            if (required) {
                if (value) {
                    if (value.length < minLength) {
                        passes = false;
                        error = $translate.instant('messageErrorPasswordTooShort');
                    }

                    if (maxlength >= minLength && value.length > maxlength) {
                        passes = false;
                        error = $translate.instant('messageErrorPasswordTooBig');
                    }

                    if (requiresCapital) {
                        let upperCase = new RegExp('[A-Z]');
                        if (!value.match(upperCase)) {
                            passes = false;
                            error = $translate.instant('messageErrorPasswordRequiresCapital');
                        }
                    }

                    if (requiresSymbol) {
                        let symbols = new RegExp('(?=.*[!@#$%^&*])');
                        if (!value.match(symbols)) {
                            passes = false;
                            error = $translate.instant('messageErrorPasswordRequiresSymbol');
                        }
                    }

                    let passwordValue = document.getElementById(passwordInputID).value;
                    if (value != passwordValue) {
                        passes = false;
                        error = $translate.instant('messageErrorChangePasswordMismatch');
                    }
                } else {
                    passes = false;
                    error = $translate.instant('messageErrorRequiredFieldEmpty');
                }
            } else if (value && value.length > 0) {
                if (value.length < minLength) {
                    passes = false;
                    error = $translate.instant('messageErrorPasswordTooShort');
                }

                if (maxlength >= minLength && value.length > maxlength) {
                    passes = false;
                    error = $translate.instant('messageErrorPasswordTooBig');
                }

                if (requiresCapital) {
                    const upperCase = new RegExp('[A-Z]');
                    if (!value.match(upperCase)) {
                        passes = false;
                        error = $translate.instant('messageErrorPasswordRequiresCapital');
                    }
                }

                if (requiresSymbol) {
                    const symbols = new RegExp('(?=.*[!@#$?%^&*])');
                    if (!value.match(symbols)) {
                        passes = false;
                        error = $translate.instant('messageErrorPasswordRequiresSymbol');
                    }
                }

                let passwordValue = document.getElementById(passwordInputID).value;
                if (value != passwordValue) {
                    passes = false;
                    error = $translate.instant('messageErrorChangePasswordMismatch');
                }
            }

            if (passes) {
                if (value.length <= 0) {
                    $scope.resetInputState(PasswordConfirmationInputID);
                } else {
                    $scope.setInputValidated(PasswordConfirmationInputID);
                }
            } else {
                $scope.setInputError(PasswordConfirmationInputID);
            }
            return error;
        };

        $scope.validateEmailInput = function (inputID, value, required) {
            let passes = true;
            let error;

            if (required) {
                if (value && value.length > 0) {
                    const isEmail = validateEmail(value);
                    if (!isEmail) {
                        passes = false;
                        error = $translate.instant('messageErrorInvalidEmail');
                    }
                } else {
                    passes = false;
                    error = $translate.instant('messageErrorRequiredFieldEmpty');
                }
            } else if (value && value.length > 0) {
                const isEmail = validateEmail(value);
                if (!isEmail) {
                    passes = false;
                    error = $translate.instant('messageErrorInvalidEmail');
                }
            }

            // if there is an inputID, add or remove styling classes
            if (inputID.length > 0) {
                if (passes) {
                    if (value.length <= 0) {
                        $scope.resetInputState(inputID);
                    } else {
                        $scope.setInputValidated(inputID);
                    }
                } else {
                    $scope.setInputError(inputID);
                }
            }
            return error;
        };

        function validateEmail(email) {
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
                email
            );
        }

        $scope.validateTimezoneInput = function (inputID, value, required) {
            let passes = true;
            let error;
            const possibleValues = ['', 'africa/tunis', 'america/edmonton', 'america/montreal', 'america/vancouver'];

            if (required && !value) {
                passes = false;
                error = $translate.instant('messageErrorRequiredFieldEmpty');
            }

            if (!possibleValues.some((possibleValue) => possibleValue === value)) {
                passes = false;
                error = $translate.instant('messageErrorInvalidTimezone');
            }

            if (passes) {
                if (value.length <= 0) {
                    $scope.resetInputState(inputID);
                } else {
                    $scope.setInputValidated(inputID);
                }
            } else {
                $scope.setInputError(inputID);
            }

            return error;
        };

        $scope.setInputValidated = function (inputID) {
            let input = document.getElementById(inputID);
            input.classList.add('validatedInput');
            input.classList.remove('errorInput');
        };

        $scope.setInputError = function (inputID) {
            let input = document.getElementById(inputID);
            input.classList.add('errorInput');
            input.classList.remove('validatedInput');
        };

        $scope.resetInputState = function (inputID) {
            let input = document.getElementById(inputID);
            input.classList.remove('errorInput');
            input.classList.remove('validatedInput');
        };

        /* String functions **********************************************/
        /**
         * Replaces {number} in string via associative array keyed by numbers.
         * {?1} will be replaced if arg {"1": ...} exists, will be deleted otherwise.
         * @param string
         * @param args (object)
         * @returns {*}
         */
        $scope.formatString = function (string, args) {
            let formatted_string = string;
            let regex = new RegExp('{{1}(\\?)?(\\d}){1}', 'g');
            let matches = string.match(regex);
            if (matches != null) {
                for (let i = 0; i < matches.length; i++) {
                    // Replace placeholder if argument exists
                    if (typeof args[i] !== 'undefined') {
                        formatted_string = formatted_string.replace(matches[i], args[i]);
                    } else if (matches[i].includes('?')) {
                        formatted_string = formatted_string.replace(matches[i], '');
                    }
                }
            }

            return formatted_string;
        };
    },
]);
