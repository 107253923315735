/**
 * @param {Boolean} show Whether th display the component or not
 * @param {String} color color of the dots
 * @param {String} size Size of the component ( big, medium, small)
 * @returns {HTMLElement}
 */

angular.module('mTransportApp').component('loadingDots', {
    templateUrl: './shared/loadingDots/loadingDots.html',
    transclude: true,
    bindings: {
        show: '<',
        color: '@',
        size: '@',
    },
    controller: function () {
        this.$onInit = function () {
            this.color = this.color ?? 'blue';
            this.size = this.size ?? 'big';
        };
    },
});
